import axios from "axios";
import {
    arrayPush,
    arrayRemove,
    change,
    formValueSelector,
} from 'redux-form';

import { selectTab } from './../operacao/tabs/TabsAction';
import { showAllMessages } from './../../actions/message/ErrorMessagesAPIAction';
import { ATIVO_TAB } from '../../utils/tabs';
import { showConfirmDialog } from './../common/modal/ConfirmDialogAction';
import { downloadExcelFile } from '../../utils/helpers/FileHelper';

import history from './../../components/common/routers/history';
import {
    OPERACAO_ROUTE_PATH,
    ATIVO_ROUTE_PATH,
    VINCULAR_ROUTE_PATH,
    LISTA_ROUTE_PATH,
    DETALHAR_ROUTE_PATH,
    EDITAR_ROUTE_PATH,
    NOVO_ROUTE_PATH,
    ATIVO_OPERACAO_ROUTE_PATH,
} from "./../../components/common/routers/constantesRoutes";

import {
    serializeFieldsAtivoForm,
    deserializeFieldsAtivoForm,
    deserializeAtivosVinculados
} from "./../../components/business/ativo/mappings/AtivoMap";
import { toastr } from "react-redux-toastr";

export const SERVICER = 7;

export const ATIVO_FORM_TAB = 'ATIVO_FORM_TAB';
export const SELECTED_ATIVO_OPTION = 'SELECTED_ATIVO_OPTION';
export const ATIVO_OPTIONS_FETCHED = 'ATIVO_OPTIONS_FETCHED';
export const ATIVO_OPERACAO_FETCHED = 'ATIVO_FETCHED';
export const ATIVO_FETCHED = 'ATIVO_FETCHED';
export const ATIVO_SHOW_LIST = 'ATIVO_SHOW_LIST';
export const ATIVO_SHOW_FORM_VINCULO_OPERACAO = 'ATIVO_SHOW_FORM_VINCULO_OPERACAO';
export const ATIVO_NAO_VINCULADO_OPTIONS_FETCHED = 'ATIVO_NAO_VINCULADO_OPTIONS_FETCHED';
export const SELECTED_ATIVO_NAO_VINCULADO_OPTIONS = 'SELECTED_ATIVO_NAO_VINCULADO_OPTIONS';
export const ATIVO_VINCULADO_OPERACAO_FETCHED = 'ATIVO_VINCULADO_OPERACAO_FETCHED';
export const ATIVO_SHOW_DELETE_DIALOG = 'ATIVO_SHOW_DELETE_DIALOG';
export const LOAD_FORM_ATIVO = "LOAD_FORM_ATIVO";
export const LOAD_FORM_ATIVO_MOEDA = "LOAD_FORM_ATIVO_MOEDA";
export const ATIVO_CLEAN_LIST = "ATIVO_CLEAN_LIST";

const ATIVO_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_ATIVO_API;

const OPERACAO_API = process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_OPERACAO_API;

export function mudaDependenteOnChange(dependente, value = null) {
  return (dispatch) => {
    dispatch([change("ativoForm", dependente, value)]);
  };
}

export function getSugestaoAtivo(term) {
    return (dispatch) => {
        return axios
            .get(`${ATIVO_API}sugestoes?termo=${term}`)
            .then(result =>
                dispatch({
                    type: ATIVO_OPTIONS_FETCHED,
                    payload: result.content
                })
            );
    };
}

export function getAtivos(idsAtivo, page = 1) {
    let params = idsAtivo.length > 0 ? idsAtivo : 0;

    return (dispatch) => {
        return axios
            .get(`${ATIVO_API}?idsAtivos=${params}&pagina=${page}&tamanhoPagina=10`)
            .then(result =>
                dispatch(
                    {
                        type: ATIVO_FETCHED,
                        payload: result
                    }
                )
            );
    };
}

export function getAtivosOperacao(idsOperacoes, page = 1) {
    let params = idsOperacoes.length > 0 ? idsOperacoes : 0;

    return (dispatch) => {
        return axios
            .get(OPERACAO_API + `${params}/ativos?pagina=${page}&tamanhoPagina=10`)
            .then(result =>
                dispatch({
                    type: ATIVO_OPERACAO_FETCHED,
                    payload: result
                })
            );
    }
}

export function getAtivo(idAtivo) {
    return (dispatch) => {
        return axios
            .get(ATIVO_API + idAtivo)
            .then(result =>
                dispatch([serializeFieldsAtivoForm(result.content)])
            )
    }
}

export function changeMoeda(simbolo) {
    return (dispatch) => {
        return dispatch({
            type: LOAD_FORM_ATIVO_MOEDA,
            payload: simbolo || null
        })
    }  
}

export function loadFormAtivo(idAtivo, editAction) {
    return (dispatch) => {
        return axios.get(ATIVO_API + `tela/${idAtivo || ''}`)
            .then(result => {
                let routeAction = editAction ? EDITAR_ROUTE_PATH : DETALHAR_ROUTE_PATH;

                dispatch([
                    idAtivo ? history.push(`/${ATIVO_ROUTE_PATH}/${routeAction}/${idAtivo}`) : undefined,
                    {
                        type: LOAD_FORM_ATIVO,
                        payload: result.content
                    },
                    serializeFieldsAtivoForm(result.content.ativo)
                ])
            });
    };
}

export function newAtivoForm() {
    return dispatch => {
        return dispatch([
            loadFormAtivo(),
            history.push(`/${ATIVO_ROUTE_PATH}/${NOVO_ROUTE_PATH}`)
        ]);
    };
}

export function getAtivosNaoVinculados(term) {
    return (dispatch) => {
        return axios
            .get(`${ATIVO_API}/sugestoesnaovinculado?termo=${term}`)
            .then(result =>
                dispatch({
                    type: ATIVO_NAO_VINCULADO_OPTIONS_FETCHED,
                    payload: result.content
                })
            );
    }
}

export function saveAtivo(ativo) {
    const ativoDeserialized = deserializeFieldsAtivoForm(ativo);

    return (dispatch) => {
        return axios
            .post(ATIVO_API, { ...ativoDeserialized })
            .then(result =>
                dispatch([
                    showAllMessages(result.messages),
                    goBackToList()
                ])
            );
    };
}

export function editAtivo(ativo) {
    const ativoDeserialized = deserializeFieldsAtivoForm(ativo);
    let operationIds = [];
    let { codigoB3CetipList, codigoB3CetipDesvinculadoList } = ativoDeserialized;

    Array.from(codigoB3CetipList).forEach((item) => {
        if (item.operacaoCodigoB3CetipList) {
            Array.from(item.operacaoCodigoB3CetipList).forEach((op) => operationIds.push(op.idOperacao))
        }
    })

    Array.from(codigoB3CetipDesvinculadoList).filter((item) => item.idOperacao).forEach((item) => {
        operationIds.push(item.idOperacao);
    })

    let operacaoIds = [...new Set(operationIds)];

    return (dispatch) => {
        return axios
            .put(ATIVO_API, { ...ativoDeserialized })
            .then(result =>
                dispatch([
                    saveSegmentoOperacao(operacaoIds),
                    showAllMessages(result.messages),
                    goBackToList()
                ])
            );
    };
}

export function removeAtivo(idAtivo, idsAtivos) {
    return (dispatch) => {
        return axios
            .delete(ATIVO_API + idAtivo)
            .then(result =>
                dispatch([
                    showAtivoDeleteDialog(false, 0),
                    showAtivoList(true, idsAtivos),
                    showAllMessages(result.messages)
                ])
            );
    }
}

export function addAtivoNaoVinculado(idsAtivos) {
    return (dispatch) => {
        return axios
            .get(`${ATIVO_API}/codigob3cetip?idsAtivos=${(idsAtivos && idsAtivos.length > 0) ? idsAtivos : 0}&pagina=1&tamanhoPagina=10`)
            .then(result =>
                dispatch(
                    {
                        type: ATIVO_VINCULADO_OPERACAO_FETCHED,
                        payload: result.content
                    }
                )
            );
    }
}

export function saveAtivosVinculados(ativos, idOperacao) {
    const ativosVinculadosDeserialized = deserializeAtivosVinculados(ativos, idOperacao);

    let operacaoIds = [...new Set(ativosVinculadosDeserialized.map((item) => item.idOperacao))];

    return (dispatch) => {
        return axios
            .post(`${OPERACAO_API}vinculo/ativos`, { vinculoAtivos: ativosVinculadosDeserialized })
            .then(result =>
                dispatch([
                    showAllMessages(result.messages),
                    saveSegmentoOperacao(operacaoIds),
                    showAtivoOperacaoList(true),
                    history.push(`/${OPERACAO_ROUTE_PATH}/${ATIVO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`),
                ])
            );
    }
}

export function saveSegmentoOperacao(ids) {
    return (dispatch) => {
        return axios
            .post(`${OPERACAO_API}segmento`, { operacaoIds: ids })
            .then(result =>
                dispatch([
                    showAllMessages(result.messages),
                ])
            );
    }
}

export function selectedAtivoOption(option) {
    let ativosSelected = option ? option.map(o => o.key) : [];

    return (dispatch) => {
        return dispatch([
            {
                type: SELECTED_ATIVO_OPTION,
                payload: option
            },
            showAtivoList(true, ativosSelected)
        ])
    }
}

export function selectedAtivoNaoVinculadoOption(option) {
    let idsAtivos = option
        ? option.map(o => o.key)
        : [];

    return (dispatch) => {
        return dispatch([
            {
                type: SELECTED_ATIVO_NAO_VINCULADO_OPTIONS,
                payload: option
            },
            addAtivoNaoVinculado(idsAtivos)
        ])
    }
}

export function addCodigoB3Cetip(codigoB3Cetip) {
    return (dispatch) => {
        if (codigoB3Cetip) {
            return dispatch([
                arrayPush("ativoForm", "codigoB3CetipList", codigoB3Cetip),
                change("ativoForm", "codigoB3Cetip", {}),
            ]);
        } else {
            return toastr.error("Erro", "Preencha os dados acima");
        }
    }
}

export function removeCodigoB3Cetip(index) {
    return (dispatch) => {
        return dispatch(
            arrayRemove("ativoForm", "codigoB3CetipList", index)
        );
    }
}

export function disassociateCodigoB3Cetip(itemDesvinculado) {
    return (dispatch, getState) => {
        const selector = formValueSelector('ativoForm')
        let listaDeRegra = selector(getState(), 'codigoB3CetipList') || null;

        const index = listaDeRegra.findIndex(item =>
            item.codigo === itemDesvinculado.codigo);

        return dispatch([
            change("ativoForm", `codigoB3CetipList[${index}].operacaoCodigoB3CetipList`, []),
            change("ativoForm", `codigoB3CetipList[${index}].codigoOpeaAssociadoList`, []),
            arrayPush("ativoForm", `codigoB3CetipDesvinculadoList`, ...itemDesvinculado.operacaoCodigoB3CetipList),
        ])
    }
}

export function showAtivoList(showList, idsAtivo) {
    return (dispatch) => {
        return dispatch([
            {
                type: ATIVO_SHOW_LIST,
                payload: showList
            },
            getAtivos(idsAtivo)
        ])
    }
}

export function goBackToList() {
    return (dispatch) => {
        return dispatch([
            showConfirmDialog(false),
            { type: ATIVO_CLEAN_LIST },
            history.push(`/${ATIVO_ROUTE_PATH}`)
        ])
    }
}

export function showAtivoOperacaoList(showList) {
    return (dispatch) => {
        return dispatch([
            showConfirmDialog(false),
            history.push(`/${OPERACAO_ROUTE_PATH}/${ATIVO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`),
        ])
    }
}

export function showAtivoFormVinculoOperacao(showForm) {
    return (dispatch) => {
        return dispatch([
            {
                type: ATIVO_SHOW_FORM_VINCULO_OPERACAO,
                payload: showForm
            },
            history.push(`/${OPERACAO_ROUTE_PATH}/${ATIVO_OPERACAO_ROUTE_PATH}/${VINCULAR_ROUTE_PATH}`),
        ])
    }
}

export function changeAtivoFormTab(formTab) {
    return (dispatch) => {
        return dispatch([
            {
                type: ATIVO_FORM_TAB,
                payload: formTab
            }
        ])
    }
}

export function showAtivoTabActive() {
    return dispatch => {
        return dispatch([selectTab(ATIVO_TAB), showAtivoOperacaoList(true)]);
    };
}

export function showAtivoDeleteDialog(showDialog, idAtivo) {
    return (dispatch) => {
        return dispatch([
            {
                type: ATIVO_SHOW_DELETE_DIALOG,
                payload: { showDialog, idAtivo }
            }
        ])
    }
}

export function importarAtivosPorPlanilha(file) {
    let formData = new FormData();
    formData.append("file", file);

    return dispatch => {
        return axios
            .post(`${ATIVO_API}importar/planilha`, formData)
            .then(result => {
                let operacaoIds = [...new Set(result.content.map((item) => item.idOperacao))];
                dispatch([
                    // vincularCodigoB3CetipAOperacao(result.content),
                    salvarAtivosVinculados(result.content, operacaoIds),
                ])
            });
    };
}

export function salvarAtivosVinculados(ativos, operacaoIds) {
    return (dispatch) => {
        return axios
            .post(`${OPERACAO_API}vinculo/ativos`, { vinculoAtivos: ativos })
            .then(result =>
                dispatch([
                    showAllMessages(result.messages),
                    saveSegmentoOperacao(operacaoIds),
                    showAtivoOperacaoList(true),
                    history.push(`/${OPERACAO_ROUTE_PATH}/${ATIVO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`),
                ])
            );
    }
}

// export function vincularCodigoB3CetipAOperacao(codigoB3CetipList) {
//     return dispatch => {
//         return axios
//             .post(`${ATIVO_API}codigob3cetip/vinculo/operacao`, codigoB3CetipList)
//             .then(result => {
//                 dispatch([
//                     showAllMessages(result.messages),
//                 ])
//             });
//     };
// }

export function exportModeloAtivo() {
    return (dispatch) => {
        return axios
            .get(`${ATIVO_API}exportar/excel/modelo`)
            .then(result => {
                const fileName = 'ModeloAtivo.xlsx';
                dispatch([
                    downloadExcelFile(result.content, fileName),
                    showAllMessages(result.messages),
                ])
            }); 

    }
}

export function exportPlanilhaAtivosVinculadosAOperacao(idsOperacoes) {
    let params = idsOperacoes.length > 0 ? idsOperacoes : 0;

    return (dispatch) => {
        return axios
            .get(OPERACAO_API + `export/planilha/ativos/${params}`)
            .then(result => {
                const fileName = 'Ativos_Planilha.xlsx';
                dispatch([
                    downloadExcelFile(result.content, fileName),
                    showAllMessages(result.messages),
                ])
            });
    }
}
