import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { loadFormErro } from "../../../../../actions/precificacao/ErroAction";

import ContainerPage from "../../../../common/container/ContainerPage";
import ErroForm from "./form/ErroForm";

class ErroVisualizePage extends Component {
  componentDidMount() {
    if (!this.props.erro.id) {
      let idErro = this.props.match.params.idErro;
      this.props.loadFormErro(idErro);
    }
  }

  render() {
    return (
      <ContainerPage>
        <ErroForm title={"DETALHE - Erros do Sistema"} readOnly={true} floatButtonVisible={false} />
      </ContainerPage>
    );
  }
}

const mapStateToProps = (state) => ({
  erro: state.erroReducer.erroForm,
});
const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormErro,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(ErroVisualizePage));
