import axios from "axios";
import history from "../../components/common/routers/history";
import { deserializeFieldsAprovadoresFormFilter, serializeFieldsAprovadoresForm, deserializeFieldsAprovadoresForm } from "../../components/business/aprovadores/mappings/AprovadoresMap";
import { showAllMessages } from "../message/ErrorMessagesAPIAction";

import {
  APROVADORES_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
  LISTA_ROUTE_PATH,
  NOVO_ROUTE_PATH,
} from "../../components/common/routers/constantesRoutes";

import { showConfirmDialog } from "../common/modal/ConfirmDialogAction";

export const APROVADORES_RESET = "APROVADORES_RESET";
export const APROVADORES_CONSULT_CURRENT = "APROVADORES_CONSULT_CURRENT";
export const APROVADORES_FETCHED = "APROVADORES_FETCHED";
export const SALVA_FILTROS_APROVADORES = "SALVA_FILTROS_APROVADORES";
export const LOAD_FILTER_FORM_APROVADORES = "LOAD_FILTER_FORM_APROVADORES";
export const LOAD_FORM_APROVADORES = "LOAD_FORM_APROVADORES";

const APROVADORES_API =
  process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_APROVADORES_API;

export function loadFilterFormAprovadores() {
  return (dispatch, getState) => {
    return axios.get(APROVADORES_API + "tela").then((result) =>
      dispatch([
        {
          type: LOAD_FILTER_FORM_APROVADORES,
          payload: result.content,
        },
      ])
    );
  };
}

export function salvaFiltrosAprovadores(filters) {
  const params = deserializeFieldsAprovadoresFormFilter(filters, 1);

  return (dispatch, getState) => {
    return dispatch([
      {
        type: SALVA_FILTROS_APROVADORES,
        payload: params,
      },
    ]);
  };
}

export function getAprovadores(filters, page = 1) {
  const params = deserializeFieldsAprovadoresFormFilter(filters, page);

  return (dispatch) => {
    return axios.post(`${APROVADORES_API}busca`, params).then((result) =>
      dispatch([
        {
          type: APROVADORES_FETCHED,
          payload: result,
        },
        {
          type: APROVADORES_CONSULT_CURRENT,
          payload: {
            ...filters,
            pagingConfig: { pageIndex: page, pageSize: 10 },
          },
        },
      ])
    );
  };
}

export function sairDaPagina() {
  return (dispatch) => {
    return dispatch([
      { type: APROVADORES_RESET },
      showConfirmDialog(false),
      history.push(`/${APROVADORES_ROUTE_PATH}/${LISTA_ROUTE_PATH}`),
    ]);
  };
}

export function editAprovador(payload) {
  let params = deserializeFieldsAprovadoresForm(payload);

  return (dispatch) => {
    return axios.put(APROVADORES_API, params).then((result) => {
      dispatch([
        showAllMessages(result.messages),
        sairDaPagina(),
        getAprovadores(),
      ]);
    });
  };
}

export function saveAprovador(payload) {
  let params = deserializeFieldsAprovadoresForm(payload);

  return (dispatch) => {
    return axios.post(APROVADORES_API, params).then((result) => {
      dispatch([
        showAllMessages(result.messages),
        sairDaPagina(),
        getAprovadores(),
      ]);
    });
  };
}

export function loadFormAprovadores(id) {
  let appendUrl = "tela";

  if (id) appendUrl = appendUrl + `/${id}`;

  return (dispatch) => {
    return axios.get(APROVADORES_API + appendUrl).then((result) => {
      let routeAction = id ? `${EDITAR_ROUTE_PATH}/${id}` : NOVO_ROUTE_PATH;

      dispatch([
        history.push(`/${APROVADORES_ROUTE_PATH}/${routeAction}`),
        {
          type: LOAD_FORM_APROVADORES,
          payload: result.content
        },
        serializeFieldsAprovadoresForm(result.content?.aprovador)
      ])
    });
  };
}
