import axios from "axios";
import history from "../../components/common/routers/history";
import { deserializeFieldsAprovacaoFormFilter, deserializeModalPedidoCompra } from "../../components/business/aprovacao/mappings/AprovacaoMap";
import { showAllMessages } from "../message/ErrorMessagesAPIAction";

import {
  APROVACAO_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
  LISTA_ROUTE_PATH,
  OPERACAO_ROUTE_PATH,
  PASSIVO_ROUTE_PATH,
} from "../../components/common/routers/constantesRoutes";

import { downloadExcelFile } from "../../utils/helpers/FileHelper";
import { change, formValueSelector } from "redux-form";

export const APROVACAO_RESET = "APROVACAO_RESET";
export const APROVACOES_CONSULT_CURRENT = "APROVACOES_CONSULT_CURRENT";
export const APROVACOES_FETCHED = "APROVACOES_FETCHED";
export const SALVA_FILTROS_APROVACAO = "SALVA_FILTROS_APROVACAO";
export const LOAD_FILTER_FORM_APROVACAO = "LOAD_FILTER_FORM_APROVACAO";
export const LOAD_CONTRATO_DESPESA_APROVACAO = "LOAD_CONTRATO_DESPESA_APROVACAO";
export const LOAD_PEDIDO_COMPRA_APROVACAO = "LOAD_PEDIDO_COMPRA_APROVACAO";

const APROVACAO_API =
  process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_APROVACAO_API;

const OPERACAO_API =
  process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_OPERACAO_API;

const DESPESA_API = OPERACAO_API + process.env.REACT_APP_PATH_DESPESA_API;

const PEDIDO_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_PEDIDO_COMPRA_API;

export function loadFilterFormAprovacao() {
  return (dispatch, getState) => {
    return axios.get(APROVACAO_API + "filtro").then((result) =>
      dispatch([
        {
          type: LOAD_FILTER_FORM_APROVACAO,
          payload: result.content,
        },
      ])
    );
  };
}

export function salvaFiltrosAprovacao(filters) {
  const params = deserializeFieldsAprovacaoFormFilter(filters, 1);

  return (dispatch, getState) => {
    return dispatch([
      {
        type: SALVA_FILTROS_APROVACAO,
        payload: params,
      },
    ]);
  };
}

export function exportAprovacaoExcel(filters) {
  const params = deserializeFieldsAprovacaoFormFilter(filters, 1);

  return (dispatch) => {
      return axios
          .post(`${APROVACAO_API}exportar/excel`, params)
          .then(result => {
              dispatch([
                  showAllMessages(result.messages),
              ])

              if (result.content) {
                const { content } = result;

                dispatch([
                  content ? downloadExcelFile(content?.arquivo, `${content?.nomeArquivo}.${content?.extensaoArquivo}`) : null,
                ])
              }
          }); 

  }
}

export function actionModal(params) {
  return (dispatch) => {
    return axios.post(`${APROVACAO_API}${params?.action}`, { ...params }).then((result) =>
      dispatch([
        showAllMessages(result.messages),
      ])
    );
  };
}

export function salvaStatus(params) {
  return (dispatch) => {
    return axios.post(`${APROVACAO_API}status`, { ...params }).then((result) =>
      dispatch([
        showAllMessages(result.messages),
      ])
    );
  };
}

export function getAprovacoes(filters, page = 1) {
  const params = deserializeFieldsAprovacaoFormFilter(filters, page);

  return (dispatch) => {
    updateFilters()

    return axios.post(`${APROVACAO_API}busca`, { ...params }).then((result) =>
      dispatch([
        {
          type: LOAD_CONTRATO_DESPESA_APROVACAO,
          payload: {},
        },
        {
          type: LOAD_PEDIDO_COMPRA_APROVACAO,
          payload: {},
        },
        // 
        {
          type: APROVACOES_FETCHED,
          payload: result,
        },
        {
          type: APROVACOES_CONSULT_CURRENT,
          payload: {
            ...filters,
            pagingConfig: { pageIndex: page, pageSize: 10 },
          },
        },
        updateFilters(),
      ])
    );
  };
}

export function sairDaPagina() {
  return (dispatch) => {
    return dispatch([
      { type: APROVACAO_RESET },
      history.push(`/${APROVACAO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`),
    ]);
  };
}

export function goToFormPage(idPassivo) {
  return (dispatch) => {
    return dispatch([
      history.push(
        `/${OPERACAO_ROUTE_PATH}/${PASSIVO_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/${idPassivo}`
      ),
    ]);
  };
} 

// export function loadPassivoAprovacao(id) {
//   return (dispatch) => {
//     return axios.get(PASSIVO_API + `aprovacao/${id}`).then((result) => {
//       dispatch([
//         {
//           type: LOAD_CONTRATO_DESPESA_APROVACAO,
//           payload: result.content,
//         },
//       ]);
//     });
//   };
// }

function updateFilters() {
  return (dispatch, getState) => {
    const aprovacoesConsultCurrent = getState().aprovacaoReducer.aprovacoesConsultCurrent

    if (!aprovacoesConsultCurrent) {
      return;
    } else {
      const { dataInicio, dataFinal, idCadastro } = aprovacoesConsultCurrent
  
      dispatch([
        change('AprovacaoForm', 'dataInicio', dataInicio || ''),
        change('AprovacaoForm', 'dataFinal', dataFinal || ''),
        change('AprovacaoForm', 'idCadastro', idCadastro || ''),
      ]);
    }
  }
}

export function loadContratoDespesa(id) {
  return (dispatch) => {
    updateFilters()

    return axios.get(DESPESA_API + `modal/${id}`).then((result) => {
      dispatch([
        {
          type: LOAD_CONTRATO_DESPESA_APROVACAO,
          payload: result.content,
        },
        updateFilters()
      ]);
    });
  };
}

export function loadPedidoCompra(id) {
  return (dispatch) => {
    return axios.get(PEDIDO_API + `modal/${id}`).then((result) => {
      const values = deserializeModalPedidoCompra(result.content);

      dispatch([
        {
          type: LOAD_PEDIDO_COMPRA_APROVACAO,
          payload: values,
        },
      ]);
    });
  };
}
