import React from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { white } from "material-ui/styles/colors";
import Popover from '@material-ui/core/Popover';
import If from "../If/If";
import './input.css';

export default (props) => {

    const theme = createMuiTheme({
        palette: {
            primary: {
                main: "#0290FF",
                contrastText: white
            },
        },
        typography: {
            button: {
                textTransform: 'uppercase'
            }
        }
    });

    const anchorELRef = document.getElementById('lb-cp-link');
    let classNameInput = (className) => {
        let name = 'form-control';

        if (className) {
            name += ' ' + className;
        }

        return name;
    }

    const [open, setOpen] = React.useState(false);
    const [{ }, setValueToCopy] = React.useState({ value: props.input.value, copied: false });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    let performCopy = () => {
        setValueToCopy({ value: props.input.value, copied: true });
        setOpen(false)
    }

    const modal = (
        <Popover
            open={open}
            anchorEl={anchorELRef}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={handleClose}>
            <div className="p-2 d-flex align-items-center flex-column">
                <DialogContentText id="alert-dialog-description" className="popover-content">
                    {props.input.value}
                </DialogContentText>
                <MuiThemeProvider theme={theme} className="pt-2 ">
                    <CopyToClipboard text={props.input.value}
                        onCopy={() => performCopy()}>
                        <Button variant="contained" color="primary">
                            Copiar
                             </Button>
                    </CopyToClipboard>
                </MuiThemeProvider>
            </div>


        </Popover>
    );

    return (
        <div className={`form-group field-floating ${props.optionalClass || ''}`}>
            <If test={props.copyLink}>
                <a className="pointer" onClick={props.input.value ? handleClickOpen : undefined} >
                    <i id="lb-cp-link" aria-hidden="true"
                        className="fa fa-link p-color-imp fa-rotate-90 link-input pointer"
                    />
                </a>
                {modal}
            </If>
            <input
                {...props.input}
                className={classNameInput(props.className) + ' col-11 link-col'}
                id={"lnk-txt-input_" + props.inputId}
                type={props.type}
                readOnly={props.readOnly}
                placeholder={props.placeholder}
                max={props.max}
                mask={props.mask}
            />
            <label
                className={`control-label ${props.classLabel || ''}`}
                htmlFor={props.name}
                id={"lbl_" + props.inputId}
            >
                {props.label}
                {props.required ? <span>*</span> : ""}
            </label>
        </div>
    );
}
