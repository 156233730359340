import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import GenericPickerInput from "../../common/input/GenericPickerInput";

import { 
    showSubclasseList, 
    getSugestaoSubclasse,
    selectedSubclasseOption,
 } from "../../../actions/subclasse/SubclasseAction";

const SubclassePicker = (props) => (
    <div>
        <div>
            <GenericPickerInput 
                loadData={props.getSugestaoSubclasse}
                options={props.options}
                selectedOption={props.selectedSubclasseOption}                
                placeholder={"Pesquisar Subclasse"}
                loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                noOptionsMessage="Nenhum registro encontrado"
                isMulti={true}
                activeClassName={props.classes}
                value={props.subclasses}
            />
        </div>
    </div>
);

const mapDispatchToProps = dispatch => bindActionCreators({
    getSugestaoSubclasse,
    selectedSubclasseOption,
    showSubclasseList,
}, dispatch);

const mapStateToProps = state => ({
    options: state.subclasseReducer.subclassesOptions,
    subclasses: state.subclasseReducer.selectedSubclasseOptions
});

export default connect(mapStateToProps, mapDispatchToProps)(SubclassePicker);
