import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Table from '../../../../../common/table/Table';
import TableHeader from '../../../../../common/table/TableHeader';
import TableContent from '../../../../../common/table/TableContent';
import TableEmptyData from '../../../../../common/table/TableEmptyData';
import {
    getPatrimonios,
    goToFormPagePatrimonio
} from '../../../../../../actions/operacao/PassivoAction';
import { PATRIMONIO } from '../../../../../../utils/actionsAndFeaturesAuth';
import TableActionWithRouteAuthorization from '../../../../../common/table/TableActionWithRouteAuthorization';
import { authorizationFunction } from '../../../../../../actions/authorization/AuthorizationAction';
import If from '../../../../../common/If/If';

class PatrimonioList extends Component {
    getIdsOperacao = () => {
        let idsOperacao = 0;

        if (Array.isArray(this.props.operacoes) && this.props.operacoes.length) {
            idsOperacao = this.props.operacoes.map(o => o.key);    
        }

        return idsOperacao;
    }

    updateData = () => {
        this.props.getPatrimonios(this.getIdsOperacao());
    }

    componentDidMount() {
        this.updateData();
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.operacoes.length !== this.props.operacoes.length) {
            this.updateData();
        }
    }

    render() {
        const {
            patrimonioData,
            messages,
            goToFormPagePatrimonio,
        } = this.props;

        const emptyData = messages && messages.infos && messages.infos[0];

        const tableHeader = (
            <tr>
                <th className="text-center fw-700">Patrimônio Separado</th>
                <th className="text-center fw-700">Quantidade de Emissões</th>
                <th className="text-center fw-700">Quantidade de Séries</th>
                <th className="text-center"></th>
            </tr>
        );

        const tableContent = (
            patrimonioData && patrimonioData.length > 0
                ? patrimonioData.map(patrimonio =>
                    (<tr key={patrimonio.CodigoOpea}>
                        <td className="text-center fw-400">{patrimonio.patrimonioSeparado}</td>
                        <td className="text-center fw-400">{patrimonio.quantidadeEmissoes}</td>
                        <td className="text-center fw-400">{patrimonio.quantidadeSeries}</td>
                        <TableActionWithRouteAuthorization
                            updateAction={() => goToFormPagePatrimonio(true, patrimonio.idPatrimonioSeparado)}
                            viewAction={() => goToFormPagePatrimonio(false, patrimonio.idPatrimonioSeparado)}
                            feature={PATRIMONIO}
                        />
                    </tr>)
                )
                : <TableEmptyData message={emptyData} />
        );

        return (
            <div>
                <If test={patrimonioData.length > 0}>
                    <div className="d-flex flex-column">
                        <div className="mx-2 shadow-custom bg-white rounded-12">
                            <p className="page-head__title f-28 mb-0 px-3 mt-4 mb-3">Patrimônios da Operações</p>
                            <Table
                                withoutHeader
                                classes="card col-12 shadow-none bg-white py-0 m-0 mb-2 rounded-12"
                                totalItems={patrimonioData.length}
                            >
                                <TableHeader >
                                    {tableHeader}
                                </TableHeader>
                                <TableContent>
                                    {tableContent}
                                </TableContent>
                            </Table>
                        </div>
                    </div>
                </If>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    patrimonioData: state.passivoReducer.patrimonios || [],
    messages: state.passivoReducer.messages,
    operacoes: state.operacaoReducer.selectedOperacoes,
});

const mapDispacthToProps = dispatch => bindActionCreators({
    getPatrimonios,
    authorizationFunction,
    goToFormPagePatrimonio
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(PatrimonioList));
