import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import GenericPickerInput from "../../common/input/GenericPickerInput";

import { 
    getOperacoes, 
    selectedOperacaoPrecificacaoPrecoOption, 
} from "../../../actions/operacao/OperacaoAction";

const OperacaoPrecificacaoPrecoPicker = (props) => (
    <div className="">
        <div>
            <GenericPickerInput
                loadData={props.getOperacoes}
                options={props.options}
                selectedOption={props.selectedOperacaoPrecificacaoPrecoOption}
                isMulti={true}
                placeholder={"Pesquisar operação ex.:CRI.1.SEC.2"}
                loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                noOptionsMessage="Nenhum registro encontrado"
                value={props.operacoes}
            />
        </div>
    </div>
);

const mapDispatchToProps = dispatch => bindActionCreators({
    getOperacoes,
    selectedOperacaoPrecificacaoPrecoOption,
}, dispatch);

const mapStateToProps = state => ({
  options: state.operacaoReducer.operacoes,
  operacoes: state.operacaoReducer.selectedOperacoesPrecificacao,
});

export default connect(mapStateToProps, mapDispatchToProps)(OperacaoPrecificacaoPrecoPicker);