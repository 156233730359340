import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { formValueSelector } from "redux-form";

import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import {
  editarOrdemAssembleia,
  removeOrdemAssembleia,
  showDeleteDialogAssembleiaOrdem,
  showModalAssembleiaOrdemDialog,
  STATUS_ASSEMBLEIA_PENDENTE,
} from "../../../../../../actions/assembleia/AssembleiaAction";

import ConfirmDialog from "../../../../../common/modal/ConfirmDialog";
import If from "../../../../../common/If/If";
import EventLogbookModal from "../../../../gestao/pages/diarioBordo/modal/EventLogbookModal";
import AssembleiaFormSecaoOrdemIncluir from "./AssembleiaFormSecaoOrdemIncluir";
import RBCard from "../../../../../common/card/RBCard";
import PaginationRB from "../../../../../common/pagination/PaginationRB";
import Button from "../../../../../common/button/Button";
import Table from "../../../../../common/table/Table";
import TableHeader from "../../../../../common/table/TableHeader";
import TableContent from "../../../../../common/table/TableContent";

const STATUS_ASSEMBLEIA_CONVOCADA = 2;
const STATUS_ASSEMBLEIA_ENCERRADA = 3;

const styles = (theme) => ({});

class AssembleiaSecaoOrdensList extends Component {
  state = {
    page: 0,
    rowsPerPage: 5,
  };

  handleChangePage = (event) => {
    this.setState({ page: event - 1 });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleConfirmationRemoveItem(idOrdem) {
    this.props.removeOrdemAssembleia(idOrdem);

    const { rowsPerPage, page } = this.state;

    const paginaAtual = this.state.page + 1;
    const quantidadeItensNaLista = this.props.ordemAssembleiaList.length - 1;
    const primeiroIndexDaPagina = rowsPerPage * paginaAtual - rowsPerPage;

    if (quantidadeItensNaLista <= primeiroIndexDaPagina) {
      this.setState({ page: page - 1 });
    }
  }

  exibirEmail(arrEmail) {
    const result = arrEmail.map((item) => <div>{item}</div>);

    return result;
  }

  getIndexArray(page, rowsPerPage, indexItem) {
    let index = page * rowsPerPage + indexItem;
    return index;
  }

  render() {
    const { page, rowsPerPage } = this.state;

    const {
      ordemAssembleiaList,
      readOnly,
      editarOrdemAssembleia,
      showDeleteDialogAssembleiaOrdem,
      showModal,
      modalOrdemAssembleia,
      idAssembleiaOrigem,
    } = this.props;

    const assembleiaPodeSerEditada =
      !this.props.statusAssembleia ||
      (this.props.statusAssembleia &&
        this.props.statusAssembleia.key !== STATUS_ASSEMBLEIA_ENCERRADA &&
        this.props.statusAssembleia.key !== STATUS_ASSEMBLEIA_CONVOCADA &&
        !readOnly);

    const assembleiaDeUmaNovaConvocacao =
      this.props.statusAssembleia &&
      this.props.statusAssembleia.key === STATUS_ASSEMBLEIA_PENDENTE &&
      idAssembleiaOrigem > 0;

    let cabecalho = (
      <div>
        <If test={assembleiaPodeSerEditada && !assembleiaDeUmaNovaConvocacao}>
          <div>
            <Button
              classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
              activeClassName={"col-lg-auto p-0"}
              buttonStyle={"success"}
              action={() => this.props.showModalAssembleiaOrdemDialog(true, -1)}
              icon={"plus"}
              name={"Adicionar"}
            />
            <EventLogbookModal
              title={"Assembleia - Ordens"}
              open={modalOrdemAssembleia.showDialog}
              onClose={() =>
                this.props.showModalAssembleiaOrdemDialog(false, -1)
              }
            >
              <AssembleiaFormSecaoOrdemIncluir />
            </EventLogbookModal>
          </div>
        </If>
      </div>
    );

    let painelOrdensAssembleia = (
      <div>
        <Table
          withoutHeader
          classes="border-0 shadow-none col-12 my-3 pd-10 bg-white"
          totalItems={ordemAssembleiaList.length}
          pagination={
            <PaginationRB
              pageNumber={page}
              itemsPerPage={rowsPerPage}
              totalItems={ordemAssembleiaList.length}
              fetchFunction={(event) => this.handleChangePage(event)}
              pageRange={5}
            />
          }
        >
          <TableHeader>
            <tr>
              <th className="text-center fw-700 text-nowrap">Título</th>
              <th className="text-center fw-700 text-nowrap">Descrição</th>
              <th className="text-center fw-700 text-nowrap">Tipo Quórum</th>
              <th className="text-center fw-700 text-nowrap">
                Quórum Aprovação
              </th>
              <If
                test={
                  assembleiaPodeSerEditada && !assembleiaDeUmaNovaConvocacao
                }
              >
                <th></th>
              </If>
            </tr>
          </TableHeader>
          <TableContent>
            {ordemAssembleiaList.length > 0 ? (
              ordemAssembleiaList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center fw-400 text-nowrap">
                        {item.titulo}
                      </td>
                      <td className="text-center fw-400 text-nowrap">
                        {item.descricao}
                      </td>
                      <td className="text-center fw-400 text-nowrap">
                        {item.tipoQuorumOrdem ? item.tipoQuorumOrdem.value : ""}
                      </td>
                      <td className="text-center fw-400 text-nowrap">
                        {item.tipoQuorumOrdem ? item.quorumAprovacao.value : ""}
                      </td>
                      <If
                        test={
                          assembleiaPodeSerEditada &&
                          !assembleiaDeUmaNovaConvocacao
                        }
                      >
                        <td className="text-center text-nowrap">
                          <div className="d-flex flex-row">
                            <DeleteIcon
                              role="button"
                              className={`mx-3 p-color-imp f-22`}
                              onClick={() =>
                                showDeleteDialogAssembleiaOrdem(
                                  true,
                                  this.getIndexArray(page, rowsPerPage, index)
                                )
                              }
                            />
                            <EditIcon
                              role="button"
                              className={`mx-3 p-color-imp f-22`}
                              onClick={() =>
                                editarOrdemAssembleia(
                                  this.getIndexArray(page, rowsPerPage, index)
                                )
                              }
                            />
                          </div>
                        </td>
                      </If>
                    </tr>
                  );
                })
            ) : (
              <tr>
                <td>Não existem ordens</td>
              </tr>
            )}
          </TableContent>
        </Table>
        <ConfirmDialog
          title="Aviso"
          contentText="Você realmente deseja excluir a Ordem da Assembléia selecionado ?"
          textButtonCancel="CANCELAR"
          textButtonConfirm="CONFIRMAR"
          showDialog={showModal.showDialog}
          onClose={() => showDeleteDialogAssembleiaOrdem(false, null)}
          onConfirm={() => this.handleConfirmationRemoveItem(showModal.idOrdem)}
          type="warning"
          maxWidth="md"
        />
      </div>
    );

    return (
      <RBCard header={cabecalho}>
        <If test={ordemAssembleiaList.length > 0}>{painelOrdensAssembleia}</If>
      </RBCard>
    );
  }
}

const selector = formValueSelector("assembleiaIncluirForm");

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editarOrdemAssembleia,
      removeOrdemAssembleia,
      showDeleteDialogAssembleiaOrdem,
      showModalAssembleiaOrdemDialog,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  showModal: state.assembleiaReducer.showOrdemAssembleiaDeleteDialogContato,
  modalOrdemAssembleia: state.assembleiaReducer.showModalOrdemAssembleia,
  ordemAssembleiaList: selector(state, "ordemAssembleiaDtoList") || [],
  idAssembleiaOrigem: selector(state, "idAssembleiaOrigem") || 0,
});

AssembleiaSecaoOrdensList = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssembleiaSecaoOrdensList);

export default withStyles(styles)(AssembleiaSecaoOrdensList);
