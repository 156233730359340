import axios from "axios";
import { change } from "redux-form";
import history from './../../components/common/routers/history';
import { 
    OPERACAO_ROUTE_PATH,
    PASSIVO_ROUTE_PATH,
    NOVO_ROUTE_PATH
} from "./../../components/common/routers/constantesRoutes";

export const OPERACAO_OPTIONS_FETCHED = 'OPERACAO_OPTIONS_FETCHED';
export const SELECTED_OPERACAO_OPTIONS = 'SELECTED_OPERACAO_OPTIONS';
export const DESELECTED_OPERACAO_OPTIONS = 'DESELECTED_OPERACAO_OPTIONS';
export const MOEDA_SELECTED = 'MOEDA_SELECTED';

const GET_OPERACOES_API = process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_OPERACAO_API;

export function getOperacoes(term, ids) {

    let url = `${GET_OPERACOES_API}sugestoes?termo=${term}`;

    if (ids?.length > 0) {
        let psIds = ids?.map(o => o.key);
        url = url + `&idsPatrimonioSeparado=${psIds}`
    }

    return dispatch => {
        return axios
            .get(url).then(result =>
                dispatch({
                    type: OPERACAO_OPTIONS_FETCHED,
                    payload: result.content
                })
            );
    };
}

export function getOperacoesFilter(term,reducerType) {
    return dispatch => {
        return axios
            .get(`${GET_OPERACOES_API}sugestoes?termo=${term}`).then(result =>
                dispatch({
                    type: reducerType,
                    payload: result.content
                })
            );
    };
}

export function selectedOperacaoOption(option) {
    return (dispatch) => {
        return dispatch([
            {
                type: SELECTED_OPERACAO_OPTIONS,
                payload: option
            },
            // showDashboardTabActive(),
            // history.push(`/${OPERACAO_ROUTE_PATH}/${DASHBOARD_ROUTE_PATH}`)
        ])
    }
}

export function deselectedOperacaoOption() {
    return (dispatch) => {
        return dispatch([
            {
                type: DESELECTED_OPERACAO_OPTIONS,
                payload: []
            }
        ])
    }
}

export function selectOperacaoNoForm(option, formName, fieldName) {
    return (dispatch) => {
        return dispatch([
            change(formName, fieldName, option),
            {
                type: MOEDA_SELECTED,
                payload: option.moeda,
            },
        ])
    }
}

export function selectedOperacaoPrecificacaoCalculoOption(option) {
    return (dispatch) => {
        return dispatch(
            change('calculoForm', 'operacao', option)
        )
    }
}

export function selectedOperacaoPrecificacaoPrecoOption(option) {
    return (dispatch) => {
        return dispatch(
            change('precoForm', 'operacao', option)
        )
    }
}

export function showIniciarImplantacao() {
    return dispatch => {
        return dispatch([
            deselectedOperacaoOption(),            
            history.push(`/${OPERACAO_ROUTE_PATH}/${PASSIVO_ROUTE_PATH}/${NOVO_ROUTE_PATH}`)
        ]);
    };
}
