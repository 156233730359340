/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { IconButton } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

import ContainerPage from "../../../../common/container/ContainerPage";
import history from "../../../../common/routers/history";
import ButtonMuiTheme from "../../../../common/button/ButtonMuiTheme";
import FileUploadCard from "../../../../common/File/FileUploadCard";
import ExpansionPane from "../../../../common/expansion/ExpansionPane";
import {
  ASSEMBLEIA_ROUTE_PATH,
  LISTA_ROUTE_PATH,
} from "../../../../common/routers/constantesRoutes";

import {
  convertFileToBase64,
  ACCEPT_FILE_OPTIONS,
} from "../../../../../utils/helpers/FileHelper";

import {
  atualizaInvestidores,
  laodAbaParticipante,
  uploadProcuradoresFile,
  donwloadPlanilhaModeloProcuradores,
  STATUS_ASSEMBLEIA_ENCERRADA,
  STATUS_ASSEMBLEIA_SUSPENSA,
  STATUS_ASSEMBLEIA_NOVA_CONVOCACAO,
  COLOR_TAB_INDICATOR,
} from "../../../../../actions/assembleia/AssembleiaAction";
import { authorizationFunction } from "../../../../../actions/authorization/AuthorizationAction";
import { showErrorMessages } from "../../../../../actions/message/ErrorMessagesAPIAction";

import {
  ASSEMBLEIA,
  UPDATE,
} from "../../../../../utils/actionsAndFeaturesAuth";

import "../assets/AssembleiaForm.css";
import { ASSEMBLEIA_GERENCIAR_TABS } from "./AssembleiaGerenciarTabs";
import AssembleiaGerenciarHeader from "./AssembleiaGerenciarHeader";
import AssembleiaGerenciarParticipanteConfirmadosList from "../list/AssembleiaGerenciarParticipanteConfirmadosList";

class AssembleiaGerenciarParticipantePage extends Component {
  state = {
    painelInvestidoresExpanded: true,
    painelSolicitacoesExpanded: true,
  };

  handleChangePainelInvestidores = () => {
    this.setState({
      painelInvestidoresExpanded: !this.state.painelInvestidoresExpanded,
    });
  };

  handleChangePainelSolicitacoes = () => {
    this.setState({
      painelSolicitacoesExpanded: !this.state.painelSolicitacoesExpanded,
    });
  };

  componentDidMount() {
    this.props.laodAbaParticipante(this.getParamIdAssembleia());
  }

  getParamIdAssembleia() {
    let idAssembleia = 0;

    if (this.props.match.params.idAssembleia) {
      idAssembleia = this.props.match.params.idAssembleia;
    }

    return idAssembleia;
  }

  handleUploadFile() {
    let element = document.createElement("input");
    element.type = "file";
    element.multiple = false;
    element.accept = ACCEPT_FILE_OPTIONS.EXCEL;
    element.onchange = (e) => {
      let files = element.files;
      if (files == null) {
        return;
      } else if (files.length == 0) {
        return;
      } else {
        this.upload(files[0]);
      }
    };

    element.click();
  }
  handleUploadProcuradores() {
    let element = document.createElement("input");
    element.type = "file";
    element.multiple = false;
    element.accept = ACCEPT_FILE_OPTIONS.EXCEL;
    element.onchange = (e) => {
      let files = element.files;
      if (files == null) {
        return;
      } else if (files.length == 0) {
        return;
      } else {
        this.props.uploadProcuradoresFile(
          this.getParamIdAssembleia(),
          files[0]
        );
      }
    };

    element.click();
  }

  async upload(file) {
    try {
      let fileToUpload = {
        name: file.name,
        arquivo: await convertFileToBase64(file),
        all: file,
        idAssembleia: this.getParamIdAssembleia(),
      };

      this.props.atualizaInvestidores(fileToUpload);
      this.setState({ painelInvestidoresExpanded: true });
    } catch (e) {
      showErrorMessages("Erro ao importar o arquivo");
    }
  }

  getHeadParticipantePanel() {
    return (
      <div className="monitor-workflow w-100">
        <div className="row col anexar-investidores-assembleia_header">
          <div className="col-3">
            <div className="pr-4 p-color-imp">Anexar Lista de Investidores</div>
            <FileUploadCard
              disabled={
                this.props.status &&
                (this.props.status.key === STATUS_ASSEMBLEIA_ENCERRADA ||
                  this.props.status.key === STATUS_ASSEMBLEIA_SUSPENSA ||
                  this.props.status.key === STATUS_ASSEMBLEIA_NOVA_CONVOCACAO)
              }
              showCard={false}
              handleUploadFile={() =>
                this.props.authorizationFunction(
                  { idAcao: UPDATE, idFuncionalidade: ASSEMBLEIA },
                  () => this.handleUploadFile()
                )
              }
              outterClass="pb-1 col"
            />
          </div>
          <div className="col-4">
            <div className="mt-2 p-color-imp text-nowrap">
              Anexar lista de Procuradores / download planilha modelo
            </div>
            <Tooltip
              TransitionComponent={Zoom}
              title="Importar lista de procuradores"
              placement="left"
              className="mr-3"
            >
              <IconButton
                onClick={() => this.handleUploadProcuradores()}
                style={{ color: "#630a37", fontSize: 33 }}
              >
                <i className="fa fa-cloud-upload" aria-hidden="true"></i>
              </IconButton>
            </Tooltip>
            <Tooltip
              TransitionComponent={Zoom}
              title="Download planilha modelo"
              placement="left"
              className="mr-3"
            >
              <IconButton
                onClick={() => this.props.donwloadPlanilhaModeloProcuradores()}
                style={{ color: "#630a37", fontSize: 33 }}
              >
                <i className="fa fa-cloud-download" aria-hidden="true"></i>
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
  getHeadParticipanteSolicicatcaoPanel() {
    return (
      <div className="monitor-workflow w-100">
        <div className="row col anexar-investidores-assembleia_header">
          <div>
            Solicitações de Participações / Procuradores e investidores com
            login não confirmado
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="pr-1">
        <div className="monitor-workflow px-3 pt-3">
          <AssembleiaGerenciarHeader
            showBadgeInstalada
            assembleiaInstalada={this.props.assembleiaInstalada}
            title="Participantes da Assembleia"
          />

          <div className="monitor-workflow-head w-100 bg-transparent">
            <ContainerPage
              tabs={ASSEMBLEIA_GERENCIAR_TABS}
              tabIndicatorColor={COLOR_TAB_INDICATOR}
              paramsRoute={this.getParamIdAssembleia()}
            ></ContainerPage>
          </div>
        </div>
        <div className="px-4">
          <ExpansionPane
            classes="shadow-custom rounded-12 bg-white px-3 py-2"
            header={this.getHeadParticipantePanel()}
            expanded
          >
            <AssembleiaGerenciarParticipanteConfirmadosList
              idAssembleia={this.getParamIdAssembleia()}
              statusAssembleia={this.props.status}
            />
          </ExpansionPane>
        </div>
        <div className="col-12 pt-2 px-4 mt-3 d-flex justify-content-end">
          <div className={"pl-3"}>
            <ButtonMuiTheme
              textColor={"#000"}
              mainColor={"#e0e0e0"}
              action={() =>
                history.push(`/${ASSEMBLEIA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`)
              }
              name="Cancelar"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  assembleiaInstalada:
    state.assembleiaReducer.assembleiaDadosGerenciar.assembleiaInstalada ||
    false,
  status: state.assembleiaReducer.assembleiaDadosGerenciar.status,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      atualizaInvestidores,
      laodAbaParticipante,
      authorizationFunction,
      uploadProcuradoresFile,
      donwloadPlanilhaModeloProcuradores,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AssembleiaGerenciarParticipantePage)
);
