import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {editSaveAssembleia, loadFormAssembleia ,loadFormAssembleiaVisualize} from '../../../../actions/assembleia/AssembleiaAction';
import ContainerPage from "../../../common/container/ContainerPage";
import AssembleiaIncluirFormGeral from "./form/AssembleiaIncluirFormGeral";

class AssembleiaVizualizePage extends Component {
    
    componentDidMount() {
        let idAssembleia = this.props.match.params.idAssembleia;
        this.props.loadFormAssembleiaVisualize(idAssembleia);
    }

    render() {
        const {
            editSaveAssembleia
        } = this.props;

        return (
            <ContainerPage>
                <AssembleiaIncluirFormGeral
                    onSubmit={editSaveAssembleia}
                    readOnly={true}
                    floatButtonVisible={true}
                    nameForm={"Detalhar Assembléia"}
                />
            </ContainerPage>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    editSaveAssembleia,
    loadFormAssembleia,
    loadFormAssembleiaVisualize
}, dispatch);

export default connect(null, mapDispatchToProps)(AssembleiaVizualizePage);
