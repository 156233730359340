import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, formValueSelector } from "redux-form";

import Button from "../../../../common/button/Button";

import DropdownListInput from "../../../../common/input/DropdownListInput";
import GenericPickerInput from "../../../../common/input/GenericPickerInput";
import Input from "../../../../common/input/Input";

import { getOperacoes } from "../../../../../actions/operacao/OperacaoAction";
import {
  selectOperacaoNoForm,
  getAssembleias,
  cleanResult,
} from "../../../../../actions/assembleia/AssembleiaAction";
import { ASSEMBLEIA, READ } from "../../../../../utils/actionsAndFeaturesAuth";
import { authorizationFunction } from "../../../../../actions/authorization/AuthorizationAction";

let AssembleiaFilter = (props) => {
  return (
    <div className="monitor-workflow monitor-workflow-head w-100 p-4 mx-2 logbook-head">
      <div className="mt-0 page-head__title f-36 mb-4 mt-0">
        Assembleias
      </div>
      <div className="shadow-custom rounded-12 bg-white px-3 py-2">
        <div className="row px-0 mb-2">
          <div className="col-12">
            <label className="f-12 text-dark mb-1">Busca</label>
            <div className="col-lg-6 px-0">
              <GenericPickerInput
                loadData={props.getOperacoes}
                options={props.options}
                formName={"assembleiaFilterForm"}
                fieldName={"operacao"}
                placeholder={"Pesquisar operação ex.:CRI.1.SEC.2"}
                loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                noOptionsMessage="Nenhum registro encontrado"
                value={
                  props.operacao
                    ? props.operacao
                    : "Pesquisar operação ex.:CRI.1.SEC.2"
                }
                selectedOptionForm={props.selectOperacaoNoForm}
              />
            </div>
          </div>
        </div>
        <div className="row px-0">
          <div className="col-lg-2">
            <Field
              component={Input}
              className={"filter-input"}
              inputId="dataInicio"
              name={`dataInicio`}
              type={"date"}
              label="Data Início"
            />
          </div>
          <div className="col-lg-2">
            <Field
              component={Input}
              className={"filter-input"}
              inputId="dataFim"
              name={`dataFim`}
              type={"date"}
              label="Data Fim"
            />
          </div>
          <div className="col-lg-2">
            <Field
              component={DropdownListInput}
              inputId="status"
              name="status"
              label="Status"
              options={props.statusList}
              textField="value"
              valueField="key"
              filter="contains"
            />
          </div>
        </div>
        <div className="row px-0">
          <div className="col-lg-12">
            <div className="d-flex flex-row align-items-center">
              <Button
                action={() =>
                  props.authorizationFunction(
                    { idAcao: READ, idFuncionalidade: ASSEMBLEIA },
                    () =>
                      props.getAssembleias({
                        operacao: props.operacao,
                        status: props.status,
                        dataInicio: props.dataInicio,
                        dataFim: props.dataFim,
                      })
                  )
                }
                classes="p-bg-imp rounded"
                icon={"search"}
                name={"Buscar"}
              />
              <Button
                classes="rounded bg-white shadow-none border-0 text-danger m-0 mxh-40 p-0 my-2 text-center text-capitalize"
                activeClassName={"col-lg-auto"}
                buttonStyle={"success"}
                action={() => props.cleanResult()}
                icon={"trash"}
                name={"Limpar"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AssembleiaFilter = reduxForm({ form: "assembleiaFilterForm" })(
  AssembleiaFilter
);
const selector = formValueSelector("assembleiaFilterForm");

const mapStateToProps = (state) => ({
  operacao: selector(state, "operacao"),
  options: state.operacaoReducer.operacoes,
  status: selector(state, "status"),
  dataInicio: selector(state, "dataInicio"),
  dataFim: selector(state, "dataFim"),
  statusList:
    state.assembleiaReducer.assembleiaFormTypes.statusAssembleiaList || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOperacoes,
      selectOperacaoNoForm,
      getAssembleias,
      cleanResult,
      authorizationFunction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AssembleiaFilter);
