/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import Table from "../../../../../common/table/Table";
import TableHeader from "../../../../../common/table/TableHeader";
import TableContent from "../../../../../common/table/TableContent";
import TableEmptyData from "../../../../../common/table/TableEmptyData";
import PaginationRB from "../../../../../common/pagination/PaginationRB";
import ConfirmDialog from "../../../../../common/modal/ConfirmDialog";
import FloatButton from "../../../../../common/button/FloatButton";
import If from "../../../../../common/If/If";

import {
  getIndices,
  showIndiceForm,
  showIndiceDeleteDialog,
  removeIndice,
  loadFormIndice,
} from "../../../../../../actions/precificacao/IndiceAction";

import { getDateFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";
import {
  formatPercent,
  formatDecimal,
} from "../../../../../../utils/helpers/NumberHelper";
import TableActionWithRouteAuthorization from "../../../../../common/table/TableActionWithRouteAuthorization";
import {
  INDICES,
  CREATE,
  DELETE,
} from "../../../../../../utils/actionsAndFeaturesAuth";
import { authorizationFunction } from "../../../../../../actions/authorization/AuthorizationAction";

const IndiceList = (props) => {
  const {
    getIndices,
    showIndiceForm,
    showIndiceDeleteDialog,
    showDeleteDialog,
    removeIndice,
    indiceData,
    messages,
    tipoTaxa,
    dataInicio,
    labelCampoVariacao,
    loadFormIndice,
    authorizationFunction,
  } = props;

  const tableHeader = (
    <tr>
      <th className="text-center fw-700 text-nowrap">Tipo</th>
      <th className="text-center fw-700 text-nowrap">Data Referência</th>
      <th className="text-center fw-700 text-nowrap">
        {tipoTaxa && tipoTaxa.tipoTaxa == 9 ? "Cotação" : "Número Índice"}
      </th>
      <th className="text-center fw-700 text-nowrap">{labelCampoVariacao} </th>
      <th className="text-center fw-700 text-nowrap">Taxa Projetada</th>
      <th className="text-center fw-700 text-nowrap">Início</th>
      <th className="text-center fw-700 text-nowrap">Fim</th>
      <th className="text-center"></th>
    </tr>
  );

  const emptyData = messages && messages.infos && messages.infos[0];

  const tableContent =
    indiceData && indiceData.hasItems ? (
      indiceData.items.map((item, index) => (
        <tr key={index}>
          <td className="text-center fw-400 text-nowrap">
            {item.descricaoTaxa}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {getDateFormatedFromString(item.dataInicio)}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {formatDecimal(item.numeroIndice, 4)}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {formatPercent(item.valorTaxa, 16)}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {formatPercent(item.valorTaxaProjecao, 16)}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {getDateFormatedFromString(item.dataInicioProjecao)}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {getDateFormatedFromString(item.dataFimProjecao)}
          </td>
          <TableActionWithRouteAuthorization
            updateAction={() => loadFormIndice(item.id, true)}
            viewAction={() => loadFormIndice(item.id)}
            feature={INDICES}
            remove={() => showIndiceDeleteDialog(true, item.id)}
          />
        </tr>
      ))
    ) : (
      <TableEmptyData message={emptyData} />
    );

  return (
    <div className="w-100 px-4 mx-2">
      <If test={indiceData.totalCount > 0}>
        <Table
          withoutHeader
          classes="shadow-custom rounded-12 col-12 my-3 pd-10 bg-white"
          totalItems={indiceData.totalCount}
          pagination={
            <PaginationRB
              pageNumber={indiceData.pageIndex - 1}
              itemsPerPage={indiceData.pageSize}
              totalItems={indiceData.totalCount}
              filter={{ tipoTaxa, dataInicio }}
              fetchFunction={getIndices}
              pageRange={5}
            />
          }
        >
          <TableHeader>{tableHeader}</TableHeader>
          <TableContent>{tableContent}</TableContent>
        </Table>
      </If>
      <FloatButton
        activeClassName={"float"}
        title="Novo"
        icon={"fa fa-plus my-float"}
        action={() =>
          authorizationFunction(
            { idAcao: CREATE, idFuncionalidade: INDICES },
            () => showIndiceForm()
          )
        }
      />
      <ConfirmDialog
        title="Aviso"
        contentText="Você realmente deseja excluir o item selecionado ?"
        textButtonCancel="CANCELAR"
        textButtonConfirm="CONFIRMAR"
        showDialog={showDeleteDialog.showDialog}
        onClose={() => showIndiceDeleteDialog(false)}
        onConfirm={() =>
          authorizationFunction(
            { idAcao: DELETE, idFuncionalidade: INDICES },
            () => removeIndice(showDeleteDialog.idIndice)
          )
        }
        type="warning"
        maxWidth="md"
      />
    </div>
  );
};

const selector = formValueSelector("indiceForm");

const mapStateToProps = (state) => ({
  indiceData: state.indiceReducer.indices,
  messages: state.indiceReducer.messages,
  showDeleteDialog: state.indiceReducer.showIndiceDeleteDialog,
  dataInicio: selector(state, "dataInicio"),
  tipoTaxa: selector(state, "taxa"),
  taxaMensal: state.indiceReducer.taxaMensal,
  labelCampoVariacao: state.indiceReducer.labelCampoVariacao,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getIndices,
      showIndiceForm,
      showIndiceDeleteDialog,
      removeIndice,
      loadFormIndice,
      authorizationFunction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(IndiceList);
