/* eslint eqeqeq: 0 */

import React from 'react';
import { Route, Switch } from "react-router-dom";
import { 
    PRECIFICACAO_ROUTE_PATH, 
    OPCAO_SISTEMA_ROUTE_PATH,
    DOMINIO_ROUTE_PATH,
    ERRO_ROUTE_PATH, 
    LOG_ROUTE_PATH,
    CALCULADORA_ROUTE_PATH,
    CALCULO_ROUTE_PATH,
    PRECO_ROUTE_PATH,
    INDICE_ROUTE_PATH,
    PAGAMENTO_ROUTE_PATH,
    AGING_ROUTE_PATH,
    
    LISTA_ROUTE_PATH,
    OPCAO_ROUTE_PATH,
    NOVO_ROUTE_PATH,
    DETALHAR_ROUTE_PATH,
    EDITAR_ROUTE_PATH,
    FLUXO_ROUTE_PATH,
    MOEDA_ROUTE_PATH,
    SELO_ROUTE_PATH,
    TIPO_APROVACAO_ROUTE_PATH,
    PRODUTO_ROUTE_PATH,
    MOVIMENTACAO_FINANCEIRA_ROUTE_PATH,
    NATUREZA_ROUTE_PATH,
    BANCOS_ROUTE_PATH,
} from '../../../common/routers/constantesRoutes';

import CalculoPage from './../pages/calculo/CalculoPage';
import PrecoPage from './../pages/preco/PrecoPage';

import IndicePage from './../pages/indice/IndicePage';
import IndiceIncludePage from './../pages/indice/IndiceIncludePage';
import IndiceUpdatePage from './../pages/indice/IndiceUpdatePage';
import IndiceVisualizePage from './../pages/indice/IndiceVisualizePage';

import OpcaoPage from './../pages/opcao/OpcaoPage';
import MoedaIncludePage from './../pages/opcao/moeda/MoedaIncludePage';
import MoedaUpdatePage from './../pages/opcao/moeda/MoedaUpdatePage';
import MoedaVisualizePage from './../pages/opcao/moeda/MoedaVisualizePage';
import SeloIncludePage from '../pages/opcao/selo/SeloIncludePage';
import SeloUpdatePage from '../pages/opcao/selo/SeloUpdatePage';
import SeloVisualizePage from '../pages/opcao/selo/SeloVisualizePage';
import TipoAprovacaoIncludePage from '../pages/opcao/tipoAprovacao/TipoAprovacaoIncludePage';
import TipoAprovacaoUpdatePage from '../pages/opcao/tipoAprovacao/TipoAprovacaoUpdatePage';
import TipoAprovacaoVisualizePage from '../pages/opcao/tipoAprovacao/TipoAprovacaoVisualizePage';
import ProdutoIncludePage from '../pages/opcao/produto/ProdutoIncludePage';
import ProdutoUpdatePage from '../pages/opcao/produto/ProdutoUpdatePage';
import ProdutoVisualizePage from '../pages/opcao/produto/ProdutoVisualizePage';
import MovimentacaoFinanceiraIncludePage from '../pages/opcao/movimentacaoFinanceira/MovimentacaoFinanceiraIncludePage';
import MovimentacaoFinanceiraUpdatePage from '../pages/opcao/movimentacaoFinanceira/MovimentacaoFinanceiraUpdatePage';
import MovimentacaoFinanceiraVisualizePage from '../pages/opcao/movimentacaoFinanceira/MovimentacaoFinanceiraVisualizePage';
import NaturezaIncludePage from '../pages/opcao/natureza/NaturezaIncludePage';
import NaturezaUpdatePage from '../pages/opcao/natureza/NaturezaUpdatePage';
import NaturezaVisualizePage from '../pages/opcao/natureza/NaturezaVisualizePage';
import BancosIncludePage from '../pages/opcao/bancos/BancosIncludePage';
import BancosUpdatePage from '../pages/opcao/bancos/BancosUpdatePage';
import BancosVisualizePage from '../pages/opcao/bancos/BancosVisualizePage';

import DominioPage from './../pages/dominio/DominioPage';
import DominioIncludePage from './../pages/dominio/DominioIncludePage';
import DominioUpdatePage from './../pages/dominio/DominioUpdatePage';

import LogPage from '../pages/log/LogPage';
import LogVisualizePage from '../pages/log/LogVisualizePage';
import ErroPage from './../pages/erro/ErroPage';
import ErroVisualizePage from './../pages/erro/ErroVisualizePage';

import PagamentoPage from './../pages/pagamento/PagamentoPage';
import PagamentoPageGest from './../pages/pagamentogestora/PagamentoPage';
import PagamentoFasesPage from './../pages/pagamento/form/PagamentoFasesPage';
import PagamentoFasesPageGest from './../pages/pagamentogestora/form/PagamentoFasesPage';

import AgingPage from './../pages/aging/AgingPage';
import CalculadoraPage from './../pages/calculadora/CalculadoraPage';

const currEnv = localStorage.getItem('currEnv') ?? localStorage.getItem('currEnv');
const gestoraId = process.env.REACT_APP_ENV_GEST_ID;
const gestora = gestoraId == currEnv;

export default () => (
    <Switch>
        <Route exact={true} path={`/${PRECIFICACAO_ROUTE_PATH}/${CALCULADORA_ROUTE_PATH}`} component={CalculadoraPage} />
        <Route exact={true} path={`/${PRECIFICACAO_ROUTE_PATH}/${CALCULO_ROUTE_PATH}`} component={CalculoPage} />
        <Route exact={true} path={`/${PRECIFICACAO_ROUTE_PATH}/${PRECO_ROUTE_PATH}`} component={PrecoPage} />
        
        {/* INDICES */}
        <Route exact={true} path={`/${PRECIFICACAO_ROUTE_PATH}/${INDICE_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} component={IndicePage} />
        <Route exact={true} path={`/${PRECIFICACAO_ROUTE_PATH}/${INDICE_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={IndiceIncludePage} />
        <Route exact={true} path={`/${PRECIFICACAO_ROUTE_PATH}/${INDICE_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idIndice`} component={IndiceUpdatePage} />
        <Route exact={true} path={`/${PRECIFICACAO_ROUTE_PATH}/${INDICE_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idIndice`} component={IndiceVisualizePage} />

        {/* ------------------------ */}
        
        {/* OPCOES */}
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} component={OpcaoPage} />
        {/* OPCOES - MOEDA */}
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${MOEDA_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={MoedaIncludePage} />
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${MOEDA_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idOpcao`} component={MoedaUpdatePage} />
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${MOEDA_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idOpcao`} component={MoedaVisualizePage} />
        {/* OPCOES - SELO */}
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${SELO_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={SeloIncludePage} />
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${SELO_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idOpcao`} component={SeloUpdatePage} />
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${SELO_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idOpcao`} component={SeloVisualizePage} />
        {/* OPÇÕES - TIPO APROVACAO */}
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${TIPO_APROVACAO_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={TipoAprovacaoIncludePage} />
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${TIPO_APROVACAO_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idOpcao`} component={TipoAprovacaoUpdatePage} />
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${TIPO_APROVACAO_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idOpcao`} component={TipoAprovacaoVisualizePage} />
        {/* OPÇÕES - PRODUTO */}
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${PRODUTO_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={ProdutoIncludePage} />
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${PRODUTO_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idOpcao`} component={ProdutoUpdatePage} />
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${PRODUTO_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idOpcao`} component={ProdutoVisualizePage} />
        {/* OPÇÕES - MOVIMENTAÇÃO FINANCEIRA */}
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${MOVIMENTACAO_FINANCEIRA_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={MovimentacaoFinanceiraIncludePage} />
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${MOVIMENTACAO_FINANCEIRA_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idOpcao`} component={MovimentacaoFinanceiraUpdatePage} />
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${MOVIMENTACAO_FINANCEIRA_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idOpcao`} component={MovimentacaoFinanceiraVisualizePage} />
        {/* OPÇÕES - NATUREZA */}
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${NATUREZA_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={NaturezaIncludePage} />
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${NATUREZA_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idOpcao`} component={NaturezaUpdatePage} />
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${NATUREZA_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idOpcao`} component={NaturezaVisualizePage} />
        {/* OPÇÕES - BANCOS */}
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${BANCOS_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={BancosIncludePage} />
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${BANCOS_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idOpcao`} component={BancosUpdatePage} />
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${BANCOS_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idOpcao`} component={BancosVisualizePage} />

        {/* ------------------------ */}

        {/* LOG */}
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${LOG_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} component={LogPage} />
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${LOG_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idLog`} component={LogVisualizePage} />

        {/* ERROS */}
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${ERRO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} component={ErroPage} />
        <Route exact={true} path={`/${OPCAO_SISTEMA_ROUTE_PATH}/${ERRO_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idErro`} component={ErroVisualizePage} />

        {/* DOMINIOS */}
        <Route exact={true} path={`/${DOMINIO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} component={DominioPage} />
        <Route exact={true} path={`/${DOMINIO_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={DominioIncludePage} />
        <Route exact={true} path={`/${DOMINIO_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idDominio`} component={DominioUpdatePage} />

        {/* ------------------------ */}

        {/* PAGAMENTO */}
        <Route exact={true} path={`/${PRECIFICACAO_ROUTE_PATH}/${PAGAMENTO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} component={gestora ? PagamentoPageGest : PagamentoPage} />
        <Route 
            exact={true} 
            path={`/${PRECIFICACAO_ROUTE_PATH}/${PAGAMENTO_ROUTE_PATH}/${FLUXO_ROUTE_PATH}/:idPrecificacao`} 
            render={(props) => !gestora ? <PagamentoFasesPage {...props} getSteps={true} /> : <PagamentoFasesPageGest {...props} getSteps={false} /> } 
        />

        {/* AGING */}
        <Route exact={true} path={`/${PRECIFICACAO_ROUTE_PATH}/${AGING_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} component={AgingPage} />
    </Switch>
);