import { initialize } from "redux-form";
import { convertCurrencyToNumber } from "../../../../utils/helpers/NumberHelper";

export function serializeFieldsOpcaoForm(opcao) {
  let result = {};

  if (opcao) {
    result = {
      
    };
  }

  return initialize("opcaoForm", result);
}

export function deserializeFieldsOpcaoForm(opcao) {
  return {
    ...opcao,
    valorLimite: convertCurrencyToNumber(opcao?.valorLimite),
  };
}
