import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, formValueSelector, change } from "redux-form";

import {
  addOrdemAssembleia,
  atualizarContatoDeMonitoramento,
  showModalAssembleiaOrdemDialog
} from '../../../../../../actions/assembleia/AssembleiaAction';

import Input from "../../../../../common/input/Input";
import Fieldset from "../../../../../common/fieldset/Fieldset";
import Button from "../../../../../common/button/Button";
import Textarea from '../../../../../common/input/Textarea';
import { Grid } from '@material-ui/core';
import DropdownListInput from "../../../../../common/input/DropdownListInput";

class AssembleiaFormSecaoOrdemIncluir extends Component {
  render() {
    const {
      readOnly,
      umaOrdem,
      atualizarContatoDeMonitoramento,
      addOrdemAssembleia,
      indexContato,
      showModalAssembleiaOrdemDialog,
      tipoQuorumOrdemList,
      quorumAprovacaoList,
    } = this.props;


    let AssembleiaFormIncluir = (
      <div>
        <div className="d-flex mt-6 w-100 align-items-center">
          <div className="col more-fields pl-0">
            <div className="row">
              <div className="col-lg-8">
                <Field
                  component={Input}
                  inputId="titulo"
                  name="umaOrdem.titulo"
                  type="text"
                  label="Título"
                  readOnly={readOnly}
                  maxLength={80}
                />
              </div>

              <div className="col-lg-2">
                  <Field
                      component={DropdownListInput}
                      inputId="tipoQuorumOrdem"
                      name="umaOrdem.tipoQuorumOrdem"
                      label="Tipo Quórum"
                      options={tipoQuorumOrdemList}
                      textField="value"
                      valueField="key"
                      filter="contains"
                      readOnly={readOnly}
                  />
              </div>

              <div className="col-lg-2">
                  <Field
                      component={DropdownListInput}
                      inputId="quorumAprovacao"
                      name="umaOrdem.quorumAprovacao"
                      label="Quórum Aprovação"
                      options={quorumAprovacaoList}
                      textField="value"
                      valueField="key"
                      filter="contains"
                      readOnly={readOnly}
                  />
              </div>

              {/* <div className="col-lg-2">
                <Field
                  component={MaskedInput}
                  maxLength={10}
                  className={"filter-input"}
                  allowNegative={false}
                  inputId="quorum"
                  name="umaOrdem.quorum"
                  label="Quórum Aprovação"
                />
              </div> */}

              <div className="col-lg-12">
                <Field
                  component={Textarea}
                  className={"filter-input "}
                  inputId="descricao"
                  name="umaOrdem.descricao"
                  type="text"
                  label="Descrição"
                  readOnly={readOnly}
                  minHeight={6}
                  maxLength={8000}
                  resize="vertical"
                />
              </div>
              
            </div>
          </div>
        </div>

        <Grid item xs={12}>
          <div className="row justify-content-center">
            <div className="col-md-2">
              <Button
                activeClassName={"col-lg-auto"}
                buttonStyle={"success"}
                action={() => { (indexContato >= 0 ? atualizarContatoDeMonitoramento(umaOrdem, indexContato) : addOrdemAssembleia(umaOrdem)) }}
                name={indexContato >= 0 ? "Atualizar" : "Adicionar"}
              />
            </div>

            <div className="col-md-3">
              <Button
                activeClassName={"col-lg-auto"}
                buttonStyle={"cancel"}
                action={() => showModalAssembleiaOrdemDialog(false, -1)}
                name={!readOnly ? "Cancelar" : "Fechar"}
              />
            </div>
          </div>
        </Grid>

      </div>

    );

    return (
      <Fieldset>
        {AssembleiaFormIncluir}
      </Fieldset>
    );
  }
}

const selector = formValueSelector("assembleiaIncluirForm");

const mapStateToProps = state => ({
  umaOrdem: selector(state, "umaOrdem"),
  indexContato: state.assembleiaReducer.showModalOrdemAssembleia.indexContato,
  tipoQuorumOrdemList: state.assembleiaReducer.assembleiaFormTypes.tipoQuorumOrdemList,
  quorumAprovacaoList: state.assembleiaReducer.assembleiaFormTypes.quorumAprovacaoList
});

const mapDispatchToProps = dispatch => bindActionCreators({
  change,
  showModalAssembleiaOrdemDialog,
  addOrdemAssembleia,
  atualizarContatoDeMonitoramento,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AssembleiaFormSecaoOrdemIncluir);