import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import { iniciarPagamento } from "../../../../../../actions/precificacao/PagamentoAction";

import {
  getCurrentDateTimeStringNormal,
  getDateFormatedFromString,
  getDateTimeStringNormalizedTwo,
} from "../../../../../utils/helpers/DateTimeHelper";
import { Dialog } from "@material-ui/core";
import Button from "../../../../common/button/Button";
import { Close } from "@material-ui/icons";
import { DropdownList } from "react-widgets";
import { useDropzone } from "react-dropzone";
import {
  getAnexoMonitoramento,
  saveMonitoramento,
  voltarMonitoramento,
} from "../../../../../actions/monitoramento/MonitoramentoAction";
import ModalDescricaoRegra from "./ModalDescricaoRegra";
import ModalContatosResponsaveis from "./ModalContatosResponsaveis";
import { convertFileToBase64 } from "../../../../../utils/helpers/FileHelper";
import { hasNull, msgError } from "../../../../../utils/helpers/OthersHelpers";
import ModalConfirm from "./ModalConfirm";

let ModalMonitoramentos = (props) => {
  const {
    close,
    open,
    monitoramento,
    usuarioResponsavelList,
    condicaoApuracaoList,
    getAnexoMonitoramento,
    saveMonitoramento,
    voltarMonitoramento,
    ratingList,
    ratingNotaList,
    perspectivaRatingList,
  } = props;

  let user = JSON.parse(localStorage.getItem("userLogged"));
  let acessosUser = user.acessos;
  let permitido = acessosUser.some((acesso) => acesso === 3 || acesso === 1);
  let consulta = acessosUser.some((acesso) => acesso === 4);
  let especialista = acessosUser.some((acesso) => acesso === 2);
  let isRating = monitoramento?.tipoComplemento?.id == 3;
  let statusId = monitoramento?.agendaStatus?.id;
  let readOnly = statusId == 3;

  const messages = {
    emptyList: "Lista vazia",
    emptyFilter: "Nenhum registro encontrado",
  };

  React.useEffect(() => {
    if (!monitoramento.usuario.id) {
      let userName = usuarioResponsavelList.find(
        (u) => u.descricao == user.nome
      );
      setResposavelMonitoramento(userName);
    }

    if (isRating) {
      let agendaRating = monitoramento?.agendaRating;
      const notaId = agendaRating.idRatingAgencia;

      if (notaId) {
        const nota = ratingNotaList.find((n) => n.id == notaId);

        setNotaRating(notaId);
        changeNotasAgencia(nota?.idAgencia);
        setEmpresaRating(nota?.idAgencia);
      }

      setPerspectivaRating(agendaRating?.perspectivaRating);

      const dataAtribuicao = getCurrentDateTimeStringNormal(
        agendaRating?.dataAtribuicao
      );
      setDataAtribuicao(dataAtribuicao);
    }
  }, []);

  const [submitted, setSubmitted] = React.useState(false);

  const [resposavelMonitoramento, setResposavelMonitoramento] = React.useState(
    monitoramento.usuario
  );
  const [gestaoAgendaRegraList, setGestaoAgendaRegraList] = React.useState(
    monitoramento.gestaoAgendaRegraList
  );
  const [agendaAnexoList, setAgendaAnexoList] = React.useState(
    monitoramento.agendaAnexoList || []
  );
  const [agendaComentarioList, setAgendaComentarioList] = React.useState(
    monitoramento.agendaComentarioList || []
  );

  const [ratingNotaLocalList, setRatingNotaLocalList] = React.useState([]);

  const [empresaRating, setEmpresaRating] = React.useState(null);
  const [notaRating, setNotaRating] = React.useState(null);
  const [perspectivaRating, setPerspectivaRating] = React.useState(null);
  const [dataAtribuicao, setDataAtribuicao] = React.useState("");

  const [dataProximaRenovacao, setDataProximaRenovacao] = React.useState(
    getCurrentDateTimeStringNormal(monitoramento?.dataProximaRenovacao) || ""
  );

  const [isDragging, setIsDragging] = React.useState(false);
  const [regra, setRegra] = React.useState(null);
  const [contatos, setContatos] = React.useState(null);
  const [avisoVoltar, setAvisoVoltar] = React.useState(null);

  const [comentario, setComentario] = React.useState("");

  //

  const changeNotasAgencia = (idAgencia) => {
    const notasLocaisList = ratingNotaList.filter(
      (n) => n.idAgencia == idAgencia
    );
    setRatingNotaLocalList(notasLocaisList);
  };

  const handleValorApuradoChange = (e, index) => {
    const newValue = e.target.value;

    const newGestaoAgendaRegraList = [...gestaoAgendaRegraList];

    newGestaoAgendaRegraList[index] = {
      ...newGestaoAgendaRegraList[index],
      agendaRegra: {
        ...newGestaoAgendaRegraList[index]?.agendaRegra,
        valorApurado: newValue,
      },
    };

    setGestaoAgendaRegraList(newGestaoAgendaRegraList);
  };

  const handleCondicaoApuradaChange = (e, index) => {
    const newGestaoAgendaRegraList = [...gestaoAgendaRegraList];

    newGestaoAgendaRegraList[index] = {
      ...newGestaoAgendaRegraList[index],
      agendaRegra: {
        ...newGestaoAgendaRegraList[index]?.agendaRegra,
        regraCondicaoApurada: e?.id ? e : { ...e, descricao: null },
      },
    };

    setGestaoAgendaRegraList(newGestaoAgendaRegraList);
  };

  const onAddComent = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString();

    const newComentarios = [...agendaComentarioList];

    newComentarios.push({
      id: 0,
      descricao: comentario,
      autor: user.nome,
      dataHoraRegistro: formattedDate,
    });

    setAgendaComentarioList(newComentarios);
    setComentario("");
  };

  const onDrop = async (acceptedFiles) => {
    const files = acceptedFiles;

    let arquivos = await Promise.all(
      files.map(async (file) => ({
        nomeArquivo: file.name,
        arquivo: await convertFileToBase64(file),
      }))
    );

    setAgendaAnexoList([...agendaAnexoList, ...arquivos]);
    setIsDragging(false);
  };

  const onDelete = (index) => {
    const newFiles = [...agendaAnexoList];
    newFiles.splice(index, 1);
    setAgendaAnexoList(newFiles);
  };

  const onDeleteComent = (index) => {
    const newComentarios = [...agendaComentarioList];
    newComentarios.splice(index, 1);
    setAgendaComentarioList(newComentarios);
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/vnd.ms-excel": [".xls"],
    },
    onDragEnter: () => {
      setIsDragging(true);
    },
    onDragLeave: () => {
      setIsDragging(false);
    },
    onDropRejected: (rejected) => {
      const names = rejected.map((item) => item.file.name);
      names.forEach((name) => msgError(`Arquivo (${name}) inválido`));
    },
    disabled: readOnly,
    noClick: readOnly,
  });

  const salvarMonitoramento = async (finalizar) => {
    let agendaRegraList = gestaoAgendaRegraList?.map((ag) => {
      return {
        ...ag.agendaRegra,
        id: ag.agendaRegra?.id || 0,
        idComplementoMonitoramentoRegra: ag.agendaRegra?.idComplementoMonitoramentoRegra || ag?.id,
        idComplementoMonitoramentoAgenda: ag?.agendaRegra?.idComplementoMonitoramentoAgenda || monitoramento?.idComplementoMonitoramentoAgenda,
      };
    });

    let data = {
      ...monitoramento,
      usuario: resposavelMonitoramento,
      agendaRegraList: agendaRegraList || [],
      agendaAnexoList: agendaAnexoList || [],
      agendaComentarioList: agendaComentarioList,
      dataProximaRenovacao: dataProximaRenovacao,
      agendaRating: {
        ...monitoramento?.agendaRating,
        dataAtribuicao: dataAtribuicao,
        empresaRating: empresaRating,
        idRatingAgencia: notaRating,
        perspectivaRating: perspectivaRating,
      },
      agendaStatus: {
        id: finalizar ? 3 : statusId < 2 ? 2 : statusId,
      },
    };

    setSubmitted(true);



    // TIPO RATING
    if (monitoramento?.tipoComplemento?.id == 3) {
      const validation = {
        dataAtribuicao: data.agendaRating.dataAtribuicao,
        empresaRating: data.agendaRating.empresaRating,
        idRatingAgencia: data.agendaRating.idRatingAgencia,
        perspectivaRating: data.agendaRating.perspectivaRating,
      }

      if (hasNull(validation)) {
        return false;
      }
    }


    // VERIFICAÇÃO RESUMO
    const validationResumo = {
      usuario: data.usuario,
      dataProximaRenovacao: data.dataProximaRenovacao,
    }

    if (hasNull(validationResumo)) {
      return false;
    }


    // VERIFICAÇÃO REGRA
    const validationRegra = Array.from(data.agendaRegraList).some((regra) => {
      return hasNull({
        regraCondicaoApurada: regra?.regraCondicaoApurada,
        valorApurado: regra?.valorApurado,
      })
    });

    if (validationRegra) {
      return false;
    }


    await saveMonitoramento(data);
    close(true);
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth={false}
        PaperProps={{ style: { maxWidth: "max-content" } }}
        onClose={() => close()}
      >
        <div className="modal modal-fullscreen modal-dialog modal-dialog-scrollable m-0">
          <div className="modal-content">
            <div className="modal-header d-flex flex-row align-items-center justify-content-between pb-3">
              <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
                Realizar Monitoramento
              </p>
              <Close
                role="button"
                className="text-danger"
                onClick={() => close()}
              />
            </div>

            <div className="modal-body text-dark f-16 fw-400 py-2">
              <div>
                <div className="row">
                  <div className="col-5 d-flex flex-column py-2">
                    <span className="txt-grey f-12">Patrimônio Separado</span>
                    <span>{monitoramento?.descricaoPatrimonioSeparado}</span>
                  </div>
                  <div className="col-3 d-flex flex-column py-2">
                    <span className="txt-grey f-12">Operação</span>
                    <span>{monitoramento?.codigosOpea?.join(", ")}</span>
                  </div>
                  <div className="col-2 d-flex flex-column py-2">
                    <span className="txt-grey f-12">Data de Início</span>
                    <span>
                      {getDateFormatedFromString(monitoramento?.dataInicio)}
                    </span>
                  </div>
                  <div className="col-2 d-flex flex-column py-2">
                    <span className="txt-grey f-12">Data de Renovação</span>
                    <span>
                      {getDateFormatedFromString(monitoramento?.dataRenovacao)}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-3 d-flex flex-column py-2">
                    <span className="txt-grey f-12">
                      Data da Próxima Renovação
                    </span>
                    <input
                      type="date"
                      max="2999-12-31"
                      name="dataProximaRenovacao"
                      onChange={(e) => setDataProximaRenovacao(e.target.value)}
                      className={`h-38-imp rw-widget-input rounded w-100 ${
                        readOnly ? "monit-blocked p-0" : "p-2"
                      }`}
                      value={dataProximaRenovacao}
                    />
                    {submitted && !dataProximaRenovacao && <span className="text-danger f-12">Preenchimento Obrigatório</span>}
                  </div>
                  <div className="col-3 d-flex flex-column py-2">
                    <span className="txt-grey f-12">
                      Responsável pelo Monitoramento
                    </span>
                    <DropdownList
                      value={resposavelMonitoramento?.id ? resposavelMonitoramento : ""}
                      onChange={(e) => setResposavelMonitoramento(e)}
                      valueField="id"
                      textField="descricao"
                      messages={messages}
                      className={`my-0 ${readOnly ? "monit-blocked" : ""}`}
                      readOnly={readOnly}
                      filter={"contains"}
                      data={[
                        { id: null, descricao: "Selecione..." },
                        ...usuarioResponsavelList,
                      ]}
                    />
                    {submitted && !resposavelMonitoramento?.id && <span className="text-danger f-12">Preenchimento Obrigatório</span>}
                  </div>
                </div>

                <div className="row">
                  <div className="col-3 d-flex flex-column py-2">
                    <span className="txt-grey f-12">
                      Descrição do Monitoramento
                    </span>
                    <span>{monitoramento?.descricaoComplemento}</span>
                  </div>
                  <div className="col-3 d-flex flex-column py-2">
                    <span className="txt-grey f-12">Contrato</span>
                    <span>{monitoramento?.contratoReferencia}</span>
                  </div>
                  <div className="col-6 d-flex flex-column py-2">
                    <span className="txt-grey f-12">Responsável</span>
                    <span>
                      <i
                        role="button"
                        className="fa fa-align-left"
                        onClick={() =>
                          setContatos(monitoramento?.contatosResponsaveis)
                        }
                      ></i>
                    </span>
                  </div>
                </div>
              </div>

              {monitoramento?.tipoComplemento?.id == 3 && (
                <div className="pt-3 pb-2">
                  <p className="f-20 fw-500 m-0 p-color-imp">Rating</p>
                  <div className="py-2">
                    <div className="row">
                      <div className="col-lg-3 col-xl-3">
                        <span className="d-flex fw-500 justify-content-center f-12">
                          AGÊNCIA
                        </span>
                        <DropdownList
                          value={empresaRating ? empresaRating : ""}
                          valueField="id"
                          textField="descricao"
                          data={[
                            { id: null, descricao: "Selecione..." },
                            ...ratingList,
                          ]}
                          filter="contains"
                          readOnly={readOnly}
                          className={`${readOnly ? "monit-blocked" : ""}`}
                          containerClassName="h-38-imp text-center"
                          messages={messages}
                          onChange={(e) => {
                            setEmpresaRating(e.id);
                            changeNotasAgencia(e.id);
                            setNotaRating(null);
                          }}
                        />
                        {submitted && !empresaRating && <span className="text-danger f-12">Preenchimento Obrigatório</span>}
                      </div>

                      <div className="col-lg-3 col-xl-3">
                        <span className="d-flex fw-500 justify-content-center f-12">
                          RATING
                        </span>
                        <DropdownList
                          value={notaRating ? notaRating : ""}
                          valueField="id"
                          textField="nota"
                          data={[
                            { id: null, nota: "Selecione..." },
                            ...ratingNotaLocalList,
                          ]}
                          filter="contains"
                          readOnly={readOnly}
                          className={`${readOnly ? "monit-blocked" : ""}`}
                          containerClassName="h-38-imp text-center"
                          messages={messages}
                          onChange={(e) => setNotaRating(e.id)}
                        />
                        {submitted && !notaRating && <span className="text-danger f-12">Preenchimento Obrigatório</span>}
                      </div>

                      <div className="col-lg-3 col-xl-3">
                        <span className="d-flex fw-500 justify-content-center f-12">
                          PERSPECTIVA RATING
                        </span>
                        <DropdownList
                          value={perspectivaRating?.key ? perspectivaRating : ""}
                          valueField="key"
                          textField="value"
                          data={[
                            { key: null, value: "Selecione..." },
                            ...perspectivaRatingList,
                          ]}
                          filter="contains"
                          readOnly={readOnly}
                          className={`${readOnly ? "monit-blocked" : ""}`}
                          containerClassName="h-38-imp text-center"
                          messages={messages}
                          onChange={(e) => setPerspectivaRating(e)}
                        />
                        {submitted && !perspectivaRating?.key && <span className="text-danger f-12">Preenchimento Obrigatório</span>}
                      </div>

                      <div className="col-lg-3 col-xl-3">
                        <span className="d-flex fw-500 justify-content-center f-12">
                          DATA ATRIBUIÇÃO
                        </span>
                        <input
                          type="date"
                          max="2999-12-31"
                          name="dataAtribuicao"
                          value={dataAtribuicao || ""}
                          onChange={(e) => setDataAtribuicao(e.target.value)}
                          className={`h-38-imp rw-widget-input rounded w-100 text-center ${
                            readOnly ? "monit-blocked p-0" : "p-2 pdl-28"
                          }`}
                        />
                        {submitted && !dataAtribuicao && <span className="text-danger f-12">Preenchimento Obrigatório</span>}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="pt-3 pb-2">
                <p className="f-20 fw-500 m-0 p-color-imp">
                  Regras do Monitoramento
                </p>
                {/* TABLE */}
                <div className="py-2">
                  {gestaoAgendaRegraList?.length > 0 ? (
                    <table className="table f-16">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="text-center fw-bold f-12-imp border-0"
                          >
                            REGRA
                          </th>
                          <th
                            scope="col"
                            className="text-center fw-bold f-12-imp border-0"
                          >
                            ATIVIDADE
                          </th>
                          <th
                            scope="col"
                            className="text-center fw-bold f-12-imp border-0"
                          >
                            VALOR REFERÊNCIA
                          </th>
                          <th
                            scope="col"
                            className="text-center fw-bold f-12-imp border-0"
                          >
                            CONDIÇÃO REFERÊNCIA
                          </th>
                          <th
                            scope="col"
                            className="text-center fw-bold f-12-imp border-0"
                          >
                            VALOR APURADO
                          </th>
                          <th
                            scope="col"
                            className="text-center fw-bold f-12-imp border-0"
                          >
                            CONDIÇÃO APURADA
                          </th>
                          <th
                            scope="col"
                            className="text-center fw-bold f-12-imp border-0"
                          >
                            DESCRIÇÃO
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {gestaoAgendaRegraList?.map((item, i) => {
                          return (
                            <tr key={`table-regra-${i}`}>
                              <td className="text-center f-12-imp border-0">
                                {item?.regra}
                              </td>
                              <td className="text-center f-12-imp border-0">
                                {item?.regraAtividade?.descricao}
                              </td>
                              <td className="text-center f-12-imp border-0">
                                {item?.valorReferencia}
                              </td>
                              <td className="text-center f-12-imp border-0">
                                {item?.regraCondicao?.descricao}
                              </td>
                              <td className="f-12-imp border-0 d-flex flex-column text-center">
                                <input
                                  type="text"
                                  value={item?.agendaRegra?.valorApurado}
                                  onChange={(e) =>
                                    handleValorApuradoChange(e, i)
                                  }
                                  className={`input-monit brd-monit-input rounded text-center ${
                                    readOnly ? "monit-blocked" : ""
                                  }`}
                                />
                                {submitted && !item?.agendaRegra?.valorApurado && <span className="text-danger f-12 text-nowrap">Preenchimento Obrigatório</span>}
                              </td>
                              <td className="f-12-imp border-0 text-center">
                                <DropdownList
                                  value={item?.agendaRegra?.regraCondicaoApurada?.id ? item?.agendaRegra?.regraCondicaoApurada : ""}
                                  onChange={(e) => handleCondicaoApuradaChange(e, i)}
                                  valueField="id"
                                  textField="descricao"
                                  messages={messages}
                                  readOnly={readOnly}
                                  className={`my-0 ${
                                    readOnly ? "monit-blocked" : ""
                                  }`}
                                  data={[
                                    { id: null, descricao: "Selecione..." },
                                    ...condicaoApuracaoList,
                                  ]}
                                />
                                {submitted && !item?.agendaRegra?.regraCondicaoApurada?.id && <span className="text-danger f-12 text-nowrap">Preenchimento Obrigatório</span>}
                              </td>
                              <td className="text-center f-12-imp border-0">
                                <i
                                  role="button"
                                  className="fa fa-align-left"
                                  onClick={() => setRegra(item)}
                                ></i>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <p className="mb-2">Sem regras neste monitoramento</p>
                  )}
                </div>
              </div>

              <div className="pt-3 pb-2">
                <p className="f-20 fw-500 m-0 p-color-imp">Anexar Arquivos</p>

                <div
                  {...getRootProps()}
                  className="w-100 d-flex flex-column justify-content-center align-items-center text-center position-relative p-2 rounded my-2"
                  style={{
                    height: "200px",
                    transition: "border 0.3s ease-out",
                    border: isDragging
                      ? "2px dashed #630A37"
                      : "2px dashed #cccccc",
                  }}
                >
                  {!readOnly && (
                    <>
                      <input {...getInputProps()} />
                      <p className="py-2 mb-0">
                        Arraste os arquivos ou{" "}
                        <u className="btn-link-opea" role="button">
                          clique aqui
                        </u>{" "}
                        para upload
                      </p>
                    </>
                  )}
                  <div className="d-flex flex-column h-100 overflow-auto w-100 p-2">
                    {agendaAnexoList?.length > 0 &&
                      agendaAnexoList?.map((file, i) => {
                        return (
                          <div
                            key={`file-${i}`}
                            onClick={(e) => e.stopPropagation()}
                            style={{
                              backgroundColor: "#E7E7E7",
                              border: "1px solid #B8B8B8",
                            }}
                            className="p-2 d-flex flex-row align-items-center justify-content-between rounded fw-400 my-1"
                          >
                            <span>{file.nomeArquivo || file.name}</span>
                            <div className="d-flex flex-row">
                              {file?.id && (
                                <i
                                  role="button"
                                  className="fa fa-cloud-download f-20 mr-3"
                                  onClick={() =>
                                    getAnexoMonitoramento(file?.id)
                                  }
                                ></i>
                              )}

                              {!readOnly && (
                                <i
                                  role="button"
                                  onClick={() => onDelete(i)}
                                  className="fa fa-minus-circle f-20 d-flex align-items-center mr-1"
                                ></i>
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>

              <div className="pt-3 pb-2">
                <p className="f-20 fw-500 m-0 p-color-imp">Comentários</p>
                {!readOnly && (
                  <div className="row px-3 pt-3">
                    <input
                      type="text"
                      value={comentario}
                      onChange={(e) => setComentario(e.target.value)}
                      className="input-monit brd-monit-input rounded h-38 col mr-3 p-2"
                    />
                    <Button
                      name={"Adicionar"}
                      icon="plus-circle"
                      disabled={!comentario}
                      action={() => onAddComent()}
                      classes="brd-1 text-dark bg-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 h-38"
                    />
                  </div>
                )}
                <div className="py-3">
                  {agendaComentarioList.length > 0 ? (
                    agendaComentarioList?.map((cmt, i) => {
                      return (
                        <div
                          key={i}
                          className="d-flex flex-row align-items-center py-3"
                        >
                          <i
                            role="button"
                            onClick={() => onDeleteComent(i)}
                            className="fa fa-minus-circle f-22"
                          ></i>
                          <div className="ml-3">
                            <div className="d-flex flex-row align-items-center">
                              <span className="mr-2 fw-500 p-color text-underline">
                                {cmt.autor}
                              </span>
                              <span className="f-12 txt-grey mt-1">
                                {getDateTimeStringNormalizedTwo(
                                  cmt.dataHoraRegistro
                                )}
                              </span>
                            </div>
                            <div className="font-italic mb-0 d-flex flex-row align-items-lg-start px-3 py-1">
                              <i className="fa fa-quote-left f-8"></i>
                              <p className="mb-0 mx-1">{cmt.descricao}</p>
                              <i className="fa fa-quote-right f-8"></i>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="mb-2">Sem comentários no momento</p>
                  )}
                </div>
              </div>
            </div>

            <div className="modal-footer d-flex flex-row align-content-center justify-content-between p-3">
              <Button
                name={"Cancelar"}
                action={() => close()}
                classes="bg-danger px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0 mw-100px"
              />

              <Button
                name={"Voltar Etapa do Monitoramento"}
                disabled={
                  statusId == 1 || !permitido || consulta || especialista
                }
                action={() =>
                  setAvisoVoltar(
                    monitoramento?.idComplementoMonitoramentoAgenda
                  )
                }
                classes="p-bg-imp default-bg-imp px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 mw-100px"
              />

              <Button
                name={"Salvar"}
                action={async () => {
                  await salvarMonitoramento();
                }}
                disabled={statusId == 3 || consulta}
                classes="bg-warning warning-bg-imp text-dark px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 mw-100px"
              />

              <Button
                name={"Finalizar Monitoramento"}
                action={async () => {
                  await salvarMonitoramento(true);
                }}
                disabled={statusId == 3 || consulta}
                classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 mw-100px"
              />
            </div>
          </div>
        </div>

        {regra && (
          <ModalDescricaoRegra
            regra={regra}
            open={Boolean(regra)}
            close={() => setRegra(null)}
          />
        )}

        {contatos && (
          <ModalContatosResponsaveis
            contatos={contatos}
            open={Boolean(contatos)}
            close={() => setContatos(null)}
          />
        )}

        {avisoVoltar && (
          <ModalConfirm
            open={Boolean(avisoVoltar)}
            confirm={async () => {
              await voltarMonitoramento(avisoVoltar);
              close(true);
            }}
            close={() => setAvisoVoltar(null)}
          />
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  usuarioResponsavelList:
    state.monitoramentoReducer.monitoramento.usuarioResponsavelList || [],
  condicaoApuracaoList:
    state.monitoramentoReducer.monitoramento.condicaoApuracaoList || [],
  agendaAnexoList:
    state.monitoramentoReducer.monitoramento.agendaAnexoList || [],

  ratingList: state.monitoramentoReducer.monitoramento.agenciaRatingList,
  ratingNotaList:
    state.monitoramentoReducer.monitoramento.ratingAgenciaNotaList,
  perspectivaRatingList:
    state.monitoramentoReducer.monitoramento.perspectivaRatingList,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getAnexoMonitoramento,
      saveMonitoramento,
      voltarMonitoramento,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(ModalMonitoramentos);
