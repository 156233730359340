/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { reduxForm } from "redux-form";

import { changePatrimonioFormTab, goBackToList, showMessageAlert, limpaDependenteOnChange } from "../../../../../actions/patrimonio/PatrimonioAction";
import { showConfirmDialog } from "../../../../../actions/common/modal/ConfirmDialogAction";

import Container from "../../../../common/container/Container";
import ContainerRow from "../../../../common/container/ContainerRow";
import ConfirmDialog from "../../../../common/modal/ConfirmDialog";
import Form from "../../../../common/form/Form";
import FormContent from "../../../../common/form/FormContent";
import FormHeader from "../../../../common/form/FormHeader";
import FormTab from "../../../../common/form/FormTab";

import PatrimonioFormSeriesVinculadas from "./tabs/PatrimonioFormSeriesVinculadas";
import PatrimonioFormContasVinculadas from "./tabs/PatrimonioFormContasVinculadas";
import PatrimonioFormGeral from "./tabs/PatrimonioFormGeral";
import PatrimonioFormEstrutura from "./tabs/PatrimonioFormEstrutura";
import Button from "../../../../common/button/Button";
import FixedFloaterErrors from "../../../../common/floats/FixedFloaterErrors";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";

import { withStyles } from "@material-ui/core/styles";
import { validateDataEncerramentoSocialAnual } from "../../../../../utils/helpers/DateTimeHelper";

const PATRIMONIO_FORM_GERAL = "GERAL";
const PATRIMONIO_FORM_ESTRUTURA = "ESTRUTURA DA OPERAÇÃO";
const PATRIMONIO_FORM_SERIES_VINCULADAS = "SÉRIES VINCULADAS";
const PATRIMONIO_FORM_CONTAS_VINCULADAS = "CONTAS VINCULADAS";

const styles = (theme) => ({
  parent: {
    width: "100%",
    marginTop: "0.5%",
    backgroundColor: "transparent",
  },
  mrButton: {
    marginRight: theme.spacing.unit,
  },
  button: {
    color: "#630A37",
    "&:disabled": {
      color: "#D0D0D0",
    },
  },
  buttons: {
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: "1rem !important",
  },
  stepper: {
    backgroundColor: "transparent",
  },
  active: {},
  completed: {},
  customStepLabel: {
    "& [class*='MuiStepLabel-labelContainer']": {
      display: "flex",
      justifyContent: "center",
    },
    "& [class*='MuiTypography-root']": {
      width: "max-content",
      padding: "0 10px",
      borderRadius: "20px",
      // fontSize: "12px",
      // textTransform: "uppercase",
      "&[class*='MuiStepLabel']": {
        backgroundColor: "#B8B8B8",
        color: "#121212c9",
        fontWeight: 500,
      },
      "&[class*='MuiStepLabel-active']": {
        backgroundColor: "#FFC107",
      },
      "&[class*='MuiStepLabel-completed']": {
        backgroundColor: "#83BF74",
        color: "#ffffff",
        "&.revisao": {
          backgroundColor: "#cc0000",
        },
      },
    },
  },
});

class PatrimonioForm extends Component {
  componentDidMount() {
    this.props.changePatrimonioFormTab(PATRIMONIO_FORM_GERAL);
  }

  isActivateForm(state) {
    return this.props.formActive === state;
  }

  render() {
    const {
      handleSubmit,
      readOnly,
      view,
      floatButtonVisible,
      title,
      showConfirmDialog,
      showCancelConfirmDialog,
      patrimonioSeparado,
      goBackToList,
      formActive,
      changePatrimonioFormTab,
      showMessageAlert,
      messages,
      classes,
      limpaDependenteOnChange,
    } = this.props;

    let renderStatus = [
      {
        key: 1,
        value: "Cadastrado",
        raw: "Cadastrado",
        show: true,
        concluidos: [1],
      },
      {
        key: 2,
        value: "Parametrizado",
        raw: "Parametrizado",
        show: true,
        concluidos: [1, 2],
      },
    ];

    const statusValue = patrimonioSeparado?.statusAprovacao?.key;

    let user = JSON.parse(localStorage.getItem("userLogged"));
    let acessosUser = user.acessos;
    const userLogged = JSON.parse(localStorage.getItem("userLogged"));

    let isAdmin = view ? false : acessosUser.some((acesso) => acesso === 1);
    let isGestor = view ? false : acessosUser.some((acesso) => acesso === 3);
    const isTreasury = userLogged?.areasAtuacoes?.some((area) => area.id == 9);

    const steps = renderStatus.filter((sts) => sts.show).map((sts) => sts.value);
    const concluidos = renderStatus.filter((sts) => sts.key === statusValue).flatMap((sts) => sts.concluidos);

    function CustomStepIcon({ active, completed, classes }) {
      const cComp = "#83BF74";
      const cActv = "#FFC107";
      const cDflt = "#888888";

      return completed ? (
        <CheckCircleOutline style={{ color: cComp }} />
      ) : active ? (
        <RadioButtonChecked style={{ color: cActv }} />
      ) : (
        <RadioButtonUnchecked style={{ color: cDflt }} />
      );
    }

    return (
      <Container>
        <ContainerRow>
          <div className="col-md">
            <Form>
              <FormHeader title={title} col={"col-md"} offSet={20}>

                <Stepper
                  className="mt-4"
                  classes={{ root: classes.stepper }}
                  activeStep={concluidos.length || 0}
                  alternativeLabel
                >
                  {steps.map((step, index) => (
                    <Step
                      key={index}
                      completed={concluidos.some((et) => et === index + 1)}
                    >
                      <StepLabel
                        completed={concluidos.some((et) => et === index + 1)}
                        className={classes.customStepLabel}
                        StepIconComponent={CustomStepIcon}
                        classes={{
                          completed: `${classes.completed}`,
                        }}
                        StepIconProps={{
                          classes: {
                            completed: `${classes.completed}`,
                            active: classes.active,
                            disabled: classes.disabled,
                          },
                        }}
                      >
                        {step}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
                
                <FormTab
                  formTabNames={[
                    { name: PATRIMONIO_FORM_GERAL },
                    { name: PATRIMONIO_FORM_ESTRUTURA },
                    { name: PATRIMONIO_FORM_SERIES_VINCULADAS },
                    { name: PATRIMONIO_FORM_CONTAS_VINCULADAS },
                  ]}
                  formTabActive={formActive}
                  changeFormTabActive={changePatrimonioFormTab}
                />
              </FormHeader>
              <FormContent handleSubmit={handleSubmit}>
                {messages?.hasError && (
                  <FixedFloaterErrors
                    messages={messages}
                    title="Status do Cadastro"
                  />
                )}

                <div className={`d-${this.isActivateForm(PATRIMONIO_FORM_GERAL) ? 'block' : 'none'}`}>
                  <PatrimonioFormGeral
                    readOnly={readOnly}
                    isAdmin={isAdmin}
                    isGestor={isGestor}
                    view={view}
                    isTreasury={isTreasury}
                  />
                </div>                
                <div className={`d-${this.isActivateForm(PATRIMONIO_FORM_ESTRUTURA) ? 'block' : 'none'}`}>
                  <PatrimonioFormEstrutura
                    readOnly={readOnly}
                    view={view}
                    showing={this.isActivateForm(PATRIMONIO_FORM_ESTRUTURA)}
                  />
                </div>
                {this.isActivateForm(PATRIMONIO_FORM_SERIES_VINCULADAS) && (
                  <PatrimonioFormSeriesVinculadas readOnly={readOnly} />
                )}           
                {this.isActivateForm(PATRIMONIO_FORM_CONTAS_VINCULADAS) && (
                  <PatrimonioFormContasVinculadas readOnly={readOnly} />
                )}  

                {/* --------------------- */}

                <div className="col-12 px-0">
                  <div className="d-flex flex-row justify-content-around align-items-center col-12 mb-4 p-0">
                    <Button
                      name={`Cancelar ${floatButtonVisible ? 'Alterações': ''}`}
                      classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                      action={() => {
                        floatButtonVisible
                          ? showConfirmDialog(true)
                          : goBackToList()
                      }}
                    />

                    {floatButtonVisible && (
                      <Button
                        type="submit"
                        name={"Salvar Cadastro"}
                        classes="warn-bg-imp text-dark px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                        action={async () => {
                          await limpaDependenteOnChange("typeSubmit", "Salvar");
                          await limpaDependenteOnChange("enviarEmail", false);
                          await limpaDependenteOnChange("finalizarCadastro", false);
                          await limpaDependenteOnChange("statusValue", statusValue);
                        
                          if (this.props.invalid) showMessageAlert("Alguns erros foram encontrados. Por favor, revise atentamente todas as abas.");
                        }}
                      />
                    )}

                    {(!view && readOnly && statusValue == 1 && isTreasury) && (
                      <Button
                        type="submit"
                        name={"Finalizar Cadastro"}
                        classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                        action={async () => {
                          await limpaDependenteOnChange("typeSubmit", "Finalizar");
                          await limpaDependenteOnChange("enviarEmail", false);
                          await limpaDependenteOnChange("finalizarCadastro", true);
                          await limpaDependenteOnChange("statusValue", statusValue);

                          if (this.props.invalid) showMessageAlert("Alguns erros foram encontrados. Por favor, revise atentamente todas as abas.")
                        }}
                      />
                    )}

                    {(!readOnly && !statusValue && patrimonioSeparado) && (
                      <Button
                        type="submit"
                        name={"Enviar para VACC"}
                        classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                        action={async () => {
                          await limpaDependenteOnChange("typeSubmit", "Enviar");
                          await limpaDependenteOnChange("enviarEmail", true);
                          await limpaDependenteOnChange("finalizarCadastro", false);
                          await limpaDependenteOnChange("statusValue", statusValue);

                          if (this.props.invalid) showMessageAlert("Alguns erros foram encontrados. Por favor, revise atentamente todas as abas.")
                        }}
                      />
                    )}
                  </div>
                </div>
              </FormContent>
              <ConfirmDialog
                title="Aviso"
                contentText="Os dados do formulário serão perdidos, deseja realmente sair?"
                textButtonCancel="CANCELAR"
                textButtonConfirm="CONFIRMAR"
                showDialog={showCancelConfirmDialog}
                onClose={() => showConfirmDialog(false)}
                onConfirm={() => goBackToList()}
                type="warning"
                maxWidth="md"
              />
            </Form>
          </div>
        </ContainerRow>
      </Container>
    );
  }
}

// VALIDAÇÃO
const validate = (values) => {
  const PSV = values.series;
  const novo = /novo/.test(window.location.href);

  const year = new Date().getFullYear();
  const errorsDataEncerramentoSocialAnual = validateDataEncerramentoSocialAnual(values.dataEncerramentoSocialAnual, year);

  const errorsGeral = {
    // Patrimônio Separado
    descricao: !values.descricao && !novo ? 'Preenchimento obrigatório' : null,
    apelido: !values.apelido ? 'Preenchimento obrigatório' : null,
    emissor: !values.emissor ? 'Preenchimento obrigatório' : null,
    tipoEmpresa: !values.tipoEmpresa ? 'Preenchimento obrigatório' : null,
    regimeTributario: !values.regimeTributario ? 'Preenchimento obrigatório' : null,
    codigoFilial: values?.statusValue == 1 ? (!values.codigoFilial ? 'Preenchimento obrigatório' : (values.codigoFilial.length < 5 ? "Inferior a 5 caracteres" : null)) : null,
    
    // Séries Vinculadas
    series: PSV && PSV.length
    ? PSV.map((serie) => ({
      emissao: !serie || !serie.emissao ? 'Preenchimento obrigatório' : null,
      serie: !serie || !serie.serie ? 'Preenchimento obrigatório' : null
    })).filter(Boolean)
    : null,
    
    // Controle
    idProduto: !values.idProduto ? 'Preenchimento obrigatório' : null,
    idUsuarioGestao: !values.idUsuarioGestao ? 'Preenchimento obrigatório' : null,
    idUsuarioServicoCredito: !values.idUsuarioServicoCredito ? 'Preenchimento obrigatório' : null,
    dataEncerramentoSocialDF: !values.dataEncerramentoSocialDF ? 'Preenchimento obrigatório' : null,
    dataEncerramentoSocialAnual: errorsDataEncerramentoSocialAnual.dataEncerramentoSocialAnual,
    
    // Características
    idTitulo: !values.idTitulo ? 'Preenchimento obrigatório' : null,
    idNaturezaOperacao: !values.idNaturezaOperacao ? 'Preenchimento obrigatório' : null,
  };

  return errorsGeral;
};

const mapStateToProps = state => ({
  patrimonioSeparado: state.patrimonioReducer.patrimonioFormTypes?.patrimonioSeparado,
  formActive: state.patrimonioReducer.changePatrimonioFormTab,
  showCancelConfirmDialog: state.confirmDialogReducer.showConfirmDialog,
  messages: state.patrimonioReducer.messages || [],
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePatrimonioFormTab,
      showConfirmDialog,
      goBackToList,
      showMessageAlert,
      limpaDependenteOnChange,
    },
    dispatch
  );

const ConnectedPatrimonioForm = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PatrimonioForm))
);
export default reduxForm({ form: "patrimonioForm", validate })(ConnectedPatrimonioForm);