import axios from "axios";
import { logout } from "../login/LoginAction";
import history from "../../components/common/routers/history";

const AUTHORIZATION_PATH = process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_AUTORIZACAO_API;

export function authorizationFunction(authData, sucessAction) {
  const email = JSON.parse(localStorage.getItem("userLogged"))?.email;

  return (dispatch) => {
    if (email) {
      return axios({
        method: "post",
        url: AUTHORIZATION_PATH,
        data: {
          ...authData,
          email,
        },
      }).then((data) => {
        dispatch(sucessAction);
      }).catch(() => {
        history.push("/")
      });
    } else {
      dispatch(logout());
    }
  };
}

// WITHOUT DISPATCH
export function authorizationWithoutDispatch(authData, successAction) {
  const AUTHORIZATION_URL = `${process.env.REACT_APP_URL_API}${process.env.REACT_APP_PATH_AUTORIZACAO_API}`;
  const userLogged = localStorage.getItem("userLogged");

  if (!userLogged) {
    return;
  }

  const { email } = JSON.parse(userLogged);

  return axios.post(AUTHORIZATION_URL, { ...authData, email }).then(() => successAction());
}
