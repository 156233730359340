import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, formValueSelector, Field } from "redux-form";

import { showConfirmDialog } from "../../../../../actions/common/modal/ConfirmDialogAction";
import {
  showUsuarioList,
  onChangeConta,
  changeConta,
  onChangeUsuario,
} from "../../../../../actions/usuario/UsuarioAction";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";
import DropdownListInput from "../../../../common/input/DropdownListInput";
import Input from "../../../../common/input/Input";
import { actionExportacao, sairDaPagina } from "../../../../../actions/exportacao/ExportacaoAction";
import Button from "../../../../common/button/Button";
import { withRouter } from "react-router-dom";
import ModalCriarArquivo from "../modals/ModalCriarArquivo";
import { convertCurrencyNumber } from "../../../../../utils/helpers/NumberHelper";
import { getDateFormatedFromString } from "../../../../../utils/helpers/DateTimeHelper";
import TableEmptyData from "../../../../common/table/TableEmptyData";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import Table from "../../../../common/table/Table";
import ModalConfirmacao from "../modals/ModalConfirmacao";

let ExportacaoForm = (props) => {
  const {
    title,
    handleSubmit,
    readOnly,
    sairDaPagina,
    idTipoItem,
    exportacao,
    tipoItemsList,
    pedidosCompras,
    edit,
    status,
    actionExportacao,
  } = props;

  const [openCriar, setOpenCriar] = React.useState(false);
  const [action, setAction] = React.useState("");

  const PENDENTE = status?.key == 1;
  const CONCLUIDO = status?.key == 2;

  const data = {
    id: props.match.params?.idExportacao,
    tipoItem: exportacao?.tipoItem,
    action
  }

  const tableHeader = (
    <tr>
        <th className='text-center fw-700'>ID</th>
        <th className='text-center fw-700'>DATA CRIAÇÃO</th>
        <th className='text-center fw-700'>DATA PAGAMENTO</th>
        <th className='text-center fw-700'>PATRIMÔNIO SEPARADO</th>
        <th className='text-center fw-700'>SÉRIE</th>
        <th className='text-center fw-700'>TIPO DE DESPESA</th>
        <th className='text-center fw-700'>FORNECEDOR</th>
        <th className='text-center fw-700'>VALOR BRUTO</th>
    </tr>
  );

  const tableContent = (
    pedidosCompras?.length > 0 
        ? pedidosCompras.map((item, index) =>
            (<tr key={index}>
                <td className='text-center'>{item?.identificador || '--'}</td>
                <td className='text-center'>{item.dataCriacao ? getDateFormatedFromString(item?.dataCriacao) : '--'}</td>
                <td className='text-center'>{item.dataPrevisaoPagamento ? getDateFormatedFromString(item?.dataPrevisaoPagamento) : '--'}</td>
                <td className='text-center'>{item?.patrimonioSeparado || '--'}</td>
                <td className='text-center'>{item?.codigoOpea || '--'}</td>
                <td className='text-center'>{item?.tipoDespesa || '--'}</td>
                <td className='text-center'>{item?.fornecedor || '--'}</td>
                <td className='text-center'>{item?.valorBruto ? convertCurrencyNumber(item?.valorBruto, "R$", 2) : '--'}</td>
            </tr>)
        )
        : <TableEmptyData message={"Sem dados a serem exibidos"} />
  );

  return (
    <Form>
      <FormHeader title={title} col={"col-lg"} offSet={20}></FormHeader>
      <FormContent handleSubmit={handleSubmit}>
        <div className="shadow-custom rounded-12 bg-white px-3 py-2 column">
          <div className="row px-0">
            <div className="col-lg">
              <Field
                component={Input}
                inputId="idExportacao"
                name="idExportacao"
                label="ID de Exportação"
                readOnly={true}
              />
            </div>

            <div className="col-lg">
              <Field
                component={DropdownListInput}
                inputId="idTipoItem"
                name="idTipoItem"
                label="Tipo de Item "
                options={tipoItemsList}
                textField="value"
                valueField="key"
                readOnly={readOnly}
              />
            </div>

            <div className="col-lg">
              <Field
                component={Input}
                inputId="status"
                name="status"
                label="Status"
                readOnly={true}
              />
            </div>
          </div>
        </div>

        {pedidosCompras?.length > 0 && (
          <Table
              withoutHeader
              fileExcel={
                CONCLUIDO
                  ? () =>
                      actionExportacao({
                        ...data,
                        action: "gerar",
                        somenteExportarArquivoCsv: true,
                      })
                  : null
              }
              fileLabel="Exportar Arquivo"
              classes="col-12 shadow-none bg-transparent py-0 my-4 mx-0 mb-2"
          >
              <TableHeader >
                  {tableHeader}
              </TableHeader>
              <TableContent>
                  {tableContent}
              </TableContent>
          </Table>
        )}

        <div className="col-12 px-0">
          <div className="d-flex flex-row justify-content-between align-items-center col-12 p-0 mb-2 mt-3">
            <Button
              name="Voltar Tela"
              classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              action={() => sairDaPagina()}
            />

            {(!exportacao && !edit) && (
              <Button
                name={"Iniciar Criação do Arquivo"}
                classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                action={() => setOpenCriar(true)}
                disabled={!idTipoItem}
              />
            )}

            {PENDENTE && (
              <div className="d-flex flex-row justify-content-between align-items-center">
                <Button
                  name={"Cancelar Criação"}
                  classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 mr-3"
                  action={() => setAction("cancelar")}
                />

                <Button
                  name={"Gerar Arquivo"}
                  classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                  action={() => setAction("gerar")}
                />
              </div>
            )}
          </div>
        </div>
      </FormContent>

      {!exportacao && (
        <ModalCriarArquivo
          open={openCriar}
          close={() => setOpenCriar(false)}
          item={idTipoItem}
        />
      )}

      {action && (
        <ModalConfirmacao
          open={action}
          close={() => setAction("")}
          data={data}
        />
      )}
    </Form>
  );
};

// VALIDAÇÃO
const validate = (values) => {
  const errors = {};
  return errors;
};

const selector = formValueSelector("ExportacaoForm");

const mapStateToProps = (state) => ({
  idTipoItem: selector(state, "idTipoItem") || null,
  lista: selector(state, "lista") || null,
  exportacao: state.exportacaoReducer.exportacaoForm.exportacao || null,
  status: state.exportacaoReducer.exportacaoForm.exportacao?.status || null,
  pedidosCompras: state.exportacaoReducer.exportacaoForm.exportacao?.pedidosCompras || [],
  tipoItemsList: state.exportacaoReducer.exportacaoForm.tipoItemsList || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showUsuarioList,
      showConfirmDialog,
      onChangeConta,
      changeConta,
      onChangeUsuario,
      sairDaPagina,
      actionExportacao,
    },
    dispatch
  );

const ConnectedExportacaoForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(ExportacaoForm));

export default reduxForm({
  form: "ExportacaoForm",
  validate,
})(ConnectedExportacaoForm);
