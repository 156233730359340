import React from "react";
import { Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import DropdownListInput from "../../../../../../common/input/DropdownListInput";
import { bindActionCreators } from "redux";
import { limpaDependenteOnChange } from "../../../../../../../actions/operacao/PassivoAction";
import { getBooleanValueFromString } from "../../../../../../../utils/helpers/BooleanHelper";
import Button from "../../../../../../common/button/Button";

const PassivoFormRegistro = (props) => {
  const {
    tipoArquivoList,
    negociacaoSecundariaList,
    artigoLeiList,
    readOnly,
    limpaDependenteOnChange,
    possuiRating,
    agenciaClassificadoraRisco,
  } = props;
  
  const isRating = getBooleanValueFromString(possuiRating)

  return (
    <div className="mt-4">
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Registro</span>
          <div className="row">
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="tipoArquivo"
                name="tipoArquivo"
                label="Tipo de Arquivo"
                options={tipoArquivoList}
                textField="value"
                valueField="key"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="negociacaoSecundaria"
                name="negociacaoSecundaria"
                label="Negociação Secundária"
                options={negociacaoSecundariaList}
                textField="value"
                valueField="key"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="possuiCoobrigacaoEmissora"
                name="possuiCoobrigacaoEmissora"
                label="Possui Coobrigação da Emissora?"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="existePrevisaoIncorporacaoJuros"
                name="existePrevisaoIncorporacaoJuros"
                label="Existe Previsão para Incorporação de Juros?"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="possuiGarantiaFlutuante"
                name="possuiGarantiaFlutuante"
                label="Possui Garantia Flutuante"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="utilizaAnuncioDistribuicao"
                name="utilizaAnuncioDistribuicao"
                label="Utilização do Anúncio de Distribuição"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="possuiRating"
                name="possuiRating"
                label="Operação Possui Rating"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
                onChange={() => {
                  limpaDependenteOnChange("agenciaClassificadoraRisco", null);
                  limpaDependenteOnChange("ratingSerie", null);
                }}
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="agenciaClassificadoraRisco"
                name="agenciaClassificadoraRisco"
                label="Agência Classificadora de Risco"
                options={tipoArquivoList}
                textField="value"
                valueField="key"
                readOnly={readOnly || !isRating}
                outerClassName={readOnly && "disabled-field"}
                required
                onChange={() => {
                  limpaDependenteOnChange("ratingSerie", null);
                  // BUSCA A LISTA DO ITEM ABAIXO CONFORME ESCOLHA DESTE ITEM
                }}
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="ratingSerie"
                name="ratingSerie"
                label="Rating Série"
                options={tipoArquivoList}
                textField="value"
                valueField="key"
                readOnly={readOnly || !isRating || !agenciaClassificadoraRisco}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="utilizaFaculdade"
                name="utilizaFaculdade"
                label="Utilização da Faculdade do 3º do art. 7º?"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="solicitaRegistroProvisorioCVM"
                name="solicitaRegistroProvisorioCVM"
                label="Solicita Registro Provisório na CVM?"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="enviaArquivosRegistroCVM"
                name="enviaArquivosRegistroCVM"
                label="Envia Arquivos para Registro na CVM?"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="artigoLei"
                name="artigoLei"
                label="Artigo Lei 12.431"
                options={artigoLeiList}
                textField="value"
                valueField="key"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
          </div>

          <Button
            type="button"
            name="Gerar arquivo .txt"
            // action={() => ENDPOINT }
            classes="p-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 mt-2"
          />
        </div>
      </div>
    </div>
  );
};

const selector = formValueSelector("passivoForm");

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      limpaDependenteOnChange,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    possuiRating: selector(state, "possuiRating"),
    agenciaClassificadoraRisco: selector(state, "agenciaClassificadoraRisco"),
    // tipoArquivoList: state.passivoReducer.passivoFormTypes.tipoArquivoList,
    tipoArquivoList: [
      { value: "Registro", key: 1 },
      { value: "Alteração", key: 2 },
    ],
    negociacaoSecundariaList: [
      { value: "Profissional", key: 1 },
      { value: "Qualificado", key: 2 },
      { value: "Sem Restrição", key: 3 },
    ],
    artigoLeiList: [
      { value: "Sim", key: 1 },
      { value: "Não", key: 2 },
      { value: "Não se Aplica", key: 3 },
    ],
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PassivoFormRegistro);
