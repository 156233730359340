import React from "react";
import { Button } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

export default (props) => {
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: props.mainColor,
                contrastText: props.textColor
            },
        },
        typography: {
            button: {
                //capitalize, lowercase, uppercase
                textTransform: !props.textTransform ? "nome" : props.textTransform
            }
        }
    });

    return (
        <MuiThemeProvider theme={theme}>
            <Button
                variant="contained"
                disableelevation="true"
                color="primary"
                disabled={props.disabled}
                onClick={props.action}>
                {props.name}
            </Button>
        </MuiThemeProvider>
    );
}
