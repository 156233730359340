import React from "react";
import { connect } from "react-redux";
import If from "../If/If";
import "./../../../assets/css/loadingScreen.css";

const LoadingScreen = (props) => {
  return (
    <If test={props.isLoading}>
      <div
        className="d-flex h-100 w-100 justify-content-center align-items-center"
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "9999",
          opacity: "0.8",
          backgroundColor: "#00000050",
        }}
      >
        <div className="loading-screen">
          <i className="fa fa-circle-o-notch" aria-hidden="true"></i>
        </div>
      </div>
    </If>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.loadingScreen.isLoading,
});

export default connect(mapStateToProps)(LoadingScreen);
