import axios from "axios";

import history from "../../components/common/routers/history";
import { OPCAO_SISTEMA_ROUTE_PATH, ERRO_ROUTE_PATH, LISTA_ROUTE_PATH, DETALHAR_ROUTE_PATH } from "../../components/common/routers/constantesRoutes";

import { showAllMessages } from "../message/ErrorMessagesAPIAction";

export const LOAD_FILTER_FORM_ERRO = "LOAD_FILTER_FORM_ERRO";
export const LOAD_FORM_ERRO = "LOAD_FORM_ERRO";
export const CLEAN_LIST_CONTENT = "CLEAN_LIST_CONTENT";
export const SEARCH_ERRO = "SEARCH_ERRO";
export const LOAD_FORM_FILTER_LOGS = "LOAD_FORM_FILTER_LOGS";

const ERRO_API = process.env.REACT_APP_URL_API;

export function showErroList(from = "") {
  return (dispatch) => {
    return dispatch([history.push(`/${OPCAO_SISTEMA_ROUTE_PATH}/${ERRO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`)]);
  };
}

export function getErros(data, page = 1, tamanhoPagina = 20) {
  const payload = {
    ...data,
    config: {
      pageIndex: page,
      pageSize: tamanhoPagina
    }
  }

  return (dispatch) => {
    return axios.post(ERRO_API + `logs`, payload).then((result) =>
      dispatch([
        {
          type: LOAD_FILTER_FORM_ERRO,
          payload: result.content,
        },
        {
          type: SEARCH_ERRO,
          payload: data,
        },
        showAllMessages(result.messages),
      ])
    );
  };
}

export function loadFormErro(id) {
  return (dispatch) => {
    return axios.get(ERRO_API + `logs/detalhe/${id || ""}`).then((result) => {
      dispatch([
        id ? history.push(`/${OPCAO_SISTEMA_ROUTE_PATH}/${ERRO_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/${id}`) : undefined,
        {
          type: LOAD_FORM_ERRO,
          payload: result.content,
        },
      ]);
    });
  };
}

export function loadFilterLogs() {
  return (dispatch) => {
    return axios
      .get(ERRO_API + `/logs/tela/filtro`)
      .then((result) => {
        dispatch([
          {
            type: LOAD_FORM_FILTER_LOGS,
            payload: result.content,
          },
        ]);
      });
  };
}

export function cleanResult() {
  return { type: CLEAN_LIST_CONTENT };
}
