/**
 * Busca uma propriedade qualquer de um objeto qualquer (independente se estiver dentro de outras)
 * @param {*} obj
 * @param {*} prop
 */
export function fetchFromObject(obj, prop) {
  if (typeof obj === "undefined") {
    return false;
  }

  var _index = prop.indexOf(".");
  if (_index > -1) {
    return fetchFromObject(
      obj[prop.substring(0, _index)],
      prop.substr(_index + 1)
    );
  }

  return obj[prop];
}

/**
 * @description Clona um objeto e suas dependências considereando referências cíclicas quando houver.
 * @param {*} obj
 * @param {*} hash
 */
export function deepClone(obj, hash = new WeakMap()) {
  if (Object(obj) !== obj) {
    // tipos primitivos
    return obj;
  }

  if (hash.has(obj)) {
    // referências cíclicas
    return hash.get(obj);
  }

  const result =
    obj instanceof Date
      ? new Date(obj)
      : obj instanceof RegExp
      ? new RegExp(obj.source, obj.flags)
      : obj.constructor
      ? new obj.constructor()
      : Object.create(null);

  hash.set(obj, result);

  if (obj instanceof Map) {
    Array.from(obj, ([key, val]) => result.set(key, deepClone(val, hash)));
  }

  return Object.assign(
    result,
    ...Object.keys(obj).map(key => ({
      [key]: deepClone(obj[key], hash)
    }))
  );
}

/**
 * @description Extrai uma Chave específica do Objeto.
 * @param {*} obj Objeto da extração 
 * @param {*} key Chave que deseja extrair
 */
export function extractFromObj(obj, key = "id") {
  return obj && typeof obj == "object" ? obj[key] : obj; 
}
