/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';

import ButtonMuiTheme from '../../../../common/button/ButtonMuiTheme';
import If from "../../../../common/If/If";
import ContainerPage from '../../../../common/container/ContainerPage';
import history from "../../../../common/routers/history";
import ConfirmDialog from "../../../../common/modal/ConfirmDialog";

import AssembleiaGerenciarHeader from './AssembleiaGerenciarHeader';

import InfoWithLeftBar from "../../../../common/text/InfoWithLeftBar";
import { getHourFormatedFromString, getDateFormatedFromString } from "../../../../../utils/helpers/DateTimeHelper";
import { truncate } from "../../../../../utils/helpers/NumberHelper";

import {
    COLOR_TAB_INDICATOR,
    obterDadosTelaResumo,
    finalizarVotacao,
    encerrarAssembleia,
    showDialogEncerrarAssembleia,
    showDialogSuspenderAssembleia,
    STATUS_ASSEMBLEIA_CONVOCADA,
    STATUS_ASSEMBLEIA_ENCERRADA,
    STATUS_ASSEMBLEIA_VOTACAO_ENCERRADA,
    STATUS_ASSEMBLEIA_SUSPENSA,
    STATUS_ASSEMBLEIA_NOVA_CONVOCACAO,
    showDialogNovaConvocacaoAssembleia,
    novaConvocacaoAssembleia,
} from './../../../../../actions/assembleia/AssembleiaAction';
import { authorizationFunction } from '../../../../../actions/authorization/AuthorizationAction';

import { ASSEMBLEIA_ROUTE_PATH, LISTA_ROUTE_PATH } from "../../../../common/routers/constantesRoutes";
import { ASSEMBLEIA, UPDATE } from "../../../../../utils/actionsAndFeaturesAuth";

import { ASSEMBLEIA_GERENCIAR_TABS } from './AssembleiaGerenciarTabs';
import SuspenderAssembleiaModal from "./SuspenderAssembleiaModal";
import AssembleiaGerenciarResumoList from "../list/AssembleiaGerenciarResumoList";

class AssembleiaGerenciarResumoPage extends Component {

    componentDidMount() {
        this.props.obterDadosTelaResumo(this.getParamIdAssembleia());
    }

    getParamIdAssembleia() {
        let idAssembleia = 0;

        if (this.props.match.params.idAssembleia) {
            idAssembleia = this.props.match.params.idAssembleia;
        }

        return idAssembleia;
    }

    getLabelStatus() {
        const { resumo } = this.props;

        let classLabel = '';
        let label = '';
        if (resumo.status) {

            if (resumo.status.key === STATUS_ASSEMBLEIA_ENCERRADA) {
                classLabel = 'label-info-grey';
                label = 'Assembleia Encerrada';
            } else if (resumo.status.key == STATUS_ASSEMBLEIA_VOTACAO_ENCERRADA) {
                classLabel = 'label-info-yellow';
                label = 'Votação Encerrada';
            } else if (resumo.status.key == STATUS_ASSEMBLEIA_NOVA_CONVOCACAO) {
                classLabel = 'label-info-grey';
                label = 'Nova Convocação';
            } else if (resumo.status.key == STATUS_ASSEMBLEIA_SUSPENSA) {
                classLabel = 'label-info-grey';
                label = 'Suspensa';
            }

            return (<div className={`monitor-workflow mt-3 ml-3 mr-3 p-2 ${classLabel}`}>
                {label}
            </div>
            );
        }

        return '';
    }

    getResumoAssembleia() {
        const { resumo } = this.props;

        return (
            <div className="row">
                <InfoWithLeftBar
                    title="Operação"
                    value={resumo.codigoRb}
                    titleClass="pl-2"
                    valueClass="pl-2 pt-1"
                    colorBar="lightgrey"
                    marginRightClass="mr-4"
                    marginLeftClass="ml-4"
                />

                <InfoWithLeftBar
                    title="Data"
                    value={getDateFormatedFromString(resumo.dataHora)}
                    titleClass="pl-2"
                    valueClass="pl-2 pt-1"
                    colorBar="lightgrey"
                    marginRightClass="mr-4"
                    marginLeftClass="ml-4"
                />

                <InfoWithLeftBar
                    title="Hora"
                    value={getHourFormatedFromString(resumo.dataHora)}
                    titleClass="pl-2"
                    valueClass="pl-2 pt-1"
                    colorBar="lightgrey"
                    marginRightClass="mr-4"
                    marginLeftClass="ml-4"
                />

                <InfoWithLeftBar
                    title="Convocação"
                    value={resumo.convocacao ? `${resumo.convocacao}ª convocação` : ''}
                    titleClass="pl-2"
                    valueClass="pl-2 pt-1"
                    colorBar="lightgrey"
                    marginRightClass="mr-4"
                    marginLeftClass="ml-4"
                />

                <InfoWithLeftBar
                    title="Quórum instalação"
                    value={`${resumo.quorumInstalacao}`}
                    titleClass="pl-2"
                    valueClass="pl-2 pt-1"
                    colorBar="lightgrey"
                    marginRightClass="mr-4"
                    marginLeftClass="ml-4"
                />

                <InfoWithLeftBar
                    title="Quórum obtido"
                    value={`${resumo.quorumObtido}`}
                    titleClass="pl-2"
                    valueClass="pl-2 pt-1"
                    colorBar="lightgrey"
                    marginRightClass="mr-4"
                    marginLeftClass="ml-4"
                />

                <InfoWithLeftBar
                    title="% Obtido"
                    value={`${truncate(resumo.percentualObtido, 2)}%`}
                    titleClass="pl-2"
                    valueClass="pl-2 pt-1"
                    colorBar="lightgrey"
                    marginRightClass="mr-4"
                    marginLeftClass="ml-4"
                />
            </div>
        );
    }

    render() {
        const idAssembleia = this.getParamIdAssembleia();
        const { resumo } = this.props;
        const acaoDesabilitada = resumo && resumo.status &&
            (resumo.status.key === STATUS_ASSEMBLEIA_ENCERRADA || resumo.status.key === STATUS_ASSEMBLEIA_SUSPENSA || resumo.status.key === STATUS_ASSEMBLEIA_NOVA_CONVOCACAO);

        return (
            <div className="pr-1">
                <div className="monitor-workflow p-3">
                    <AssembleiaGerenciarHeader
                        showBadgeInstalada
                        assembleiaInstalada={this.props.assembleiaInstalada}
                        title="Gerenciar Assembleia"
                    />

                    <div className="monitor-workflow-head w-100 pb-3 bg-transparent">
                        <ContainerPage
                            tabs={ASSEMBLEIA_GERENCIAR_TABS}
                            tabIndicatorColor={COLOR_TAB_INDICATOR}
                            paramsRoute={idAssembleia}
                        >
                        </ContainerPage>
                        <div className="shadow-custom rounded-12 bg-white px-3 py-2 mx-2">
                            {this.getResumoAssembleia()}
                        </div>
                    </div>
                </div>

                {this.getLabelStatus()}

                <AssembleiaGerenciarResumoList
                    idAssembleia={idAssembleia} />

                <div className="d-flex flex-row pt-2 px-4 justify-content-end">
                    <If test={resumo.status && resumo.status.key === STATUS_ASSEMBLEIA_VOTACAO_ENCERRADA}>
                        <If test={this.props.assembleiaInstalada}>
                            <div className={"pl-3"}>
                                <ButtonMuiTheme
                                    textColor={"#f2f1ef"}
                                    mainColor={"#8c8279"}
                                    action={() => this.props.showDialogSuspenderAssembleia(true, idAssembleia)}
                                    name="Suspender"
                                    disabled={acaoDesabilitada}
                                />
                            </div>
                        </If>
                        <If test={!this.props.assembleiaInstalada}>
                            <div className={"pl-3"}>
                                <ButtonMuiTheme
                                    textColor={"#f2f1ef"}
                                    mainColor={"#8c8279"}
                                    action={() => this.props.showDialogNovaConvocacaoAssembleia(idAssembleia)}
                                    name="Nova Convocação"
                                    disabled={acaoDesabilitada}
                                />
                            </div>
                        </If>

                        <div className={"pl-3"}>
                            <ButtonMuiTheme
                                textColor={"#f2f1ef"}
                                mainColor={"#8c8279"}
                                action={() => this.props.showDialogEncerrarAssembleia(true)}
                                name="Encerrar"
                                disabled={acaoDesabilitada}
                            />
                        </div>
                    </If>


                    <If test={resumo.status && resumo.status.key === STATUS_ASSEMBLEIA_CONVOCADA}>
                        <div className={"pl-3"}>
                            <ButtonMuiTheme
                                textColor={"#ffffff"}
                                mainColor={"#83BF74"}
                                action={() => this.props.authorizationFunction(
                                    { idAcao: UPDATE, idFuncionalidade: ASSEMBLEIA },
                                    () => this.props.finalizarVotacao(idAssembleia)
                                )}
                                name="Finalizar Votação"
                                disabled={acaoDesabilitada}
                            />
                        </div>
                    </If>
                    <div className={"pl-3"}>
                        <ButtonMuiTheme
                            textColor={"#333333"}
                            mainColor={"#D0D0D0"}
                            action={() => history.push(`/${ASSEMBLEIA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`)}
                            name="Cancelar"
                        />
                    </div>
                </div>

                <SuspenderAssembleiaModal />

                <ConfirmDialog
                    title="Aviso"
                    contentText="Deseja encerra esta assembleia ?"
                    textButtonCancel="CANCELAR"
                    textButtonConfirm="CONFIRMAR"
                    showDialog={this.props.encerrarAssembleiaDialog.showDialog}
                    onClose={() => this.props.showDialogEncerrarAssembleia(false)}
                    onConfirm={() => this.props.authorizationFunction(
                        { idAcao: UPDATE, idFuncionalidade: ASSEMBLEIA },
                        () => this.props.encerrarAssembleia(idAssembleia)
                    )}
                    type="warning"
                    maxWidth="md"
                />

                <ConfirmDialog
                    title="Aviso"
                    contentText="Deseja realizar nova convocação?"
                    textButtonCancel="CANCELAR"
                    textButtonConfirm="CONFIRMAR"
                    showDialog={this.props.novaConvocacaoAssembleiaDialog.showDialog}
                    onClose={() => this.props.showDialogNovaConvocacaoAssembleia(false)}
                    onConfirm={() => this.props.authorizationFunction(
                        { idAcao: UPDATE, idFuncionalidade: ASSEMBLEIA },
                        () => this.props.novaConvocacaoAssembleia(idAssembleia)
                    )}
                    type="warning"
                    maxWidth="md"
                />
            </div >
        );
    }
}


const mapStateToProps = state => ({
    resumo: state.assembleiaReducer.assembleiaDadosGerenciar.resumo || null,
    assembleiaInstalada: state.assembleiaReducer.assembleiaDadosGerenciar.assembleiaInstalada || false,
    encerrarAssembleiaDialog: state.assembleiaReducer.showEncerrarAssembleiaDialog,
    novaConvocacaoAssembleiaDialog: state.assembleiaReducer.showNovaConvocacaoAssembleiaDialog,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    obterDadosTelaResumo, finalizarVotacao, encerrarAssembleia,
    showDialogEncerrarAssembleia, showDialogSuspenderAssembleia,
    showDialogNovaConvocacaoAssembleia, novaConvocacaoAssembleia,
    authorizationFunction
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssembleiaGerenciarResumoPage));
