import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { saveAssembleia } from '../../../../actions/assembleia/AssembleiaAction';
import ContainerPage from "../../../common/container/ContainerPage";
import AssembleiaIncluirFormGeral from "./form/AssembleiaIncluirFormGeral";

class AssembleiaPageIncluir extends Component {

    render() {
        const {
            saveAssembleia
        } = this.props;

        return (
            <ContainerPage>
                <AssembleiaIncluirFormGeral
                    onSubmit={saveAssembleia}
                    readOnly={false}
                    floatButtonVisible={true}
                    nameForm={"Incluir Assembléia"}
                    justView={false}
                />
            </ContainerPage>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    saveAssembleia
}, dispatch);

export default connect(null, mapDispatchToProps)(AssembleiaPageIncluir);
