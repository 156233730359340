import axios from "axios";
import { reset, change, formValueSelector, arrayInsert, arrayRemove, initialize } from "redux-form";
import {
    deserializeFieldsAssembleiaFormFilter, serializeFieldsAssembleia, deserializeFieldsAssembleia
} from "../../components/business/assembleia/mappings/AssembleiaMap";
import {
    ASSEMBLEIA_ROUTE_PATH,
    NOVO_ROUTE_PATH,
    DETALHAR_ROUTE_PATH,
    EDITAR_ROUTE_PATH,
    LISTA_ROUTE_PATH,
    GERENCIAR_ROUTE_PATH,
    RESUMO_ROUTE_PATH,
    DOCUMENTO_ROUTE_PATH,
} from "./../../components/common/routers/constantesRoutes";
import history from "../../components/common/routers/history";
import { showErrorMessages } from "../message/ErrorMessagesAPIAction";
import { showAllMessages } from "../message/ErrorMessagesAPIAction";
import { showConfirmDialog } from "../common/modal/ConfirmDialogAction";
import { downloadExcelFile, downloadFile } from "../../utils/helpers/FileHelper";
import { getStringDateTimeTwoField } from "../../utils/helpers/DateTimeHelper";
export const CLEAN_LIST_CONTENT = "CLEAN_LIST_CONTENT";
export const LOAD_FILTER_FORM_ASSEMBLEIA = "LOAD_FILTER_FORM_ASSEMBLEIA";
export const ASSEMBLEIAS_FETCHED = "ASSEMBLEIAS_FETCHED";
export const SHOW_MODAL_ORDEM_ASSEMBLEIA = "SHOW_MODAL_ORDEM_ASSEMBLEIA";
export const ASSEMBLEIA_SHOW_DELETE_DIALOG_CONTATO = "ASSEMBLEIA_SHOW_DELETE_DIALOG_CONTATO";
export const ASSEMBLEIA_SHOW_DELETE_DIALOG = "ASSEMBLEIA_SHOW_DELETE_DIALOG";
export const LOAD_FORM_NOVA_ASSEMBLEIA = "LOAD_FORM_NOVA_ASSEMBLEIA";
export const ASSEMBLEIA_SHOW_CONVOCAR_DIALOG = "ASSEMBLEIA_SHOW_CONVOCAR_DIALOG";
export const LOAD_FORM_ASSEMBLEIA = "LOAD_FORM_ASSEMBLEIA";
export const ASSEMBLEIA_OPERACAO_OPTIONS_FETCHED = "ASSEMBLEIA_OPERACAO_OPTIONS_FETCHED";
export const ASSEMBLEIA_GERENCIAR_TELA_RESUMO = "ASSEMBLEIA_GERENCIAR_TELA_RESUMO";
export const ASSEMBLEIAS_RESUMO_ORDENS_FETCHED = "ASSEMBLEIAS_RESUMO_ORDENS_FETCHED";
export const SHOW_MODAL_DESCRICAO_ORDEM = "SHOW_MODAL_DESCRICAO_ORDEM";
export const ASSEMBLEIA_VOTACAO_FINALIZADA = "ASSEMBLEIA_VOTACAO_FINALIZADA";
export const ASSEMBLEIAS_GERENCIAR_PARTICIPANTES_FETCHED = "ASSEMBLEIAS_GERENCIAR_PARTICIPANTES_FETCHED";
export const ASSEMBLEIAS_GERENCIAR_SOLICITACOES_FETCHED = "ASSEMBLEIAS_GERENCIAR_SOLICITACOES_FETCHED";
export const ASSEMBLEIA_SHOW_ANEXO_DELETE_DIALOG = "ASSEMBLEIA_SHOW_ANEXO_DELETE_DIALOG";
export const ASSEMBLEIA_SHOW_GERENCIAR_INVESTIDOR_DIALOG = "ASSEMBLEIA_SHOW_GERENCIAR_INVESTIDOR_DIALOG";
export const ASSEMBLEIA_GERENCIAR_INVESTIDOR = "ASSEMBLEIA_GERENCIAR_INVESTIDOR";
export const ASSEMBLEIA_GERENCIAR_INVESTIDOR_VOTOS = "ASSEMBLEIA_GERENCIAR_INVESTIDOR_VOTOS";
export const ASSEMBLEIA_SITUACAO_INSTALACAO = "ASSEMBLEIA_SITUACAO_INSTALACAO";
export const ASSEMBLEIA_SHOW_GERENCIAR_SOLICITACAO_PARTICIPACAO_DIALOG = "ASSEMBLEIA_SHOW_GERENCIAR_SOLICITACAO_PARTICIPACAO_DIALOG";
export const ASSEMBLEIA_GERENCIAR_SOLICITACAO_PARTICIPACAO = "ASSEMBLEIA_GERENCIAR_SOLICITACAO_PARTICIPACAO";
export const ASSEMBLEIA_GERENCIAR_SOLICITACAO_PARTICIPACAO_PROCURADORES = "ASSEMBLEIA_GERENCIAR_SOLICITACAO_PARTICIPACAO_PROCURADORES";
export const ASSEMBLEIA_SHOW_GERENCIAR_ENCERRAR_ASSEMBLEIA_DIALOG = "ASSEMBLEIA_SHOW_GERENCIAR_ENCERRAR_ASSEMBLEIA_DIALOG";
export const ASSEMBLEIA_SHOW_GERENCIAR_SUSPENDER_ASSEMBLEIA_DIALOG = "ASSEMBLEIA_SHOW_GERENCIAR_SUSPENDER_ASSEMBLEIA_DIALOG";
export const ASSEMBLEIA_SHOW_GERENCIAR_NOVA_CONVOCACAO_DIALOG = "ASSEMBLEIA_SHOW_GERENCIAR_NOVA_CONVOCACAO_DIALOG";
export const ASSEMBLEIA_GERENCIAR_INVESTIDOR_REPRESENTANTES = "ASSEMBLEIA_GERENCIAR_INVESTIDOR_REPRESENTANTES";
const NO_ACTION = { type: '' };


const ASSEMBLEIA_API = process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_ASSEMBLEIA_API;
const ASEMBLEIA_GERENCIAR_API = ASSEMBLEIA_API + GERENCIAR_ROUTE_PATH;

export const COLOR_TAB_INDICATOR = "#630A37";
export const ORDEM_APROVADA = 1;
export const ORDEM_REJEITADA = 2;
export const DOCUMENTO_TIPO_ATA = 1;
export const DOCUMENTO_TIPO_PROPOSTA_ADM = 2;
export const DOCUMENTO_TIPO_DIVERSOS = 3;

export const STATUS_ASSEMBLEIA_PENDENTE = 1;
export const STATUS_ASSEMBLEIA_CONVOCADA = 2;
export const STATUS_ASSEMBLEIA_ENCERRADA = 3;
export const STATUS_ASSEMBLEIA_VOTACAO_ENCERRADA = 4;
export const STATUS_ASSEMBLEIA_SUSPENSA = 5;
export const STATUS_ASSEMBLEIA_NOVA_CONVOCACAO = 6;

export const TIPO_PARTICIPANTE_PROCURADOR = 2;

export const TIPO_ASSEMBLEIA_PRESENCIAL = 1;
export const TIPO_ASSEMBLEIA_REMOTO = 2;
export const TIPO_ASSEMBLEIA_AMBOS = 3;

export function cleanResult() {
    return (dispatch) => {
        return dispatch([
            reset('assembleiaFilterForm'),
            { type: CLEAN_LIST_CONTENT },
        ])
    }
}

export function loadFilterFormAssembleia() {
    return dispatch => {
        return axios.get(`${ASSEMBLEIA_API}tela/filtro`)
            .then(result =>
                dispatch({
                    type: LOAD_FILTER_FORM_ASSEMBLEIA,
                    payload: result.content
                })
            );
    };
}

export function loadFormGeralAssembleia() {
    return dispatch => {
        return axios.get(`${ASSEMBLEIA_API}tela`)
            .then(result =>
                dispatch({
                    type: LOAD_FORM_NOVA_ASSEMBLEIA,
                    payload: result.content
                })
            );
    };
}

export function selectOperacaoNoForm(option, formName, fieldName) {
    return (dispatch) => {
        return dispatch([
            change(formName, fieldName, option),
        ])
    }
}

export function getAssembleias(filters, page = 1) {
    const params = deserializeFieldsAssembleiaFormFilter(filters, page);

    return (dispatch) => {
        return axios
            .post(`${ASSEMBLEIA_API}assembleias`, { ...params })
            .then(result =>
                dispatch({
                    type: ASSEMBLEIAS_FETCHED,
                    payload: { result },
                })
            );
    }
}

export function novaAssembleia() {
    return dispatch => {
        return dispatch([
            history.push(`/${ASSEMBLEIA_ROUTE_PATH}/${NOVO_ROUTE_PATH}`)
        ]);
    };
}

export function editarOrdemAssembleia(indexOrdem) {
    return (dispatch, getState) => {
        const selector = formValueSelector('assembleiaIncluirForm')
        let listaOrdemAssembleia = selector(getState(), 'ordemAssembleiaDtoList') || null;

        return dispatch([
            change("assembleiaIncluirForm", "umaOrdem", listaOrdemAssembleia[indexOrdem]),
            showModalAssembleiaOrdemDialog(true, indexOrdem)
        ]);
    }
}

export function removeOrdemAssembleia(index) {
    return (dispatch) => {
        return dispatch([
            arrayRemove("assembleiaIncluirForm", "ordemAssembleiaDtoList", index),
            showDeleteDialogAssembleiaOrdem(false, 0)
        ]);
    }
}

export function showDeleteDialogAssembleiaOrdem(showDialog, idOrdem) {
    return {
        type: ASSEMBLEIA_SHOW_DELETE_DIALOG_CONTATO,
        payload: { showDialog, idOrdem }
    }
}

export function showModalAssembleiaOrdemDialog(showDialog, indexContato) {
    return (dispatch) => {

        let objDispatch = [{
            type: SHOW_MODAL_ORDEM_ASSEMBLEIA,
            payload: { showDialog, indexContato }
        }];

        if (indexContato < 0)
            objDispatch.push(change("assembleiaIncluirForm", "umaOrdem", {}));

        return dispatch(objDispatch);
    }
}

export function addOrdemAssembleia(umaOrdem) {
    let error = validarOrdemAssembleia(umaOrdem)

    return (dispatch, getState) => {
        if (error.length === 0) {
            const selector = formValueSelector('assembleiaIncluirForm');
            let listaOrdemAssembleia = selector(getState(), 'ordemAssembleiaDtoList') || null;
            let indexAdd = listaOrdemAssembleia != null ? listaOrdemAssembleia.length : 0;

            return dispatch([
                arrayInsert("assembleiaIncluirForm", "ordemAssembleiaDtoList", indexAdd, umaOrdem),
                change("assembleiaIncluirForm", "umaOrdem", {}),
                showModalAssembleiaOrdemDialog(false, -1)
            ]);
        } else {
            return dispatch([
                showErrorMessages(error)
            ]);
        }
    }
}

export function atualizarContatoDeMonitoramento(umaOrdem, index) {
    let error = validarOrdemAssembleia(umaOrdem)

    return (dispatch) => {
        if (error.length === 0) {
            return dispatch([
                change(`assembleiaIncluirForm`, `ordemAssembleiaDtoList[${index}]`, umaOrdem),
                change("assembleiaIncluirForm", "umaOrdem", {}),
                showModalAssembleiaOrdemDialog(false, -1)
            ]);
        } else {
            return dispatch([
                showErrorMessages(error)
            ]);
        }
    }
}

function validarOrdemAssembleia(ordem) {
    let errors = [];

    if (!ordem || !ordem.titulo)
        errors.push("O campo Título é obrigatório");

    if (!(ordem) || !ordem.descricao)
        errors.push("O campo Descrição é obrigatório");

    /*if (!(ordem) || !ordem.quorum)
        errors.push("O campo Quórum Aprovação é obrigatório");*/

    if (!(ordem) || !ordem.tipoQuorumOrdem)
        errors.push("O campo Tipo Quórum é obrigatório");

    if (!(ordem) || !ordem.quorumAprovacao)
        errors.push("O campo Quórum Aprovaço é obrigatório");

    return errors;
}

export function acoes(idAcao, idAssembleia, anyObject) {
    // ordem dos ids: Alterar(0), Excluir(1), Detalhar(2), Convocar(3), Gerenciar(4) 
    return dispatch => {
        switch (idAcao) {
            case 0:
                return dispatch(loadFormAssembleia(idAssembleia, true, anyObject));
            case 1:
                return dispatch(showAssembleiaDeleteDialog({ showDialog: true, idAssembleia }));
            case 2:
                return dispatch(loadFormAssembleiaVisualize(idAssembleia, false));
            case 3:
                return dispatch(showAssembleiaConvocarDialog({ showDialog: true, idAssembleia }));
            case 4:
                return dispatch(getDispatchGerencarAssembleia(idAssembleia, anyObject));

            default:
                return dispatch(NO_ACTION);
        }
    }
}

function getDispatchGerencarAssembleia(idAssembleia, status) {
    let result = [];
    const ASSEMBLEIA_STATUS_KEY_PENDENTE = 1;

    if (status.key === ASSEMBLEIA_STATUS_KEY_PENDENTE) {
        result.push(
            showErrorMessages(["Não é permitido gerenciar assembleia antes da sua convocação."])
        );
    } else {
        result.push(history.push(`/${ASSEMBLEIA_ROUTE_PATH}/${GERENCIAR_ROUTE_PATH}/${RESUMO_ROUTE_PATH}/${idAssembleia}`));
    }

    return result;
}

export function showAssembleiaDeleteDialog(payload) {
    return dispatch => {
        return dispatch({ type: ASSEMBLEIA_SHOW_DELETE_DIALOG, payload });
    }
}

export function loadFormAssembleia(idAssembleia, editAction, idStatusAssembleia = null) {
    let url = ASSEMBLEIA_API + idAssembleia;
    let routeAction = DETALHAR_ROUTE_PATH;
    const STATUS_ASSEMBLEIA_ENCERRADA = 3;
    let errors = [];

    if (editAction) {
        url = ASSEMBLEIA_API + `tela/${idAssembleia || ''}`;
        routeAction = EDITAR_ROUTE_PATH;
    }

    return dispatch => {
        if (idStatusAssembleia && idStatusAssembleia.key === STATUS_ASSEMBLEIA_ENCERRADA) {

            errors.push("Assembléia já Encerrada. Utilize a opção de Detalhar.");
            showErrorMessages(errors)

        } else {
            return axios.get(url)
                .then(result => {
                    dispatch([
                        idAssembleia ? history.push(`/${ASSEMBLEIA_ROUTE_PATH}/${routeAction}/${idAssembleia}`) : undefined,
                        {
                            type: LOAD_FORM_ASSEMBLEIA,
                            payload: result.content
                        },
                        deserializeFieldsAssembleia(result.content)
                    ])
                });
        }

    };

}
export function loadFormAssembleiaVisualize(idAssembleia) {
    let url = ASSEMBLEIA_API + `tela/${idAssembleia || ''}`;

    return dispatch => {
        return axios.get(url)
            .then(result => {
                dispatch([
                    idAssembleia ? history.push(`/${ASSEMBLEIA_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/${idAssembleia}`) : undefined,
                    {
                        type: LOAD_FORM_ASSEMBLEIA,
                        payload: result.content
                    },
                    deserializeFieldsAssembleia(result.content)
                ])
            });
    };
}

export function showAssembleiaConvocarDialog(payload) {
    return dispatch => {
        return dispatch({ type: 'ASSEMBLEIA_SHOW_CONVOCAR_DIALOG', payload });
    }
}

export function removerAssembleia(idAssembleia, filter, page) {
    return (dispatch) => {
        return axios
            .delete(ASSEMBLEIA_API + idAssembleia)
            .then(result =>
                dispatch([
                    showAssembleiaDeleteDialog({ showDialog: false }),
                    showAllMessages(result.messages),
                    getAssembleias(filter, page)
                ])
            );
    }
}

export function uploadReadListaInvestidores(fileInvestidores) {
    let arquivo = fileInvestidores[0].all;
    let formData = new FormData();
    formData.append("file", arquivo);

    return dispatch => {
        return axios
            .post(ASSEMBLEIA_API + "prepararExcelInvestidores", formData)
            .then(result => {
                let investidoresList = [];

                result.content.forEach(investidor => {
                    investidoresList.push(investidor)
                });

                dispatch([
                    change('assembleiaIncluirForm', 'investidoresAssembleiaDtoList', null),
                    change('assembleiaIncluirForm', 'investidoresAssembleiaDtoList', investidoresList),
                    change('assembleiaIncluirForm', 'listaInvestidorExpanded', true)
                ])
            })
            .catch(error => {
                dispatch([change('assembleiaIncluirForm', 'listaAnexosInvestidores', [])])
            });
    };
}

export function convocarAssembleia(idAssembleia, filter, page) {
    return (dispatch) => {
        return axios
            .post(`${ASSEMBLEIA_API}convocar`, { idAssembleia })
            .then(result =>
                dispatch([
                    showAssembleiaConvocarDialog({ showDialog: false }),
                    showAllMessages(result.messages),
                    getAssembleias(filter, page)
                ])
            ).catch(e => {
                dispatch(showAssembleiaConvocarDialog({ showDialog: false }))
            });
    }
}

export function backAssembleiaList() {
    return dispatch => {
        return dispatch([
            showConfirmDialog(false),
            history.push(`/${ASSEMBLEIA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`)
        ]);
    };
}

export function saveAssembleia(assembleia) {
    const serialized = serializeFieldsAssembleia(assembleia);
    let horaErro = validaDataHora(assembleia.dataAssembleia, assembleia.hora)

    return (dispatch) => {
        if (horaErro.length === 0) {
            return axios
                .post(ASSEMBLEIA_API, { ...serialized })
                .then(result =>
                    dispatch([
                        backAssembleiaList(),
                        showAllMessages(result.messages),
                    ])
                );
        } else {
            return dispatch([
                showErrorMessages(horaErro)
            ]);
        }
    }
}

export function editSaveAssembleia(assembleia) {
    const serialized = serializeFieldsAssembleia(assembleia);
    let horaErro = validaDataHora(assembleia.dataAssembleia, assembleia.hora)

    return (dispatch) => {
        if (horaErro.length === 0) {
            return axios
                .put(ASSEMBLEIA_API, { ...serialized })
                .then(result =>
                    dispatch([
                        backAssembleiaList(),
                        showAllMessages(result.messages),
                        atualizarInstalacaoAssembleia(serialized.id)
                    ])
                );
        } else {
            return dispatch([
                showErrorMessages(horaErro)
            ]);
        }
    }
}

function validaDataHora(dataAssembleiaPreenchida, horaPreenchida) {
    const matchHora = "^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]?$";
    let errors = [];

    if (horaPreenchida && horaPreenchida !== "" && !horaPreenchida.match(matchHora)) {
        errors.push("O campo Horário não é válido.");
    }
    else if ((dataAssembleiaPreenchida && dataAssembleiaPreenchida !== "") && (!horaPreenchida || horaPreenchida === "")) {
        errors.push("O campo Horário deve ser preenchido.");
    }
    else if ((horaPreenchida && horaPreenchida !== "") && (!dataAssembleiaPreenchida || dataAssembleiaPreenchida === "")) {
        errors.push("O campo Data deve ser preenchido.");
    }

    return errors;
}

export function donwloadTemplateAnexoInvestidores() {
    return (dispatch) => {
        return axios
            .get(`${ASSEMBLEIA_API}exportar/excel/investidor/modelo`)
            .then(result => {
                dispatch([
                    downloadExcelFile(result.content, "Modelo lista de investidores.xlsx"),
                    showAllMessages(result.messages),
                ])
            });
    }
}

export function donwloadEditalConvocacao(idAssembleia, nomeArquivo) {
    return (dispatch) => {
        return axios
            .get(`${ASSEMBLEIA_API}downloadeditalconvocacao/${idAssembleia}`)
            .then(result => {
                dispatch([
                    downloadExcelFile(result.content, nomeArquivo),
                    showAllMessages(result.messages),
                ])
            });
    }
}

export function obterDadosTelaResumo(idAssembleia) {
    return (dispatch) => {
        const paramsOrder = {
            idAssembleia,
            pagingConfig: { pageIndex: 1, pageSize: 10 }
        };
        const API_RESUMO = `${ASEMBLEIA_GERENCIAR_API}/resumo`;

        const reqResumo = axios.post(API_RESUMO, { idAssembleia });
        const reqOrdens = axios.post(`${API_RESUMO}/ordens`, { ...paramsOrder })

        return axios
            .all([reqResumo, reqOrdens])
            .then(axios.spread((...responses) => {
                const resResumo = responses[0];
                const resOrdens = responses[1];

                dispatch([
                    { type: ASSEMBLEIA_GERENCIAR_TELA_RESUMO, payload: { resumo: resResumo.content, ordemList: resOrdens.content } },
                    showAllMessages(resResumo.messages),
                ]);
            }));
    }
}

export function getOrdens(idAssembleia, page = 1) {
    const paramsOrder = {
        idAssembleia,
        pagingConfig: {
            pageIndex: page,
            pageSize: 10
        },
    };

    return (dispatch) => {
        return axios
            .post(`${ASEMBLEIA_GERENCIAR_API}/resumo/ordens`, { ...paramsOrder })
            .then(result =>
                dispatch({
                    type: ASSEMBLEIAS_RESUMO_ORDENS_FETCHED,
                    payload: { ...result.content },
                })
            );
    }
}

export function showModalDescricaoOrdem(showDialog = false, titulo = '', descricao = '') {
    return (dispatch) => {
        return dispatch([
            {
                type: SHOW_MODAL_DESCRICAO_ORDEM,
                payload: { showDialog, titulo, descricao }
            },
        ])
    }
}

export function finalizarVotacao(idAssembleia) {
    return (dispatch) => {
        return axios
            .post(`${ASSEMBLEIA_API}finalizarVotacao`, { idAssembleia })
            .then(result => {
                dispatch([
                    { type: ASSEMBLEIA_VOTACAO_FINALIZADA, payload: { ...result.content } },
                    showAllMessages(result.messages)
                ])
            });
    }
}

export function obterDocumentosDaAssembleia(idAssembleia) {
    return (dispatch) => {
        return axios
            .get(`${ASSEMBLEIA_API}anexos/${idAssembleia}`)
            .then(result => {
                const documentos = result.content;
                const editalConvocacao = documentos.find(doc => doc.tipoAnexo.key === 0) || undefined;
                const ata = documentos.find(doc => doc.tipoAnexo.key === DOCUMENTO_TIPO_ATA) || undefined;
                const propostaAdm = documentos.find(doc => doc.tipoAnexo.key === DOCUMENTO_TIPO_PROPOSTA_ADM) || undefined;
                const diversos = documentos.filter(doc => doc.tipoAnexo.key === DOCUMENTO_TIPO_DIVERSOS) || [];

                const dataForm = { editalConvocacao, ata, propostaAdm, diversos };

                dispatch([
                    initialize("assembleiaGerenciarDocumentosForm", { ...dataForm }),
                    showAllMessages(result.messages),
                    ObterSituacaoInstalacaoAssembleia(idAssembleia)
                ])
            });
    }
}

function getTipoAnexo(tipoAnexo) {
    let raw = 'Diversos';

    if (tipoAnexo === DOCUMENTO_TIPO_ATA)
        raw = 'AtaAssembleia';
    else if (tipoAnexo === DOCUMENTO_TIPO_PROPOSTA_ADM)
        raw = 'PropostaAdministracao';

    return { key: tipoAnexo, raw }
}

export function uploadAnexo(idAssembleia, fileToUpload) {
    const dto = {
        idAssembleia,
        tipoAnexo: getTipoAnexo(fileToUpload.tipoAnexo),
        nomeArquivo: fileToUpload.name,
        arquivo: fileToUpload.arquivo
    }

    return (dispatch) => {
        return axios
            .post(`${ASSEMBLEIA_API}anexo/upload`, { ...dto })
            .then(result => {
                dispatch([
                    showAllMessages(result.messages),
                    obterDocumentosDaAssembleia(idAssembleia)
                ])
            });
    }
}

export function removerAnexo(idAnexo, idAssembleia) {
    return (dispatch) => {
        return axios
            .delete(`${ASSEMBLEIA_API}anexo/${idAnexo}`)
            .then(result =>
                dispatch([
                    showAllMessages(result.messages),
                    obterDocumentosDaAssembleia(idAssembleia),
                    showAnexoDeleteDialog(false, idAssembleia)
                ])
            );
    }
}

export function showAnexoDeleteDialog(showDialog, idAssembleia, fileId, tipoAnexo) {
    let descricaoTitulo = 'o anexo';

    if (tipoAnexo === DOCUMENTO_TIPO_ATA)
        descricaoTitulo = 'a ata da assembleia';
    else if (tipoAnexo === DOCUMENTO_TIPO_PROPOSTA_ADM)
        descricaoTitulo = 'a proposta da administração';

    return (dispatch) => {
        return dispatch([
            {
                type: ASSEMBLEIA_SHOW_ANEXO_DELETE_DIALOG,
                payload: { showDialog, fileId, idAssembleia, descricaoTitulo }
            },
            history.push(`/${ASSEMBLEIA_ROUTE_PATH}/${GERENCIAR_ROUTE_PATH}/${DOCUMENTO_ROUTE_PATH}/${idAssembleia}`)
        ])
    }
}

export function downloadAnexo(fileId) {
    return (dispatch) => {
        return axios
            .get(`${ASSEMBLEIA_API}anexo/download/${fileId}`)
            .then(result => {
                dispatch([
                    showAllMessages(result.messages),
                    downloadFile(result.content.arquivo, result.content.nomeArquivo),
                ])
            });
    }
}

export function updloadDocumentosDiversos(idAssembleia, filesToUpload) {
    return (dispatch) => {
        for (let i = 0; i < filesToUpload.length; i++) {
            const errors = arquivoDiversoValido(filesToUpload[i]);

            if (errors.length > 0) {
                return dispatch([
                    showErrorMessages(errors)
                ]);
            }
        }

        let promises = [];
        let messages = [];
        for (let i = 0; i < filesToUpload.length; i++) {
            const dto = {
                idAssembleia,
                tipoAnexo: { key: 3, raw: 'Diversos' },
                nomeArquivo: filesToUpload[i].name,
                arquivo: filesToUpload[i].arquivo
            }

            promises.push(
                axios.post(`${ASSEMBLEIA_API}anexo/upload`, { ...dto })
                    .then(result => {
                        messages.push(result.messages)
                    })
            );
        }

        return Promise.all(promises).then(result => {
            dispatch([
                showAllMessages(messages[0]),
                obterDocumentosDaAssembleia(idAssembleia)
            ])
        });
    }
}

export function atualizaInvestidores(fileInvestidores) {
    const arquivoAnexo = {
        nomeArquivo: fileInvestidores.all.name,
        arquivo: fileInvestidores.arquivo
    };

    const data = {
        arquivoInvestidor: arquivoAnexo,
        idAssembleia: fileInvestidores.idAssembleia
    }


    return dispatch => {
        return axios
            .post(ASSEMBLEIA_API + "atualizarInvestidores", { ...data })
            .then(result => {
                dispatch([
                    showAllMessages(result.messages),
                    laodAbaParticipante(fileInvestidores.idAssembleia),
                    ObterSituacaoInstalacaoAssembleia(fileInvestidores.idAssembleia)
                ])
            });
    };
}

export function buscarInvestidoresPorAssembleiaTermo(data, page = 1) {
    const filter = {
        idAssembleia: data.idAssembleia,
        termo: data.termo,
        pagingConfig: {
            pageIndex: page,
            pageSize: 5
        },
        statusSolicitacao: data.statusSolicitacao && data.statusSolicitacao.raw ? data.statusSolicitacao : null,
    };

    return (dispatch) => {
        return axios
            .post(`${ASSEMBLEIA_API}investidores`, { ...filter })
            .then(result => {
                dispatch([
                    { type: ASSEMBLEIAS_GERENCIAR_PARTICIPANTES_FETCHED, payload: result.content },
                ])
            });
    }
}

export function laodAbaParticipante(idAssembleia) {
    return (dispatch) => {
        const filter = {
            idAssembleia,
            statusSolicitacaoParticipacao: 2,
            pagingConfig: {
                pageIndex: 1,
                pageSize: 5
            },
        };

        const reqInvestidores = axios.post(`${ASSEMBLEIA_API}investidores`, { ...filter })
        const reqObterSituacaoInstalacao = axios.get(`${ASSEMBLEIA_API}situacaoInstalacao/${idAssembleia}`);

        return axios
            .all([reqInvestidores, reqObterSituacaoInstalacao])
            .then(axios.spread((...responses) => {
                const resInvestidores = responses[0];
                const resObterSituacaoInstalacao = responses[1];


                dispatch([
                    { type: ASSEMBLEIAS_GERENCIAR_PARTICIPANTES_FETCHED, payload: resInvestidores.content },
                    { type: ASSEMBLEIA_SITUACAO_INSTALACAO, payload: resObterSituacaoInstalacao },
                ]);
            }));
    }
}

export function buscarSolicitacoesPorAssembleiaTermo(data, page = 1) {
    const filter = {
        idAssembleia: data.idAssembleia,
        termo: data.termo,
        tipoParticipanteAssembleia: data.tipoParticipanteAssembleia && data.tipoParticipanteAssembleia.key > 0 ?
            data.tipoParticipanteAssembleia : null,
        statusSolicitacaoParticipacao: data.statusSolicitacaoParticipacao ?
            data.statusSolicitacaoParticipacao.key : null,
        pagingConfig: {
            pageIndex: page,
            pageSize: 5
        },
    };

    return (dispatch) => {
        return axios
            .post(`${ASSEMBLEIA_API}usuariosComSolicitacoes`, { ...filter })
            .then(result => {
                dispatch([
                    { type: ASSEMBLEIAS_GERENCIAR_SOLICITACOES_FETCHED, payload: result.content },
                ])
            });
    }
}

export function obterDadosGerenciarInvestidor(idAssembleia, idInvestidor) {
    return (dispatch) => {
        const paramsVotos = {
            idAssembleia,
            idInvestidor,
            pagingConfig: { pageIndex: 1, pageSize: 5 }
        };

        const paramsRepresentantes = {
            idAssembleia,
            idInvestidor,
            pagingConfig: {
                pageIndex: 1,
                pageSize: 10
            }
        };

        const apiGerenciarInvestidor = `${ASSEMBLEIA_API}gerenciar/investidor`;
        const reqDadosInvestidor = axios.post(apiGerenciarInvestidor, { idAssembleia, idInvestidor });
        const reqVotos = axios.post(`${apiGerenciarInvestidor}/ordens`, { ...paramsVotos });
        const reqRepresentantes = axios.post(`${apiGerenciarInvestidor}/representantes`, { ...paramsRepresentantes });
        const reqObterInstalacao = axios.get(`${ASSEMBLEIA_API}situacaoInstalacao/${idAssembleia}`);

        return axios
            .all([reqDadosInvestidor, reqVotos, reqRepresentantes, reqObterInstalacao])
            .then(axios.spread((...responses) => {
                const resInvestidor = responses[0];
                const resVotos = responses[1];
                const votoAceito = resVotos.content && resVotos.content.hasItems ? resVotos.content.items[0].votoAceito : false;
                const resRepresentantes = responses[2];
                const resObterInstalacao = responses[3];

                const dadosForm = {
                    ...resInvestidor.content,
                    idAssembleia,
                    votoAceito
                };

                dispatch([
                    initialize('gerenciarInvestidorForm', { ...dadosForm }),
                    { type: ASSEMBLEIA_GERENCIAR_INVESTIDOR, payload: { dadosInvestidor: resInvestidor.content } },
                    { type: ASSEMBLEIA_GERENCIAR_INVESTIDOR_VOTOS, payload: { votosList: resVotos.content } },
                    { type: ASSEMBLEIA_GERENCIAR_INVESTIDOR_REPRESENTANTES, payload: { representantesList: resRepresentantes.content } },
                    { type: ASSEMBLEIA_SHOW_GERENCIAR_INVESTIDOR_DIALOG, payload: { showDialog: true, idAssembleia } },
                    { type: ASSEMBLEIA_SITUACAO_INSTALACAO, payload: resObterInstalacao },
                    showAllMessages(resInvestidor.messages),
                ]);
            }));
    }
}

export function getVotos(dadosConsulta, page = 1) {
    const { idAssembleia, idInvestidor } = dadosConsulta;
    const idProcurador = dadosConsulta.idUsuarioPortal || 0;

    let paramVotos = {
        idAssembleia,
        idInvestidor,
        pagingConfig: {
            pageIndex: page,
            pageSize: 5
        },
    };

    if (idProcurador > 0) {
        paramVotos.idProcuradorHabilitado = idProcurador;
    }


    return (dispatch) => {
        return axios
            .post(`${ASSEMBLEIA_API}gerenciar/investidor/ordens`, { ...paramVotos })
            .then(result => {
                const votoAceito = result.content && result.content.hasItems ? result.content.items[0].votoAceito : false;
                dispatch([
                    {
                        type: ASSEMBLEIA_GERENCIAR_INVESTIDOR_VOTOS,
                        payload: { votosList: result.content },
                    },
                    change('gerenciarInvestidorForm', 'votoAceito', votoAceito)
                ])
            });
    }
}

export function gerenciarVotos(dadosForm) {
    const { idAssembleia, idInvestidor, representantesList } = dadosForm;

    return (dispatch, getState) => {
        const selector = formValueSelector('gerenciarInvestidorForm')
        const votoAceito = selector(getState(), 'votoAceito') || false;

        return axios
            .put(`${ASSEMBLEIA_API}gerenciar/investidor/voto`, { idAssembleia, idInvestidor, votoAceito, representantesList })
            .then(result =>
                dispatch([
                    closeModalGerenciarInvestidor(),
                    laodAbaParticipante(idAssembleia),
                    showAllMessages(result.messages),
                    atualizarInstalacaoAssembleia(idAssembleia),
                ])
            );
    }
}

export function closeModalGerenciarInvestidor() {
    return (dispatch) => {
        return dispatch([
            {
                type: ASSEMBLEIA_SHOW_GERENCIAR_INVESTIDOR_DIALOG,
                payload: { showDialog: false }
            }
        ]);
    }
}

export function toggleRemoverProcurador(value, statusAssembleia) {
    return (dispatch) => {
        return dispatch(
            statusPermiteRealizarAcao(statusAssembleia) ?
                change('gerenciarInvestidorForm', 'removerProcurador', value) :
                { type: NO_ACTION }
        );
    }
}

export function toggleAceitarVotos(value, statusAssembleia) {
    return (dispatch) => {
        return dispatch(
            statusPermiteRealizarAcao(statusAssembleia) ?
                change('gerenciarInvestidorForm', 'votoAceito', value) :
                { type: NO_ACTION }
        );
    }
}

function statusPermiteRealizarAcao(statusAssembleia) {
    return statusAssembleia &&
        statusAssembleia.key !== STATUS_ASSEMBLEIA_ENCERRADA &&
        statusAssembleia.key !== STATUS_ASSEMBLEIA_SUSPENSA &&
        statusAssembleia.key !== STATUS_ASSEMBLEIA_NOVA_CONVOCACAO;
}

export function downloadDocumentoInvestidor(id, nomeArquivo) {
    return (dispatch) => {
        return axios
            .get(`${ASSEMBLEIA_API}gerenciar/investidor/downloaddocumento/${id}`)
            .then(result => {
                dispatch([
                    showAllMessages(result.messages),
                    downloadFile(result.content, nomeArquivo),
                ])
            });
    }
}

function arquivoDiversoValido(dto) {
    let errors = [];

    if (dto.arquivo === undefined || dto.arquivo.length === 0) {
        errors.push("O campo Arquivo é obrigatório.");
    }

    if (dto.name === undefined || dto.name.length === 0) {
        errors.push("O campo Nome Arquivo é obrigatório.");
    }

    if (dto.name !== undefined && dto.name.length > 0) {
        const extensao = dto.name.split('.')[1];
        const extensoesValidas = ["png", "jpeg", "jpg", "pdf", "xls", "xlsx"];
        if (!extensoesValidas.some(e => e === extensao)) {
            errors.push("O arquivo informado não está num formato válido.");
        }
    }

    return errors;
}

export function ObterSituacaoInstalacaoAssembleia(idAssembleia) {
    return dispatch => {
        return axios
            .get(`${ASSEMBLEIA_API}situacaoInstalacao/${idAssembleia}`)
            .then(result =>
                dispatch({
                    type: ASSEMBLEIA_SITUACAO_INSTALACAO,
                    payload: result
                })
            );
    };
}

export function atualizarInstalacaoAssembleia(idAssembleia) {
    return dispatch => {
        return axios
            .put(`${ASSEMBLEIA_API}atualizarInstalacao/${idAssembleia}`)
            .then(result =>
                dispatch([
                    ObterSituacaoInstalacaoAssembleia(idAssembleia),
                ])
            );
    };
}

export function obterDadosSolicitacaoParticipacao(idAssembleia, IdUsuarioPortal) {
    return (dispatch) => {
        const params = {
            idAssembleia,
            IdUsuarioPortal,
            pagingConfig: { pageIndex: 1, pageSize: 5 }
        };

        const apiGerenciarSolicitacao = `${ASSEMBLEIA_API}gerenciar/solicitacao/`;
        const reqDadosInvestidor = axios.post(`${apiGerenciarSolicitacao}investidor`, { idAssembleia, IdUsuarioPortal });
        const reqProcuracoes = axios.post(`${apiGerenciarSolicitacao}procuracoes`, { ...params })

        return axios
            .all([reqDadosInvestidor, reqProcuracoes])
            .then(axios.spread((...responses) => {
                const resInvestidor = responses[0];
                const resProcuracoes = responses[1];
                const { id, solicitacaoLiberada } = resInvestidor.content;

                const dadosForm = {
                    id,
                    idAssembleia,
                    solicitacaoLiberada,
                    assembleiaSolicitacaoOpcao: resInvestidor.content.assembleiaSolicitacaoOpcao || null
                };

                dispatch([
                    initialize('gerenciarSolicitacaoParticipacaoForm', { ...dadosForm }),
                    { type: ASSEMBLEIA_GERENCIAR_SOLICITACAO_PARTICIPACAO, payload: { dadosInvestidor: resInvestidor.content } },
                    { type: ASSEMBLEIA_GERENCIAR_SOLICITACAO_PARTICIPACAO_PROCURADORES, payload: { procuracoesList: resProcuracoes.content } },
                    { type: ASSEMBLEIA_SHOW_GERENCIAR_SOLICITACAO_PARTICIPACAO_DIALOG, payload: { showDialog: true, idAssembleia } },
                    showAllMessages(resInvestidor.messages),
                ]);
            }));
    }
}

export function closeModalGerenciarSolicitacaoParticipacao() {
    return (dispatch) => {
        return dispatch([
            {
                type: ASSEMBLEIA_SHOW_GERENCIAR_SOLICITACAO_PARTICIPACAO_DIALOG,
                payload: { showDialog: false }
            }
        ]);
    }
}

export function toggleLiberarInvestidor(value, statusAssembleia) {
    return (dispatch) => {
        return dispatch(
            statusPermiteRealizarAcao(statusAssembleia) ?
                change('gerenciarSolicitacaoParticipacaoForm', 'solicitacaoLiberada', value) :
                { type: NO_ACTION }
        );
    }
}

export function getProcuracoes(dadosConsulta, page = 1) {
    const { idAssembleia } = dadosConsulta;
    const idUsuarioPortal = dadosConsulta.id ? dadosConsulta.id : 0;

    const params = {
        idAssembleia,
        idUsuarioPortal,
        pagingConfig: {
            pageIndex: page,
            pageSize: 5
        },
    };

    return (dispatch) => {
        return axios
            .post(`${ASSEMBLEIA_API}gerenciar/solicitacao/procuracoes`, { ...params })
            .then(result =>
                dispatch({
                    type: ASSEMBLEIA_GERENCIAR_SOLICITACAO_PARTICIPACAO_PROCURADORES,
                    payload: { procuracoesList: result.content }
                })
            );
    }
}

export function downloadProcuracao(id) {
    return (dispatch) => {
        return axios
            .get(`${ASSEMBLEIA_API}gerenciar/solicitacao/downloadprocuracao/${id}`)
            .then(result => {
                dispatch([
                    showAllMessages(result.messages),
                    downloadFile(result.content, 'procuracoes.zip'),
                ])
            });
    }
}

export function gerenciarLiberacaoSolicitacao(dadosForm) {
    const ASSEMBLEIA_SOLICITACA_OPCAO_HABILITAR = 1;
    const ASSEMBLEIA_SOLICITACA_OPCAO_DESABILITAR = 2;
    let solicitacaoLiberada = null;

    if (dadosForm.assembleiaSolicitacaoOpcao && dadosForm.assembleiaSolicitacaoOpcao.key === ASSEMBLEIA_SOLICITACA_OPCAO_HABILITAR) {
        solicitacaoLiberada = true;
    } else if (dadosForm.assembleiaSolicitacaoOpcao && dadosForm.assembleiaSolicitacaoOpcao.key === ASSEMBLEIA_SOLICITACA_OPCAO_DESABILITAR) {
        solicitacaoLiberada = false;
    }

    const { idUsuarioPortal, idAssembleia } = dadosForm;

    return (dispatch) => {
        return axios
            .put(`${ASSEMBLEIA_API}gerenciar/solicitacao/liberacao`, { idUsuarioPortal, idAssembleia, solicitacaoLiberada })
            .then(result =>
                dispatch([
                    closeModalGerenciarSolicitacaoParticipacao(),
                    showAllMessages(result.messages),
                    laodAbaParticipante(idAssembleia)
                ])
            );
    }
}

export function showDialogEncerrarAssembleia(showDialog) {
    return (dispatch) => {
        return dispatch(
            {
                type: ASSEMBLEIA_SHOW_GERENCIAR_ENCERRAR_ASSEMBLEIA_DIALOG,
                payload: { showDialog }
            }
        );
    }
}

export function encerrarAssembleia(idAssembleia) {
    return (dispatch) => {
        return axios
            .put(`${ASSEMBLEIA_API}encerrar`, { idAssembleia })
            .then(result => {
                dispatch([
                    showDialogEncerrarAssembleia(false),
                    history.push(`/${ASSEMBLEIA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`),
                    showAllMessages(result.messages)
                ])
            });
    }
}

export function showDialogSuspenderAssembleia(showDialog, idAssembleia) {
    return (dispatch) => {
        return dispatch([
            {
                type: ASSEMBLEIA_SHOW_GERENCIAR_SUSPENDER_ASSEMBLEIA_DIALOG,
                payload: { showDialog }
            },
            idAssembleia ? loadFormGeralAssembleia() : undefined,
            idAssembleia ? initialize('suspenderAssembleiaForm', { idAssembleia }) : undefined
        ]);
    }
}

export function suspenderAssembleia(dadosAssembleia) {
    dadosAssembleia.dataHora = (dadosAssembleia.dataAssembleia) ? getStringDateTimeTwoField(dadosAssembleia.dataAssembleia, dadosAssembleia.hora) : null;
    dadosAssembleia.endereco = dadosAssembleia.endereco && {
        ...dadosAssembleia.endereco,
        estado: dadosAssembleia.endereco &&
            dadosAssembleia.endereco.estado &&
            dadosAssembleia.endereco.estado.key ?
            dadosAssembleia.endereco.estado.key : null
    };

    return (dispatch) => {
        return axios
            .put(`${ASSEMBLEIA_API}suspender`, { ...dadosAssembleia })
            .then(result => {
                dispatch([
                    showDialogSuspenderAssembleia(false),
                    history.push(`/${ASSEMBLEIA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`),
                    showAllMessages(result.messages)
                ])
            });
    }
}

export function downloadExcelInvestidores(filter) {
    return (dispatch) => {
        return axios
            .post(`${ASSEMBLEIA_API}exportar/excel/investidores`, filter)
            .then(result => {
                dispatch([
                    downloadExcelFile(result.content, "Lista de Investidores.xlsx"),
                    showAllMessages(result.messages),
                ])
            });
    }
}

export function showDialogNovaConvocacaoAssembleia(showDialog) {
    return (dispatch) => {
        return dispatch(
            {
                type: ASSEMBLEIA_SHOW_GERENCIAR_NOVA_CONVOCACAO_DIALOG,
                payload: { showDialog }
            }
        );
    }
}

export function novaConvocacaoAssembleia(idAssembleia) {
    return (dispatch) => {
        return axios
            .post(`${ASSEMBLEIA_API}novaconvocacao`, { idAssembleia })
            .then(result => {
                dispatch([
                    showDialogNovaConvocacaoAssembleia(false),
                    history.push(`/${ASSEMBLEIA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`),
                    showAllMessages(result.messages)
                ])
            });
    }
}

export function downloadExcelBoletinVotacao(idAssembleia) {
    return (dispatch) => {
        return axios
            .get(`${ASSEMBLEIA_API}exportar/excel/boletimvoto/${idAssembleia}`)
            .then(result => {
                dispatch([
                    downloadExcelFile(result.content, "Boletim de Votação.xlsx"),
                    showAllMessages(result.messages),
                ])
            });
    }
}

export function getRepresentantes(dadosConsulta, page = 1) {
    const { idAssembleia, idInvestidor } = dadosConsulta;

    const param = {
        idAssembleia,
        idInvestidor,
        pagingConfig: {
            pageIndex: page,
            pageSize: 10
        },
    };

    return (dispatch) => {
        return axios
            .post(`${ASSEMBLEIA_API}gerenciar/investidor/representantes`, { ...param })
            .then(result =>
                dispatch({
                    type: ASSEMBLEIA_GERENCIAR_INVESTIDOR_REPRESENTANTES,
                    payload: { representantesList: result.content }
                })
            );
    }
}

export function toggleRepresentante(item, representantes) {
    return (dispatch) => {
        let representantesNovaList = representantes.map(representante => ({
            ...representante,
            solicitacaoLiberada: setStatusListaRepresentante(item.idSolicitacaoParticipante, representante.idSolicitacaoParticipante, item.solicitacaoLiberada, representante.solicitacaoLiberada)
        }));

        return dispatch({
            type: ASSEMBLEIA_GERENCIAR_INVESTIDOR_REPRESENTANTES,
            payload: { representantesList: representantesNovaList }
        });
    }
}

function setStatusListaRepresentante(idSolicitacaoItem, idSolicitacaoMap, statusSolicitacaoMudanca, statusAtualSolicitacao) {
    let result = statusAtualSolicitacao;
    if (idSolicitacaoItem === idSolicitacaoMap) {
        result = statusSolicitacaoMudanca;
    }
    else if (statusSolicitacaoMudanca === true) {
        result = false
    }
    return result;
}

export function uploadProcuradoresFile(idAssembleia, procuradoresFile) {
    let formData = new FormData();
    formData.append("file", procuradoresFile);

    return (dispatch) => {
        return axios
            .post(`${ASEMBLEIA_GERENCIAR_API}/solicitacaoprocurador/${idAssembleia}`, formData)
            .then(result => {
                dispatch([
                    showAllMessages(result.messages),
                    laodAbaParticipante(idAssembleia)
                ])
            });
    };
}
export function donwloadPlanilhaModeloProcuradores() {
    return (dispatch) => {
        return axios
            .get(`${ASSEMBLEIA_API}exportar/excel/solicitacaoprocurador/modelo`)
            .then(result => {
                const fileName = 'Modelo lista de procurações.xlsx';
                dispatch([
                    downloadExcelFile(result.content, fileName),
                    showAllMessages(result.messages),
                ])
            });
    }
}
