import {
    CLEAN_LIST_CONTENT,
    LOAD_FILTER_FORM_ASSEMBLEIA,
    ASSEMBLEIAS_FETCHED,
    LOAD_FORM_NOVA_ASSEMBLEIA,
    SHOW_MODAL_ORDEM_ASSEMBLEIA,
    ASSEMBLEIA_SHOW_DELETE_DIALOG_CONTATO,
    ASSEMBLEIA_SHOW_DELETE_DIALOG,
    ASSEMBLEIA_SHOW_CONVOCAR_DIALOG,
    ASSEMBLEIA_OPERACAO_OPTIONS_FETCHED,
    ASSEMBLEIA_GERENCIAR_TELA_RESUMO,
    ASSEMBLEIAS_RESUMO_ORDENS_FETCHED,
    SHOW_MODAL_DESCRICAO_ORDEM,
    ASSEMBLEIA_VOTACAO_FINALIZADA,
    ASSEMBLEIAS_GERENCIAR_PARTICIPANTES_FETCHED,
    ASSEMBLEIAS_GERENCIAR_SOLICITACOES_FETCHED,
    ASSEMBLEIA_SHOW_ANEXO_DELETE_DIALOG,
    ASSEMBLEIA_SHOW_GERENCIAR_INVESTIDOR_DIALOG,
    ASSEMBLEIA_GERENCIAR_INVESTIDOR,
    ASSEMBLEIA_GERENCIAR_INVESTIDOR_VOTOS,
    ASSEMBLEIA_SITUACAO_INSTALACAO,
    ASSEMBLEIA_SHOW_GERENCIAR_SOLICITACAO_PARTICIPACAO_DIALOG,
    ASSEMBLEIA_GERENCIAR_SOLICITACAO_PARTICIPACAO,
    ASSEMBLEIA_GERENCIAR_SOLICITACAO_PARTICIPACAO_PROCURADORES,
    ASSEMBLEIA_SHOW_GERENCIAR_ENCERRAR_ASSEMBLEIA_DIALOG,
    ASSEMBLEIA_SHOW_GERENCIAR_SUSPENDER_ASSEMBLEIA_DIALOG,
    ASSEMBLEIA_SHOW_GERENCIAR_NOVA_CONVOCACAO_DIALOG,
    ASSEMBLEIA_GERENCIAR_INVESTIDOR_REPRESENTANTES,
} from "../../actions/assembleia/AssembleiaAction";
import { deepClone } from "../../utils/helpers/ObjectHelper";

const INITIAL_STATE = {
    assembleias: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    operacaoFormAssembleia: [],
    assembleiaFormTypes: {},
    messages: null,
    showModalOrdemAssembleia: {
        showDialog: false,
        indexContato: false,
    },
    showOrdemAssembleiaDeleteDialogContato: {
        showDialog: false,
        idComplemento: 0
    },
    assembleiaDeleteDialog: {
        showDialog: false,
        idAssembleia: 0
    },
    assembleiaConvocarDialog: {
        showDialog: false,
        idAssembleia: 0
    },
    showDialogDescricaoOrdem: {
        showDialog: false,
        titulo: '',
        descricao: ''
    },
    showAnexoDeleteDialog:
    {
        showDialog: false,
        fileId: 0,
        idAssembleia: 0,
        descricaoTitulo: ''
    },
    showGerenciarInvestidorDialog:
    {
        showDialog: false,
        investidorId: 0,
        idAssembleia: 0,
    },
    showGerenciarSolicitacaoParticipacaoDialog: {
        showDialog: false,
        idAssembleia: 0,
    },
    showEncerrarAssembleiaDialog: {
        showDialog: false,
    },
    showSuspenderAssembleiaDialog: {
        showDialog: false,
    },
    showNovaConvocacaoAssembleiaDialog: {
        showDialog: false,
    },
    listaInvestidorExpanded: false,
    assembleiaDadosGerenciar: {
        assembleiaInstalada: false,
        status: {},
        resumo: {},
        ordemList: {
            lastPage: 0,
            totalCount: 0,
            hasItems: false,
            pageSize: 0,
            pageIndex: 0,
            items: [],
        },
        participantesConfirmadosList: {
            lastPage: 0,
            totalCount: 0,
            hasItems: false,
            pageSize: 0,
            pageIndex: 0,
            items: [],
        },
        solicitacoesList: {
            lastPage: 0,
            totalCount: 0,
            hasItems: false,
            pageSize: 0,
            pageIndex: 0,
            items: [],
        },
        documentosAssembleia: {},
        tipoParticipantes: [
            {
                key: 1,
                value: "Investidor",
                raw: "Investidor"
            },
            {
                key: 2,
                value: "Procurador",
                raw: "Procurador"
            }
        ],
        statusParticipacao: [
            {
                key: 0,
                value: "Desabilitado"
            },
            {
                key: 1,
                value: "Habilitado"
            },
            {
                key: 2,
                value: "Pendente"
            }
        ],
        statusSolicitacao: [
            {
                key: 1,
                value: "Habilitada",
                raw: "Habilitada"
            },
            {
                key: 2,
                value: "Não Habilitada",
                raw: "NaoHabilitada"
            },
            {
                key: 3,
                value: "Pendente",
                raw: "Pendente"
            }
        ]
    },
    assembleiaGerenciarInvestidor: {
        dadosInvestidor: {},
        votosList: {
            lastPage: 0,
            totalCount: 0,
            hasItems: false,
            pageSize: 0,
            pageIndex: 0,
            items: [],
        },
        representantesList: [],
        nomeRepresentanteHabilitado: ''
    },
    assembleiaGerenciarSolicitacaoParticipacao: {
        dadosInvestidor: {},
        procuracoesList: {
            lastPage: 0,
            totalCount: 0,
            hasItems: false,
            pageSize: 0,
            pageIndex: 0,
            items: [],
        }
    }
}

const CLEAN_CONTENT = {
    assembleias: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    operacaoFormAssembleia: []
}

export default function (state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case CLEAN_LIST_CONTENT:
            return {
                ...state,
                ...CLEAN_CONTENT
            }
        case ASSEMBLEIAS_FETCHED:
            return {
                ...state,
                assembleias: action.payload.result.content,
                messages: action.payload.result.messages,
            }
        case LOAD_FILTER_FORM_ASSEMBLEIA:
            return {
                ...state,
                assembleiaFormTypes: action.payload
            }
        case LOAD_FORM_NOVA_ASSEMBLEIA:
            return {
                ...state,
                assembleiaFormTypes: action.payload
            }
        case SHOW_MODAL_ORDEM_ASSEMBLEIA:
            return {
                ...state,
                showModalOrdemAssembleia: action.payload
            };
        case ASSEMBLEIA_SHOW_DELETE_DIALOG_CONTATO:
            return {
                ...state,
                showOrdemAssembleiaDeleteDialogContato: action.payload
            };
        case ASSEMBLEIA_SHOW_DELETE_DIALOG:
            return {
                ...state,
                assembleiaDeleteDialog: action.payload
            }
        case ASSEMBLEIA_SHOW_CONVOCAR_DIALOG:
            return {
                ...state,
                assembleiaConvocarDialog: action.payload
            }
        case ASSEMBLEIA_OPERACAO_OPTIONS_FETCHED:
            return {
                ...state,
                operacaoFormAssembleia: action.payload
            }
        case ASSEMBLEIA_GERENCIAR_TELA_RESUMO:
            const { resumo, ordemList } = action.payload;
            const assembleiaDadosGerenciar = deepClone(state.assembleiaDadosGerenciar);
            assembleiaDadosGerenciar.resumo = resumo;
            assembleiaDadosGerenciar.ordemList = ordemList;
            assembleiaDadosGerenciar.assembleiaInstalada = resumo.assembleiaInstalada;

            return {
                ...state,
                assembleiaDadosGerenciar
            }
        case ASSEMBLEIAS_RESUMO_ORDENS_FETCHED:
            let dadosGerenciarAbaResumo = deepClone(state.assembleiaDadosGerenciar);
            dadosGerenciarAbaResumo.ordemList = { ...action.payload };
            return {
                ...state,
                assembleiaDadosGerenciar: { ...dadosGerenciarAbaResumo }
            }
        case SHOW_MODAL_DESCRICAO_ORDEM:
            return {
                ...state,
                showDialogDescricaoOrdem: action.payload
            }
        case ASSEMBLEIA_VOTACAO_FINALIZADA:
            const { assembleiaInstalada, status } = action.payload;

            let dadosGerenciarAposVotacaoFinalizada = deepClone(state.assembleiaDadosGerenciar);
            dadosGerenciarAposVotacaoFinalizada.assembleiaInstalada = assembleiaInstalada;
            dadosGerenciarAposVotacaoFinalizada.resumo = { ...state.assembleiaDadosGerenciar.resumo, status }
            return {
                ...state,
                assembleiaDadosGerenciar: { ...dadosGerenciarAposVotacaoFinalizada }
            }
        case ASSEMBLEIAS_GERENCIAR_PARTICIPANTES_FETCHED:
            let dadosGerenciarAbaParticipanteInvestidores = deepClone(state.assembleiaDadosGerenciar);
            dadosGerenciarAbaParticipanteInvestidores.participantesConfirmadosList = { ...action.payload };

            return {
                ...state,
                assembleiaDadosGerenciar: { ...dadosGerenciarAbaParticipanteInvestidores }
            }
        case ASSEMBLEIAS_GERENCIAR_SOLICITACOES_FETCHED:
            let dadosGerenciarAbaParticipanteSolicitacoes = deepClone(state.assembleiaDadosGerenciar);
            dadosGerenciarAbaParticipanteSolicitacoes.solicitacoesList = { ...action.payload };

            return {
                ...state,
                assembleiaDadosGerenciar: { ...dadosGerenciarAbaParticipanteSolicitacoes }
            }
        case ASSEMBLEIA_SHOW_ANEXO_DELETE_DIALOG:
            return {
                ...state,
                showAnexoDeleteDialog: action.payload
            }
        case ASSEMBLEIA_SHOW_GERENCIAR_INVESTIDOR_DIALOG:
            return {
                ...state,
                showGerenciarInvestidorDialog: action.payload
            }
        case ASSEMBLEIA_GERENCIAR_INVESTIDOR:
            const assembleiaGerenciarDadosInvestidor = deepClone(state.assembleiaGerenciarInvestidor);
            assembleiaGerenciarDadosInvestidor.dadosInvestidor = action.payload.dadosInvestidor;

            return {
                ...state,
                assembleiaGerenciarInvestidor: assembleiaGerenciarDadosInvestidor
            }
        case ASSEMBLEIA_GERENCIAR_INVESTIDOR_VOTOS:
            let assembleiaInvestidorVotos = deepClone(state.assembleiaGerenciarInvestidor);
            assembleiaInvestidorVotos.votosList = action.payload.votosList;

            return {
                ...state,
                assembleiaGerenciarInvestidor: { ...assembleiaInvestidorVotos }
            }
        case ASSEMBLEIA_SITUACAO_INSTALACAO:
            return {
                ...state,
                assembleiaDadosGerenciar: {
                    ...state.assembleiaDadosGerenciar,
                    assembleiaInstalada: action.payload.content.assembleiaInstalada,
                    status: action.payload.content.status

                }
            }
        case ASSEMBLEIA_SHOW_GERENCIAR_SOLICITACAO_PARTICIPACAO_DIALOG:
            return {
                ...state,
                showGerenciarSolicitacaoParticipacaoDialog: action.payload
            }
        case ASSEMBLEIA_GERENCIAR_SOLICITACAO_PARTICIPACAO:
            const assembleiaGerenciarDadosSolicitacaoParticipacao = deepClone(state.assembleiaGerenciarSolicitacaoParticipacao);
            assembleiaGerenciarDadosSolicitacaoParticipacao.dadosInvestidor = action.payload.dadosInvestidor;

            return {
                ...state,
                assembleiaGerenciarSolicitacaoParticipacao: assembleiaGerenciarDadosSolicitacaoParticipacao
            }
        case ASSEMBLEIA_GERENCIAR_SOLICITACAO_PARTICIPACAO_PROCURADORES:
            let assembleiaInvestidorProcuracoes = deepClone(state.assembleiaGerenciarSolicitacaoParticipacao);
            assembleiaInvestidorProcuracoes.procuracoesList = action.payload.procuracoesList;

            return {
                ...state,
                assembleiaGerenciarSolicitacaoParticipacao: assembleiaInvestidorProcuracoes
            }
        case ASSEMBLEIA_SHOW_GERENCIAR_ENCERRAR_ASSEMBLEIA_DIALOG:
            return {
                ...state,
                showEncerrarAssembleiaDialog: action.payload
            }
        case ASSEMBLEIA_SHOW_GERENCIAR_SUSPENDER_ASSEMBLEIA_DIALOG:
            return {
                ...state,
                showSuspenderAssembleiaDialog: action.payload
            }
        case ASSEMBLEIA_SHOW_GERENCIAR_NOVA_CONVOCACAO_DIALOG:
            return {
                ...state,
                showNovaConvocacaoAssembleiaDialog: action.payload
            }
        case ASSEMBLEIA_GERENCIAR_INVESTIDOR_REPRESENTANTES:
            let assembleiaInvestidorRepresentantes = deepClone(state.assembleiaGerenciarInvestidor);
            assembleiaInvestidorRepresentantes.representantesList = action.payload.representantesList;
            const representanteHabilitado = action.payload.representantesList.length > 0 &&
                action.payload.representantesList.find(representante => representante.solicitacaoLiberada === true);
            let nome = '';

            if (representanteHabilitado) {
                nome = representanteHabilitado.nomeProcurador;
            } else if (!assembleiaInvestidorRepresentantes.dadosInvestidor.votoAceito) {
                const votosSemResposta = assembleiaInvestidorRepresentantes.votosList.items.map(voto => ({ ...voto, resposta: null }));
                assembleiaInvestidorRepresentantes.votosList.items = votosSemResposta;
            }



            return {
                ...state,
                assembleiaGerenciarInvestidor: {
                    ...assembleiaInvestidorRepresentantes,
                    nomeRepresentanteHabilitado: nome
                }
            }
        default:
            return state;
    }
}
