import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { TableCell, TableRow, Checkbox } from '@material-ui/core';

import TableSelect from '../../../../../common/table/TableSelect';

import { formatPercent } from '../../../../../../utils/helpers/NumberHelper';

import { 
    changeSelectItems, 
} from '../../../../../../actions/common/TableSelectAction';


class AtivoVinculoOperacaoList extends Component {
    state = {
        selectedItems: [],
        page: 0,
        rowsPerPage: 5,
    };

    handleChangePage = (event, page) => {
        this.setState({ page: page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleSelectAllClick = event => {
        if (event.target.checked) {
          this.setState({ selectedItems: this.props.dataList });
        } else {
            this.setState({ selectedItems: [] });
        }

        this.props.changeSelectItems(this.props.dataList);
    }

    handleClick = (event, item) => {
        const { selectedItems } = this.state;
        const selectedIndex = selectedItems.indexOf(item);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selectedItems, item);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selectedItems.slice(1));
        } else if (selectedIndex === selectedItems.length - 1) {
          newSelected = newSelected.concat(selectedItems.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selectedItems.slice(0, selectedIndex),
            selectedItems.slice(selectedIndex + 1),
          );
        }
    
        this.setState({ selectedItems: newSelected });
        this.props.changeSelectItems(newSelected);
    };

    isSelected = item => this.state.selectedItems.indexOf(item) !== -1;
    
    render() {
        const { selectedItems, page, rowsPerPage } = this.state;
        const { dataList } = this.props;

        const headerRows = [
            { id: 'codigoAtivo', numeric: false, disablePadding: false, label: 'Cód. Ativo'},
            { id: 'codigoB3Cetip', numeric: false, disablePadding: false, label: 'Cód. B3CETIP'},
            { id: 'percentualVinculado', numeric: false, disablePadding: false, label: 'Percentual Vinculado'},
        ];
        
        const contentRows = dataList
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, index) => {
            const isSelected = this.isSelected(item);
    
            return (
                <TableRow
                    hover
                    onClick={event => this.handleClick(event, item)}
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isSelected}
                >
                    <TableCell 
                        padding="checkbox" 
                        style={{fontSize: '1em'}}
                    >
                        <Checkbox checked={isSelected} color='default' />
                    </TableCell>
                    <TableCell 
                        component="th" 
                        scope="row" 
                        padding="none" 
                        style={{fontSize: '1em'}}
                    >
                        {item.codigoAtivo}
                    </TableCell> 
                    <TableCell style={{fontSize: '1em'}}>
                        {item.codigoB3Cetip}
                    </TableCell>
                    <TableCell style={{fontSize: '1em'}}>
                        {formatPercent(item.percentual)}
                    </TableCell>
                </TableRow>
            )
        });
        
        return (
            <TableSelect
                titleTable={'Ativos para Vincular'}
                dataHeader={headerRows}
                data={dataList}
                emptyRowsMessage={'Nenhum ativo selecionado'}
                selectedItems={selectedItems}
                handleSelectAllClick={(e) => this.handleSelectAllClick(e)}
                page={page}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage="Linhas por página"
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            >
                {contentRows}
            </TableSelect>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeSelectItems,
}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(AtivoVinculoOperacaoList));