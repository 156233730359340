import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";

import ConfirmDialog from "../../../../common/modal/ConfirmDialog";
import PaginationRB from "../../../../common/pagination/PaginationRB";

import { getDateFormatedFromString } from "../../../../../utils/helpers/DateTimeHelper";
import { authorizationFunction } from "../../../../../actions/authorization/AuthorizationAction";
import {
  getAssembleias,
  novaAssembleia,
  acoes,
  removerAssembleia,
  showAssembleiaDeleteDialog,
  convocarAssembleia,
  showAssembleiaConvocarDialog,
  STATUS_ASSEMBLEIA_PENDENTE,
  STATUS_ASSEMBLEIA_CONVOCADA,
  STATUS_ASSEMBLEIA_VOTACAO_ENCERRADA,
  STATUS_ASSEMBLEIA_ENCERRADA,
} from "../../../../../actions/assembleia/AssembleiaAction";

import {
  DELETE,
  ASSEMBLEIA,
  UPDATE,
  READ,
  CREATE,
} from "../../../../../utils/actionsAndFeaturesAuth";
import PopoverButonGroup from "../../../../common/button/PopoverButonGroup";
import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import If from "../../../../common/If/If";

const AssembleiaList = (props) => {
  const { novaAssembleia } = props;

  const options = [
    {
      title: "Alterar",
      hasAuthentication: true,
      action: UPDATE,
      feature: ASSEMBLEIA,
    },
    { title: "Excluir" },
    {
      title: "Detalhar",
      hasAuthentication: true,
      action: READ,
      feature: ASSEMBLEIA,
    },
    { title: "Convocar" },
    {
      title: "Gerenciar",
      hasAuthentication: true,
      action: UPDATE,
      feature: ASSEMBLEIA,
    },
  ];
  const filterInfo = {
    operacao: props.operacao,
    status: props.status,
    dataInicio: props.dataInicio,
    dataFim: props.dataFim,
  };
  const emptyData =
    props.messages && props.messages.infos && props.messages.infos[0];

  const getStatusColor = (status) => {
    let color = "";

    switch (status) {
      case STATUS_ASSEMBLEIA_PENDENTE:
        color = "yellow";
        break;
      case STATUS_ASSEMBLEIA_CONVOCADA:
        color = "green";
        break;
      case STATUS_ASSEMBLEIA_VOTACAO_ENCERRADA:
        color = "darkred";
        break;
      case STATUS_ASSEMBLEIA_ENCERRADA:
        color = "grey";
        break;
      default:
        color = "grey";
        break;
    }

    return color;
  };

  const getLabelInstacao = (assembleiaInstalada) => {
    let label = "";

    if (assembleiaInstalada !== null) {
      label = assembleiaInstalada === true ? "Instalada" : "Não instalada";
    }

    return label;
  };

  const tableContent =
    props.assembleias && props.assembleias.hasItems ? (
      props.assembleias.items.map((item, index) => (
        <tr key={index}>
          <td
            className={
              "text-center fw-400 text-nowrap border pt-3 " +
              getStatusColor(item.status.key)
            }
          >
            {item.codigoOpea}
          </td>
          <td className="text-center fw-400 text-nowrap pt-3">
            {item.data && getDateFormatedFromString(item.data)}
          </td>
          <td className="text-center fw-400 text-nowrap pt-3">
            {item.convocacao && item.convocacao}
          </td>
          <td className="text-center fw-400 text-nowrap pt-3">
            {item.tipoLocalizacao && item.tipoLocalizacao.value}
          </td>
          <td className="text-center fw-400 text-nowrap pt-3">
            {getLabelInstacao(item.assembleiaInstalada)}
          </td>
          <td className="text-center fw-400 text-nowrap pt-3">
            {item.status && item.status.value}
          </td>
          <PopoverButonGroup
            classNameDiv="col pt-1"
            idItem={item.id}
            acoes={props.acoes}
            options={options}
            anyObject={item.status}
          />
        </tr>
      ))
    ) : (
      <tr className="w-100">
        <td className="empty-item-label">{emptyData}</td>
      </tr>
    );

  return (
    <MuiThemeProvider>
      <div className="container-fluid monitor-workflow pt-1">
        <div className="logbook__data-header">
          <div className="row mx-0 py-2 px-2 logbook-list__header">
            <div className="col"></div>
            <Fab
              className="p-bg-imp"
              aria-label="add"
              onClick={() =>
                props.authorizationFunction(
                  { idAcao: CREATE, idFuncionalidade: ASSEMBLEIA },
                  () => novaAssembleia()
                )
              }
            >
              <AddIcon className=" text-white" />
            </Fab>
          </div>
        </div>

        <div className="w-100 px-3">
          <If test={props.assembleias.totalCount > 0}>
            <Table
              withoutHeader
              classes="shadow-custom rounded-12 col-12 my-3 pd-10 bg-white"
              totalItems={props.assembleias.totalCount}
              pagination={
                <PaginationRB
                  pageNumber={props.assembleias.pageIndex - 1}
                  itemsPerPage={props.assembleias.pageSize}
                  totalItems={props.assembleias.totalCount}
                  filter={filterInfo}
                  fetchFunction={props.getAssembleias}
                  pageRange={5}
                />
              }
            >
              <TableHeader>
                <tr>
                  <th className="text-center fw-700 text-nowrap">
                    Código Opea
                  </th>
                  <th className="text-center fw-700 text-nowrap">Data</th>
                  <th className="text-center fw-700 text-nowrap">Convocação</th>
                  <th className="text-center fw-700 text-nowrap">Tipo</th>
                  <th className="text-center fw-700 text-nowrap">Instalação</th>
                  <th className="text-center fw-700 text-nowrap">Status</th>
                  <th className="text-center fw-700 text-nowrap"></th>
                </tr>
              </TableHeader>
              <TableContent>{tableContent}</TableContent>
            </Table>
          </If>

          <ConfirmDialog
            title="Aviso"
            contentText="Você realmente deseja excluir o item selecionado ?"
            textButtonCancel="CANCELAR"
            textButtonConfirm="CONFIRMAR"
            showDialog={props.showDeleteDialog.showDialog}
            onClose={() =>
              props.showAssembleiaDeleteDialog({ showDialog: false })
            }
            onConfirm={() =>
              props.authorizationFunction(
                { idAcao: DELETE, idFuncionalidade: ASSEMBLEIA },
                () =>
                  props.removerAssembleia(
                    props.showDeleteDialog.idAssembleia,
                    filterInfo,
                    props.assembleias.pageIndex
                  )
              )
            }
            type="warning"
            maxWidth="md"
          />
          <ConfirmDialog
            title="Aviso"
            contentText="Deseja convocar essa assembleia ?"
            textButtonCancel="CANCELAR"
            textButtonConfirm="CONFIRMAR"
            showDialog={props.showConvocarDialog.showDialog}
            onClose={() =>
              props.showAssembleiaConvocarDialog({ showDialog: false })
            }
            onConfirm={() =>
              props.authorizationFunction(
                { idAcao: UPDATE, idFuncionalidade: ASSEMBLEIA },
                () =>
                  props.convocarAssembleia(
                    props.showConvocarDialog.idAssembleia,
                    filterInfo,
                    props.assembleias.pageIndex
                  )
              )
            }
            type="warning"
            maxWidth="md"
          />
        </div>
      </div>
    </MuiThemeProvider>
  );
};

const selector = formValueSelector("assembleiaFilterForm");
const mapStateToProps = (state) => ({
  assembleias: state.assembleiaReducer.assembleias || [],
  messages: state.assembleiaReducer.messages,
  operacao: selector(state, "operacao"),
  status: selector(state, "status"),
  dataInicio: selector(state, "dataInicio"),
  dataFim: selector(state, "dataFim"),
  showDeleteDialog: state.assembleiaReducer.assembleiaDeleteDialog,
  showConvocarDialog: state.assembleiaReducer.assembleiaConvocarDialog,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getAssembleias,
      novaAssembleia,
      acoes,
      removerAssembleia,
      authorizationFunction,
      showAssembleiaDeleteDialog,
      convocarAssembleia,
      showAssembleiaConvocarDialog,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(AssembleiaList);
