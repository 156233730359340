/* eslint eqeqeq: 0 */

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
    getAtivos,
    removeAtivo,
    showAtivoDeleteDialog,
    loadFormAtivo,
    importarAtivosPorPlanilha,
    exportModeloAtivo
} from '../../../../../actions/ativo/AtivoAction';

import Table from '../../../../common/table/Table';
import TableHeader from '../../../../common/table/TableHeader';
import TableContent from '../../../../common/table/TableContent';
import TableEmptyData from '../../../../common/table/TableEmptyData';
import PaginationRB from '../../../../common/pagination/PaginationRB';
import ConfirmDialog from '../../../../common/modal/ConfirmDialog';
import TableActionWithRouteAuthorization from '../../../../common/table/TableActionWithRouteAuthorization';
import { ATIVO, DELETE } from '../../../../../utils/actionsAndFeaturesAuth';
import { authorizationFunction } from '../../../../../actions/authorization/AuthorizationAction';
import FloatButtonGroup from '../../../../common/button/FloatButtonGroup';
import FloatButton from '../../../../common/button/FloatButton';
import { ACCEPT_FILE_OPTIONS } from "../../../../../utils/helpers/FileHelper";
import { showErrorMessages } from '../../../../../actions/message/ErrorMessagesAPIAction';
import If from '../../../../common/If/If';

class AtivoList extends Component {
    handleUploadFile() {
        let element = document.createElement('input');

        element.type = 'file';
        element.multiple = false;
        element.accept = ACCEPT_FILE_OPTIONS.EXCEL;

        element.onchange = (e) => {
            let files = element.files;

            if (files == null) {
                return;
            } else if (files.length == 0) {
                return;
            } else {
                this.uploadPlanilhaAtivos(files[0]);
            }
        };

        element.click();
    }

    async uploadPlanilhaAtivos(file) {
        try {
            this.props.importarAtivosPorPlanilha(file);
        } catch (e) {
            showErrorMessages(["Erro ao importar o arquivo."]);
        }
    }

    render() {
        const {
            selectedAtivos,
            ativoData,
            messages,
            getAtivos,
            removeAtivo,
            showAtivoDeleteDialog,
            showDeleteDialog,
            loadFormAtivo,
            authorizationFunction,
            exportModeloAtivo
        } = this.props;

        const idsAtivos = selectedAtivos.map(o => o.key);
        const emptyData = messages && messages.infos && messages.infos[0];

        const tableHeader = (
            <tr>
                 <th className="text-center fw-700">Cód. Ativo</th>
                 <th className="text-center fw-700">Contrato</th>
                 <th className="text-center fw-700">Devedor</th>
                 <th className="text-center fw-700">Remuneração</th>
                 <th className="text-center"></th>
            </tr>
        );

        const tableContent = (
            ativoData && ativoData.hasItems
                ? ativoData.items.map(ativo =>
                    (<tr key={ativo.idAtivo}>
                        <td className="text-center">{ativo.codigoAtivo || "--"}</td>
                        <td className="text-center">{ativo.descricaoContrato || "--"}</td>
                        <td className="text-center">{ativo.descricaoDevedor || "--"}</td>
                        <td className="text-center">{ativo.remuneracao || "--"}</td>
                        <TableActionWithRouteAuthorization
                            updateAction={() => loadFormAtivo(ativo.idAtivo, true)}
                            viewAction={() => loadFormAtivo(ativo.idAtivo)}
                            feature={ATIVO}
                            remove={() => showAtivoDeleteDialog(true, ativo.idAtivo)}
                        />
                    </tr>)
                )
                : <TableEmptyData message={emptyData} />
        );

        return (
            <div>
                <If test={ativoData.totalCount > 0}>
                    <Table
                        withoutHeader
                        classes="shadow-custom rounded-12 col-12 my-3 pd-10 bg-white"
                        totalItems={ativoData.totalCount}
                        pagination={
                            <PaginationRB
                                pageNumber={ativoData.pageIndex - 1}
                                itemsPerPage={ativoData.pageSize}
                                totalItems={ativoData.totalCount}
                                filter={idsAtivos}
                                fetchFunction={getAtivos}
                                pageRange={5}
                            />
                        }
                    >
                        <TableHeader >
                            {tableHeader}
                        </TableHeader>
                        <TableContent>
                            {tableContent}
                        </TableContent>
                    </Table>
                </If>
                
                <FloatButtonGroup>
                    <FloatButton
                        activeClassName={'nd1 nds yellow-button`'}
                        title={'Importar Planilha'}
                        icon={'fa fa-upload edit edit-sub-menu'}
                        action={() => this.handleUploadFile() }
                    />
                    <FloatButton
                        activeClassName={'nd3 nds dark-grey-button'}
                        title={'Download planilha modelo'}
                        icon={'fa fa-cloud-download edit edit-sub-menu'}
                        action={() => exportModeloAtivo()}
                    />
                </FloatButtonGroup>

                <ConfirmDialog
                    title="Aviso"
                    contentText="Confirmar exclusão do ativo selecionado?"
                    textButtonCancel="CANCELAR"
                    textButtonConfirm="CONFIRMAR"
                    showDialog={showDeleteDialog.showDialog}
                    onClose={() => showAtivoDeleteDialog(false)}
                    onConfirm={() => authorizationFunction(
                        { idAcao: DELETE, idFuncionalidade: ATIVO },
                        () => removeAtivo(showDeleteDialog.idAtivo, idsAtivos)
                    )}
                    type="warning"
                    maxWidth="md"
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    showDeleteDialog: state.ativoReducer.showAtivoDeleteDialog,
    ativoData: state.ativoReducer.ativos,
    messages: state.ativoReducer.messages,
    selectedAtivos: state.ativoReducer.selectedAtivoOptions,
})

const mapDispacthToProps = dispatch => bindActionCreators({
    getAtivos,
    removeAtivo,
    showAtivoDeleteDialog,
    loadFormAtivo,
    authorizationFunction,
    importarAtivosPorPlanilha,
    exportModeloAtivo,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(AtivoList));
