import React from 'react';
import { Route, Switch } from "react-router-dom";
 
import { 
    RELATORIOS_ROUTE_PATH, 
    GERENCIA_ROUTE_PATH,
    ANALITICO_ROUTE_PATH,
    LISTA_ROUTE_PATH
} from './../../../common/routers/constantesRoutes';

import ViewMainRelatorios from '../pages/gerenciais/viewMainRelatorios';
import RelatorioAnaliticoPage from '../pages/analiticos/relatorioAnaliticoPage';

export default () => (
    <Switch>
        <Route exact={true} path={`/${RELATORIOS_ROUTE_PATH}/${GERENCIA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} component={ViewMainRelatorios} />
        <Route exact={true} path={`/${RELATORIOS_ROUTE_PATH}/${ANALITICO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} component={RelatorioAnaliticoPage} />
    </Switch>
);