import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSelectToolbar from './TableSelectToolbar';
import TableSelectHeader from './TableSelectHeader';

const styles = theme => ({
    root: {
        display: 'block',
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        //marginBottom: theme.spacing.unit * 2,
        overflowX: 'auto',
    },
    table: {
        width: '100%',
        display: 'table',
        fontFamily: ["Roboto", "Helvetica", "Arial", 'sans-serif'],
        borderSpacing: 0,
        borderCollapse: 'collapse',
    },
    tableCell: {
        fontSize: '0.9em',
        fontWeight: 300,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tablePagination: {
        fontSize: '12pt',
    }
});

class TableSelect extends Component {

    render() {
        const {
            children, 
            classes, 
            titleTable, 
            dataHeader, 
            data, 
            selectedItems, 
            handleSelectAllClick, 
            page, 
            rowsPerPage, 
            handleChangePage,
            handleChangeRowsPerPage,
            emptyRowsMessage,
        } = this.props;

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

        return(
            <Paper className={classes.root}>
                <Divider />
                <TableSelectToolbar title={titleTable} numSelected={selectedItems.length} />
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <TableSelectHeader
                            dataHeader={dataHeader}
                            numSelected={selectedItems.length}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={data.length}
                        />
                        <TableBody>
                            {data.length > 0 
                                ? children
                                : <TableRow style={{ height: 20 * emptyRows }}>
                                    <TableCell
                                        className={classes.tableCell} 
                                        align="center"
                                        colSpan={12}
                                    >
                                        {emptyRowsMessage}
                                    </TableCell>
                                  </TableRow>
                            }
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    classes={classes.tablePagination}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Página anterior',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Próxima página',
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        );
    }
}

export default withStyles(styles)(TableSelect);

TableSelect.propTypes = {
    classes: PropTypes.object.isRequired,
};

