import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { saveAtivo } from '../../../../../actions/ativo/AtivoAction';
import AtivoForm from './AtivoForm';

const AtivoFormInclude = (props) => {
    const { readOnly, saveAtivo } = props;

    return (
        <AtivoForm 
            title={"Novo - Ativo"} 
            onSubmit={saveAtivo} 
            readOnly={readOnly}
            floatButtonVisible={true} />
    );
}

const mapDispatchToProps = dispatch => bindActionCreators({
    saveAtivo
}, dispatch);

export default connect(null, mapDispatchToProps)(AtivoFormInclude);