import React, { Component } from "react";
import { Field, formValueSelector, arrayInsert, arrayRemove } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import If from "../../../../../../common/If/If";
import Input from "../../../../../../common/input/Input";
import MaskedInput from "../../../../../../common/input/MaskedInput";

import {
  getDataPrimeiraIntegralizacaoGest,
  calcularQuantidadeDistribuidaIntegralizacaoGest,
} from "../../../../../../../actions/operacao/PassivoAction";
import Button from "../../../../../../common/button/Button";

const TIPO_PAGAMENTO_FLUTUANTE = 2;

class PassivoFormIntegralizacao extends Component {
  componentWillMount() {
    if (this.props.integralizacaoList.length === 0) {
      this.addIntegralizacao(this.props.integralizacaoList.length);
    }
  }

  addIntegralizacao(index) {
    if (!this.props.readOnly) {
      this.props.arrayInsert("passivoForm", "pagamentoPassivo.integralizacaoList", index, {});
    }
  }

  removeIntegralizacao(index) {
    if (!this.props.readOnly && this.props.integralizacaoList.length > 1) {
      this.props.arrayRemove("passivoForm", "pagamentoPassivo.integralizacaoList", index);
      this.props.getDataPrimeiraIntegralizacaoGest();
      this.props.calcularQuantidadeDistribuidaIntegralizacaoGest();
    }
  }

  render() {
    const {
      readOnly,
      integralizacaoList,
      getDataPrimeiraIntegralizacaoGest,
      calcularQuantidadeDistribuidaIntegralizacaoGest,
      tipoPagamentoPassivo,
      passivoOperacao,
      moedaDinamica,
    } = this.props;

    let moedaSimbolo = moedaDinamica || passivoOperacao?.moeda?.simbolo || "R$";

    return (
      <div className="mt-4">
        <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
          <div className="column">
            <span className="page-head__title h3 mb-3">Controle</span>
            <div className="row">
              <div className="col-12 col-lg">
                <Field
                  component={Input}
                  inputId="dataPrimeiraIntegralizacao"
                  name="dataPrimeiraIntegralizacao"
                  type="date"
                  classLabel="text-nowrap"
                  label="Data Primeira Integralização"
                  readOnly={true}
                  required
                />
              </div>

              <div className="col-12 col-lg">
                <Field
                  component={MaskedInput}
                  inputId="quantidadeDistribuida"
                  name="quantidadeDistribuida"
                  label="Quantidade Integralizada"
                  readOnly={true}
                  allowNegative={true}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={8}
                  fixedDecimalScale
                  icon="info-circle"
                  iconColor="#630a3679"
                  tooltipMessage="Soma das quantidades no agrupamento integralização."
                  tooltipId="sqai"
                  required
                />
              </div>

              <div className="col-12 col-lg">
                <Field
                  component={MaskedInput}
                  inputId="totalDistribuido"
                  name="totalDistribuido"
                  label="Total Distribuído"
                  readOnly={true}
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={8}
                  fixedDecimalScale
                  icon="info-circle"
                  iconColor="#630a3679"
                  tooltipMessage="Quantidade Distribuída X Preço Unitário."
                  tooltipId="qd-pu"
                  required
                />
              </div>

              <div className="col-12 col-lg">
                <Field
                  component={Input}
                  inputId="dataInicioDistribuicao"
                  name="dataInicioDistribuicao"
                  type="date"
                  classLabel="text-nowrap"
                  label="Data Início Distribuição"
                  readOnly={readOnly}
                />
              </div>

              <div className="col-12 col-lg">
                <Field
                  component={Input}
                  inputId="dataFinalDistribuicao"
                  name="dataFinalDistribuicao"
                  type="date"
                  label="Data Final Distribuição"
                  readOnly={readOnly}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
          <div className="column">
            <span className="page-head__title h3 mb-3">Integralização</span>
            <div className="d-flex flex-column w-100">
              <div className="col more-fields p-0">
                {integralizacaoList.map((item, index) => {
                  const tipoPagamentoFlutuante = tipoPagamentoPassivo && tipoPagamentoPassivo.key === TIPO_PAGAMENTO_FLUTUANTE;
                  
                  return (
                    <div key={index} className="more-fields-item">
                      <div className="row">
                        <div className="col-12 col-lg-2">
                          <Field
                            component={Input}
                            inputId="dataIntegralizacao"
                            inputName="dataIntegralizacao"
                            name={`pagamentoPassivo.integralizacaoList[${index}].dataIntegralizacao`}
                            type="date"
                            required={true}
                            label="Data Integralização"
                            readOnly={readOnly}
                            onBlur={() => getDataPrimeiraIntegralizacaoGest()}
                          />
                        </div>

                        <div className={`col-12 col-lg-${tipoPagamentoFlutuante ? '2' : '3'}`}>
                          <Field
                            component={MaskedInput}
                            inputId="quantidadeIntegralizada"
                            inputName="quantidadeIntegralizada"
                            name={`pagamentoPassivo.integralizacaoList[${index}].quantidadeIntegralizada`}
                            required={true}
                            label="Quantidade Integralizada"
                            classLabel="text-nowrap"
                            readOnly={readOnly}
                            allowNegative={true}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={8}
                            fixedDecimalScale
                            onValueChange={() =>
                              calcularQuantidadeDistribuidaIntegralizacaoGest()
                            }
                            blur={true}
                          />
                        </div>

                        <div className={`col-12 col-lg-${tipoPagamentoFlutuante ? '2' : '3'}`}>
                          <Field
                            component={MaskedInput}
                            inputId="taxaVenda"
                            inputName="taxaVenda"
                            name={`pagamentoPassivo.integralizacaoList[${index}].taxaVenda`}
                            label="Taxa de Venda"
                            readOnly={readOnly}
                            suffix={"%"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={4}
                            fixedDecimalScale
                          />
                        </div>

                        {tipoPagamentoFlutuante && (
                          <div className="col-12 col-lg-2">
                            <Field
                              component={MaskedInput}
                              inputId="percentualFlutuante"
                              inputName="taxaVenda"
                              name={`pagamentoPassivo.integralizacaoList[${index}].percentualFlutuante`}
                              label="Percentual Flutuante"
                              readOnly={readOnly}
                              suffix={"%"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              decimalScale={4}
                              fixedDecimalScale
                            />
                          </div>
                        )}

                        <div className={`col-12 col-lg-${tipoPagamentoFlutuante ? '3' : '3'}`}>
                          <Field
                            component={MaskedInput}
                            inputId="precoUnitario"
                            inputName="precoUnitario"
                            name={`pagamentoPassivo.integralizacaoList[${index}].precoUnitario`}
                            required={true}
                            label="Preço Unitário"
                            readOnly={readOnly}
                            prefix={`${moedaSimbolo} `}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={11}
                            fixedDecimalScale
                            placeholder={"R$ 0,00000000000"}
                            onValueChange={() => calcularQuantidadeDistribuidaIntegralizacaoGest()}
                            blur={true}
                          />
                        </div>

                        <If test={!readOnly}>
                          <div className="col-12 col-lg-1 d-flex align-items-center">
                            <Button
                              classes="rounded bg-white shadow-none border-0 text-danger m-0 mxh-40 p-0 my-2 text-center text-capitalize"
                              activeClassName={"col-lg-auto p-0"}
                              buttonStyle={"success"}
                              action={() => this.removeIntegralizacao(index)}
                              icon={"trash"}
                              name={"Remover"}
                            />
                          </div>
                        </If>
                      </div>
                    </div>
                  );
                })}
              </div>
              <If test={!readOnly}>
                <div className="mx-auto mt-3">
                  <Button
                    classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                    activeClassName={"col-lg-auto p-0"}
                    buttonStyle={"success"}
                    action={() => this.addIntegralizacao(integralizacaoList.length)}
                    icon={"plus"}
                    name={"Adicionar"}
                  />
                </div>
              </If>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const selector = formValueSelector("passivoForm");

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      arrayInsert,
      arrayRemove,
      getDataPrimeiraIntegralizacaoGest,
      calcularQuantidadeDistribuidaIntegralizacaoGest,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  integralizacaoList: selector(state, "pagamentoPassivo.integralizacaoList") || [],
  tipoPagamentoPassivo: selector(state, "pagamentoPassivo.tipoPagamentoPassivo"),
  passivoOperacao: state.passivoReducer.passivoFormTypes.passivoOperacao || [],
  moedaDinamica: state.passivoReducer.moedaDinamica || null,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PassivoFormIntegralizacao);
