import React from 'react';
import CKEditor from "react-ckeditor-component";

//Editor WYSIWYG
const Editor = ({ input, inputId, readOnly, meta }) => (
  <>
    <CKEditor
      id={"txtfield_" + inputId}
      content={input.value}
      onInit= {(editor) => console.log(editor)}
      config={{
        readOnly: readOnly,
      }}
      events={{
        change: event => input.onChange(event.editor.getData())
      }}
    />
    {(meta && meta.error && meta.touched &&
      <p className="error-msg sp-error text-danger f-12 mb-0">{meta.error}</p>)
    }
  </>
);
export default Editor;
