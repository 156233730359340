import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { routerReducer, routerMiddleware } from 'react-router-redux';

import thunk from 'redux-thunk';
import multi from 'redux-multi';
import promise from 'redux-promise';

import LoginReducer from './login/LoginReducer';
import OperacaoReducer from './operacao/OperacaoReducer';
import AtivoReducer from './ativo/AtivoReducer';
import PatrimonioReducer from './patrimonio/PatrimonioReducer';
import PedidosReducer from './pedidos/PedidosReducer';
import DashboardReducer from './operacao/DashboardReducer';
import PassivoReducer from './operacao/PassivoReducer';
import AprovacaoReducer from './aprovacao/AprovacaoReducer';
import DespesaReducer from './operacao/DespesaReducer';
import ComplementoReducer from './operacao/ComplementoReducer';
import PrecificacaoReducer from './precificacao/PrecificacaoReducer';
import CalculadoraReducer from './precificacao/CalculadoraReducer';
import PrecificacaoPrecisaoReducer from './operacao/PrecificacaoPrecisaoReducer';
import IndiceReducer from './precificacao/IndiceReducer';
import OpcaoReducer from './precificacao/OpcaoReducer';
import ErroReducer from './precificacao/ErroReducer';
import LogReducer from './precificacao/LogReducer';
import DominioReducer from './precificacao/DominioReducer';
import PagamentoReducer from './precificacao/PagamentoReducer';
import MonitoramentoReducer from './monitoramento/MonitoramentoReducer';
import CarteiraReducer from './carteira/CarteiraReducer';
import CotaReducer from './cota/CotaReducer';
import HistoricoCarteiraReducer from './historicocarteira/HistoricoCarteiraReducer';
import HistoricoCotasReducer from './historicocotas/HistoricoCotasReducer';
import AgingReducer from './precificacao/AgingReducer';
import PrestadoraReducer from './prestadora/PrestadoraReducer';
import UsuarioReducer from './usuario/UsuarioReducer';
import ReportReducer from './relatorios/ReportReducer';
import AssembleiaReducer from './assembleia/AssembleiaReducer';
import AcessoPortalReducer from './acessoPortal/AcessoPortalReducer';
import FundoReducer from './fundo/FundoReducer';
import SubclasseReducer from './sublasse/SubclasseReducer';

import TabReducer from './operacao/tabs/TabReducer';
import LoadingScreen from './infra/LoadingScreenReducer';
import TableSelectReduder from './common/TableSelectReduder';
import ConfirmDialogReducer from './common/modal/ConfirmDialogReducer';
import SideBarReducer from './common/sidebar/SideBarReducer';
import ErrorMessagesAPIReducer from './message/ErrorMessagesAPIReducer';
import GerenciamentoJobReducer from './job/GerenciamentoJobReducer';
import AnaliticReportReducer from './relatorios/AnaliticReportReducer';

import DiarioBordoReducer from './gestao/DiarioBordoReducer';
import AprovadoresReducer from './aprovadores/AprovadoresReducer';
import ExportacaoReducer from './exportacao/ExportacaoReducer';
import RemessaReducer from './remessa/RemessaReducer';


export default function configureStore(history, initialState) {
  const reducers = {
    form: formReducer,
    toastr: toastrReducer,
    loginReducer: LoginReducer,
    tab: TabReducer,
    loadingScreen: LoadingScreen,
    tableSelectReduder: TableSelectReduder,
    confirmDialogReducer: ConfirmDialogReducer,
    sideBarReducer: SideBarReducer,
    operacaoReducer: OperacaoReducer,
    ativoReducer: AtivoReducer,
    patrimonioReducer: PatrimonioReducer,
    pedidosReducer: PedidosReducer,
    dashboardReducer: DashboardReducer,
    passivoReducer: PassivoReducer,
    aprovacaoReducer: AprovacaoReducer,
    aprovadoresReducer: AprovadoresReducer,
    exportacaoReducer: ExportacaoReducer,
    remessaReducer: RemessaReducer,
    despesaReducer: DespesaReducer,
    complementoReducer: ComplementoReducer,
    precificacaoReducer: PrecificacaoReducer,
    calculadoraReducer: CalculadoraReducer,
    precificacaoPrecisaoReducer: PrecificacaoPrecisaoReducer,
    indiceReducer: IndiceReducer,
    opcaoReducer: OpcaoReducer,
    erroReducer: ErroReducer,
    logReducer: LogReducer,
    dominioReducer: DominioReducer,
    pagamentoReducer: PagamentoReducer,
    monitoramentoReducer: MonitoramentoReducer,
    carteiraReducer: CarteiraReducer,
    cotaReducer: CotaReducer,
    historicoCarteiraReducer: HistoricoCarteiraReducer,
    historicoCotasReducer: HistoricoCotasReducer,
    agingReducer: AgingReducer,
    prestadoraReducer: PrestadoraReducer,
    usuarioReducer: UsuarioReducer,
    gerenciamentoJobReducer: GerenciamentoJobReducer,
    reportReducer: ReportReducer,
    errorMessagesAPIReducer: ErrorMessagesAPIReducer,
    analiticReportReducer: AnaliticReportReducer,
    diarioBordoReducer: DiarioBordoReducer,
    assembleiaReducer: AssembleiaReducer,
    acessoPortalReducer: AcessoPortalReducer,
    fundoReducer: FundoReducer,
    subclasseReducer: SubclasseReducer,
  };

  const middleware = [
    thunk,
    multi,
    promise,
    routerMiddleware(history)
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    routing: routerReducer
  });

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
