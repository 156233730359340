import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./Expansion.css";
import { ButtonBase } from "@material-ui/core";
import If from "../If/If";

export default class ExpansionPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = { expanded: props.expanded };
  }
  changeExpandedState() {
    const currentState = this.state.expanded;
    this.setState({
      expanded: !currentState,
    });
  }
  render() {
    return (
      <div className={` ${this.props.classes || "expansion-pane"}`}>
        <div className="header align-items-center">
          {this.props.header}
          <If test={!this.props.classes}>
            <ButtonBase
              className={`drop_button ${
                this.state.expanded ? "button_active" : ""
              }`}
            >
              <ExpandMoreIcon
                onClick={() => this.changeExpandedState()}
                className="drop_icon"
                color="action"
              />
            </ButtonBase>
          </If>
        </div>
        <div className={`content ${this.state.expanded ? "active-pane" : ""}`}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
