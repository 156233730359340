/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, formValueSelector } from "redux-form";
import AssembleiaSecaoOrdensList from "./SecaoIncluirOrdens/AssembleiaSecaoOrdensList";
import AssembleiaUploadArquivo from "./arquivo/AssembleiaUploadArquivo";
import AssembleiaSecaoAnexoInvestidores from "./SecaoAnexos/AssembleiaSecaoAnexoInvestidores";
import SecaoIncluirAssembleiaForm from "./SecaoIncluirAssembleia/SecaoIncluirAssembleiaForm";
import ConfirmDialog from "../../../../common/modal/ConfirmDialog";
import FloatButtonGroup from "../../../../common/button/FloatButtonGroup";
import FloatButton from "../../../../common/button/FloatButton";
import If from "../../../../common/If/If";
import FormContent from "../../../../common/form/FormContent";
import {
  backAssembleiaList,
  STATUS_ASSEMBLEIA_PENDENTE,
  STATUS_ASSEMBLEIA_CONVOCADA,
  STATUS_ASSEMBLEIA_ENCERRADA,
  STATUS_ASSEMBLEIA_NOVA_CONVOCACAO,
} from "../../../../../actions/assembleia/AssembleiaAction";
import { showConfirmDialog } from "../../../../../actions/common/modal/ConfirmDialogAction";
import RBCard from "../../../../common/card/RBCard";
import { ACCEPT_FILE_OPTIONS } from "../../../../../utils/helpers/FileHelper";

import "../assets/AssembleiaForm.css";

class AssembleiaIncluirFormGeral extends Component {
  isStatusNovaConvocacao() {
    return this.props.statusAssembleia &&
      this.props.statusAssembleia.key === STATUS_ASSEMBLEIA_NOVA_CONVOCACAO
      ? true
      : this.props.readOnly;
  }

  render() {
    const {
      conteudoAnexoList,
      readOnly,
      handleSubmit,
      floatButtonVisible,
      showCancelConfirmDialog,
      showConfirmDialog,
      backAssembleiaList,
      statusAssembleia,
    } = this.props;

    return (
      <div className="container-fluid assembleia-container">
        {/* DIV FLOATBUTTON*/}
        <div className="row">
          <FormContent handleSubmit={handleSubmit}>
            <FloatButtonGroup>
              <FloatButton
                activeClassName={"nd1 nds"}
                title={"Cancelar"}
                icon={"fa fa-ban edit edit-sub-menu"}
                action={() =>
                  floatButtonVisible
                    ? showConfirmDialog(true)
                    : backAssembleiaList()
                }
              />
              <If
                test={
                  !readOnly &&
                  statusAssembleia &&
                  (statusAssembleia.key == undefined ||
                    statusAssembleia.key === STATUS_ASSEMBLEIA_PENDENTE ||
                    statusAssembleia.key === STATUS_ASSEMBLEIA_CONVOCADA)
                }
              >
                <FloatButton
                  activeClassName={"nd3 nds"}
                  title={"Salvar"}
                  icon={"material-icons edit edit-sub-menu"}
                  cloudDone={true}
                  action={handleSubmit}
                />
              </If>
            </FloatButtonGroup>
          </FormContent>

          <ConfirmDialog
            title="Aviso"
            contentText="Os dados do formulário serão perdidos, deseja realmente sair?"
            textButtonCancel="CANCELAR"
            textButtonConfirm="CONFIRMAR"
            showDialog={showCancelConfirmDialog}
            onClose={() => showConfirmDialog(false)}
            onConfirm={() => backAssembleiaList()}
            type="warning"
            maxWidth="md"
          />
        </div>

        {/* DIV CAMPOS FORM*/}
        <div className="row p-2 mr-0">
          <div className="monitor-workflow monitor-workflow-head w-100 pt-3 pb-3 pl-3 logbook-head">
            <div className="mt-0 page-head__title f-36">
              {" "}
              {this.props.nameForm}{" "}
            </div>
            <SecaoIncluirAssembleiaForm
              readOnly={this.isStatusNovaConvocacao()}
              statusAssembleia={statusAssembleia}
            />
          </div>
        </div>

        {/* DIV ANEXOS*/}
        <div className="row px-0 mb-4">
          <div className="col">
            <div className="shadow-custom rounded-12 bg-white px-3 py-2 mx-2">
              <RBCard>
                <div className="mb-2">
                  <div className="page-head__title f-22 mt-2 mb-3">
                    Anexar Arquivo
                  </div>
                  <AssembleiaUploadArquivo
                    formSelectorstate={"assembleiaIncluirForm"}
                    conteudoStateAnexo={"editalConvovacaoDto"}
                    conteudoAnexoList={conteudoAnexoList}
                    hasCallBack={false}
                    isDownloaded={true}
                    onlyOneFile={true}
                    accept={ACCEPT_FILE_OPTIONS.PDF}
                    justView={
                      (statusAssembleia &&
                        (statusAssembleia.key === STATUS_ASSEMBLEIA_ENCERRADA ||
                          statusAssembleia.key ===
                            STATUS_ASSEMBLEIA_CONVOCADA)) ||
                      this.isStatusNovaConvocacao()
                    }
                  />
                </div>
              </RBCard>
            </div>
          </div>
        </div>

        {/* DIV ANEXOS INVESTIDORES*/}
        <div className="row px-0 mb-4">
          <div className="col">
            <div className="shadow-custom rounded-12 bg-white px-3 py-2 mx-2">
              <div className="mb-2">
                <div className="page-head__title f-22 mt-2 mb-3">
                  Anexar Lista de Investidores
                </div>
                <AssembleiaSecaoAnexoInvestidores
                  readOnly={this.isStatusNovaConvocacao()}
                  statusAssembleia={statusAssembleia}
                />
              </div>
            </div>
          </div>
        </div>

        {/* DIV ORDENS DA ASSEMBLÉIA*/}
        <div className="row px-0">
          <div className="col">
            <div className="shadow-custom rounded-12 bg-white px-3 py-2 mx-2">
              <div className="mb-2">
                <div className="page-head__title f-22 mt-2 mb-3">
                  Ordens da assembleia
                </div>
                <AssembleiaSecaoOrdensList
                  readOnly={this.isStatusNovaConvocacao()}
                  statusAssembleia={statusAssembleia}
                />
              </div>
            </div>
          </div>
        </div>

        {/* DIV SIMPLES*/}
        <div className="row">
          <div className="col"></div>
        </div>
      </div>
    );
  }
}

AssembleiaIncluirFormGeral = reduxForm({ form: "assembleiaIncluirForm" })(
  AssembleiaIncluirFormGeral
);

const selector = formValueSelector("assembleiaIncluirForm");

const mapStateToProps = (state) => ({
  conteudoAnexoList: selector(state, "editalConvovacaoDto") || [],
  statusAssembleia: selector(state, "status") || [],
  showCancelConfirmDialog: state.confirmDialogReducer.showConfirmDialog,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showConfirmDialog,
      backAssembleiaList,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssembleiaIncluirFormGeral);
