import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';

import { OPERACAO_TABS } from './../OperacaoTabs';

import AtivoOperacaoForm from './form/AtivoOperacaoForm';
import ContainerPage from '../../../../common/container/ContainerPage';

class AtivoVinculoOperacaoPage extends Component {

    render() {
        const { 
            operacoes,
        } = this.props;
    
        const idsOperacoes = operacoes.map(o => o.key);

        return (
            <ContainerPage
                tabs={OPERACAO_TABS}
                paramsRoute={idsOperacoes}
            >
                <AtivoOperacaoForm/>
            </ContainerPage>
        );
    }
}

const mapStateToProps = state => ({ 
    tab: state.tab,
    operacoes: state.operacaoReducer.selectedOperacoes, 
});

const mapDispacthToProps = dispatch => bindActionCreators({     
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(AtivoVinculoOperacaoPage));
