import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import GenericPickerInput from "../../common/input/GenericPickerInput";

import { 
    showFundoList, 
    getSugestaoFundo,
    selectedFundoOption,
 } from "../../../actions/fundo/FundoAction";

const FundoPicker = (props) => (
    <div>
        <div>
            <GenericPickerInput 
                loadData={props.getSugestaoFundo}
                options={props.options}
                selectedOption={props.selectedFundoOption}                
                placeholder={"Pesquisar Fundo"}
                loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                noOptionsMessage="Nenhum registro encontrado"
                isMulti={true}
                activeClassName={props.classes}
                value={props.fundos}
            />
        </div>
    </div>
);

const mapDispatchToProps = dispatch => bindActionCreators({
    getSugestaoFundo,
    selectedFundoOption,
    showFundoList,
}, dispatch);

const mapStateToProps = state => ({
    options: state.fundoReducer.fundosOptions,
    fundos: state.fundoReducer.selectedFundoOptions
});

export default connect(mapStateToProps, mapDispatchToProps)(FundoPicker);
