import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, formValueSelector, Field } from "redux-form";

import { showConfirmDialog } from "../../../../../actions/common/modal/ConfirmDialogAction";
import {
  showUsuarioList,
  onChangeConta,
  changeConta,
  onChangeUsuario,
} from "../../../../../actions/usuario/UsuarioAction";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";
import ConfirmDialog from "../../../../common/modal/ConfirmDialog";
import DropdownListInput from "../../../../common/input/DropdownListInput";
import SwitchField from "../../../../common/input/SwitchField";
import { sairDaPagina } from "../../../../../actions/aprovadores/AprovadoresAction";
import Button from "../../../../common/button/Button";
import { showMessageAlert } from "../../../../../actions/operacao/PassivoAction";
import { withRouter } from "react-router-dom";

let AprovadoresForm = (props) => {
  const {
    tipoAprovacoesList,
    usuariosList,
    invalid,
    floatButtonVisible,
    showCancelConfirmDialog,
    showConfirmDialog,
    title,
    handleSubmit,
    readOnly,
    readOnlyField,
    sairDaPagina,
  } = props;

  let user = JSON.parse(localStorage.getItem("userLogged"));
  let acessosUser = user.acessos;
  let permitido = acessosUser.some((acesso) => acesso === 1);
  
  React.useEffect(() => {
    if (!permitido) {
      sairDaPagina()
    };
  }, []);

  return (
    <Form>
      <FormHeader title={title} col={"col-lg"} offSet={20}></FormHeader>
      <FormContent handleSubmit={handleSubmit}>
        <div className="shadow-custom rounded-12 bg-white px-3 py-2 column">
          <div className="row px-0">
            <div className="col-lg">
              <Field
                component={DropdownListInput}
                inputId="idUsuario"
                name="idUsuario"
                label="Usuário"
                options={usuariosList}
                textField="nome"
                valueField="id"
                placeholder={"Todos"}
                filter="contains"
                readOnly={readOnly || readOnlyField}
              />
            </div>

            <div className="col-lg">
              <Field
                component={DropdownListInput}
                inputId="idTipoAprovacao"
                name="idTipoAprovacao"
                label="Tipo de Aprovador "
                options={tipoAprovacoesList}
                textField="tipo"
                valueField="id"
                placeholder={"Todos"}
                filter="contains"
                readOnly={readOnly}
              />
            </div>

            <div className="col-lg-auto d-flex align-items-center pt-2 mr-3">
              <Field
                name="ativo"
                component={SwitchField}
                inactiveLabel="Inativo"
                activeLabel="Ativo"
                width={80}
                uncheckedOffset={15}
                checkedOffset={15}
                onColor="#83BF74"
                offColor="#dc3545"
              />
            </div>
          </div>

          <div className="col-12 px-0">
            <div className="d-flex flex-row justify-content-between align-items-center col-12 p-0 mb-2 mt-3">
              <Button
                name={`Cancelar ${floatButtonVisible ? "Alterações" : ""}`}
                classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                action={() => {
                  floatButtonVisible
                    ? showConfirmDialog(true)
                    : sairDaPagina();
                }}
              />

              {(floatButtonVisible && permitido) && (
                <Button
                  type="submit"
                  name={"Salvar Cadastro"}
                  classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                  action={() => invalid && showMessageAlert("Alguns erros foram encontrados. Por favor, revise atentamente o cadastro.")}
                />
              )}
            </div>
          </div>
        </div>
      </FormContent>
      <ConfirmDialog
        title="Aviso"
        contentText="Os dados do formulário serão perdidos, deseja realmente sair ?"
        textButtonCancel="CANCELAR"
        textButtonConfirm="CONFIRMAR"
        showDialog={showCancelConfirmDialog}
        onClose={() => showConfirmDialog(false)}
        onConfirm={() => sairDaPagina()}
        type="warning"
        maxWidth="md"
      />
    </Form>
  );
};

// VALIDAÇÃO
const validate = (values) => {
  const errors = {
    idUsuario: !values.idUsuario ? 'Preenchimento obrigatório' : null,
    idTipoAprovacao: !values.idTipoAprovacao ? 'Preenchimento obrigatório' : null,
  };

  return errors;
};

const mapStateToProps = (state) => ({
  showCancelConfirmDialog: state.confirmDialogReducer.showConfirmDialog,
  tipoAprovacoesList: state.aprovadoresReducer.aprovadoresForm.tipoAprovacoes || [],
  usuariosList: state.aprovadoresReducer.aprovadoresForm.usuarios || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showUsuarioList,
      showConfirmDialog,
      onChangeConta,
      changeConta,
      onChangeUsuario,
      sairDaPagina,
    },
    dispatch
  );

const ConnectedAprovadoresForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(AprovadoresForm));

export default reduxForm({
  form: "aprovadoresForm",
  validate,
})(ConnectedAprovadoresForm);
