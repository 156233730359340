import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Table from "../../../../../../common/table/Table";
import TableHeader from "../../../../../../common/table/TableHeader";
import TableContent from "../../../../../../common/table/TableContent";
import TableEmptyData from "../../../../../../common/table/TableEmptyData";
import ConfirmDialog from "../../../../../../common/modal/ConfirmDialog";
import FloatButton from "../../../../../../common/button/FloatButton";

import {
  showOpcaoForm,
  showOpcaoDeleteDialog,
  removeOpcao,
  loadFormOpcao,
  getTipos,
} from "../../../../../../../actions/precificacao/OpcaoAction";

import TableActionWithRouteAuthorization from "../../../../../../common/table/TableActionWithRouteAuthorization";
import {
  OPCAO,
  CREATE,
  DELETE,
} from "../../../../../../../utils/actionsAndFeaturesAuth";
import { authorizationWithoutDispatch } from "../../../../../../../actions/authorization/AuthorizationAction";
import PaginationRB from "../../../../../../common/pagination/PaginationRB";
import If from "../../../../../../common/If/If";

const SeloList = (props) => {
  const {
    showOpcaoForm,
    showOpcaoDeleteDialog,
    showDeleteDialog,
    removeOpcao,
    opcoes,
    loadFormOpcao,
    getTipos,
    tipo,
  } = props;

  const { items, hasItems, pageIndex, pageSize, totalCount } = opcoes;

  const tableHeader = (
    <tr>
      <th className="text-center fw-700 text-nowrap col-3">Sigla</th>
      <th className="text-center fw-700 text-nowrap col-8">Descrição</th>
      <th className="text-center col-2"></th>
    </tr>
  );

  const tableContent =
    opcoes && hasItems ? (
      items.map((item, index) => (
        <tr key={index}>
          <td className="text-center fw-400 text-nowrap">{item.sigla}</td>
          <td className="text-center fw-400 text-nowrap">{item.descricao}</td>
          <TableActionWithRouteAuthorization
            feature={OPCAO}
            viewAction={() => loadFormOpcao(item.id, false, tipo)}
            updateAction={() => loadFormOpcao(item.id, true, tipo)}
            remove={() => showOpcaoDeleteDialog(true, item.id)}
          />
        </tr>
      ))
    ) : (
      <TableEmptyData message={"Sem itens no momento"} />
    );

  return (
    <div className="w-100 px-4 m-2">
      <If test={totalCount > 0}>
        <Table
          withoutHeader
          classes="shadow-custom rounded-12 col-12 my-3 pd-10 bg-white"
          totalItems={totalCount}
          pagination={
            <PaginationRB
              pageRange={5}
              itemsPerPage={pageSize || 0}
              totalItems={totalCount || 0}
              pageNumber={pageIndex - 1 || 0}
              fetchFunction={(event) => getTipos(tipo, event)}
            />
          }
        >
          <TableHeader>{tableHeader}</TableHeader>
          <TableContent>{tableContent}</TableContent>
        </Table>
      </If>
      <FloatButton
        activeClassName={"float"}
        title="Novo"
        icon={"fa fa-plus my-float"}
        action={() =>
          authorizationWithoutDispatch(
            { idAcao: CREATE, idFuncionalidade: OPCAO },
            () => showOpcaoForm(tipo)
          )
        }
      />
      <ConfirmDialog
        title="Aviso"
        contentText="Você realmente deseja excluir o item selecionado ?"
        textButtonCancel="CANCELAR"
        textButtonConfirm="CONFIRMAR"
        showDialog={showDeleteDialog.showDialog}
        onClose={() => showOpcaoDeleteDialog(false)}
        onConfirm={() =>
          authorizationWithoutDispatch(
            { idAcao: DELETE, idFuncionalidade: OPCAO },
            () => removeOpcao(showDeleteDialog.idOpcao, "selo")
          )
        }
        type="warning"
        maxWidth="md"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  messages: state.opcaoReducer.messages,
  showDeleteDialog: state.opcaoReducer.showOpcaoDeleteDialog,
  opcoes: state.opcaoReducer.opcaoFormTypes || [],
  tipo: state.opcaoReducer.tipo,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      showOpcaoForm,
      showOpcaoDeleteDialog,
      removeOpcao,
      loadFormOpcao,
      getTipos,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(SeloList);
