/* eslint eqeqeq: 0 */

import React from "react";
import If from "../../../../common/If/If";
import '../assets/ToggleDiv.css';

export const SOLICITACAO_DESABILITADA = 0;
export const SOLICITACAO_PENDENTE = 1;
export const SOLICITACAO_HABILITADA = 2;

function useForceUpdate(){
    const [, setValue] = React.useState(0);
    return () => setValue(value => ++value); 
}

let ToggleGerenciar = (props) => { 

    const options = [{value:SOLICITACAO_DESABILITADA, color:"red"},{value:SOLICITACAO_PENDENTE, color:"grey"},{value:SOLICITACAO_HABILITADA, color:"green"}];
    const forceUpdate = useForceUpdate();

    let renderItem = (position,color) => {
       return( 
        <div className={`togglediv__item ${(convertReduxToSelectedState() == position) ? `togglediv__item-higlited ${color}`  : ""}`} onClick={() => handleToggle(position)}>
            <If test={position == 0}>
                <i className={`toggle_icon fa fa-times ${convertReduxToSelectedState() != 1 ? `grey`:``} `}></i>
            </If>
            <If test={position == options.length -1}>
                <i className={`toggle_icon fa fa-check ${convertReduxToSelectedState() != 1 ? `grey`:``} `}></i>
            </If>
            
        </div>
       )
    }
    const convertReduxToSelectedState = () => {
        return props.value.solicitacaoLiberada == null ? SOLICITACAO_PENDENTE :  props.value.solicitacaoLiberada == true ? SOLICITACAO_HABILITADA : SOLICITACAO_DESABILITADA;
    }

    const handleToggle = (value) => {   
        if(props.onToggle){
            props.value.solicitacaoLiberada = (value == SOLICITACAO_PENDENTE) ? false : value === SOLICITACAO_HABILITADA ? true : false;
            forceUpdate();
            props.onToggle(props.value);
        }
    };
    
    return (
        <div className="d-flex w-100 justify-content-center align-items-center">
            <section className={`togglediv ${options[convertReduxToSelectedState()].color}`}>
                {options.map((item,index) => (
                    renderItem(item.value,item.color)
                ))}
            </section>
        </div>  
    );
}

export default (ToggleGerenciar);