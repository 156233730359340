import {
    ASSEMBLEIA_ROUTE_PATH,
    GERENCIAR_ROUTE_PATH,
    RESUMO_ROUTE_PATH,
    PARTICIPANTE_ROUTE_PATH,
    DOCUMENTO_ROUTE_PATH,
} from '../../../../common/routers/constantesRoutes';

export const RESUMO_TAB = 'RESUMO';
export const PARTICIPANTE_TAB = 'PARTICIPANTES';
export const DOCUMENTOS_TAB = 'DOCUMENTOS DA ASSEMBLEIA';

export const ASSEMBLEIA_GERENCIAR_TABS = [{
    pathRoute: `/${ASSEMBLEIA_ROUTE_PATH}/${GERENCIAR_ROUTE_PATH}/${RESUMO_ROUTE_PATH}`,
    target: RESUMO_TAB,
    label: RESUMO_TAB
}, {
    pathRoute: `/${ASSEMBLEIA_ROUTE_PATH}/${GERENCIAR_ROUTE_PATH}/${PARTICIPANTE_ROUTE_PATH}`,
    target: PARTICIPANTE_ROUTE_PATH,
    label: PARTICIPANTE_TAB
}, {
    pathRoute: `/${ASSEMBLEIA_ROUTE_PATH}/${GERENCIAR_ROUTE_PATH}/${DOCUMENTO_ROUTE_PATH}`,
    target: DOCUMENTO_ROUTE_PATH,
    label: DOCUMENTOS_TAB
},
];
