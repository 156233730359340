import moment from "moment";
import 'moment/locale/pt-br';

/**
 *  @description Retorna a data atual no formato DD/MM/YYYY HH:mm:ss
 */
export function getCurrentDateTimeString() {
  return moment().format("DD/MM/YYYY HH:mm:ss");
}

export function getCurrentDateTimeStringDefault() {
  return moment().format("YYYY-MM-DD HH:mm:ss");
}

export function getCurrentDateStringDefault() {
  return moment().format("YYYY-MM-DD");
}

/**
*  @description Retorna a data atual no formato YYYY-MM-DD
*/
export function getCurrentDateTimeStringNormal(string, month = false) {
  return string ? moment(string).format(`YYYY-MM${month ? '' : '-DD'}`) : null;
}

export function getDateTimeStringNormalized(string) {
  return string ? moment(string).format('DD/MM/YYYY HH:mm') : null;
}

export function getDateTimeString(string) {
  return string ? moment(string).format('DD/MM/YYYY') : null;
}

export function getDateTimeStringNormalizedTwo() {
  return moment().format('HH:mm - DD [de] MMM');
}

/**
 *  @description Retorna a data atual no formato DD/MM/YYYY
 */
export function getCurrentDateString() {
  return moment().format("DD/MM/YYYY");
}

/**
 * @description Converte uma string no formato 29/05/2018 em um Date.
 * @param {String} string: string no formato 29/05/2018
 */
export function getDateFromString(string) {
  return string ? moment(string, "DD/MM/YYYY").toDate() : null;
}

/**
 * @description Converte uma string no formato 29/05/2018 00:00:00 em um DateTime.
 * @param {String} string: string no formato 29/05/2018 00:00:00
 */
export function getDateTimeFromString(string) {
  return string ? moment(string, "DD/MM/YYYY HH:mm:ss").toDate() : null;
}

/**
 * @description
 * @param {String} string no formato 2018-05-29T16:04:42-03:00
 * @returns {*} string no formato DD/MM/YYYY
 */
export function getDateFormatedFromString(string) {
  return string ? moment(string).format("DD/MM/YYYY") : null;
}

export function getDateFormatedFromStringWithTime(string) {
  return string ? moment(string).format("DD/MM/YYYY HH:mm:ss") : null;
}

/**
 * @description Retorna a data no formato MM/YYYY
 * @param {String} string: string no formato YYYY-MM-DD (ou outro formato válido)
 */

export function getMonthYearString(string) {
  return string ? moment(string).format("MM/YYYY") : null;
}

/**
 * @description Retorna a data em formato string formatada para DD/MM/YYY hh:mm:ss
 * @param {String} string: string no formato 2018-05-29T16:04:42-03:00
 */
export function convertDateToString_DDMMYYYhhmmss(string) {
  return string ? moment(string).format("DD/MM/YYYY hh:mm:ss") : null;
}

/**
 * @description Valida se um Date é válido.
 * @param {Date} date: Date no formato DD/MM/YYYY
 */
export function validateData(date) {
  return moment(
    date.toLocaleDateString(),
    "DD/MM/YYYY",
    "pt-br",
    true
  ).isValid();
}

/**
 * @description Valida se um DateTime é válido.
 * @param {DateTime} dateTime: DateTime no formato DD/MM/YYYY HH:mm:ss.
 */
export function validateDateTime(dataTime) {
  return moment(dataTime, "DD/MM/YYYY HH:mm:ss", "pt-br", true).isValid();
}

/**
 * @description Valida se um DateTime é válido.
 * @param {DateTime} dateTime: DateTime no formato YYYY-MM-DD HH:mm:ss.
 */
export function validateDateTimeUS(dataTime) {
  return moment(dataTime).isValid();
}


export function formatDate(date, format) {
  return date ? moment(date, format).toDate() : null;
}

export function getDateFormatedMonthYear(string) {
  return string ? moment(string).locale("pt-br").format("MMMM YYYY") : null;
}

/**
 * @description Captura o ano de um DateTime.
 * @param {String} string: string no formato 29/05/2018 00:00:00
 */
export function getCurrentYearDateString(string) {
  return string ? moment(string, "YYYY").toDate().getFullYear() : null;
}

/**
 * @description Obtem data formatada de acordo com a formatacao
 * @param {String} stringDateFormat ex.: DD/MM/YYYY HH:mm:ss ou YYYYMMDD, etc
 */
export function getCurrentDateByFormat(stringDateFormat) {
  return moment().format(stringDateFormat);
}

/**
 *  @description Retorna o formato de data concatenando DD/MM/YYYY HH:mm:ss
 */
export function getStringDateTimeTwoField(data, hora) {
  let dataRecebida = data + " " + hora+":00";
  if (!validateDateTimeUS(dataRecebida)) {
    dataRecebida = null;
  }
  return dataRecebida;
}

/**
 * @description
 * @param {String} string no formato 2018-05-29T16:04:42-03:00
 * @returns {*} string no formato DD/MM/YYYY
 */
export function getHourFormatedFromString(string) {
  return string ? moment(string).format("HH:mm") : null;
}

/**
 * @description
 * @param {String} string no formato 2018-05-29T16:04:42-03:00
 * @returns {*} string no formato YYYY-MM-DD
 */
export function getDateInvertedFormatedFromString(string) {
  return string ? moment(string).format("YYYY-MM-DD") : null;
}

/**
 * @description
 * @param {String} string no formato 2018-05-29 (Sem horas)
 * @returns {*} string no formato YYYY-MM-DD
 */
export function getDateNormalInvertedFormatedFromString(string) {
  return string ? moment(string, 'DD/MM/YYYY').format('YYYY-MM-DD') : null;
}

/**
 * @description Obtem o primeiro dia do mês passado.
*/
export function primeiroDiaMesPassado() {
  const primeiroDiaMesPassado = moment().subtract(1, 'months').startOf('month');
  return primeiroDiaMesPassado.format('YYYY-MM-DD');
}

/**
 * @description Obtem o último dia do próximo mês
 */
export function ultimoDiaProximoMes() {
  const ultimoDiaProximoMes = moment().add(2, 'months').startOf('month').subtract(1, 'days');
  return ultimoDiaProximoMes.format('YYYY-MM-DD');
}

export function ultimoDiadoMês() {
  const ultimoDiaMes = moment().endOf('month')
  return ultimoDiaMes.format('YYYY-MM-DD');
}

/**
 * @description Calcula uma nova data com base na data atual, adicionando ou subtraindo um número especificado de dias, e retorna a data resultante no formato 'YYYY-MM-DD'.
 * Se a operação fornecida não for válida, retorna a data atual.
 * 
 * @param {string} operation - O tipo de operação a ser realizada: '+' para adicionar dias ou '-' para subtrair dias.
 * @param {number} days - A quantidade de dias a serem adicionados ou subtraídos.
 * 
 * @returns {string} A data resultante no formato 'YYYY-MM-DD'. Se a operação for inválida, retorna a data atual no formato 'YYYY-MM-DD'.
 * 
 * @example
 * // Adiciona 3 dias à data atual
 * console.log(calculateDate('+', 3)); // Exemplo de saída: '2024-05-23'
 * 
 * @example
 * // Subtrai 3 dias da data atual
 * console.log(calculateDate('-', 3)); // Exemplo de saída: '2024-05-17'
 * 
 * @example
 * // Operação inválida retorna a data atual
 * console.log(calculateDate('*', 3)); // Exemplo de saída: '2024-05-20'
 */
export function calculateDate(operation, days) {
  let date = moment();

  if (operation === '+') {
    date = date.add(days, 'days');
  } else if (operation === '-') {
    date = date.subtract(days, 'days');
  }

  return date.format('YYYY-MM-DD');
}

/**
 * @description Booleano para datas PTBR 'DD/MM/YYYY'
 */
export function dataMenorOuIgualHoje(data) {
  const hoje = moment();
  const dataFormatada = moment(data, 'DD/MM/YYYY');

  return dataFormatada.isSameOrBefore(hoje);
}

/**
 * @description Booleano para datas EUA 'YYYY-MM-DD'
 */
export function dataMenorOuIgualHojeDefault(data) {
  const hoje = moment();
  const dataFormatada = moment(data, 'YYYY-MM-DD');

  return dataFormatada.isSameOrBefore(hoje);
}

/**
 * @description Booleano para datas EUA 'YYYY-MM-DD'
 */
export function dataMenorQueHoje(data) {
  const hoje = moment();
  const dataFormatada = moment(data, 'YYYY-MM-DD');

  return dataFormatada.isBefore(hoje, 'day');
}

export function dataMenorOuIgualAHoje(data) {
  const hoje = moment();
  const dataFormatada = moment(data, 'YYYY-MM-DD');

  return dataFormatada.isSameOrBefore(hoje, 'day');
}

/**
 * @description Verifica se a data é igual ou maior que uma data específica
 * @param {string} data A data a ser verificada no formato 'YYYY-MM-DD'
 * @param {string} dataEspecifica A data específica a ser comparada no formato 'YYYY-MM-DD'
 * @returns {boolean} Retorna true se a data for igual ou maior que a data específica, caso contrário retorna false
 */
export function dataMaiorOuIgualA(data, dataEspecifica) {
  const dataFormatada = moment(data, 'YYYY-MM-DD');
  const dataEspecificaFormatada = moment(dataEspecifica, 'YYYY-MM-DD');

  return dataFormatada.isSameOrAfter(dataEspecificaFormatada);
}

/**
 * @description Verifica se a data é igual ou menor que uma data específica
 * @param {string} data A data a ser verificada no formato 'YYYY-MM-DD'
 * @param {string} dataEspecifica A data específica a ser comparada no formato 'YYYY-MM-DD'
 * @returns {boolean} Retorna true se a data for igual ou menor que a data específica, caso contrário retorna false
 */
export function dataMenorOuIgualA(data, dataEspecifica) {
  const dataFormatada = moment(data, 'YYYY-MM-DD');
  const dataEspecificaFormatada = moment(dataEspecifica, 'YYYY-MM-DD');

  return dataFormatada.isSameOrBefore(dataEspecificaFormatada);
}

/**
 * @description Verifica se a data é maior que uma data específica
 * @param {string} data A data a ser verificada no formato 'YYYY-MM-DD'
 * @param {string} dataEspecifica A data específica a ser comparada no formato 'YYYY-MM-DD'
 * @returns {boolean} Retorna true se a data for maior que a data específica, caso contrário retorna false
 */
export function dataMaiorQue(data, dataEspecifica) {
  const dataFormatada = moment(data, 'YYYY-MM-DD');
  const dataEspecificaFormatada = moment(dataEspecifica, 'YYYY-MM-DD');

  return dataFormatada.isAfter(dataEspecificaFormatada);
}

export async function calcularDiferencaEmDias(data1, data2) {
  return new Promise((resolve, reject) => {
      try {
          const inicio = moment(data1, 'YYYY-MM-DD');
          const fim = moment(data2, 'YYYY-MM-DD');

          let diferencaEmDias = fim.diff(inicio, 'days') - 1;

          let resultado;
          if (diferencaEmDias >= 0) {
              resultado = 'C' + (diferencaEmDias).toString().padStart(2, '0');
          } else {
              resultado = 'C00';
          }

          resolve(resultado);
      } catch (error) {
          reject(error);
      }
  });
}

export function calcularDiferencaDataDias(data1, data2) {
  const inicio = moment(data1, 'YYYY-MM-DD');
  const fim = moment(data2, 'YYYY-MM-DD');

  const diferencaEmDias = inicio.diff(fim, 'days');

  return diferencaEmDias;
}

export function validateDataEncerramentoSocialAnual(dataEncerramentoSocialAnual, year) {
  const errors = {};

  // Verifica se o campo está vazio
  if (!dataEncerramentoSocialAnual) {
    errors.dataEncerramentoSocialAnual = 'Preenchimento obrigatório';
    return errors;
  }

  // Verifica se está no formato DD/MM
  if (!moment(dataEncerramentoSocialAnual, 'DD/MM').isValid()) {
    errors.dataEncerramentoSocialAnual = 'Formato inválido (use DD/MM)';
    return errors;
  }

  // Verifica se a data é válida com o ano especificado
  const data = moment(`${dataEncerramentoSocialAnual}/${year}`, 'DD/MM/YYYY');
  if (!data.isValid()) {
    errors.dataEncerramentoSocialAnual = 'Data inválida';
    return errors;
  }

  // Verifica se o ano é bissexto
  const isLeapYear = data.isLeapYear();

  // Calcula o número de dias em fevereiro
  const februaryDays = isLeapYear ? 29 : 28;

  // Cria um array com os dias em cada mês (considerando o ano bissexto)
  const diasNoMes = [
    31, februaryDays, 31, 30, 31, 30,
    31, 31, 30, 31, 30, 31
  ];

  const dia = parseInt(data.format('DD'), 10);
  const mes = parseInt(data.format('MM'), 10);

  // Verifica se o dia está dentro do intervalo correto para o mês e ano
  if (dia < 1 || dia > diasNoMes[mes - 1]) {
    errors.dataEncerramentoSocialAnual = 'Dia inválido para o mês especificado';
  }

  // Verifica se o mês está dentro do intervalo de 1 a 12
  if (mes < 1 || mes > 12) {
    errors.dataEncerramentoSocialAnual = 'Mês inválido';
  }

  return errors;
}

export function converterParaFormatoEUA(data) {
  const dataMoment = moment(data, 'DD/MM/YYYY');

  if (!dataMoment.isValid()) {
    return 'Data inválida';
  }

  const dataFormatoEUA = dataMoment.format('YYYY-MM-DD');
  return dataFormatoEUA;
}

export function extrairDiaMes(data) {
  const dataMoment = moment(data, 'YYYY-MM-DD');

  if (!dataMoment.isValid()) {
    return 'Data inválida';
  }

  const diaMes = dataMoment.format('DD/MM');
  return diaMes;
}