/* eslint eqeqeq: 0 */

import axios from "axios";
import { change, formValueSelector, initialize, reset } from "redux-form";
import { showAllMessages } from "./../message/ErrorMessagesAPIAction";
import {
  deserializeFieldsDiarioBordoFormFilter,
  deserializeFieldsCadastroDiarioBordoForm,
  serializeFieldsDiarioBordoFormFilter,
} from "../../components/business/gestao/mappings/DiarioBordoMap";

import {
  downloadExcelFile,
  downloadFile,
} from "../../utils/helpers/FileHelper";
import {
  getDateFormatedFromString,
  getMonthYearString,
} from "../../utils/helpers/DateTimeHelper";
import { caracterDiferenteZero } from "../../utils/helpers/NumberHelper";
import moment from "moment";

const DIARIO_BORDO_API =
  process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_DIARIO_BORDO_API;

const NO_ACTION = "";

export const DIARIO_BORDO_OPERACAO_OPTIONS_FETCHED =
  "DIARIO_BORDO_OPERACAO_OPTIONS_FETCHED";
export const DIARIO_BORDO_DELETE_DIALOG = "DIARIO_BORDO_DELETE_DIALOG";
export const DIARIOS_BORDO_FETCHED = "DIARIOS_BORDO_FETCHED";
export const DIARIO_BORDO_LOAD_FILTER_FORM = "DIARIO_BORDO_LOAD_FILTER_FORM";
export const DIARIO_BORDO_CHANGED_TIPO_EVENTO =
  "DIARIO_BORDO_CHANGED_TIPO_EVENTO";
export const ADICIONAR_ARQUIVO_UPLOAD = "ADICIONAR_ARQUIVO_UPLOAD";
export const SHOW_MODAL_GENERICO = "SHOW_MODAL_GENERICO";
export const LOAD_DADOS_TELA_FORM_DIARIOBORDO =
  "LOAD_DADOS_TELA_FORM_DIARIOBORDO";
export const RESET_DIARIO_BORDO_FILTERS = "RESET_DIARIO_BORDO_FILTERS";

export function loadFilterForm() {
  return (dispatch) => {
    return axios.get(DIARIO_BORDO_API + "tela/filtro").then((result) =>
      dispatch({
        type: DIARIO_BORDO_LOAD_FILTER_FORM,
        payload: result.content,
      })
    );
  };
}

export function onChangeFieldInput(option, formName, fieldName) {
  return (dispatch) => {
    return dispatch([change(formName, fieldName, option)]);
  };
}

export function onChangeField(option, formName, fieldName) {
  return (dispatch) => {
    return dispatch([change(formName, fieldName, option), getDiariosBordo()]);
  };
}

export function onChanteTipoEvento(idTipoEvento) {
  return (dispatch) => {
    return dispatch([
      { type: DIARIO_BORDO_CHANGED_TIPO_EVENTO, payload: idTipoEvento },
      getDiariosBordo(),
    ]);
  };
}
export function resetFilters() {
  return (dispatch) => {
    return dispatch([
      reset("diarioBordoForm"),
      { type: RESET_DIARIO_BORDO_FILTERS },
      getDiariosBordo(),
    ]);
  };
}
export function getDiariosBordo(page = 1) {
  return (dispatch, getState) => {
    const selector = formValueSelector("diarioBordoForm");
    const filters = selector(
      getState(),
      "operacao",
      "idsDiarioBordoTipoEvento",
      "dataInicio",
      "dataFim"
    );

    const params = deserializeFieldsDiarioBordoFormFilter(filters, page);

    return axios
      .post(`${DIARIO_BORDO_API}diarios`, { ...params })
      .then((result) =>
        dispatch({
          type: DIARIOS_BORDO_FETCHED,
          payload: { result },
        })
      );
  };
}
export function exportDiarioBordoToExcel() {
  return (dispatch, getState) => {
    const selector = formValueSelector("diarioBordoForm");
    const filters = selector(
      getState(),
      "operacao",
      "idsDiarioBordoTipoEvento",
      "dataInicio",
      "dataFim"
    );

    const params = deserializeFieldsDiarioBordoFormFilter(filters);

    if (!params.dataInicio && params.dataFim) {
      return { type: NO_ACTION };
    }

    return axios
      .post(`${DIARIO_BORDO_API}exportar/excel`, { ...params })
      .then((result) => {
        const fileName = "Diário de bordo.xlsx";
        dispatch([
          downloadExcelFile(result.content, fileName),
          showAllMessages(result.messages),
        ]);
      });
  };
}

export function saveEventoDiarioDeBordo(evento) {
  const eventoDeserialized = serializeFieldsDiarioBordoFormFilter(evento);

  return (dispatch) => {
    return axios
      .post(DIARIO_BORDO_API, { ...eventoDeserialized })
      .then((result) =>
        dispatch([
          getDiariosBordo(),
          showModalGenerico(false, false, 0),
          showAllMessages(result.messages),
        ])
      );
  };
}

export function showEventoDiarioBordoDeleteDialog(
  showDialog,
  idEventoDiarioBordo
) {
  return (dispatch) => {
    return dispatch([
      {
        type: DIARIO_BORDO_DELETE_DIALOG,
        payload: { showDialog, idEventoDiarioBordo },
      },
    ]);
  };
}

export function obterPreenchimentoTelaAlteracaoDiarioBordo(idDiarioBordo) {
  return (dispatch) => {
    return axios
      .get(DIARIO_BORDO_API + `tela/${idDiarioBordo || ""}`)
      .then((result) =>
        dispatch([
          initialize(
            "cadastroDiarioBordoForm",
            deserializeFieldsCadastroDiarioBordoForm(
              result.content.diarioDeBordoDto
            ),
            []
          ),
        ])
      );
  };
}

export function editEventoDiarioDeBordo(evento) {
  const eventoDeserialized = serializeFieldsDiarioBordoFormFilter(evento);

  return (dispatch) => {
    return axios
      .put(DIARIO_BORDO_API, { ...eventoDeserialized })
      .then((result) =>
        dispatch([
          getDiariosBordo(),
          showModalGenerico(false, false, 0),
          showAllMessages(result.messages),
        ])
      );
  };
}

export function removerEventoDiarioDeBordo(idEventoDiarioBordo) {
  return (dispatch) => {
    return axios
      .delete(DIARIO_BORDO_API + idEventoDiarioBordo)
      .then((result) =>
        dispatch([
          showAllMessages(result.messages),
          showEventoDiarioBordoDeleteDialog(false, 0),
          getDiariosBordo(),
        ])
      );
  };
}

export function showModalGenerico(
  showDialogCadastro = false,
  showDialogUploadImg = false,
  showDialogId = 0,
  justView = false
) {
  return (dispatch) => {
    return dispatch([
      {
        type: SHOW_MODAL_GENERICO,
        payload: {
          showDialogCadastro,
          showDialogUploadImg,
          showDialogId,
          justView,
        },
      },
    ]);
  };
}

export function buscarAnexoDiariodeBordoPorId(params) {
  return (dispatch) => {
    return axios
      .get(`${DIARIO_BORDO_API}anexo/` + params.idAnexo)
      .then((result) => {
        dispatch([
          downloadFile(result.content.arquivo, result.content.nomeArquivo),
          showAllMessages(result.messages),
        ]);
      });
  };
}

export function changeValueForm(target, value = null) {
  return (dispatch) => {
    dispatch([change("cadastroDiarioBordoForm", target, value)]);
  };
}

export function formatacaoDoTituloConformeTiposEscolhidos(
  tipoEvento,
  descricaoItem,
  campoFormulario
) {
  return (dispatch, getState) => {
    const selector = formValueSelector("cadastroDiarioBordoForm");
    const novaData = selector(getState(), "dataOcorrencia");

    let data =
      novaData && novaData != undefined
        ? getDateFormatedFromString(novaData)
        : "";
    let tituloNovo;
    let tituloPortal;

    var addTituloNovo =
      (tipoEvento === "Assembleia" &&
        campoFormulario === "tipoEspecieAssembleia") ||
      (tipoEvento === "Comunicado" &&
        campoFormulario === "tipoEspecieComunicado");

    if (addTituloNovo) {
      tituloNovo = `${descricaoItem || ""}`;
    }

    tituloPortal = `${tituloNovo} - ${data}`;

    return dispatch([
      change("cadastroDiarioBordoForm", "titulo", tituloNovo),
      change("cadastroDiarioBordoForm", "tituloPortal", tituloPortal),
    ]);
  };
}

export function formataTituloDeAcordoComTipoEvento(tipoEvento) {
  return (dispatch, getState) => {
    const selector = formValueSelector("cadastroDiarioBordoForm");
    const dataOcorrencia = selector(getState(), "dataOcorrencia");
    const dataBase = selector(getState(), "dataBase");

    let data =
      dataOcorrencia && dataOcorrencia != undefined
        ? getDateFormatedFromString(dataOcorrencia)
        : "";

    let dataB =
      dataBase && dataBase != undefined ? getMonthYearString(dataBase) : "";

    let tituloNovo = "";
    let tituloPortal = "";

    if (tipoEvento === "Fato Relevante" || tipoEvento === "Informe CVM") {
      tituloNovo = `${tipoEvento} - ${data}`;
      tituloPortal = tituloNovo;
    } else if (tipoEvento === "Pagamento") {
      tituloNovo = `${tipoEvento} - ${data}`;
      tituloPortal = tituloNovo;
    } else if (tipoEvento === "Monitoramento") {
      tituloNovo = `${tipoEvento} - ${data}`;
      tituloPortal = tituloNovo;
    } else if (tipoEvento === "Performance de Lastro e Garantia") {
      tituloNovo = `Relatório ${tipoEvento} - ${dataB}`;
      tituloPortal = tituloNovo;
    }

    return dispatch([
      change("cadastroDiarioBordoForm", "composicaoTitulo.idTipoEspecie", null),
      change("cadastroDiarioBordoForm", "composicaoTitulo.idTipoContrato", null),
      change("cadastroDiarioBordoForm", "composicaoTitulo.ordemContrato", null),
      change("cadastroDiarioBordoForm", "titulo", tituloNovo),
      change("cadastroDiarioBordoForm", "tituloPortal", tituloPortal),
    ]);
  };
}

export function formataTituloDeAcordoComDataOcorrencia(
  dataOcorrencia,
  objTipoEvento,
  tipoContratoAditamentoLista,
  isEdit
) {
  return (dispatch, getState) => {
    const selector = formValueSelector("cadastroDiarioBordoForm");
    let objTipoContrato = selector(getState(), "composicaoTitulo.idTipoContrato"); // tipoContratoAditamentoLista
    const objTipoEspecie = selector(getState(), "composicaoTitulo.idTipoEspecie"); 
    const ordemContrato = selector(getState(), "composicaoTitulo.ordemContrato");
    const titulo = selector(getState(), "titulo");

    let tipoContrato;

    if (isEdit) {
      objTipoContrato = tipoContratoAditamentoLista.find((item) => item.id == objTipoContrato?.id)
    } 
    
    const numOrdemFormatado = caracterDiferenteZero(ordemContrato) ? `${ordemContrato}º` : "";
    
    let tituloNovo;
    let tituloPortal;
    
    const tipoEvento = objTipoEvento ? objTipoEvento.descricao : "";
    tipoContrato = objTipoContrato ? objTipoContrato?.descricao : "";
    const tipoEspecie = objTipoEspecie ? objTipoEspecie?.descricao : "";

    let data = dataOcorrencia && dataOcorrencia != undefined ? getDateFormatedFromString(dataOcorrencia) : "";

    if (tipoEvento == "Aditamento") {
      tituloNovo = `${numOrdemFormatado} ${tipoEvento} - ${tipoContrato}`;
      tituloPortal = `${tituloNovo} - ${data}`;
    } else if (tipoEvento == "Comunicado" || tipoEvento == "Assembleia") {
      tituloNovo = tipoEspecie;
      tituloPortal = `${tituloNovo} - ${data}`;
    } else if (tipoEvento == "Fato Relevante" || tipoEvento == "Informe CVM" || tipoEvento == "Monitoramento" || tipoEvento == "Pagamento") {
      tituloNovo = `${tipoEvento} - ${data}`;
      tituloPortal = tituloNovo;
    } else if (tipoEvento == "Histórico") {
      tituloNovo = titulo;
      tituloPortal = `${tituloNovo} - ${data}`;
    }

    return dispatch([
      change("cadastroDiarioBordoForm", "titulo", tituloNovo),
      change("cadastroDiarioBordoForm", "tituloPortal", `${tituloPortal}`),
    ]);
  };
}

export function formataTituloDeAcordoComDataBase(dataBase, objTipoEvento) {
  return (dispatch, getState) => {
    let tituloNovo;
    let tituloPortal;

    const dataB =
      dataBase && dataBase !== undefined ? getMonthYearString(dataBase) : "";
    const tipoEvento = objTipoEvento ? objTipoEvento.descricao : "";

    if (tipoEvento === "Performance de Lastro e Garantia") {
      const dataBaseMoment = moment(dataBase);
      const ultimoDiaMes = dataBaseMoment.endOf("month").format("YYYY-MM-DD");

      tituloNovo = `Relatório ${tipoEvento} - ${dataB}`;
      tituloPortal = tituloNovo;
      tituloNovo = `Relatório ${tipoEvento} - ${dataB}`;
      tituloPortal = tituloNovo;
      dispatch(
        change("cadastroDiarioBordoForm", "dataOcorrencia", ultimoDiaMes)
      );
    } else {
      dispatch(change("cadastroDiarioBordoForm", "dataOcorrencia", ""));
    }

    return dispatch([
      change("cadastroDiarioBordoForm", "titulo", tituloNovo),
      change("cadastroDiarioBordoForm", "tituloPortal", `${tituloPortal}`),
    ]);
  };
}

export function formatacaoDoTituloTipoContrato(
  numeroOrdemContrato,
  tipoContratoAditamentoLista,
  isEdit
) {
  return (dispatch, getState) => {
    const selector = formValueSelector("cadastroDiarioBordoForm");
    let objTipoContrato = selector(getState(), "composicaoTitulo.idTipoContrato"); // tipoContratoAditamentoLista
    const novaData = selector(getState(), "dataOcorrencia");
    const tipoEvento = selector(getState(), "tipoEvento");

    let tipoContrato;

    if (isEdit) {
      objTipoContrato = tipoContratoAditamentoLista.find((item) => item.id == objTipoContrato?.id)
    } 

    let tituloNovo;
    let tituloPortal;
    let data = novaData && novaData != undefined ? getDateFormatedFromString(novaData) : "";

    tipoContrato = objTipoContrato ? objTipoContrato?.descricao : "";

    if (tipoEvento.descricao === "Aditamento") {
      const descricaoContrato = `- ${tipoContrato}`;
      const numOrdemFormatado = caracterDiferenteZero(numeroOrdemContrato) ? `${numeroOrdemContrato}º` : "";

      tituloNovo = `${numOrdemFormatado} ${tipoEvento.descricao} ${descricaoContrato}`;
      tituloPortal = `${tituloNovo} - ${data}`;
    }

    return dispatch([
      change("cadastroDiarioBordoForm", "titulo", tituloNovo),
      change("cadastroDiarioBordoForm", "tituloPortal", tituloPortal),
    ]);
  };
}

export function digitacaoTextoLivre(tipoEvento, textoTitulo) {
  return (dispatch, getState) => {
    const selector = formValueSelector("cadastroDiarioBordoForm");
    const novaData = selector(getState(), "dataOcorrencia");

    let data =
      novaData && novaData != undefined
        ? getDateFormatedFromString(novaData)
        : "";
    let tituloPortal;

    if (tipoEvento === "Histórico") {
      tituloPortal = `${textoTitulo} - ${data}`;
    }

    return dispatch([
      change("cadastroDiarioBordoForm", "tituloPortal", `${tituloPortal}`),
    ]);
  };
}
