import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field } from "redux-form";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";
import Button from "../../../../common/button/Button";
import DropdownListInput from "../../../../common/input/DropdownListInput";

import {
  changeTipoFilter,
  getTipos,
} from "../../../../../actions/precificacao/OpcaoAction";

let OpcaoListFilter = (props) => {
  const { getTipos, changeTipoFilter, tipo } = props;

  let tiposList = [
    { id: "moeda", descricao: "Moedas" },
    { id: "selo", descricao: "Selos" },
    { id: "tipo-aprovacao", descricao: "Tipo Aprovação" },
    { id: "produto", descricao: "Produto (Pedido de Compra)" },
    { id: "movimentacao-financeira", descricao: "Movimentação Financeira (Pedido de Compra)" },
    { id: "natureza", descricao: "Natureza" },
    { id: "banco-externo", descricao: "Bancos" },
  ];

  return (
    <div className="w-100 px-3">
      <Form>
        <FormHeader title={"Opções do Sistema"} />
        <FormContent
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
          handleSubmit={getTipos}
        >
          <div className="row">
            <div className="col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="tipo"
                name="tipo"
                label="Tipo Campo"
                options={tiposList}
                textField="descricao"
                valueField="id"
                filter="contains"
                onChange={(value) => changeTipoFilter(value.id)}
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="row">
              <Button
                action={() => getTipos(tipo)}
                classes="p-bg-imp rounded"
                icon={"search"}
                name={"Buscar"}
              />
            </div>
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

OpcaoListFilter = reduxForm({ form: "opcaoForm" })(OpcaoListFilter);

const mapStateToProps = (state) => ({
  tipo: state.opcaoReducer.tipo,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeTipoFilter,
      getTipos,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OpcaoListFilter);
