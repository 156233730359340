/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, formValueSelector, Field } from "redux-form";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCog } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

import { getStringBooleanValue } from "../../../../../utils/helpers/BooleanHelper";
import { formatStrToCnpjOrCpf } from "../../../../../utils/helpers/StringHelper";

import DropdownListInput from "../../../../common/input/DropdownListInput";
import Input from "../../../../common/input/Input";
import PaginationRB from "../../../../common/pagination/PaginationRB";
import IconWithSubtitle from "../../../../common/popover/IconWithSubtitle";

import AssembleiaGerenciarInvestidorModal from "../gerenciar/AssembleiaGerenciarInvestidorModal";

import {
  ASSEMBLEIA,
  UPDATE,
} from "../../../../../utils/actionsAndFeaturesAuth";

import {
  buscarInvestidoresPorAssembleiaTermo,
  obterDadosGerenciarInvestidor,
  downloadExcelBoletinVotacao,
} from "../../../../../actions/assembleia/AssembleiaAction";
import { authorizationFunction } from "../../../../../actions/authorization/AuthorizationAction";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import Table from "../../../../common/table/Table";
import If from "../../../../common/If/If";

const GREY_COLOR = "#e1dfdc";
const YELLOW_COLOR = "#ffc700";
const GREEN_COLOR = "#96d600";

let AssembleiaGerenciarParticipanteConfirmadosList = (props) => {
  const { idAssembleia, termo, statusSolicitacao, statusSolicitacaoList } =
    props;

  const filterKeyHandler = (e) => {
    if (e.keyCode === 13) {
      props.buscarInvestidoresPorAssembleiaTermo({ idAssembleia, termo });
    }
  };

  return (
    <MuiThemeProvider>
      <div className="col monitor-workflow w-100">
        <div className="row side-by-side-grid h-100">
          <div className="col-md px-0">
            <div className="monitor-assembly-field h-100">
              <div className="row d-flex flex-row col-12">
                <div className="col-6 mt-3">
                  <Field
                    component={Input}
                    className={"filter-input participante-input"}
                    outerClassName={"participante-search-input"}
                    label="Pesquisar por nome, e-mail ou CPF"
                    inputId="termo"
                    name="termo"
                    type="text"
                    onKeyUp={(e) => filterKeyHandler(e)}
                    onBlur={(e) =>
                      props.buscarInvestidoresPorAssembleiaTermo({
                        idAssembleia,
                        termo: e.target.value,
                        statusSolicitacao,
                      })
                    }
                  />
                </div>
                <div className="col-4 mt-3">
                  <Field
                    outerClassName={"participante-search-input"}
                    component={DropdownListInput}
                    inputId="statusSolicitacao"
                    name="statusSolicitacao"
                    label="Situação Solicitação"
                    options={statusSolicitacaoList}
                    textField="value"
                    valueField="id"
                    filter="contains"
                    onChange={(e) =>
                      props.buscarInvestidoresPorAssembleiaTermo({
                        idAssembleia,
                        termo,
                        statusSolicitacao: e,
                      })
                    }
                  />
                </div>
                <div className="col-2">
                  <Tooltip
                    TransitionComponent={Zoom}
                    title="Exportar Boletim de Votação"
                    placement="right"
                  >
                    <IconButton
                      style={{ color: "#630a37" }}
                      onClick={() =>
                        props.authorizationFunction(
                          { idAcao: UPDATE, idFuncionalidade: ASSEMBLEIA },
                          () => props.downloadExcelBoletinVotacao(idAssembleia)
                        )
                      }
                    >
                      <i className="fa fa-file-excel-o" aria-hidden="true"></i>
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              
              <If test={props.participantesConfirmardosList.totalCount > 0}>
                <Table
                  withoutHeader
                  classes="border-0 shadow-none col-12 my-3 pd-10 bg-white"
                  totalItems={props.participantesConfirmardosList.totalCount}
                  pagination={
                    <PaginationRB
                      pageNumber={
                        props.participantesConfirmardosList.pageIndex - 1
                      }
                      itemsPerPage={
                        props.participantesConfirmardosList.pageSize
                      }
                      totalItems={
                        props.participantesConfirmardosList.totalCount
                      }
                      filter={{ idAssembleia, termo, statusSolicitacao }}
                      fetchFunction={props.buscarInvestidoresPorAssembleiaTermo}
                      pageRange={5}
                    />
                  }
                >
                  <TableHeader>
                    <tr>
                      <th className="text-center fw-700 text-nowrap">Nome</th>
                      <th className="text-center fw-700 text-nowrap">
                        CPF/CNPJ
                      </th>
                      <th className="text-center fw-700 text-nowrap">E-mail</th>
                      <th className="text-center fw-700 text-nowrap">
                        Custodiante
                      </th>
                      <th className="text-center fw-700 text-nowrap">
                        Situação Solicitação
                      </th>
                      <th className="text-center fw-700 text-nowrap">
                        Instrução de voto
                      </th>
                      <th className="text-center fw-700 text-nowrap">
                        Status Voto
                      </th>
                      <th className="text-center fw-700 text-nowrap">
                        Parte Relacionada
                      </th>
                      <th className="text-center"></th>
                    </tr>
                  </TableHeader>

                  <TableContent>
                    {props.participantesConfirmardosList &&
                    props.participantesConfirmardosList.hasItems ? (
                      props.participantesConfirmardosList.items.map(
                        (item, idx) => {
                          let key = "monitor_workflow_table_item__" + idx;
                          let solicitouIconColor = GREY_COLOR;
                          let statusVotoIconColor = GREY_COLOR;
                          let solicitouIconSubtitle =
                            "O investidor não solicitou participar da assembleia e não votou";
                          let statusVotoIconSubtitle = "O investidor não votou";

                          if (item.realizouVoto) {
                            solicitouIconColor = GREEN_COLOR;
                            solicitouIconSubtitle =
                              "O investidor informou voto";
                          } else if (item.solicitouParticipacao) {
                            solicitouIconColor = YELLOW_COLOR;
                            solicitouIconSubtitle =
                              "O investidor solicitou participar da assembleia";
                          }

                          if (item.possuiVotoAceito) {
                            statusVotoIconColor = GREEN_COLOR;
                            statusVotoIconSubtitle =
                              "Os votos do investidor foram aceitos";
                          } else if (item.realizouVoto) {
                            statusVotoIconColor = YELLOW_COLOR;
                            statusVotoIconSubtitle =
                              "Os votos do investidor não foram aceitos";
                          }

                          return (
                            <tr key={key}>
                              <td className="text-center fw-400 text-nowrap">
                                {item.nome}
                              </td>
                              <td className="text-center fw-400 text-nowrap">
                                {formatStrToCnpjOrCpf(item.documento)}
                              </td>
                              <td className="text-center fw-400 text-nowrap">
                                {item.email}
                              </td>
                              <td className="text-center fw-400 text-nowrap">
                                {item.custodiante}
                              </td>
                              <td className="text-center fw-400 text-nowrap">
                                {item.statusSolicitacao}
                              </td>
                              <td className="text-center fw-400 text-nowrap">
                                <IconWithSubtitle
                                  icon="check_circle"
                                  colorIcon={solicitouIconColor}
                                  subtitle={solicitouIconSubtitle}
                                />
                              </td>
                              <td className="text-center fw-400 text-nowrap">
                                <IconWithSubtitle
                                  icon="check_circle"
                                  colorIcon={statusVotoIconColor}
                                  subtitle={statusVotoIconSubtitle}
                                />
                              </td>
                              <td className="text-center fw-400 text-nowrap">
                                {getStringBooleanValue(item.parteRelacionada)}
                              </td>
                              <td className="text-center fw-400 text-nowrap">
                                <a
                                  className="pointer"
                                  onClick={() =>
                                    props.authorizationFunction(
                                      {
                                        idAcao: UPDATE,
                                        idFuncionalidade: ASSEMBLEIA,
                                      },
                                      () =>
                                        props.obterDadosGerenciarInvestidor(
                                          idAssembleia,
                                          item.id
                                        )
                                    )
                                  }
                                >
                                  <FontAwesomeIcon
                                    className="p-color-imp f-22"
                                    icon={faUserCog}
                                  />
                                </a>
                              </td>
                            </tr>
                          );
                        }
                      )
                    ) : (
                      <tr className="text-center pt-4 pb-2">
                        <td>Nenhum resultado encontrado </td>
                      </tr>
                    )}
                  </TableContent>
                </Table>
              </If>

              <AssembleiaGerenciarInvestidorModal
                statusAssembleia={props.statusAssembleia}
              />
            </div>
          </div>
        </div>
      </div>
    </MuiThemeProvider>
  );
};

AssembleiaGerenciarParticipanteConfirmadosList = reduxForm({
  form: "assembleiaParticipanteSearchForm",
  destroyOnUnmount: true,
})(AssembleiaGerenciarParticipanteConfirmadosList);
const selector = formValueSelector("assembleiaParticipanteSearchForm");
const mapStateToProps = (state) => ({
  tab: state.tab,
  termo: selector(state, "termo") || "",
  statusSolicitacao: selector(state, "statusSolicitacao"),
  participantesConfirmardosList:
    state.assembleiaReducer.assembleiaDadosGerenciar
      .participantesConfirmadosList || null,
  modalGerenciarInvestidor:
    state.assembleiaReducer.showGerenciarInvestidorDialog,
  statusSolicitacaoList:
    state.assembleiaReducer.assembleiaDadosGerenciar.statusSolicitacao,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      buscarInvestidoresPorAssembleiaTermo,
      obterDadosGerenciarInvestidor,
      downloadExcelBoletinVotacao,
      authorizationFunction,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssembleiaGerenciarParticipanteConfirmadosList);
