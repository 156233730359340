import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { editSaveAssembleia, loadFormAssembleia } from '../../../../actions/assembleia/AssembleiaAction';
import ContainerPage from "../../../common/container/ContainerPage";
import AssembleiaIncluirFormGeral from "./form/AssembleiaIncluirFormGeral";

class AssembleiaPageEditar extends Component {

    componentDidMount() {
        let idAssembleia = this.props.match.params.idAssembleia;
        this.props.loadFormAssembleia(idAssembleia, true);
    }

    render() {
        const {
            editSaveAssembleia
        } = this.props;

        return (
            <ContainerPage>
                <AssembleiaIncluirFormGeral
                    onSubmit={editSaveAssembleia}
                    readOnly={false}
                    floatButtonVisible={true}
                    nameForm={"Editar Assembléia"}
                />
            </ContainerPage>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    editSaveAssembleia,
    loadFormAssembleia
}, dispatch);

export default connect(null, mapDispatchToProps)(AssembleiaPageEditar);
