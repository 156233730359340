/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { formValueSelector, change } from "redux-form";

import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import "../../assets/AssembleiaForm.css";

import {
  uploadReadListaInvestidores,
  donwloadTemplateAnexoInvestidores,
} from "../../../../../../actions/assembleia/AssembleiaAction";

import AssembleiaUploadArquivo from "../arquivo/AssembleiaUploadArquivo";
import PaginationRB from "../../../../../common/pagination/PaginationRB";
import "../../../../../common/expansion/Expansion.css";
import { ACCEPT_FILE_OPTIONS } from "../../../../../../utils/helpers/FileHelper";
import { getStringBooleanValue } from "../../../../../../utils/helpers/BooleanHelper";
import If from "../../../../../common/If/If";
import Table from "../../../../../common/table/Table";
import TableContent from "../../../../../common/table/TableContent";
import TableHeader from "../../../../../common/table/TableHeader";

const STATUS_ASSEMBLEIA_CONVOCADA = 2;
const STATUS_ASSEMBLEIA_ENCERRADA = 3;

const styles = (theme) => ({
  root: {
    display: "block",
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  table: {
    width: "100%",
    display: "table",
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
    borderSpacing: 0,
    borderCollapse: "collapse",
  },
  tableCell: {
    fontSize: "0.95em",
    fontWeight: 300,
  },
  icon: {
    fontSize: "1.3em",
    cursor: "pointer",
  },
  tablePagination: {
    fontSize: "12pt",
  },
});

class AssembleiaSecaoAnexoInvestidores extends Component {
  state = {
    page: 0,
    rowsPerPage: 5,
    expanded: false,
  };

  handleChangePage = (event, page) => {
    this.setState({ page: event - 1 });
  };
  changeExpandedState() {
    const currentState = this.props.listaInvestidorExpanded;
    this.props.change(
      "assembleiaIncluirForm",
      "listaInvestidorExpanded",
      !currentState
    );
  }

  callbackFunctionComponent() {
    if (
      this.props.conteudoAnexoInvestidoresList &&
      this.props.conteudoAnexoInvestidoresList.length > 0
    ) {
      this.props.uploadReadListaInvestidores(
        this.props.conteudoAnexoInvestidoresList
      );
    } else if (
      this.props.conteudoAnexoInvestidoresList &&
      this.props.conteudoAnexoInvestidoresList.length == 0
    ) {
      this.props.change(
        "assembleiaIncluirForm",
        "investidoresAssembleiaDtoList",
        []
      );
    }
  }
  getEmailsSplitWordbreak(emails) {
    return <span>{emails}</span>;
  }

  render() {
    const { page, rowsPerPage } = this.state;

    const {
      conteudoAnexoInvestidoresList,
      listaInvestidores,
      readOnly,
    } = this.props;

    /**
     * Permite alterar todas as informações quando STATUS_ASSEMBLEIA_PENDENTE
     * Permite alterar apenas link e Quorum de instalação quando STATUS_ASSEMBLEIA_CONVOCADA
     * Não permite alterar nenhuma informação quando STATUS_ASSEMBLEIA_ENCERRADA
     */
    const statusAssembleiaEscolhida =
      this.props.statusAssembleia &&
      (this.props.statusAssembleia.key === STATUS_ASSEMBLEIA_ENCERRADA ||
        this.props.statusAssembleia.key === STATUS_ASSEMBLEIA_CONVOCADA);

    let painelOrdensAssembleia = (
      <Table
        withoutHeader
        classes="shadow-none border-0 col-12 mt-3 mb-0 pd-10 bg-white"
        totalItems={listaInvestidores.length}
        pagination={
          <PaginationRB
            pageNumber={page}
            itemsPerPage={rowsPerPage}
            totalItems={listaInvestidores.length}
            fetchFunction={(event) => this.handleChangePage(event)}
            pageRange={5}
          />
        }
      >
        <TableHeader>
          <tr>
            <th className="text-center fw-700 text-nowrap">Nome</th>
            <th className="text-center fw-700 text-nowrap">CPF/CNPJ</th>
            <th className="text-center fw-700 text-nowrap">E-mail</th>
            <th className="text-center fw-700 text-nowrap">Custodiante</th>
            <th className="text-center fw-700 text-nowrap">CNPJ Custodiante</th>
            <th className="text-center fw-700 text-nowrap">Quantidade</th>
            <th className="text-center fw-700 text-nowrap">Parte Relacionada</th>
          </tr>
        </TableHeader>
        {listaInvestidores.length > 0 ? (
          listaInvestidores
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, index) => {
              return (
                <TableContent>
                  <tr key={index}>
                    <td className="text-center fw-400 text-nowrap">{item.nome}</td>
                    <td className="text-center fw-400 text-nowrap">{item.documento}</td>
                    <td className="text-center fw-400 text-nowrap">{item.email}</td>
                    <td className="text-center fw-400 text-nowrap">{item.custodiante}</td>
                    <td className="text-center fw-400 text-nowrap">{item.cnpjCustodiante}</td>
                    <td className="text-center fw-400 text-nowrap">{item.quantidadePapeis}</td>
                    <td className="text-center fw-400 text-nowrap">{getStringBooleanValue(item.parteRelacionada)}</td>
                  </tr>
                </TableContent>
              );
            })
        ) : (
          <tr>
            <td>Não há investidores</td>
          </tr>
        )}
      </Table>
    );

    let headerListaInvestidor = (
      <div className="column anexar-investidores-assembleia_header">
        <AssembleiaUploadArquivo
          justView={statusAssembleiaEscolhida || readOnly}
          formSelectorstate={"assembleiaIncluirForm"}
          conteudoStateAnexo={"listaAnexosInvestidores"}
          conteudoAnexoList={conteudoAnexoInvestidoresList}
          hasCallBack={true}
          callbackFunctionComponent={this.callbackFunctionComponent.bind(this)}
          isDownloaded={false}
          onlyOneFile={true}
          hasDropArea={false}
          accept={ACCEPT_FILE_OPTIONS.EXCEL}
        />
        <Button
          variant="contained"
          className="p-bg-imp text-white mt-3"
          onClick={this.props.donwloadTemplateAnexoInvestidores}
        >
          <div className="d-flex align-items-center">
            <span>Baixar modelo</span>
            <i
              className="fa fa-download card-spreadsheet-model_button-ic"
              aria-hidden="true"
            ></i>
          </div>
        </Button>
      </div>
    );

    return (
      <div>
        <div>
          {headerListaInvestidor}
          <If test={listaInvestidores.length > 0}>
            {painelOrdensAssembleia}
          </If>
        </div>
      </div>
    );
  }
}

const selector = formValueSelector("assembleiaIncluirForm");

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      change,
      uploadReadListaInvestidores,
      donwloadTemplateAnexoInvestidores,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  listaInvestidorExpanded: selector(state, "listaInvestidorExpanded") || false,
  conteudoAnexoInvestidoresList:
    selector(state, "listaAnexosInvestidores") || [],
  listaInvestidores: selector(state, "investidoresAssembleiaDtoList") || [],
});

AssembleiaSecaoAnexoInvestidores = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssembleiaSecaoAnexoInvestidores);

export default withStyles(styles)(AssembleiaSecaoAnexoInvestidores);
