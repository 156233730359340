import {
    LOADING_CHANGE
} from '../../actions/infra/LoadingScreenAction';

const INITIAL_STATE = {
    isLoading: false
}

export default function loadingScreen(state = INITIAL_STATE, action = {}) {
    switch(action.type) {
        case LOADING_CHANGE:
            return {
                isLoading: action.payload
            }
        default:
            return state;
    }
}