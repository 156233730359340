import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, change } from "redux-form";
import { bindActionCreators } from "redux";
import TextField from "@material-ui/core/TextField";

import DropdownListInput from "../../../../../common/input/DropdownListInput";
import If from "./../../../../../common/If/If";
import Input from "../../../../../common/input/Input";
import MaskedInput from "../../../../../common/input/MaskedInput";
import MaskedFormatInput from "../../../../../common/input/MaskedFormatInput";
import GenericPickerInput from "../../../../../common/input/GenericPickerInput";

import { getArrayUF } from "../../../../../../utils/helpers/ArrayHelper";
import {
  HORA,
  CEP,
  formatHora,
  formatCEP,
} from "../../../../../../utils/helpers/StringHelper";
import LinkInput from "../../../../../common/input/LinkInput";

import { getOperacoes } from "../../../../../../actions/operacao/OperacaoAction";
import {
  selectOperacaoNoForm,
  loadFormGeralAssembleia,
  ASSEMBLEIA_OPERACAO_OPTIONS_FETCHED,
  TIPO_ASSEMBLEIA_PRESENCIAL,
  TIPO_ASSEMBLEIA_REMOTO,
  TIPO_ASSEMBLEIA_AMBOS,
  STATUS_ASSEMBLEIA_CONVOCADA,
  STATUS_ASSEMBLEIA_ENCERRADA,
} from "../../../../../../actions/assembleia/AssembleiaAction";

class SecaoIncluirAssembleiaForm extends Component {
  componentDidMount() {
    this.props.loadFormGeralAssembleia();
  }

  render() {
    const {
      tipoLocalizacao,
      tipoLocalizacaoAssembleiaList,
      listUF,
      readOnly,
    } = this.props;

    /**
     * Permite alterar todas as informações quando STATUS_ASSEMBLEIA_PENDENTE
     * Permite alterar apenas link e Quorum de instalação quando STATUS_ASSEMBLEIA_CONVOCADA
     * Não permite alterar nenhuma informação quando STATUS_ASSEMBLEIA_ENCERRADA
     */
    const statusAssembleiaEscolhida =
      this.props.statusAssembleia &&
      (this.props.statusAssembleia.key === STATUS_ASSEMBLEIA_ENCERRADA ||
        this.props.statusAssembleia.key === STATUS_ASSEMBLEIA_CONVOCADA);

    let assembleiaGeral = (
      <div className="shadow-custom rounded-12 bg-white px-3 py-2 mt-4">
        {/* FILTROS */}
        <div className="row px-0">
          <div className="col-12 col-lg-6 mb-2">
            <If test={!statusAssembleiaEscolhida && !readOnly}>
              <label className="f-12 text-dark mb-1">Busca</label>
              <GenericPickerInput
                id={"idOperacao"}
                loadData={this.props.getOperacoes}
                options={this.props.options}
                formName={"assembleiaIncluirForm"}
                fieldName={"operacao"}
                placeholder={"Pesquisar operação ex.:CRI.1.SEC.2"}
                loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                noOptionsMessage="Nenhum registro encontrado"
                value={this.props.operacao}
                selectedOptionForm={this.props.selectOperacaoNoForm}
                reducerName={ASSEMBLEIA_OPERACAO_OPTIONS_FETCHED}
                filter="contains"
              />
            </If>
            <If test={statusAssembleiaEscolhida || readOnly}>
              <TextField
                className="w-100 mt-2"
                value={
                  this.props.operacao == null ? "" : this.props.operacao.value
                }
                disabled={true}
              />
            </If>
          </div>
        </div>
        <div className="row px-0">
          <div className="col-lg-2">
            <Field
              component={Input}
              className={"filter-input"}
              inputId="dataAssembleia"
              name={`dataAssembleia`}
              type={"date"}
              label="Data"
              readOnly={statusAssembleiaEscolhida || readOnly}
            />
          </div>
          <div className="col-lg-2">
            <Field
              name={"hora"}
              maxLength="5"
              inputId="hora"
              label="Horário"
              formatMask={formatHora}
              className={"filter-input"}
              component={MaskedFormatInput}
              placeholder={HORA.placeholder}
              readOnly={statusAssembleiaEscolhida || readOnly}
            />
          </div>
          <div className="col-lg-2">
            <Field
              component={MaskedInput}
              inputId="convocacao"
              name={"convocacao"}
              label="Convocação"
              maxLength={10}
              decimalScale={0}
              className={"filter-input"}
              allowNegative={false}
              readOnly={statusAssembleiaEscolhida || readOnly}
            />
          </div>
          <div className="col-lg-2">
            <Field
              component={MaskedInput}
              inputId="quorumInstalacao"
              name={"quorumInstalacao"}
              label="Quórum Instalação"
              maxLength={10}
              className={"filter-input"}
              allowNegative={false}
              decimalScale={0}
              readOnly={
                (this.props.statusAssembleia &&
                  this.props.statusAssembleia.key ===
                    STATUS_ASSEMBLEIA_ENCERRADA) ||
                readOnly
              }
            />
          </div>
        </div>

        {/* TIPO */}
        <div className="row px-0">
          <div className="col-2-resumo">
            <Field
              component={DropdownListInput}
              inputId="tipoLocalizacao"
              name="tipoLocalizacao"
              label="Tipo Localização"
              options={tipoLocalizacaoAssembleiaList}
              textField="value"
              valueField="key"
              filter="contains"
              readOnly={statusAssembleiaEscolhida || readOnly}
            />
          </div>
          <If
            test={
              tipoLocalizacao &&
              (tipoLocalizacao.key === TIPO_ASSEMBLEIA_REMOTO ||
                tipoLocalizacao.key === TIPO_ASSEMBLEIA_AMBOS)
            }
          >
            <div className="col-lg-6 ml-2">
              <Field
                copyLink
                component={LinkInput}
                inputId="linkAssembleia"
                name={"linkAssembleia"}
                label={"Link acesso conferência"}
                className={"filter-input p-color-imp"}
                maxLength={255}
                readOnly={
                  (this.props.statusAssembleia &&
                    this.props.statusAssembleia.key ===
                      STATUS_ASSEMBLEIA_ENCERRADA) ||
                  readOnly
                }
              />
            </div>
          </If>
        </div>
        
        {/* ENDEREÇO */}
        <If
          test={
            tipoLocalizacao &&
            (tipoLocalizacao.key === TIPO_ASSEMBLEIA_PRESENCIAL ||
              tipoLocalizacao.key === TIPO_ASSEMBLEIA_AMBOS)
          }
        >
          <div className="column px-0 mt-2">
            <p className="page-head__title f-22 my-2">Endereço</p>
            <div className="column">
              <div className="row px-0">
                <div className="col-lg-5">
                  <Field
                    component={Input}
                    inputId="logradouro"
                    name={"endereco.logradouro"}
                    label="Logradouro"
                    maxLength={120}
                    className={"filter-input"}
                    type="text"
                    readOnly={statusAssembleiaEscolhida || readOnly}
                  />
                </div>
                <div className="col-lg-1">
                  <Field
                    component={MaskedInput}
                    inputId="numero"
                    name={"endereco.numero"}
                    label="Número"
                    maxLength={10}
                    className={"filter-input"}
                    allowNegative={false}
                    decimalScale={0}
                    readOnly={statusAssembleiaEscolhida || readOnly}
                  />
                </div>
                <div className="col-lg-4">
                  <Field
                    component={Input}
                    inputId="complemento"
                    name={"endereco.complemento"}
                    label="Complemento"
                    maxLength={30}
                    className={"filter-input"}
                    readOnly={statusAssembleiaEscolhida || readOnly}
                  />
                </div>
                <div className="col-lg-2">
                  <Field
                    label="CEP"
                    inputId="cep"
                    maxLength="9"
                    name={"endereco.cep"}
                    formatMask={formatCEP}
                    className={"filter-input"}
                    component={MaskedFormatInput}
                    placeholder={CEP.placeholder}
                    readOnly={statusAssembleiaEscolhida || readOnly}
                  />
                </div>
              </div>

              <div className="row px-0">
                <div className="col-lg-4">
                  <Field
                    component={Input}
                    inputId="bairro"
                    name={"endereco.bairro"}
                    label="Bairro"
                    maxLength={30}
                    className={"filter-input"}
                    readOnly={statusAssembleiaEscolhida || readOnly}
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    component={Input}
                    inputId="cidade"
                    name={"endereco.cidade"}
                    label="Cidade"
                    maxLength={30}
                    className={"filter-input"}
                    readOnly={statusAssembleiaEscolhida || readOnly}
                  />
                </div>
                <div className="col-lg-2">
                  <Field
                    component={DropdownListInput}
                    inputId="estado"
                    name="endereco.estado"
                    label="Estado"
                    options={listUF}
                    filter="contains"
                    textField="value"
                    valueField="key"
                    readOnly={statusAssembleiaEscolhida || readOnly}
                  />
                </div>
              </div>
            </div>
          </div>
        </If>
      </div>
    );

    return <div>{assembleiaGeral}</div>;
  }
}

const selector = formValueSelector("assembleiaIncluirForm");

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOperacoes,
      selectOperacaoNoForm,
      loadFormGeralAssembleia,
      change,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  operacao: selector(state, "operacao"),
  options: state.operacaoReducer.operacoes,
  operacaoSelected: selector(state, "operacao"),
  dataAssembleia: selector(state, "dataAssembleia"),
  tipoLocalizacao: selector(state, "tipoLocalizacao"),
  listUF: getArrayUF(),
  tipoLocalizacaoAssembleiaList:
    state.assembleiaReducer.assembleiaFormTypes.tipoLocalizacaoAssembleiaList,
  tipoQuorumList: state.assembleiaReducer.assembleiaFormTypes.tipoQuorumList,
  porcentagemQuorumList:
    state.assembleiaReducer.assembleiaFormTypes.porcentagemQuorumList,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecaoIncluirAssembleiaForm);
