import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Chip } from "@material-ui/core";

let AssembleiaGerenciarHeader = (props) => {
    let badgeInstalada = 'chip-bg-red';
    let textInstalada = 'Não Instalada';

    if (props.assembleiaInstalada || props.assembleiaInstalada_) {
        badgeInstalada = 'chip-bg-green';
        textInstalada = 'Instalada';
    }

    return (
        <div className="monitor-workflow-head w-100 pl-2 pb-3 logbook-head px-0 bg-transparent">
            <div className="row align-items-center px-0">
                <div className="col-10 pl-3 logbook-head__title f-36 p-color pr-2">
                    {props.title}
                </div>
                <div className="d-flex justify-content-center col-2 mt-2">
                    {props.showBadgeInstalada && <Chip className={badgeInstalada} color="secondary" label={textInstalada} />}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    assembleiaInstalada_: state.assembleiaReducer.assembleiaDadosGerenciar.assembleiaInstalada
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AssembleiaGerenciarHeader);
