import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { editAtivo, loadFormAtivo } from '../../../../../actions/ativo/AtivoAction';
import AtivoForm from './AtivoForm';

class AtivoFormUpdate extends Component {
    componentDidMount() {
        let idAtivo = this.props.match.params.idAtivo;
        this.props.loadFormAtivo(idAtivo, true);
    }

    render() {
        const { readOnly, editAtivo } = this.props;
        
        return (
            <AtivoForm 
                title={"Editar - Ativo"} 
                onSubmit={editAtivo} 
                readOnly={readOnly}
                floatButtonVisible={true} />
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    editAtivo,
    loadFormAtivo
}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(AtivoFormUpdate));