/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkOffIcon from '@material-ui/icons/LinkOff';

import {
    removeCodigoB3Cetip,
    disassociateCodigoB3Cetip,
} from '../../../../../../actions/ativo/AtivoAction';

import If from './../../../../../common/If/If';
import { getDateFormatedFromString } from '../../../../../../utils/helpers/DateTimeHelper';
import Table from '../../../../../common/table/Table';
import TableHeader from '../../../../../common/table/TableHeader';
import TableContent from '../../../../../common/table/TableContent';
import { getLabelDisplayedRows } from "../../../../../../utils/helpers/OthersHelpers"

const styles = theme => ({
    root: {
        display: 'block',
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 1,
        overflowX: 'auto',
    },
    tablePagination: {
        fontSize: '12pt',
    }
});

class AtivoCodigoB3CetipList extends Component {
    state = {
        page: 0,
        rowsPerPage: 5,
    };

    handleChangePage = (event, page) => {
        this.setState({ page: page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    render() {
        const {
            page, 
            rowsPerPage,
        } = this.state;

        const { 
            classes,
            dataList,
            readOnly,
            removeCodigoB3Cetip,
            disassociateCodigoB3Cetip,
        } = this.props;

        return(
            <div className={`table-responsive ${classes.root}`}>
                <Table
                    withoutHeader
                    classes="border-0 shadow-none col-12 my-0 pd-10 bg-white">
                    <TableHeader>
                        <tr>
                            <th className='text-center fw-700 text-nowrap'>Código do título na B3/CETIP</th>
                            <th className='text-center fw-700 text-nowrap'>Percentual B3/CETIP</th>
                            <th className='text-center fw-700 text-nowrap'>Valor Emissão Atual</th>
                            <th className='text-center fw-700 text-nowrap'>Tipo Código</th>
                            <th className='text-center fw-700 text-nowrap'>Data de Emissão</th>
                            <th className='text-center fw-700 text-nowrap'>Data de Vencimento</th>
                            <th className='text-center fw-700 text-nowrap'>Operações</th>
                            <If test={!readOnly}>
                                <th></th>
                            </If>
                        </tr>
                    </TableHeader>
                    <TableContent>
                        {
                            dataList.length > 0 
                            ? (
                                dataList
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className='text-center fw-400'>{item.codigo}</td>
                                            <td className='text-center fw-400'>{item.percentual}</td>
                                            <td className='text-center fw-400'>{item.valorEmissaoAtual}</td>
                                            <td className='text-center fw-400'>{item.tipoCodigo ? item.tipoCodigo.value : 'Não informado'}</td>
                                            <td className='text-center fw-400'>{getDateFormatedFromString(item.dataEmissao)}</td>
                                            <td className='text-center fw-400'>{getDateFormatedFromString(item.dataVencimento)}</td>
                                            <td className='text-center'>
                                                {
                                                    item.codigoOpeaAssociadoList ?? 'Não há Operações vinculadas.'
                                                }
                                            </td>
                                            {!readOnly && (
                                                <td className='text-center'>
                                                    <a className="view pointer mx-2 table-action-button" onClick={() => removeCodigoB3Cetip(index)}>
                                                        <DeleteIcon className='p-color-imp' />
                                                    </a>
                                                    {item.operacaoCodigoB3CetipList && item.operacaoCodigoB3CetipList.length > 0 && (
                                                        <a className="view pointer mx-2 table-action-button p-color-imp" onClick={() => disassociateCodigoB3Cetip(item)} >
                                                            <LinkOffIcon/>
                                                        </a>
                                                    )}
                                                </td>
                                            )}
                                        </tr>
                                    );
                                })
                            )
                            : (
                                <tr>
                                    <td 
                                        align="center" 
                                        colSpan={12}
                                    >
                                        Não há códigos
                                    </td>
                                </tr>
                            )
                        }
                        
                    </TableContent>
                </Table>
                <TablePagination
                    className={classes.tablePagination}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={dataList.length}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage="Linhas por página"
                    labelDisplayedRows={getLabelDisplayedRows}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Página anterior',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Próxima página',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
    removeCodigoB3Cetip,
    disassociateCodigoB3Cetip,
}, dispatch);

AtivoCodigoB3CetipList = connect(null, mapDispatchToProps)(AtivoCodigoB3CetipList);

export default withStyles(styles)(AtivoCodigoB3CetipList);
