import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field, formValueSelector } from "redux-form";

import {
  editOpcao,
  saveOpcao,
  showOpcaoList,
  alterarFormArquivo,
} from "../../../../../../../actions/precificacao/OpcaoAction";
import { showConfirmDialog } from "../../../../../../../actions/common/modal/ConfirmDialogAction";

import Form from "../../../../../../common/form/Form";
import FormHeader from "../../../../../../common/form/FormHeader";
import FormContent from "../../../../../../common/form/FormContent";
import ConfirmDialog from "../../../../../../common/modal/ConfirmDialog";
import Input from "../../../../../../common/input/Input";

import {
  convertFileToBase64,
  downloadFile,
} from "../../../../../../../utils/helpers/FileHelper";
import ReactTooltip from "react-tooltip";
import closeIcon from "../../../../../../../assets/img/closeIcon.svg";
import downloadIcon from "../../../../../../../assets/img/downloadIcon.svg";
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import Button from "../../../../../../common/button/Button";

let SeloForm = (props) => {
  const {
    title,
    showOpcaoList,
    floatButtonVisible,
    showConfirmDialog,
    showCancelConfirmDialog,
    readOnly,
    formValues,
    alterarFormArquivo,
    handleSubmit,
  } = props;

  const [submitted, setSubmitted] = React.useState(false)

  const inputFileRef = React.useRef(null);

  function validarArquivo(arquivo) {
    const extensoesPermitidas = ['.svg', '.png', '.jpg', '.jpeg'];
    const extensaoArquivo = arquivo.name.substring(arquivo.name.lastIndexOf('.')).toLowerCase();
  
    if (!extensoesPermitidas.includes(extensaoArquivo)) {
      toastr.error('Erro', 'Selecione o arquivo com uma das extensões permitidas: .svg, .png, .jpg, .jpeg');
      return false;
    }

    const tamanhoMaximoBytes = 500 * 1024; // 512 KB

    if (arquivo.size > tamanhoMaximoBytes) {
      toastr.error('Erro', 'O tamanho do arquivo não pode exceder 512KB.');
      return false;
    }
  
    return new Promise((resolve) => {
      const reader = new FileReader();
      
      reader.onload = function (e) {
        const img = new Image();

        img.onload = function () {
          if (img.width <= 64 && img.height <= 64) {
            resolve(true);
          } else {
            toastr.error('Erro', 'A imagem deve ter no máximo 64x64 pixels.');
            resolve(false);
          }
        };
  
        img.src = e.target.result;
      };
  
      reader.readAsDataURL(arquivo);
    });
  }

  async function mudaArquivo(arquivo) {
    if (!await validarArquivo(arquivo)) {
      document.getElementById("arquivoSeloOpcao").value = '';
    } else {
      let file = await convertFileToBase64(arquivo, arquivo.name);
      alterarFormArquivo(file, arquivo.name);
    }
  }

  return (
    <div className="w-100 px-3">
      <Form>
        <FormHeader title={title} offSet={20}></FormHeader>
        <FormContent
          handleSubmit={handleSubmit}
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
        >
          <div className="column">
            <div className="row">
              <div className="col-lg-5">
                <Field
                  component={Input}
                  inputId="sigla"
                  name="sigla"
                  label="Sigla"
                  readOnly={readOnly}
                  required={true}
                />
              </div>
              <div className="col-lg-7">
                <Field
                  component={Input}
                  inputId="descricao"
                  name="descricao"
                  label="Descrição"
                  readOnly={readOnly}
                  required={true}
                />
              </div>
              <div className="col-lg-5 mb-3">
                <div className="d-flex flex-column col-12 px-0">
                  <label
                    className="control-label txt-grey"
                    style={{ marginTop: 10, marginBottom: 4 }}
                  >
                    Imagem do Selo
                    <span className="text-warning font-weight-bold ml-1">
                      *
                    </span>
                    {!readOnly && (
                      <>
                        <i
                          className={`fa fa-info-circle icon-margin-l-5`}
                          aria-hidden="true"
                          style={{ color: "#630a3679", cursor: "default" }}
                          data-tip
                          data-for="file-input"
                        />
                        <ReactTooltip
                          effect="solid"
                          globalEventOff="click"
                          className="tooltip-custom px-2 py-1"
                          id="file-input"
                        >
                          <div className="p-1">
                            <div>● Extensões permitidas: <strong>.svg, .png, .jpg, .jpeg</strong></div>
                            <div className="my-1">● Dimensões máximas: <strong>64x64 pixels</strong></div>
                            <div>● Tamanho máximo: <strong>512KB</strong></div>
                          </div>
                        </ReactTooltip>
                      </>
                    )}
                  </label>
                  <div className="d-flex h-33 position-relative mb-3">
                    {!formValues?.nomeArquivo &&
                      !formValues?.arquivo &&
                      !readOnly && (
                        <div className="d-flex flex-column">
                          <button
                            type="button"
                            onClick={() => inputFileRef.current.click()}
                            className="btn btn-sm btn-outline-opea d-flex align-items-center justify-content-center h-38 shadow-none col-auto"
                          >
                            <p className="mb-0 mt-1px fw-500 f-14">
                              SELECIONAR ARQUIVO
                            </p>
                          </button>
                          {(!formValues?.arquivo && submitted) && (
                            <p class="error-msg sp-error text-danger f-12 mb-0">Preenchimento obrigatório</p>
                          )}
                        </div>
                      )}

                    {formValues?.nomeArquivo && formValues?.arquivo && (
                      <div className="col-12 px-0 d-flex flex-row align-items-center mt-2">
                        <div className="col-11 d-flex flex-row align-items-center justify-content-center w-100 input-file-opcao rounded h-42 p-1">
                          <div
                            title={formValues?.nomeArquivo}
                            className="flex-fill h-100 name-place rounded align-items-center pt-1 px-2 fw-400"
                          >
                            {formValues?.nomeArquivo}
                          </div>
                          {!readOnly && (
                            <img
                              alt=""
                              role="button"
                              src={closeIcon}
                              style={{ width: 25 }}
                              className="px-1 mr-0 ml-1 close-button"
                              onClick={() => alterarFormArquivo()}
                            />
                          )}
                        </div>

                        <div className="col-auto">
                          <img
                            alt=""
                            role="button"
                            src={downloadIcon}
                            style={{ width: 20, height: 20 }}
                            onClick={() =>
                              downloadFile(
                                formValues?.arquivo,
                                formValues?.nomeArquivo
                              )
                            }
                          />
                        </div>
                      </div>
                    )}

                    {!readOnly && (
                      <input
                        type="file"
                        ref={inputFileRef}
                        id="arquivoSeloOpcao"
                        accept=".svg, .png, .jpg, .jpeg"
                        className="position-absolute d-none"
                        onChange={(e) => mudaArquivo(e.target.files[0])}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-row justify-content-between align-items-center col-12 mt-1 mb-2 p-0">
              <Button
                name={`Cancelar ${floatButtonVisible ? "Alterações" : ""}`}
                classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                action={() => {
                  floatButtonVisible
                    ? showConfirmDialog(true)
                    : showOpcaoList("selo")
                }}
              />

              {floatButtonVisible && (
                <Button
                  type="submit"
                  name={"Salvar Cadastro"}
                  classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                  action={() => setSubmitted(true)}
                />
              )}
          </div>
        </FormContent>
        <ConfirmDialog
          title="Aviso"
          contentText="Os dados serão perdidos, deseja realmente sair?"
          textButtonCancel="CANCELAR"
          textButtonConfirm="CONFIRMAR"
          showDialog={showCancelConfirmDialog}
          onClose={() => showConfirmDialog(false)}
          onConfirm={() => showOpcaoList("selo")}
          type="warning"
          maxWidth="md"
        />
      </Form>
    </div>
  );
};

// VALIDAÇÃO
const validate = (values) => {
  const errors = {
    sigla: !values.sigla ? 'Preenchimento obrigatório' : null,
    descricao: !values.descricao ? 'Preenchimento obrigatório' : null,
  };

  return errors;
};

const selector = formValueSelector("OpcaoForm");

const mapStateToProps = (state) => ({
  showCancelConfirmDialog: state.confirmDialogReducer.showConfirmDialog,
  formValues: selector(state, "arquivo", "nomeArquivo"),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showOpcaoList,
      showConfirmDialog,
      editOpcao,
      saveOpcao,
      alterarFormArquivo,
    },
    dispatch
  );


const ConnectedSeloForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(SeloForm));

export default reduxForm({
  form: "OpcaoForm",
  validate,
})(ConnectedSeloForm);
