import React from 'react';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';

const ExpansionPanel = withStyles({
    root: {
        minHeight: "75px",
        border: "1px solid #e3e3e3",
        borderRadius: "4px",
        padding: "1.5rem",
        margin: "1.5rem 0",
        position: "relative",
        boxShadow: "inset 0 1px 1px rgba(0,0,0,.05)",
        '&:first-child':{
        marginTop:"1.4rem"
        },
        '&:before': {
        display: 'none',
        },
    },
    expanded: {
        /* margin: 'auto', */
    },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        fontSize: "1.2em !important",
        fontWeight: "bold !important",
        textAlign: "left !important",
        width: "calc(100% - 3rem)",
        padding: "10px 15px",
        marginBottom: "0",
        top: "-23px",
        backgroundColor: "#A57C7C",
        border: "1px solid #ccc",
        borderRadius: "3px",
        color: "white",
        position: "absolute",
        left: "1.5rem",
        minHeight: 46,
        '&$expanded': {
        minHeight: 46,
        },
    },
    content: {
        margin: '0',
        '&$expanded': {
        margin: '0',
        },
    },
    expandIcon: {
        color: "white"
    },
    expanded: {},
    focused: {
        backgroundColor: "#6e6259 !important"
    }
})(props => <MuiExpansionPanelSummary {...props} />);
ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

const ExpansionPanelDetails = withStyles(theme => ({
    root: {
        padding: 0,
        width: "100%"
    },
}))(MuiExpansionPanelDetails);

export default class Fieldset extends React.Component {
    render(){
        const props = this.props;
        let content;
        let fieldsetAltContent;
        let fieldsetAltLegend;
        if(props.expansion){
            content = (
                <ExpansionPanel defaultExpanded={props.defaultExpanded ? props.defaultExpanded : false} className={props.expansionClass}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        {props.legend}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        {props.children}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            )
        } else {
            fieldsetAltLegend = props.legend ? <legend className='scheduler-border'>{props.legend}</legend> : null;
            if(props.dataIsEmpty){
                fieldsetAltContent = (
                    <div colSpan='999' className='text-center'>
                        <span>
                            {props.message}
                        </span>
                    </div>
                ) 
            } else {
                fieldsetAltContent = (
                    props.children
                )
            }
            content = (
                fieldsetAltContent
            )
        }
        return (
            <fieldset className={`${!props.expansion ? "well p-4 mb-4": ""} ${props.hasMarginTop ? "my-4" : ""}`}>
                {fieldsetAltLegend}
                {content}
            </fieldset>
        )
    }
}
