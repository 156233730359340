import React from 'react';
import If from '../If/If';

const Table = ({
    col,
    withoutHeader,
    color,
    headerTitle,
    classes,
    pagination,
    totalItems,
    fileExcel,
    fileReport,
    fileLabel,
    fileReportLabel,
    classesOuterTable,
    styleOuterTable,
    headerCustom,
    ...props
}) => (
    <div className={`${props.classesOuter}`}>
        <div className={`card ${classes}`}>
            {headerCustom && (headerCustom)}
            <If test={!withoutHeader}>
                <div className="card-header" data-background-color={`${color || 'lightwine'}`}>
                    <h4 className="m-0">{headerTitle}</h4>
                </div>
            </If>
            <div className={`card-content table-responsive p-0 ${classesOuterTable}`} style={{styleOuterTable}}>
                <table className="table table-hover">
                    {props.children}
                </table>
            </div>

            <div className='d-flex flex-row justify-content-between align-items-end'>
                <div className='d-flex flex-xl-row flex-column align-items-xl-center'>
                    {pagination}

                    <div className='d-flex flex-xl-row flex-column align-items-xl-center mt-1'>
                        {fileExcel && (
                            <label className='ml-xl-3 ml-0 pt-xl-2 pt-0 p-color-lighten mb-0' role='button' onClick={fileExcel}>{fileLabel || 'Exportar para Excel'}</label>
                        )}
                        {(fileExcel && fileReport) && (
                            <div className='table-files-divider ml-xl-3 pt-xl-2 mt-1 d-xl-block d-none'></div>
                        )}
                        {fileReport && (
                            <label className='ml-xl-3 ml-0 pt-xl-2 pt-0 p-color-lighten mb-0' role='button' onClick={fileReport}>{fileReportLabel || 'Exportar Relatório Financeiro'}</label>
                        )}
                    </div>
                </div>
                <div className='d-flex flex-row align-items-center'>
                    <If test={totalItems}>
                        <label className='ml-2 text-gray mt-xl-3 mb-xl-2 mb-0'>Registros encontrados: {totalItems}</label>
                    </If>
                </div>
            </div>
        </div>
    </div>
        
);

export default Table;