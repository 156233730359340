/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { arrayRemove, arrayPush, formValueSelector } from "redux-form";
import Button from "@material-ui/core/Button";
import If from "../../../../../common/If/If";
import { showErrorMessages } from "../../../../../../actions/message/ErrorMessagesAPIAction";
import {
  convertFileToBase64,
  downloadExcelFile,
} from "../../../../../../utils/helpers/FileHelper";
import CardAttachment from "../../../../../common/File/CardAttachment";
import { donwloadEditalConvocacao } from "../../../../../../actions/assembleia/AssembleiaAction";
import "../../../../../common/File/assets/CardAttachment.css";
class AssembleiaUploadArquivo extends Component {
  state = {
    listaAnexo: [],
    nomeForm: this.props.formSelectorstate,
    secaoForm: this.props.conteudoStateAnexo,
  };
  verifyFileExtension(fileName) {
    return (
      String(fileName).indexOf(".xls") > 0 ||
      String(fileName).indexOf(".xlsx") > 0
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.handlefetchData(this.props.conteudoAnexoList);
    }
  }

  handlefetchData = (lista) => {
    this.setState({ listaAnexo: lista });
  };

  callBack() {
    if (this.props.hasCallBack) {
      this.props.callbackFunctionComponent();
    }
  }

  async selecionarArquivoUpload(nomeForm, nomeListaAnexo, diarioDeBordoFile) {
    const { listaAnexo } = this.state;
    try {
      let file = {
        name: diarioDeBordoFile.name,
        arquivo: await convertFileToBase64(diarioDeBordoFile),
        all: diarioDeBordoFile,
      };
      this.props.arrayPush(nomeForm, nomeListaAnexo, file);

      // Se o controle de Apenas 1 arquivo estiver habilitado para o componente, o anterior será removido.
      if (listaAnexo && listaAnexo.length > 0 && this.props.onlyOneFile) {
        this.removeArquivo(0);
      } else {
        this.callBack();
      }
    } catch (e) {
      showErrorMessages("Erro ao importar o arquivo");
    }
  }

  removeArquivo = (index) => {
    const { listaAnexo } = this.state;
    let justView = this.props.justView && this.props.justView == true;

    if (
      !justView &&
      !this.props.readOnly &&
      listaAnexo &&
      listaAnexo.length > 0
    ) {
      this.props.arrayRemove(
        this.props.formSelectorstate,
        this.props.conteudoStateAnexo,
        index
      );

      this.setState(listaAnexo.splice(index, 1));

      this.callBack();
    }
  };

  downloadFile = (index) => {
    const { listaAnexo } = this.state;
    let file = {
      name: listaAnexo[index].name,
      arquivo: listaAnexo[index].arquivo,
    };

    if (this.props.isDownloaded && listaAnexo && listaAnexo.length > 0) {
      if (this.props.idAssembleia && this.props.idAssembleia > 0) {
        this.props.donwloadEditalConvocacao(this.props.idAssembleia, file.name);
      } else {
        downloadExcelFile(file.arquivo, file.name);
      }
    }
  };

  handleUploadFile() {
    let element = document.createElement("input");
    element.type = "file";
    element.multiple = true;
    element.accept = this.props.accept;
    element.onchange = (e) => {
      let files = element.files;
      if (files == null) return;
      else if (files.length == 0) return;

      for (let i = 0; i < files.length; i++)
        this.selecionarArquivoUpload(
          this.props.formSelectorstate,
          this.props.conteudoStateAnexo,
          files[i]
        );
    };
    element.click();
  }

  renderAttachments() {
    let data = this.state.listaAnexo ? this.state.listaAnexo : [];

    return (
      <If test={data.length > 0}>
        <div className="d-flex flex-wrap shadow-custom px-3 pt-3 rounded-12 mt-3">
          {data.map((x, idx) => (
            <CardAttachment
              key={idx}
              optionalClass="mr-3 mb-3"
              isRemoveable={!this.props.justView}
              isDownloaded={this.props.isDownloaded}
              onClick={() => this.removeArquivo(idx)}
              onDownload={() =>
                this.props.isDownloaded ? this.downloadFile(idx) : null
              }
              label={x.name}
            />
          ))}
        </div>
      </If>
    );
  }

  render() {
    const { justView, hasDropArea } = this.props;

    let dropAreaCheck =
      hasDropArea == true ? (
        <div className="col-4 d-flex align-items-center  attachment-upload-field">
          <section>
            <div className="mb-2">Solte Arquivos aqui para enviar ou</div>
            <div>
              <Button
                variant="outlined"
                onClick={() => this.handleUploadFile()}
                className="brd-p-color-imp p-color-imp bg-white mr-3"
              >
                Selecionar Arquivo
              </Button>
            </div>
          </section>
        </div>
      ) : (
        <Button
          variant="outlined"
          onClick={() => this.handleUploadFile()}
          className="brd-p-color-imp p-color-imp bg-white mr-3"
        >
          <div className="d-flex ">Selecionar Arquivo</div>
        </Button>
      );

    return (
      <section>
        <div className="column w-100 mx-0 align-items-center">
          <If test={!justView}>
            <div>{dropAreaCheck}</div>
          </If>
          <div>{this.renderAttachments()}</div>
        </div>
      </section>
    );
  }
}
const selector = formValueSelector("assembleiaIncluirForm");

const mapStateToProps = (state) => ({
  idAssembleia: selector(state, "id"),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      arrayRemove,
      arrayPush,
      donwloadEditalConvocacao,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssembleiaUploadArquivo);
