import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Table from '../../../../../common/table/Table';
import TableHeader from '../../../../../common/table/TableHeader';
import TableContent from '../../../../../common/table/TableContent';
import TableEmptyData from '../../../../../common/table/TableEmptyData';
import PaginationRB from '../../../../../common/pagination/PaginationRB';
import FloatButton from '../../../../../common/button/FloatButton';
import FloatButtonGroup from '../../../../../common/button/FloatButtonGroup';
import If from '../../../../../common/If/If';

import { formatPercent } from '../../../../../../utils/helpers/NumberHelper';

import {
    getAtivosOperacao,
    showAtivoFormVinculoOperacao,
    loadFormAtivo,
    exportPlanilhaAtivosVinculadosAOperacao,
} from '../../../../../../actions/ativo/AtivoAction';

import TableActionWithRouteAuthorization from '../../../../../common/table/TableActionWithRouteAuthorization';
import { authorizationFunction } from '../../../../../../actions/authorization/AuthorizationAction';
import { OPERACAO_ATIVO, CREATE } from '../../../../../../utils/actionsAndFeaturesAuth';

class AtivoList extends Component {
    getIdsOperacao = () => {
        let idsOperacao = 0;

        if (Array.isArray(this.props.operacoes) && this.props.operacoes.length) {
            idsOperacao = this.props.operacoes.map(o => o.key);    
        }

        return idsOperacao;
    }

    updateData = () => {
        this.props.getAtivosOperacao(this.getIdsOperacao());
    }
    
    // setIdsOperacaoFromRouteParams = () => {
    //     if (this.props.match.params.idsOperacao) {
    //         const idsOperacaoFromRouteParams = this.props.match.params.idsOperacao;
            
    //         if(this.getIdsOperacao()){
    //             this.updateData();
    //         } else {
    //             this.props.getAtivosOperacao(idsOperacaoFromRouteParams);
    //         }
    //     }
    // }

    // setRouteParamsFromIdsOperacao = () => {
    //     console.log("ATIVO",this.getIdsOperacao());
    //     history.push(this.props.match.path.replace(':idsOperacao?', `${this.getIdsOperacao() || ''}`));
    // }


    componentDidMount() {
        this.updateData();
        // this.setIdsOperacaoFromRouteParams();
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.operacoes.length !== this.props.operacoes.length) {
            this.updateData();
            // this.setRouteParamsFromIdsOperacao();
        }
    }

    render() {
        const {
            operacoes,
            ativoOperacaoData,
            messages,
            getAtivosOperacao,
            showAtivoFormVinculoOperacao,
            loadFormAtivo,
            authorizationFunction,
            exportPlanilhaAtivosVinculadosAOperacao,
        } = this.props;

        const idsOperacoes = operacoes.map(o => o.key);
        const emptyData = messages && messages.infos && messages.infos[0];

        const tableHeader = (
            <tr>
                <th className="text-center fw-700 text-nowrap">Vínculo</th>
                <th className="text-center fw-700 text-nowrap">Cód. Ativo</th>
                <th className="text-center fw-700 text-nowrap">Contrato</th>
                <th className="text-center fw-700 text-nowrap">Devedor</th>
                <th className="text-center fw-700 text-nowrap">Qtd. Vinculados</th>
                <th className="text-center fw-700 text-nowrap">Total % Vinculados</th>
                <th className="text-center"></th>
            </tr>
        );

        const tableContent = (
            ativoOperacaoData && ativoOperacaoData.hasItems
                ? ativoOperacaoData.items.map(ativo =>
                    (<tr key={ativo.idAtivo}>
                        <td className='text-center fw-400 text-nowrap'>{ativo.codigoOpea}</td>
                        <td className='text-center fw-400 text-nowrap'>{ativo.codigoAtivo}</td>
                        <td className='text-center fw-400 text-nowrap'>{ativo.descricaoContrato}</td>
                        <td className='text-center fw-400'>{ativo.descricaoDevedor}</td>
                        <td className='text-center fw-400 text-nowrap'>{ativo.quantidadeCodigoB3CetipVinculado}</td>
                        <td className='text-center fw-400 text-nowrap'>{formatPercent(ativo.totalPercentualB3Cetip)}</td>
                        <TableActionWithRouteAuthorization
                            updateAction={() => loadFormAtivo(ativo.idAtivo, true)}
                            viewAction={() => loadFormAtivo(ativo.idAtivo, false)}
                            feature={OPERACAO_ATIVO}
                        />
                    </tr>)
                )
                : <TableEmptyData message={emptyData} />
        );

        return (
            <div>
                <If test={ativoOperacaoData.totalCount > 0}>
                <div className="d-flex flex-column">
                        <div className="mx-2 shadow-custom bg-white rounded-12">
                            <p className="page-head__title f-28 mb-0 px-3 mt-4 mb-3">Ativos das Operações</p>
                            <Table
                                withoutHeader
                                classes="card col-12 shadow-none bg-white py-0 m-0 mb-2 rounded-12"
                                totalItems={ativoOperacaoData.totalCount}
                                pagination={
                                    <PaginationRB
                                        pageNumber={ativoOperacaoData.pageIndex - 1}
                                        itemsPerPage={ativoOperacaoData.pageSize}
                                        totalItems={ativoOperacaoData.totalCount}
                                        filter={idsOperacoes}
                                        fetchFunction={getAtivosOperacao}
                                        pageRange={5}
                                    />
                                }
                            >
                                <TableHeader >
                                    {tableHeader}
                                </TableHeader>
                                <TableContent>
                                    {tableContent}
                                </TableContent>
                            </Table>
                        </div>
                </div>

                </If>
                    <FloatButtonGroup>               
                        <FloatButton
                            activeClassName={`${ativoOperacaoData && ativoOperacaoData.hasItems ? 'nd3' : 'nd1'} nds dark-grey-button`}
                            title="Download de Ativos"
                            icon="fa fa-file-excel-o edit edit-sub-menu"
                            action={() => exportPlanilhaAtivosVinculadosAOperacao(this.getIdsOperacao())}
                        />
                        
                        <FloatButton
                            activeClassName={"nd1 nds blue-button"}
                            title="Vincular Ativos"
                            icon={"fa fa-link edit edit-sub-menu"}
                            action={() => authorizationFunction(
                                { idAcao: CREATE, idFuncionalidade: OPERACAO_ATIVO }, 
                                () => showAtivoFormVinculoOperacao(true))
                            }
                        />
                    </FloatButtonGroup>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ativoOperacaoData: state.ativoReducer.ativos,
    messages: state.ativoReducer.messages,
    operacoes: state.operacaoReducer.selectedOperacoes,
});

const mapDispacthToProps = dispatch => bindActionCreators({
    getAtivosOperacao,
    showAtivoFormVinculoOperacao,
    loadFormAtivo,
    authorizationFunction,
    exportPlanilhaAtivosVinculadosAOperacao,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(AtivoList));