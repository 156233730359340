/* no-mixed-operators: 0 */

import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons';
import SubjectIcon from '@material-ui/icons/Subject';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import { formatStrToCnpjOrCpf } from '../../../../../utils/helpers/StringHelper';
import { getStringBooleanValue } from '../../../../../utils/helpers/BooleanHelper';
import { getDateFormatedFromString } from "../../../../../utils/helpers/DateTimeHelper";

import ButtonMuiTheme from '../../../../common/button/ButtonMuiTheme';
import PaginationRB from '../../../../common/pagination/PaginationRB';
import InfoWithLeftBar from '../../../../common/text/InfoWithLeftBar';
import If from '../../../../common/If/If';

import EventLogbookModal from '../../../gestao/pages/diarioBordo/modal/EventLogbookModal';

import {
    closeModalGerenciarInvestidor,
    toggleRemoverProcurador,
    toggleAceitarVotos,
    downloadDocumentoInvestidor,
    getVotos,
    showModalDescricaoOrdem,
    gerenciarVotos,
    STATUS_ASSEMBLEIA_ENCERRADA,
    STATUS_ASSEMBLEIA_SUSPENSA,
    STATUS_ASSEMBLEIA_NOVA_CONVOCACAO,
    downloadProcuracao,
    getRepresentantes,
    toggleRepresentante,
} from '../../../../../actions/assembleia/AssembleiaAction';
import { authorizationFunction } from '../../../../../actions/authorization/AuthorizationAction';
import { ASSEMBLEIA, UPDATE } from '../../../../../utils/actionsAndFeaturesAuth';
import ToggleGerenciar from './ToggleGerenciar';
import TextWithToolTip from '../../../../common/text/TextWithToolTip';

const CLASSES_LINHA_TABELA_VOTOS = "row table mt-2 modal-investidor-row";
const CLASSES_COLUNA_LABEL_ORDEM = "col-6 pl-2 row-align-content-centersName txt-align-left border lightgrey";
const CLASSES_COLUNA_VOTOS = "col row-align-content-center justify-content-center";
const ICONE_VOTO = "fa fa-check-square-o fa-2x";
const CLASSE_VOTO_CHECKED = "icon-light-green";
const CLASSE_VOTO_UNCHECKED = "icon-light-grey";
const VOTO_APROVADO = 1;
const VOTO_REPROVADO = 2;
const VOTO_ABSTEM = 3;

let AssembleiaGerenciarInvestidorModal = (props) => {
    const { dadosInvestidor, votosList, idAssembleia, idInvestidor, votoAceito, representantesList, nomeRepresentanteHabilitado } = props;

    const [pageRepresentante, setPageRepresentante] = useState({
        page: 0,
        rowsPerPage: 5
    });

    const handleChangeRepresentante = (event) => {
        setPageRepresentante({
            ...pageRepresentante,
            page: event - 1
        });
    };

    const handleToggleIcon = (value) => {
        props.toggleAceitarVotos(value, props.statusAssembleia);
    };

    const handleToggleRepresentante = (item) => {
        props.toggleRepresentante(item, representantesList);

        if (representantesList.some(representante => representante.solicitacaoLiberada === true)) {
            props.getVotos({ idAssembleia, idInvestidor, idUsuarioPortal: item.idUsuarioPortal });
        }
    }

    const tableRepresentantesContent = (
        <div className="row pl-3 pt-2">
            <div className="monitor-workflow mt-1 mr-3 w-100">
                <div className="row mx-0 side-by-side-grid h-100">
                    <div className="col-md px-0">
                        <div className="monitor-workflow-table-field h-100">
                            <section className="overflow-auto table-data white-background pb-1" >
                                <div className="row table head">
                                    <div className="col">Nome Representante</div>
                                    <div className="col">CPF</div>
                                    <div className="col">E-mail</div>
                                    <div className="col">Data de Validade da Representação</div>
                                    <div className="col">Documentos</div>
                                    <div className="col">Status</div>
                                </div>
                                {representantesList && representantesList.length > 0 ? representantesList
                                    .slice(pageRepresentante.page * pageRepresentante.rowsPerPage, pageRepresentante.page * pageRepresentante.rowsPerPage + pageRepresentante.rowsPerPage)
                                    .map((item, index) =>
                                    (
                                        <div className="row table mt-1" style={{ boxShadow: "3px 2px 2px #00000029" }} key={index}>
                                            <div className={"col border pl-2 txt-align-left border lightgrey"}><TextWithToolTip text={item.nomeProcurador} placement="right" /></div>
                                            <div className="col pt-3">{formatStrToCnpjOrCpf(item.cpfProcurador)}</div>
                                            <div className="col-2 pt-3"><TextWithToolTip text={item.emailProcurador} placement="right" /></div>
                                            <div className="col pt-3">{getDateFormatedFromString(item.dataValidadeRepresentacao)}</div>
                                            <div className="col row-align-content-center justify-content-center">
                                                <span className="download-procuracao">
                                                    <FontAwesomeIcon
                                                        className="procuracao-icon pointer"
                                                        icon={faDownload}
                                                        color='#FFF'
                                                        onClick={() => props.downloadProcuracao(item.idParticipanteInvestidorProcurador)}
                                                    />
                                                </span>
                                            </div>
                                            <div className="col row-align-content-center justify-content-center">
                                                <ToggleGerenciar
                                                    value={item}
                                                    onToggle={() => handleToggleRepresentante(item)}
                                                />
                                            </div>
                                        </div>)
                                    ) : (<div className="empty-item text-center">
                                        <section className="empty-item-label">Nenhuma informação encontrada.</section>
                                    </div>)}
                            </section>
                            <section className="pt-1 mb-1 modal-investidor-pagination">
                                <PaginationRB
                                    pageNumber={pageRepresentante.page}
                                    itemsPerPage={pageRepresentante.rowsPerPage}
                                    totalItems={representantesList.length}
                                    fetchFunction={(e) => handleChangeRepresentante(e)}
                                    pageRange={5}
                                />
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <EventLogbookModal
            title={'Gerenciar Participante Investidor'}
            open={props.modalGerenciarInvestidor.showDialog}
            dialogSize="xl"
            titleClass="title-modal-investidor"
            disableBackdropClick
            onClose={() => props.closeModalGerenciarInvestidor()}>
            <div className="monitor-workflow">
                <div className="monitor-workflow-head w-100 pb-2">
                    <div className="row pb-1">
                        <div className="col-2">
                            <InfoWithLeftBar
                                title="Nome"
                                value={<TextWithToolTip text={dadosInvestidor.nome} placement="right" />}
                                titleClass="pl-2"
                                valueClass="pl-2 pt-1"
                                colorBar="lightgrey"
                                marginLeftClass="ml-1"
                            />
                        </div>
                        <div className="col-2">
                            <InfoWithLeftBar
                                title="CPF/CNPJ"
                                value={formatStrToCnpjOrCpf(dadosInvestidor.documento)}
                                titleClass="pl-2"
                                valueClass="pl-2 pt-1"
                                colorBar="lightgrey"
                            />
                        </div>
                        <div className="col-2">
                            <InfoWithLeftBar
                                title="E-mail"
                                value={<TextWithToolTip text={dadosInvestidor.email} placement="right" />}
                                titleClass="pl-2"
                                valueClass="pl-2 pt-1"
                                colorBar="lightgrey"
                            />
                        </div>
                        <div className="col-2">
                            <InfoWithLeftBar
                                title="Tipo de voto"
                                value={`Pessoal / ${dadosInvestidor.procurador === null ? 'Investidor' : 'Procurador'}`}
                                titleClass="pl-2"
                                valueClass="pl-2 pt-1"
                                colorBar="lightgrey"
                            />
                        </div>
                        <div className="col-2">
                            <InfoWithLeftBar
                                title="Quantidade"
                                value={dadosInvestidor.quantidade}
                                titleClass="pl-2"
                                valueClass="pl-2 pt-1"
                                colorBar="lightgrey"
                            />
                        </div>
                        <div className="col-2">
                            <InfoWithLeftBar
                                title="Parte Relacionada"
                                value={getStringBooleanValue(dadosInvestidor.parteRelacionada)}
                                titleClass="pl-2"
                                valueClass="pl-2 pt-1"
                                colorBar="lightgrey"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <If test={dadosInvestidor.procurador}>
                <div className="row pt-3">
                    <div className="col-6">
                        <span className="bold-label">Representantes</span>
                    </div>
                </div>

                {tableRepresentantesContent}
            </If>

            <div className="row pt-3">
                <div className="col-6">
                    <span className="bold-label">Votos</span>
                    <i>
                        {nomeRepresentanteHabilitado === '' || votosList && votosList.items.every(item => item.resposta === null) ?
                            '' :
                            ` (Os votos abaixo foram enviados pelo procurador ${nomeRepresentanteHabilitado})`}
                    </i>
                </div>
                <div className="col-6 mr-1 row justify-content-end align-content-center">
                    <div className="col-4 row justify-content-end">
                        <span style={{ paddingTop: '2px' }}>Aceitar Voto:</span>
                        <FontAwesomeIcon
                            className="icon-aceitar-votos pointer"
                            icon={votoAceito ? faToggleOn : faToggleOff} color={votoAceito ? '#96d600' : '#ceccc9'}
                            onClick={() => handleToggleIcon(!votoAceito)}
                        />
                    </div>
                    <div className="liberar-votos-icon">
                        <span>{votoAceito ? 'Sim' : 'Não'}</span>
                    </div>
                </div>
            </div>
            <div className="row pl-3 pt-2">
                <div className="monitor-workflow mt-1 mr-3 w-100">
                    <div className="row mx-0 side-by-side-grid h-100">
                        <div className="col-md px-0">
                            <div className="monitor-workflow-table-field h-100">
                                <section className="overflow-auto table-data white-background pb-1" >
                                    <div className="row table head">
                                        <div className="col-6 pl-3 txt-align-left">Título</div>
                                        <div className="col">Aprovado</div>
                                        <div className="col">Rejeitado</div>
                                        <div className="col">Abstido</div>
                                        <div className="col">Descrição</div>
                                    </div>
                                    {votosList && votosList.items.map((voto, index) => {
                                        let votoChecked = voto.resposta && voto.resposta.key > 0;
                                        const votoAprovado = votoChecked && voto.resposta.key === VOTO_APROVADO ?
                                            CLASSE_VOTO_CHECKED :
                                            CLASSE_VOTO_UNCHECKED;
                                        const votoReprovado = votoChecked && voto.resposta.key === VOTO_REPROVADO ?
                                            CLASSE_VOTO_CHECKED :
                                            CLASSE_VOTO_UNCHECKED;
                                        const votoAbstem = votoChecked && voto.resposta.key === VOTO_ABSTEM ?
                                            CLASSE_VOTO_CHECKED :
                                            CLASSE_VOTO_UNCHECKED;

                                        return (
                                            <div className={CLASSES_LINHA_TABELA_VOTOS} key={index}>
                                                <div className={CLASSES_COLUNA_LABEL_ORDEM}>
                                                    {voto.titulo}
                                                </div>
                                                <div className={CLASSES_COLUNA_VOTOS}>
                                                    <i className={`${ICONE_VOTO} ${votoAprovado}`} aria-hidden="true"></i>
                                                </div>
                                                <div className={CLASSES_COLUNA_VOTOS}>
                                                    <i className={`${ICONE_VOTO} ${votoReprovado}`} aria-hidden="true"></i>
                                                </div>
                                                <div className={CLASSES_COLUNA_VOTOS}>
                                                    <i className={`${ICONE_VOTO} ${votoAbstem}`} aria-hidden="true"></i>
                                                </div>
                                                <div className={CLASSES_COLUNA_VOTOS}>
                                                    <SubjectIcon
                                                        className="pointer"
                                                        onClick={() => props.showModalDescricaoOrdem(true, voto.titulo, voto.descricao)}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </section>
                                <section className="pt-1 mb-1 modal-investidor-pagination">
                                    <PaginationRB
                                        pageNumber={votosList.pageIndex - 1}
                                        itemsPerPage={votosList.pageSize}
                                        totalItems={votosList.totalCount}
                                        filter={{ idAssembleia, idInvestidor }}
                                        fetchFunction={props.getVotos}
                                        pageRange={5}
                                    />
                                </section>
                                <EventLogbookModal
                                    title={props.dialogDescricao ? props.dialogDescricao.titulo : ''}
                                    open={props.dialogDescricao ? props.dialogDescricao.showDialog : false}
                                    onClose={() => props.showModalDescricaoOrdem(false)}>
                                    {props.dialogDescricao ? props.dialogDescricao.descricao : ''}
                                </EventLogbookModal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="hr-modal-investidor" />
            <div className="row pr-3 justify-content-end">
                <div className="mr-3">
                    <ButtonMuiTheme
                        textColor={"#630A37"}
                        mainColor={"#79C1DB"}
                        disabled={props.statusAssembleia && (
                            props.statusAssembleia.key === STATUS_ASSEMBLEIA_ENCERRADA ||
                            props.statusAssembleia.key === STATUS_ASSEMBLEIA_SUSPENSA ||
                            props.statusAssembleia.key === STATUS_ASSEMBLEIA_NOVA_CONVOCACAO)}
                        action={() => props.authorizationFunction(
                            { idAcao: UPDATE, idFuncionalidade: ASSEMBLEIA },
                            () => props.gerenciarVotos({ idAssembleia, idInvestidor, representantesList: representantesList && representantesList.length > 0 ? representantesList : [] })
                        )}
                        name="Salvar"
                    />
                </div>
                <div>

                    <ButtonMuiTheme
                        textColor={"#333333"}
                        mainColor={"#D0D0D0"}
                        action={() => props.closeModalGerenciarInvestidor()}
                        name="Cancelar"
                    />
                </div>
            </div>
        </EventLogbookModal>
    );
}

AssembleiaGerenciarInvestidorModal = reduxForm({ form: 'gerenciarInvestidorForm', destroyOnUnmount: true })(AssembleiaGerenciarInvestidorModal);
const selector = formValueSelector('gerenciarInvestidorForm');
const mapStateToProps = state => ({
    removerProcurador: selector(state, "removerProcurador"),
    votoAceito: selector(state, "votoAceito"),
    idInvestidor: selector(state, "id"),
    idAssembleia: selector(state, "idAssembleia"),
    modalGerenciarInvestidor: state.assembleiaReducer.showGerenciarInvestidorDialog || null,
    dadosInvestidor: state.assembleiaReducer.assembleiaGerenciarInvestidor.dadosInvestidor || null,
    votosList: state.assembleiaReducer.assembleiaGerenciarInvestidor.votosList,
    dialogDescricao: state.assembleiaReducer.showDialogDescricaoOrdem || null,
    representantesList: state.assembleiaReducer.assembleiaGerenciarInvestidor.representantesList || [],
    nomeRepresentanteHabilitado: state.assembleiaReducer.assembleiaGerenciarInvestidor.nomeRepresentanteHabilitado || '',
});

const mapDispatchToProps = dispatch => bindActionCreators({
    closeModalGerenciarInvestidor, toggleRemoverProcurador, toggleAceitarVotos, downloadDocumentoInvestidor,
    getVotos, showModalDescricaoOrdem, gerenciarVotos, authorizationFunction, downloadProcuracao,
    getRepresentantes, toggleRepresentante,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AssembleiaGerenciarInvestidorModal);
