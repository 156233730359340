import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Table from "../../../../../common/table/Table";
import TableHeader from "../../../../../common/table/TableHeader";
import TableContent from "../../../../../common/table/TableContent";
import TableEmptyData from "../../../../../common/table/TableEmptyData";

import {
  getErros,
  loadFormErro,
} from "../../../../../../actions/precificacao/ErroAction";

import { getDateFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";
import PaginationRB from "../../../../../common/pagination/PaginationRB";

import TableActionWithRouteAuthorization from "../../../../../common/table/TableActionWithRouteAuthorization";
import { ERRO } from "../../../../../../utils/actionsAndFeaturesAuth";
import If from "../../../../../common/If/If";
import { formatTypeError } from "../../../../../../utils/helpers/OthersHelpers";

const ErroList = (props) => {
  const { erros, loadFormErro, dataInicio, dataFinal, codigo, getErros } = props;
  const { items, hasItems, pageIndex, pageSize, totalCount } = erros;

  const tableHeader = (
    <tr>
      <th className="text-center fw-700 text-nowrap px-3">Tipo</th>
      <th className="text-center fw-700 text-nowrap">Mensagem</th>
      <th className="text-center fw-700 text-nowrap">Data</th>
      <th className="text-center fw-700 text-nowrap">Funcionalidade</th>
      <th className="text-center">Ação</th>
    </tr>
  );

  const tableContent =
    erros && hasItems ? (
      items.map((item, index) => (
        <tr key={index}>
          <td className="text-center fw-400 text-nowrap px-3">
            {formatTypeError(item.level)}
          </td>
          <td className="text-center fw-400 w-500px">{item.message}</td>
          <td className="text-center fw-400 text-nowrap">
            {getDateFormatedFromString(item.date)}
          </td>
          <td className="text-center fw-400 w-500px">{item.funcionalidade}</td>
          <TableActionWithRouteAuthorization
            feature={ERRO}
            viewAction={() => loadFormErro(item.id)}
          />
        </tr>
      ))
    ) : (
      <TableEmptyData message={"Sem itens no momento"} />
    );

  return (
    <div className="w-100 px-3">
      <If test={totalCount > 0}>
        <Table
          withoutHeader
          classes="shadow-custom rounded-12 col-12 my-3 pd-10 bg-white"
          totalItems={totalCount}
          pagination={
            <PaginationRB
              pageRange={5}
              itemsPerPage={pageSize || 0}
              totalItems={totalCount || 0}
              pageNumber={pageIndex - 1 || 0}
              fetchFunction={(event) => getErros({dataInicio, dataFinal, codigo}, event)}
            />
          }
        >
          <TableHeader>{tableHeader}</TableHeader>
          <TableContent>{tableContent}</TableContent>
        </Table>
      </If>
    </div>
  );
};

const mapStateToProps = (state) => ({
  erros: state.erroReducer.erroFormTypes || [],
  dataInicio: state.erroReducer.search.dataInicio,
  dataFinal: state.erroReducer.search.dataFinal,
  codigo: state.erroReducer.search.codigo,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormErro,
      getErros,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(ErroList);
