import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Form from "../../../../../common/form/Form";
import FormHeader from "../../../../../common/form/FormHeader";
import FormContent from "../../../../../common/form/FormContent";
import Button from "../../../../../common/button/Button";
import { ERRO, READ } from "../../../../../../utils/actionsAndFeaturesAuth";
import { authorizationWithoutDispatch } from "../../../../../../actions/authorization/AuthorizationAction";

import { getErros, loadFilterLogs } from "../../../../../../actions/precificacao/ErroAction";
import { DropdownList } from "react-widgets";

let ErroListFilter = (props) => {
  const { getErros, inicio, final, code, idFuncionalidade, funcionalidadesList, loadFilterLogs } = props;

  const [dataInicio, setDataInicio] = useState(inicio);
  const [dataFinal, setDataFinal] = useState(final);
  const [codigo, setCodigo] = useState(code);
  const [funcionality, setFuncionality] = useState(idFuncionalidade);

  const messages = {
    emptyList: "Nenhum registro encontrado",
    emptyFilter: "Nenhum registro encontrado com esse filtro",
  };

  React.useEffect(() => {
    loadFilterLogs()
  }, [])

  return (
    <div className="w-100">
      <Form>
        <FormHeader title={"Erros do Sistema"} />
        <FormContent classes="shadow-custom rounded-12 bg-white px-3 py-2">
          <div className="row px-0">
            <div className="col-lg-3 ">
              <div className="form-group field-floating">
                <input
                  type="date"
                  name="dataInicial"
                  id="txtfield_dataInicial"
                  defaultValue={dataInicio}
                  className="form-control bg-transparent"
                  onChange={(v) => setDataInicio(v.target.value)}
                />
                <label
                  className="control-label"
                  htmlFor="dataInicial"
                  id="lbl_dataInicial"
                >
                  Data Início
                </label>
                <span className="material-input" />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group field-floating">
                <input
                  type="date"
                  name="dataFinal"
                  id="txtfield_dataFinal"
                  defaultValue={dataFinal}
                  className="form-control bg-transparent"
                  onChange={(v) => setDataFinal(v.target.value)}
                />
                <label
                  className="control-label"
                  htmlFor="dataFinal"
                  id="lbl_dataFinal"
                >
                  Data Final
                </label>
                <span className="material-input" />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group field-floating has-float-label">
                <input
                  type="text"
                  name="codigo"
                  id="txtfield_codigo"
                  defaultValue={codigo}
                  className="form-control bg-transparent"
                  onChange={(v) => setCodigo(v.target.value)}
                />
                <label
                  className="control-label"
                  htmlFor="codigo"
                  id="lbl_codigo"
                >
                  Código Suporte
                </label>
                <span className="material-input" />
              </div>
            </div>
            <div className="col-lg-3 mt-10px mb-lg-0 mb-2">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p lh-1 opacity-25 text-dark">
                  Funcionalidade
                </label>
                <DropdownList
                  value={funcionality?.id ? funcionality : ""}
                  valueField="id"
                  textField="descricao"
                  data={[
                    { id: null, descricao: "Selecione..." },
                    ...funcionalidadesList
                  ]}
                  containerClassName="h-38-imp"
                  messages={messages}
                  placeholder="Todas"
                  onChange={(e) => setFuncionality(e)}
                />
              </div>
            </div>
          </div>
          <div className="row px-0">
            <div className="col-lg-12 d-flex flex-row align-items-center">
              <Button
                icon={"search"}
                name={"Buscar"}
                classes="p-bg-imp rounded"
                action={() => {
                  const idFuncionalidade = funcionality?.id;

                  authorizationWithoutDispatch(
                    { idAcao: READ, idFuncionalidade: ERRO },
                    () => getErros({ dataInicio, dataFinal, codigo, idFuncionalidade })
                  )
                }}
              />
            </div>
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  inicio: state.erroReducer.search?.dataInicio,
  final: state.erroReducer.search?.dataFinal,
  code: state.erroReducer.search?.codigo,
  idFuncionalidade: state.erroReducer.search?.idFuncionalidade,
  funcionalidadesList: state.erroReducer.opcaoFilterLogs?.funcionalidades || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getErros,
      loadFilterLogs,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ErroListFilter);
