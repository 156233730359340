import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field } from "redux-form";

import { showOpcaoList } from "../../../../../../../actions/precificacao/OpcaoAction";
import { showConfirmDialog } from "../../../../../../../actions/common/modal/ConfirmDialogAction";

import Form from "../../../../../../common/form/Form";
import FormHeader from "../../../../../../common/form/FormHeader";
import FormContent from "../../../../../../common/form/FormContent";
import ConfirmDialog from "../../../../../../common/modal/ConfirmDialog";
import Input from "../../../../../../common/input/Input";
import Button from "../../../../../../common/button/Button";
import { withRouter } from "react-router-dom";

let NaturezaForm = (props) => {
  const {
    title,
    showOpcaoList,
    floatButtonVisible,
    showConfirmDialog,
    showCancelConfirmDialog,
    readOnly,
    handleSubmit,
  } = props;

  return (
    <div className="w-100 px-3">
      <Form>
        <FormHeader title={title} offSet={20}></FormHeader>
        <FormContent
          handleSubmit={handleSubmit}
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
        >
          <div className="column">
            <div className="row">
              <div className="col-lg">
                <Field
                  component={Input}
                  inputId="naturezaDescricao"
                  name="naturezaDescricao"
                  label="Movimentação"
                  readOnly={readOnly}
                  required
                />
              </div>
              <div className="col-lg">
                <Field
                  component={Input}
                  inputId="codigoNatureza"
                  name="codigoNatureza"
                  label="Código da Natureza"
                  readOnly={readOnly}
                  required
                />
              </div>
              <div className="col-lg">
                <Field
                  component={Input}
                  inputId="naturezaProtheus"
                  name="naturezaProtheus"
                  label="Natureza"
                  readOnly={readOnly}
                  required
                />
              </div>
            </div>
          </div>

          <div className="d-flex flex-row justify-content-between align-items-center col-12 mt-2 mb-2 p-0">
              <Button
                name={`Cancelar ${floatButtonVisible ? "Alterações" : ""}`}
                classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                action={() => {
                  floatButtonVisible
                    ? showConfirmDialog(true)
                    : showOpcaoList("natureza")
                }}
              />

              {floatButtonVisible && (
                <Button
                  type="submit"
                  name={"Salvar Cadastro"}
                  classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                />
              )}
          </div>
        </FormContent>
        <ConfirmDialog
          title="Aviso"
          contentText="Os dados serão perdidos, deseja realmente sair?"
          textButtonCancel="CANCELAR"
          textButtonConfirm="CONFIRMAR"
          showDialog={showCancelConfirmDialog}
          onClose={() => showConfirmDialog(false)}
          onConfirm={() => showOpcaoList("natureza")}
          type="warning"
          maxWidth="md"
        />
      </Form>
    </div>
  );
};

// VALIDAÇÃO
const validate = (values) => {
  const errors = {
    naturezaDescricao: !values.naturezaDescricao ? 'Preenchimento obrigatório' : null,
    codigoNatureza: !values.codigoNatureza ? 'Preenchimento obrigatório' : null,
    naturezaProtheus: !values.naturezaProtheus ? 'Preenchimento obrigatório' : null,
  };

  return errors;
};

const mapStateToProps = (state) => ({
  showCancelConfirmDialog: state.confirmDialogReducer.showConfirmDialog,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showOpcaoList,
      showConfirmDialog,
    },
    dispatch
  );

const ConnectedNaturezaForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(NaturezaForm));

export default reduxForm({
  form: "OpcaoForm",
  validate,
})(ConnectedNaturezaForm);
