import {
  PATRIMONIO_OPTIONS_FETCHED,
  SELECTED_PATRIMONIO_OPTION,
  PATRIMONIO_OPERACAO_FETCHED,
  PATRIMONIO_FETCHED,
  PATRIMONIO_SHOW_LIST,
  PATRIMONIO_FORM_TAB,
  LOAD_FORM_PATRIMONIO,
  LOAD_FORM_PATRIMONIO_VINCULOS,
  LOAD_FORM_PATRIMONIO_CONTAS,
  LOAD_FORM_PATRIMONIO_MOEDA,
  PATRIMONIO_SHOW_FORM_VINCULO_OPERACAO,
  PATRIMONIO_NAO_VINCULADO_OPTIONS_FETCHED,
  SELECTED_PATRIMONIO_NAO_VINCULADO_OPTIONS,
  PATRIMONIO_VINCULADO_OPERACAO_FETCHED,
  PATRIMONIO_SHOW_DELETE_DIALOG,
  PATRIMONIO_CLEAN_LIST,
  SAVE_FORM_PATRIMONIO_ERRORS
} from "../../actions/patrimonio/PatrimonioAction";

const INITIAL_STATE = {
  patrimonios: {
    lastPage: 0,
    totalCount: 0,
    hasItems: false,
    pageSize: 0,
    pageIndex: 0,
    items: [],
  },
  patrimoniosOperacao: {
    lastPage: 0,
    totalCount: 0,
    hasItems: false,
    pageSize: 0,
    pageIndex: 0,
    items: [],
  },
  patrimoniosOptions: [],
  selectedPatrimonioOptions: [],
  patrimonioNaoVinculadoOptions: [],
  selectedPatrimonioNaoVinculadoOptions: [],
  patrimoniosVinculadoOperacao: [],
  isLoading: false,
  messages: null,
  showPatrimonioList: false,
  showPatrimonioFormVinculoOperacao: false,
  showPatrimonioDeleteDialog: {
    showDialog: false,
    idPatrimonio: 0
  },
  changePatrimonioFormTab: "",
  patrimonioFormTypes: {}
};

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case PATRIMONIO_CLEAN_LIST:
      return {
        ...state,
        patrimonios: {
          lastPage: 0,
          totalCount: 0,
          hasItems: false,
          pageSize: 0,
          pageIndex: 0,
          items: [],
        },
      }
    case PATRIMONIO_OPTIONS_FETCHED:
      return {
        ...state,
        patrimoniosOptions: action.payload
      };
    case SELECTED_PATRIMONIO_OPTION:
      return {
        ...state,
        selectedPatrimonioOptions: action.payload,
      };
    case PATRIMONIO_FETCHED:
      return {
        ...state,
        patrimonios: action.payload.content,
        messages: action.payload.messages,
        isLoading: true
      };
    case PATRIMONIO_OPERACAO_FETCHED:
      return {
        ...state,
        patrimoniosOperacao: action.payload.content.items,
        messages: action.payload.messages,
        isLoading: true
      };
    case PATRIMONIO_SHOW_LIST:
      return {
        ...state,
        showPatrimonioList: action.payload
      };
    case LOAD_FORM_PATRIMONIO:
      return {
        ...state,
        patrimonioFormTypes: action.payload,
      };
    case SAVE_FORM_PATRIMONIO_ERRORS:
      return {
        ...state,
        messages: action.payload
      }
    case LOAD_FORM_PATRIMONIO_VINCULOS:
      return {
        ...state,
        vinculos: action.payload
      };
    case LOAD_FORM_PATRIMONIO_CONTAS:
      return {
        ...state,
        contas: action.payload
      };
    case LOAD_FORM_PATRIMONIO_MOEDA:
      return {
        ...state,
        moedaDinamica: action.payload
      };
    case PATRIMONIO_FORM_TAB:
      return {
        ...state,
        changePatrimonioFormTab: action.payload
      };
    case PATRIMONIO_SHOW_DELETE_DIALOG:
      return {
        ...state,
        showPatrimonioDeleteDialog: action.payload
      }
    case PATRIMONIO_SHOW_FORM_VINCULO_OPERACAO:
      return {
        ...state,
        showPatrimonioFormVinculoOperacao: action.payload,
        showPatrimonioList: !action.payload,
        patrimonioNaoVinculadoOptions: [],
        selectedPatrimonioNaoVinculadoOptions: [],
        patrimoniosVinculadoOperacao: [],
        selectedPatrimoniosVinculadoOperacao: [],
      }
    case SELECTED_PATRIMONIO_NAO_VINCULADO_OPTIONS:
      return {
        ...state,
        selectedPatrimonioNaoVinculadoOptions: action.payload
      };
    case PATRIMONIO_NAO_VINCULADO_OPTIONS_FETCHED:
      return {
        ...state,
        patrimonioNaoVinculadoOptions: action.payload
      }
    case PATRIMONIO_VINCULADO_OPERACAO_FETCHED:
      return {
        ...state,
        patrimoniosVinculadoOperacao: action.payload.items
      }
    default:
      return state;
  }
}
