/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
/* no-mixed-operators: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field } from "redux-form";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";

import GenericPickerInput from "../../../../common/input/GenericPickerInput";
import Input from "../../../../common/input/Input";
import Button from "../../../../common/button/Button";

import {
  salvaFiltrosAprovacao,
  getAprovacoes,
  loadFilterFormAprovacao,
} from "../../../../../actions/aprovacao/AprovacaoAction";

import {
  getOperacoes,
  selectOperacaoNoForm,
} from "../../../../../actions/operacao/OperacaoAction";
import { DropdownList } from "react-widgets";
import { getSugestaoPatrimonio } from "../../../../../actions/patrimonio/PatrimonioAction";

let AprovacaoListFilter = (props) => {
  const {
    getAprovacoes,
    loadFilterFormAprovacao,
    salvaFiltrosAprovacao,
    aprovacaoConsult,
    statusList,
    tiposAprovacoesList,
    usuariosList,
    //
    getOperacoes,
    operacoesOptions,
    getSugestaoPatrimonio,
    patrimoniosOptions,
    showModal,
  } = props;

  const [idSolicitante, setIdSolicitante] = React.useState(aprovacaoConsult?.idSolicitante || null);
  const [idAprovador, setIdAprovador] = React.useState(aprovacaoConsult?.idAprovador || null);
  const [idTipoAprovacao, setIdTipoAprovacao] = React.useState(aprovacaoConsult?.idTipoAprovacao || null);
  const [status, setStatus] = React.useState(aprovacaoConsult?.status || null);
  const [idsOperacoes, setIdsOperacoes] = React.useState(aprovacaoConsult?.idsOperacoes || null);
  const [idsPatrimonioSeparados, setIdsPatrimonioSeparados] = React.useState(aprovacaoConsult?.idsPatrimonioSeparados || null);
  const [dataInicio, setDataInicio] = React.useState(aprovacaoConsult?.dataInicio || "");
  const [dataFinal, setDataFinal] = React.useState(aprovacaoConsult?.dataFinal || "");
  const [idCadastro, setIdCadastro] = React.useState(aprovacaoConsult?.idCadastro || "");

 function searchFilters() {
    const data = {
      idSolicitante,
      idAprovador,
      idTipoAprovacao,
      status,
      idsOperacoes,
      idsPatrimonioSeparados,
      dataInicio,
      dataFinal,
      idCadastro,
    };

    getAprovacoes(data, aprovacaoConsult?.pagingConfig?.pageIndex);
  }

  React.useEffect(() => {
    const fetchData = async () => {
      await loadFilterFormAprovacao();
      searchFilters();
    };
  
    if(!showModal) fetchData();
  }, []);

  // prettier-ignore
  React.useEffect(() => {
    salvaFiltrosAprovacao({
      idSolicitante,
      idAprovador,
      idTipoAprovacao,
      status,
      idsOperacoes,
      idsPatrimonioSeparados,
      dataInicio,
      dataFinal,
      idCadastro,
    })

  }, [idSolicitante, idAprovador, idTipoAprovacao, status, idsOperacoes, idsPatrimonioSeparados, dataInicio, dataFinal, idCadastro]);

  const messages = {
    emptyList: "Nenhum registro encontrado",
    emptyFilter: "Nenhum registro encontrado com esse filtro",
  };

  return (
    <div className="container-fluid">
      <Form>
        <FormHeader title={"Aprovações"} />
        <FormContent
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
          handleSubmit={getAprovacoes}
        >
          <div className="row w-100 align-items-start mx-0 mt-2">
            <div className="col-xl-6 align-self-start mt-1p mb-3 px-0 pr-xl-2">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Patrimônio Separado
                </label>
                <GenericPickerInput
                  loadData={getSugestaoPatrimonio}
                  options={patrimoniosOptions}
                  activeClassName="inputPgmt"
                  formName={"patrimonioForm"}
                  fieldName={"patrimonio"}
                  isMulti={true}
                  placeholder={"Pesquisar operação ex.:CRI.1.SEC.2"}
                  loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                  noOptionsMessage="Nenhum registro encontrado"
                  value={idsPatrimonioSeparados}
                  selectedOptionForm={(e) => setIdsPatrimonioSeparados(e)}
                />
              </div>
            </div>

            <div className="col-xl-6 align-self-start mt-1p mb-3 px-0 pl-xl-2">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Operações
                </label>
                <GenericPickerInput
                  loadData={getOperacoes}
                  options={operacoesOptions}
                  activeClassName="inputPgmt"
                  formName={"AprovacaoForm"}
                  fieldName={"operacao"}
                  isMulti={true}
                  placeholder={"Pesquisar operação ex.:CRI.1.SEC.2"}
                  loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                  noOptionsMessage="Nenhum registro encontrado"
                  value={idsOperacoes}
                  selectedOptionForm={(e) => setIdsOperacoes(e)}
                />
              </div>
            </div>
          </div>

          <div className="row w-100 align-items-start mx-0">
            <div className="col-xl align-self-start mb-3 px-0 pr-xl-2">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-1px mt-2p lh-normal opacity-25 text-dark">
                  Tipo
                </label>
                <DropdownList
                  value={idTipoAprovacao?.key ? idTipoAprovacao : ""}
                  valueField="key"
                  textField="value"
                  data={[
                    { key: null, value: "Selecione..." },
                    ...tiposAprovacoesList
                  ]}
                  filter="contains"
                  containerClassName="h-38-imp"
                  messages={messages}
                  placeholder="Todos"
                  onChange={(e) => setIdTipoAprovacao(e?.key ? e : null)}
                />
              </div>
            </div>

            <div className="col-xl align-self-start mb-3 px-0 px-xl-2">
              <Field
                component={Input}
                inputId="idCadastro"
                outerClassName={"mt-3 mb-0"}
                name="idCadastro"
                label="ID Cadastro"
                value={idCadastro}
                placeholder="Pesquisar CAP ex.: CAP-010000"
                onChange={(e) => setIdCadastro(e.target.value)}
              />
            </div>

            <div className="col-xl align-self-start mb-3 px-0 px-xl-2">
              <Field
                component={Input}
                outerClassName={"mt-3 mb-0"}
                className={"filter-input"}
                inputId="dataInicio"
                type={"date"}
                name="dataInicio"
                label="Data Início"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </div>

            <div className="col-xl align-self-start mb-3 px-0 pl-xl-2">
              <Field
                component={Input}
                outerClassName={"mt-3 mb-0"}
                className={"filter-input"}
                inputId="dataFinal"
                type={"date"}
                name="dataFinal"
                label="Data Final"
                value={dataFinal}
                onChange={(e) => setDataFinal(e.target.value)}
              />
            </div>
          </div>

          <div className="row w-100 align-items-start mx-0">
            <div className="col-xl-4 align-self-start mb-3 px-0 pr-xl-2">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p mt-2p lh-normal opacity-25 text-dark">
                  Solicitante
                </label>
                <DropdownList
                  value={idSolicitante ? idSolicitante : ""}
                  valueField="id"
                  textField="nome"
                  data={[
                    { id: null, nome: "Selecione..." },
                    ...usuariosList
                  ]}
                  filter="contains"
                  containerClassName="h-38-imp"
                  messages={messages}
                  placeholder="Todos"
                  onChange={(e) => setIdSolicitante(e.id)}
                />
              </div>
            </div>

            <div className="col-xl-4 align-self-start mb-3 px-0 px-xl-2">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p mt-2p lh-normal opacity-25 text-dark">
                  Aprovador
                </label>
                <DropdownList
                  value={idAprovador ? idAprovador : ""}
                  valueField="id"
                  textField="nome"
                  data={[
                    { id: null, nome: "Selecione..." },
                    ...usuariosList
                  ]}
                  filter="contains"
                  containerClassName="h-38-imp"
                  messages={messages}
                  placeholder="Todos"
                  onChange={(e) => setIdAprovador(e.id)}
                />
              </div>
            </div>

            <div className="col-xl align-self-start mb-3 px-0 pl-xl-2">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p mt-2p lh-normal opacity-25 text-dark">
                  Status Aprovação
                </label>
                <DropdownList
                  value={status ? status : ""}
                  valueField="key"
                  textField="value"
                  data={[
                    { key: null, value: "Selecione..." },
                    ...statusList
                  ]}
                  filter="contains"
                  containerClassName="h-38-imp"
                  messages={messages}
                  placeholder="Todos"
                  onChange={(e) => !e.key ? setStatus(null) : setStatus(e)}
                />
              </div>
            </div>
          </div>

          <div className="col-xl-2 col-12 px-0 mt-0">
            <Button
              activeClassName="w-100"
              action={() => searchFilters()}
              classes="p-bg-imp rounded btn-search h-38 my-2 w-100 d-flex justify-content-center"
              name={"Buscar"}
            />
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

AprovacaoListFilter = reduxForm({ form: "AprovacaoForm" })(AprovacaoListFilter);

const mapStateToProps = (state) => ({
  operacoesOptions: state.operacaoReducer.operacoes,
  patrimoniosOptions: state.patrimonioReducer.patrimoniosOptions,
  statusList: state.aprovacaoReducer.aprovacaoFormTypesFilter.status || [],
  tiposAprovacoesList: state.aprovacaoReducer.aprovacaoFormTypesFilter.tiposAprovacoes || [],
  usuariosList: state.aprovacaoReducer.aprovacaoFormTypesFilter.usuarios || [],
  aprovacaoConsult: state.aprovacaoReducer.aprovacoesConsultCurrent,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAprovacoes,
      loadFilterFormAprovacao,
      salvaFiltrosAprovacao,
      getOperacoes,
      getSugestaoPatrimonio,
      selectOperacaoNoForm,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AprovacaoListFilter);
