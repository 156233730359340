/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { reduxForm, formValueSelector } from "redux-form";

import { Button } from "@material-ui/core";

import FileUploadCard from "../../../../common/File/FileUploadCard";
import ContainerPage from "../../../../common/container/ContainerPage";
import CardAttachment from "../../../../common/File/CardAttachment";
import "../../../../common/File/assets/UploadFile.css";
import history from "../../../../common/routers/history";
import {
  ASSEMBLEIA_ROUTE_PATH,
  LISTA_ROUTE_PATH,
} from "../../../../common/routers/constantesRoutes";
import ButtonMuiTheme from "../../../../common/button/ButtonMuiTheme";
import ConfirmDialog from "../../../../common/modal/ConfirmDialog";

import {
  convertFileToBase64,
  ACCEPT_FILE_OPTIONS,
} from "../../../../../utils/helpers/FileHelper";

import {
  COLOR_TAB_INDICATOR,
  obterDocumentosDaAssembleia,
  removerAnexo,
  downloadAnexo,
  uploadAnexo,
  updloadDocumentosDiversos,
  donwloadEditalConvocacao,
  showAnexoDeleteDialog,
  DOCUMENTO_TIPO_ATA,
  DOCUMENTO_TIPO_PROPOSTA_ADM,
  DOCUMENTO_TIPO_DIVERSOS,
  STATUS_ASSEMBLEIA_ENCERRADA,
  STATUS_ASSEMBLEIA_SUSPENSA,
  STATUS_ASSEMBLEIA_NOVA_CONVOCACAO,
} from "./../../../../../actions/assembleia/AssembleiaAction";
import { authorizationFunction } from "../../../../../actions/authorization/AuthorizationAction";
import { showErrorMessages } from "../../../../../actions/message/ErrorMessagesAPIAction";

import {
  ASSEMBLEIA,
  UPDATE,
} from "../../../../../utils/actionsAndFeaturesAuth";

import {
  ASSEMBLEIA_GERENCIAR_TABS,
  DOCUMENTOS_TAB,
} from "./AssembleiaGerenciarTabs";
import AssembleiaGerenciarHeader from "./AssembleiaGerenciarHeader";

class AssembleiaGerenciarDocumentoPage extends Component {
  componentDidMount() {
    this.props.obterDocumentosDaAssembleia(this.getParamIdAssembleia());
  }

  getParamIdAssembleia() {
    let idAssembleia = 0;

    if (this.props.match.params.idAssembleia) {
      idAssembleia = this.props.match.params.idAssembleia;
    }

    return idAssembleia;
  }

  handleDrop(evt) {
    evt.preventDefault();

    if (evt.dataTransfer) {
      let files = Array.from(evt.dataTransfer.files);
      if (files == null) return;
      else if (files.length == 0) return;

      const arrAcceptFiles = ACCEPT_FILE_OPTIONS.PDF_EXCEL_IMAGE;
      const acceptable = files.every((file) => {
        const fileExtension = `.${file.name.split(".")[1]}`;
        return arrAcceptFiles.includes(fileExtension);
      });

      if (acceptable) {
        this.uploadMultipleFiles(files);
      }
    }
  }

  handleUploadDiversos() {
    let element = document.createElement("input");
    element.type = "file";
    element.multiple = true;
    element.accept = ACCEPT_FILE_OPTIONS.PDF_EXCEL_IMAGE;
    element.onchange = (e) => {
      let files = element.files;
      if (files == null) return;
      else if (files.length == 0) return;

      this.uploadMultipleFiles(files);
    };
    element.click();
  }

  async uploadMultipleFiles(files) {
    try {
      const filesToUpload = [];
      for (let i = 0; i < files.length; i++) {
        filesToUpload.push({
          name: files[i].name,
          arquivo: await convertFileToBase64(files[i]),
          all: files[i],
          tipoAnexo: DOCUMENTO_TIPO_DIVERSOS,
        });
      }

      this.props.updloadDocumentosDiversos(
        this.getParamIdAssembleia(),
        filesToUpload
      );
    } catch (e) {
      showErrorMessages("Erro ao importar o arquivo");
    }
  }

  handleUploadFile(tipoAnexo) {
    let acceptFileOption =
      tipoAnexo === DOCUMENTO_TIPO_ATA ||
      tipoAnexo === DOCUMENTO_TIPO_PROPOSTA_ADM
        ? ACCEPT_FILE_OPTIONS.PDF
        : ACCEPT_FILE_OPTIONS.PDF_EXCEL_IMAGE;
    let element = document.createElement("input");
    element.type = "file";
    element.multiple = false;
    element.accept = acceptFileOption;
    element.onchange = (e) => {
      let files = element.files;
      if (files == null) {
        return;
      } else if (files.length == 0) {
        return;
      } else {
        this.upload(files[0], tipoAnexo);
      }
    };

    element.click();
  }

  async upload(file, tipoAnexo) {
    try {
      let fileToUpload = {
        name: file.name,
        arquivo: await convertFileToBase64(file),
        all: file,
        tipoAnexo,
      };

      this.props.uploadAnexo(this.getParamIdAssembleia(), fileToUpload);
    } catch (e) {
      showErrorMessages("Erro ao importar o arquivo");
    }
  }

  getFileIdName(file) {
    let id = null;
    let nome = "";

    if (file) {
      id = file.id;
      nome = file.nomeArquivo;
    }

    return { id, nome };
  }

  getComponentEditalConvocacao(file) {
    const idAssembleia = this.getParamIdAssembleia();
    return (
      <>
        <div className="row">
          <span className="bold-label p-color-imp">Edital de convocação</span>
        </div>
        <FileUploadCard
          showCard
          isDownloaded
          fileName={file.nome}
          handleDownloadFile={() =>
            this.props.authorizationFunction(
              { idAcao: UPDATE, idFuncionalidade: ASSEMBLEIA },
              () => this.props.donwloadEditalConvocacao(idAssembleia, file.nome)
            )
          }
        />
      </>
    );
  }

  getComponentFileUpload(
    title,
    file,
    showCardFile,
    tipoAnexo,
    permiteRemoverArquivo
  ) {
    const idAssembleia = this.getParamIdAssembleia();
    return (
      <>
        <div className="row">
          <span className="bold-label p-color-imp">{title}</span>
        </div>
        <FileUploadCard
          showCard={showCardFile}
          fileName={file.nome}
          isRemoveable={permiteRemoverArquivo}
          isDownloaded
          handleRemoveFile={() =>
            this.props.showAnexoDeleteDialog(
              true,
              idAssembleia,
              file.id,
              tipoAnexo
            )
          }
          handleDownloadFile={() =>
            this.props.authorizationFunction(
              { idAcao: UPDATE, idFuncionalidade: ASSEMBLEIA },
              () => this.props.downloadAnexo(file.id)
            )
          }
          handleUploadFile={() =>
            this.props.authorizationFunction(
              { idAcao: UPDATE, idFuncionalidade: ASSEMBLEIA },
              () => this.handleUploadFile(tipoAnexo)
            )
          }
        />
      </>
    );
  }

  render() {
    const idAssembleia = this.getParamIdAssembleia();
    const ata = this.getFileIdName(this.props.ata);
    const propostaAdm = this.getFileIdName(this.props.propostaAdm);
    const editalConvocacao = this.getFileIdName(this.props.editalConvocacao);
    const permiteRemoverArquivo =
      this.props.status &&
      this.props.status.key !== STATUS_ASSEMBLEIA_ENCERRADA &&
      this.props.status.key !== STATUS_ASSEMBLEIA_SUSPENSA &&
      this.props.status.key !== STATUS_ASSEMBLEIA_NOVA_CONVOCACAO;

    return (
      <div className="pr-1">
        <div className="monitor-workflow px-3 pt-3">
          <AssembleiaGerenciarHeader
            showBadgeInstalada
            assembleiaInstalada={this.props.assembleiaInstalada}
            title="Documentos Assembleia"
          />

          <div className="monitor-workflow-head w-100 bg-transparent">
            <ContainerPage
              tabs={ASSEMBLEIA_GERENCIAR_TABS}
              tabIndicatorColor={COLOR_TAB_INDICATOR}
              paramsRoute={idAssembleia}
              activeTab={DOCUMENTOS_TAB}
            >
              <div className="shadow-custom rounded-12 bg-white p-3">
                <div className="row col-12 pl-4">
                  <div className="col-4">
                    {this.getComponentEditalConvocacao(editalConvocacao)}
                  </div>
                  <div className="col-4">
                    {this.getComponentFileUpload(
                      "Anexar Proposta da administração",
                      propostaAdm,
                      this.props.propostaAdm,
                      DOCUMENTO_TIPO_PROPOSTA_ADM,
                      permiteRemoverArquivo
                    )}
                  </div>
                  <div className="col-4">
                    {this.getComponentFileUpload(
                      "Anexar Ata da assembleia",
                      ata,
                      this.props.ata,
                      DOCUMENTO_TIPO_ATA,
                      permiteRemoverArquivo
                    )}
                  </div>
                </div>
                <div className="row col-12 pl-4 pt-4">
                  <span className="bold-label p-color-imp">
                    Anexar Arquivos
                  </span>
                </div>
                <div className="row pt-2 px-2">
                  <div className="col-4 align-items-center drop-area-documentos">
                    <div
                      className="d-flex align-items-center justify-content-center attachment-upload-field white-background h-100"
                      onDrop={(e) => this.handleDrop(e)}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      <section>
                        <div className="mb-3">
                          Solte arquivos aqui para enviar ou
                        </div>
                        <div className="pl-2">
                          <Button
                            variant="outlined"
                            onClick={() =>
                              this.props.authorizationFunction(
                                {
                                  idAcao: UPDATE,
                                  idFuncionalidade: ASSEMBLEIA,
                                },
                                () => this.handleUploadDiversos()
                              )
                            }
                            className="brd-p-color-imp p-color-imp bg-white mr-3"
                          >
                            Selecionar Arquivo
                          </Button>
                        </div>
                      </section>
                    </div>
                  </div>
                  <div className="col-8 files-area">
                    <div className="d-flex flex-wrap justify-content-documents pt-1">
                      {this.props.diversos.map((file) => (
                        <CardAttachment
                          key={file.id}
                          isRemoveable={permiteRemoverArquivo}
                          isDownloaded={true}
                          onClick={() =>
                            this.props.showAnexoDeleteDialog(
                              true,
                              idAssembleia,
                              file.id
                            )
                          }
                          onDownload={() =>
                            this.props.authorizationFunction(
                              { idAcao: UPDATE, idFuncionalidade: ASSEMBLEIA },
                              () => this.props.downloadAnexo(file.id)
                            )
                          }
                          label={file.nomeArquivo}
                          optionalClass="m-3 ml-4"
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </ContainerPage>

            <ConfirmDialog
              title="Aviso"
              contentText={`Você realmente deseja excluir ${this.props.anexoDeleteDialog.descricaoTitulo}?`}
              textButtonCancel="CANCELAR"
              textButtonConfirm="CONFIRMAR"
              showDialog={this.props.anexoDeleteDialog.showDialog}
              onClose={() =>
                this.props.showAnexoDeleteDialog(
                  false,
                  this.props.anexoDeleteDialog.idAssembleia
                )
              }
              onConfirm={() =>
                this.props.authorizationFunction(
                  { idAcao: UPDATE, idFuncionalidade: ASSEMBLEIA },
                  () =>
                    this.props.removerAnexo(
                      this.props.anexoDeleteDialog.fileId,
                      this.props.anexoDeleteDialog.idAssembleia
                    )
                )
              }
              type="warning"
              maxWidth="md"
            />
          </div>

          <div className="col-12 pt-2 pr-3 d-flex justify-content-end">
            <div className={"pl-3"}>
              <ButtonMuiTheme
                textColor={"#000"}
                mainColor={"#e0e0e0"}
                action={() =>
                  history.push(`/${ASSEMBLEIA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`)
                }
                name="Cancelar"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AssembleiaGerenciarDocumentoPage = reduxForm({
  form: "assembleiaGerenciarDocumentosForm",
  destroyOnUnmount: true,
})(AssembleiaGerenciarDocumentoPage);
const selector = formValueSelector("assembleiaGerenciarDocumentosForm");
const mapStateToProps = (state) => ({
  editalConvocacao: selector(state, "editalConvocacao"),
  ata: selector(state, "ata"),
  propostaAdm: selector(state, "propostaAdm"),
  diversos: selector(state, "diversos") || [],
  assembleiaInstalada:
    state.assembleiaReducer.assembleiaDadosGerenciar.assembleiaInstalada ||
    false,
  anexoDeleteDialog: state.assembleiaReducer.showAnexoDeleteDialog,
  status: state.assembleiaReducer.assembleiaDadosGerenciar.status,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      obterDocumentosDaAssembleia,
      removerAnexo,
      downloadAnexo,
      updloadDocumentosDiversos,
      uploadAnexo,
      donwloadEditalConvocacao,
      showAnexoDeleteDialog,
      authorizationFunction,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssembleiaGerenciarDocumentoPage)
);
