/* eslint eqeqeq: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import GenericPickerInput from "../../common/input/GenericPickerInput";

import { 
    getOperacoes, 
    selectedOperacaoOption, 
} from "../../../actions/operacao/OperacaoAction";
import { 
    showDashboardTabActive, 
} from "../../../actions/operacao/DashboardAction";

const currEnv = localStorage.getItem('currEnv') ?? localStorage.getItem('currEnv');

const OperacaoPicker = (props) => {
    const { gestora, multiControl } = props;
    
    return (
        <div>
            <div>
                <GenericPickerInput
                    loadData={props.getOperacoes}
                    options={props.options}
                    selectedOption={props.selectedOperacaoOption}
                    isMulti={true}
                    multiControl={multiControl}
                    placeholder={`Pesquisar ${gestora ? 'fundo ex.: FIDC Abc': 'operação ex.:CRI.1.SEC.2'}`}
                    loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                    noOptionsMessage="Nenhum registro encontrado"
                    value={props.operacoes}
                    activeClassName={props.classes}
                />
            </div>
        </div>
    )
};

const mapDispatchToProps = dispatch => bindActionCreators({
    getOperacoes,
    selectedOperacaoOption,
    showDashboardTabActive
}, dispatch);

const mapStateToProps = state => ({
  options: state.operacaoReducer.operacoes,
  operacoes: state.operacaoReducer.selectedOperacoes,
  gestora: currEnv == process.env.REACT_APP_ENV_GEST_ID,
});

export default connect(mapStateToProps, mapDispatchToProps)(OperacaoPicker);
