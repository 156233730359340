/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';

import { withStyles } from '@material-ui/core/styles';

import history from './../common/routers/history';
import { authorizationFunction } from '../../actions/authorization/AuthorizationAction';
import {
  OPERACAO_ROUTE_PATH,
  ATIVO_ROUTE_PATH,
  APROVACAO_ROUTE_PATH,
  PATRIMONIO_ROUTE_PATH,
  PRECIFICACAO_ROUTE_PATH,
  OPCAO_SISTEMA_ROUTE_PATH,
  DOMINIO_ROUTE_PATH,
  OPCAO_ROUTE_PATH,
  PRECO_ROUTE_PATH,
  CALCULADORA_ROUTE_PATH,
  CALCULO_ROUTE_PATH,
  INDICE_ROUTE_PATH,
  PAGAMENTO_ROUTE_PATH,
  LISTA_ROUTE_PATH,
  EMPRESA_PRESTADORA_ROUTE_PATH,
  USUARIO_ROUTE_PATH,
  RELATORIOS_ROUTE_PATH,
  GERENCIA_ROUTE_PATH,
  JOBS_ROUTE_PATH,
  ANALITICO_ROUTE_PATH,
  DIARIO_BORDO_ROUTE_PATH,
  ASSEMBLEIA_ROUTE_PATH,
  ACESSO_PORTAL_ROUTE_PATH,
  FUNDO_ROUTE_PATH,
  SUBCLASSE_ROUTE_PATH,
  CARTEIRA_ROUTE_PATH,
  HISTORICO_CARTEIRA_ROUTE_PATH,
  HISTORICO_COTAS_ROUTE_PATH,
  COTA_ROUTE_PATH,
  MONITORAMENTO_ROUTE_PATH,
  APROVADORES_ROUTE_PATH,
  PEDIDOS_ROUTE_PATH,
  EXPORTACAO_ROUTE_PATH,
  REMESSA_ROUTE_PATH,
  LOG_ROUTE_PATH
} from './../common/routers/constantesRoutes';

import {
  READ,
  OPERACAO_PASSIVO,
  ATIVO,
  APROVACAO,
  PATRIMONIO,
  PAGAMENTO,
  MONITORAMENTO,
  DIARIO_DE_BORDO,
  CARTEIRA,
  MEMORIA_DE_CALCULO,
  CALCULADORA,
  PRECO,
  FORNECEDORES_PRESTADORA,
  USUARIOS,
  INDICES,
  OPCAO,
  DOMINIO,
  RELATORIOS_GERENCIAIS,
  JOB,
  RELATORIOS_ANALITICOS,
  ASSEMBLEIA,
  ACESSO_PORTAL,
  FUNDO,
  SUBCLASSE,
  HISTORICO_CARTEIRA,
  HISTORICO_COTAS,
  APROVADORES,
  PEDIDOS,
  EXPORTACAO,
  REMESSA,
  LOG
} from '../../utils/actionsAndFeaturesAuth';

import './SideBar.css';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    color: '#fff'
  },
  nested: {
    paddingLeft: theme.spacing.unit * 3.5,
  },
  itemMenu: {
    color: theme.palette.common.white,
    fontWeight: "300",
    fontSize: "14px"
  },
  iconMenu: {
    color: theme.palette.common.white,
    fontSize: "1.4em;"
  },
  fab: {
    margin: theme.spacing.unit * 2,
  }
});

const MENU_LIST_ORIGIN = [
{
  name: 'Gestão',
  icon: 'business',
  order: 0,
  children: [
    {
      name: 'Operação',
      icon: 'bar_chart',
      feature: OPERACAO_PASSIVO,
      url: `/${OPERACAO_ROUTE_PATH}`,
      order: 0
    },
    {
      name: 'Patrimônio Separado',
      icon: 'home',
      feature: PATRIMONIO,
      url: `/${PATRIMONIO_ROUTE_PATH}`,
      order: 1
    },
    {
      name: 'Ativo',
      icon: 'insert_drive_file',
      feature: ATIVO,
      url: `/${ATIVO_ROUTE_PATH}`,
      order: 2
    },
    {
      name: "Monitoramento",
      icon: "timeline",
      feature: MONITORAMENTO,
      url: `/${MONITORAMENTO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 3,
    },
    {
      name: 'Pagamentos',
      icon: 'monetization_on',
      feature: PAGAMENTO,
      url: `/${PRECIFICACAO_ROUTE_PATH}/${PAGAMENTO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 5
    },
    {
      name: 'Diário de Bordo',
      icon: 'calendar_today',
      feature: DIARIO_DE_BORDO,
      url: `/${DIARIO_BORDO_ROUTE_PATH}`,
      order: 6
    },
  ]
}, {
  name: 'Precificação',
  icon: 'account_balance_wallet',
  order: 1,
  children: [{
    name: 'Memória de Cálculo',
    icon: 'done_all',
    feature: MEMORIA_DE_CALCULO,
    url: `/${PRECIFICACAO_ROUTE_PATH}/${CALCULO_ROUTE_PATH}`,
    order: 0
  }, {
    name: 'Calculadora',
    icon: 'fa fa-calculator',
    feature: CALCULADORA,
    url: `/${PRECIFICACAO_ROUTE_PATH}/${CALCULADORA_ROUTE_PATH}`,
    order: 1
  }, {
    name: 'Preço',
    icon: 'attach_money',
    feature: PRECO,
    url: `/${PRECIFICACAO_ROUTE_PATH}/${PRECO_ROUTE_PATH}`,
    order: 2
  }]
}, {
  name: 'Administração',
  icon: 'fa fa-users',
  order: 2,
  children: [
    {
      name: 'Pessoas (Prestadores)',
      icon: 'business_center',
      feature: FORNECEDORES_PRESTADORA,
      url: `/${EMPRESA_PRESTADORA_ROUTE_PATH}`,
      order: 0
    },
    {
      name: 'Acesso Portal',
      icon: 'assignment_ind',
      feature: ACESSO_PORTAL,
      url: `/${ACESSO_PORTAL_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 1
    },
    {
      name: 'Usuários',
      icon: 'supervised_user_circleIcon',
      feature: USUARIOS,
      url: `/${USUARIO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 2
    },
    {
      name: 'Índices',
      icon: 'trending_up',
      feature: INDICES,
      url: `/${PRECIFICACAO_ROUTE_PATH}/${INDICE_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 3
    },
    {
      name: 'Opções do Sistema',
      icon: 'apps',
      feature: OPCAO,
      url: `/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 4
    },
    {
      name: 'Logs',
      icon: 'cached',
      feature: LOG,
      url: `/${OPCAO_SISTEMA_ROUTE_PATH}/${LOG_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 5
    },
    {
      name: 'Domínios',
      icon: 'business',
      feature: DOMINIO,
      url: `/${DOMINIO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 6
    }
  ]
}, 
{
  name: 'Financeiro',
  icon: 'credit_card',
  order: 2,
  children: [
    {
      name: 'Aprovadores',
      icon: 'person',
      feature: APROVADORES,
      url: `/${APROVADORES_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 0
    },
    {
      name: 'Pedidos',
      icon: 'featured_play_list',
      feature: PEDIDOS,
      url: `/${PEDIDOS_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 1
    },
    {
      name: 'Aprovações',
      icon: 'checklist',
      feature: APROVACAO,
      url: `/${APROVACAO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 2
    },
    {
      name: 'Remessa',
      icon: 'cloud_upload',
      data: { validarAreaAtuacao: true },
      feature: REMESSA,
      url: `/${REMESSA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 2
    },
    {
      name: 'Exportar Protheus',
      icon: 'cloud_download',
      data: { validarAreaAtuacao: true },
      feature: EXPORTACAO,
      url: `/${EXPORTACAO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 3
    },
  ]
}, 
{
  name: 'RI',
  icon: 'contacts',
  order: 3,
  children: [
    {
      name: 'Assembleia',
      icon: 'how_to_reg',
      feature: ASSEMBLEIA,
      url: `/${ASSEMBLEIA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 0
    }
  ]
}, {
  name: 'Relatórios',
  icon: 'description',
  order: 4,
  children: [
    {
      name: 'Analíticos',
      icon: 'timeline',
      feature: RELATORIOS_ANALITICOS,
      url: `/${RELATORIOS_ROUTE_PATH}/${ANALITICO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 0
    }, {
      name: 'Gerenciais',
      icon: 'insert_chart',
      feature: RELATORIOS_GERENCIAIS,
      url: `/${RELATORIOS_ROUTE_PATH}/${GERENCIA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 1
    }, {
      name: 'Job',
      icon: 'schedule',
      feature: JOB,
      url: `/${JOBS_ROUTE_PATH}`,
      order: 2
    }]
}];

const MENU_LIST_ALT = [{
  name: 'Gestão',
  icon: 'business',
  order: 0,
  children: [{
    name: 'Cota',
    icon: 'bar_chart',
    feature: OPERACAO_PASSIVO,
    url: `/${OPERACAO_ROUTE_PATH}`,
    order: 0
  },
  {
    name: 'Subclasse',
    icon: 'show_chart',
    feature: SUBCLASSE,
    url: `/${SUBCLASSE_ROUTE_PATH}`,
    order: 1
  },
  {
    name: 'Fundo',
    icon: 'home',
    feature: FUNDO,
    url: `/${FUNDO_ROUTE_PATH}`,
    order: 2
  },
  {
    name: "Monitoramento",
    icon: "timeline",
    feature: MONITORAMENTO,
    url: `/${MONITORAMENTO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
    order: 3,
  },
  {
    name: 'Pagamentos',
    icon: 'monetization_on',
    feature: PAGAMENTO,
    url: `/${PRECIFICACAO_ROUTE_PATH}/${PAGAMENTO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
    order: 4
  }, {
    name: 'Diário de Bordo',
    icon: 'calendar_today',
    feature: DIARIO_DE_BORDO,
    url: `/${DIARIO_BORDO_ROUTE_PATH}`,
    order: 5
  },
  {
    name: 'Validações - Carteira',
    icon: 'done',
    feature: CARTEIRA,
    url: `/${CARTEIRA_ROUTE_PATH}`,
    order: 6
  },
  {
    name: 'Validações - Cotas',
    icon: 'done',
    feature: CARTEIRA,
    url: `/${COTA_ROUTE_PATH}`,
    order: 7
  },
  {
    name: 'Histórico - Carteira',
    icon: 'scanner',
    feature: HISTORICO_CARTEIRA,
    url: `/${HISTORICO_CARTEIRA_ROUTE_PATH}`,
    order: 8
  },
  {
    name: 'Histórico - Cotas',
    icon: 'view_quilt',
    feature: HISTORICO_COTAS,
    url: `/${HISTORICO_COTAS_ROUTE_PATH}`,
    order: 9
  },
  ]
}, {
  name: 'Precificação',
  icon: 'account_balance_wallet',
  order: 1,
  children: [{
    name: 'Valor da Cota',
    icon: 'done_all',
    feature: MEMORIA_DE_CALCULO,
    url: `/${PRECIFICACAO_ROUTE_PATH}/${CALCULO_ROUTE_PATH}`,
    order: 0
  }]
}, {
  name: 'Administração',
  icon: 'fa fa-users',
  order: 2,
  children: [
    {
      name: 'Pessoas (Prestadores)',
      icon: 'business_center',
      feature: FORNECEDORES_PRESTADORA,
      url: `/${EMPRESA_PRESTADORA_ROUTE_PATH}`,
      order: 0
    },
    {
      name: 'Usuários',
      icon: 'supervised_user_circleIcon',
      feature: USUARIOS,
      url: `/${USUARIO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 3
    },
    {
      name: 'Índices',
      icon: 'trending_up',
      feature: INDICES,
      url: `/${PRECIFICACAO_ROUTE_PATH}/${INDICE_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 4
    },
    {
      name: 'Opções do Sistema',
      icon: 'apps',
      feature: OPCAO,
      url: `/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 5
    },
    {
      name: 'Logs',
      icon: 'cached',
      feature: LOG,
      url: `/${OPCAO_SISTEMA_ROUTE_PATH}/${LOG_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 6
    },
    {
      name: 'Domínios',
      icon: 'business',
      feature: DOMINIO,
      url: `/${DOMINIO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 7
    }
  ]
}, {
  name: 'Relatórios',
  icon: 'description',
  order: 4,
  children: [
    {
      name: 'Analíticos',
      icon: 'timeline',
      feature: RELATORIOS_ANALITICOS,
      url: `/${RELATORIOS_ROUTE_PATH}/${ANALITICO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 0
    }, {
      name: 'Gerenciais',
      icon: 'insert_chart',
      feature: RELATORIOS_GERENCIAIS,
      url: `/${RELATORIOS_ROUTE_PATH}/${GERENCIA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
      order: 1
    }, {
      name: 'Job',
      icon: 'schedule',
      feature: JOB,
      url: `/${JOBS_ROUTE_PATH}`,
      order: 2
    }]
}];

let currEnv = localStorage.getItem('currEnv') ?? localStorage.getItem('currEnv');
let gestora = currEnv == process.env.REACT_APP_ENV_GEST_ID;

class SideBar extends Component {
  updateComponent() {
    this.setState({});
  }
  handleMenuClick(menu) {
    if (menu.children?.length > 0) {
      menu.$visible = !menu.$visible;
    }

    if (menu.url?.length > 0) {
      if (menu.feature) {
        this.props.authorizationFunction({ 
          idAcao: READ,
          idFuncionalidade: menu.feature,
          ...menu.data,
        }, () => history.push(menu.url));
      } else {
        history.push(menu.url);
      }
    }
    else if (menu.action != null) {
      menu.action(menu);
    }
    this.updateComponent();
  }
  renderMenuItem(menu) {
    let id = 'nav-menu_' + menu.name;
    let iconElement = <></>;
    let collapseIcon = <></>;
    let elementName = <></>;


    if (!this.props.sbOpen) {
      elementName = (
        <div className="col d-flex align-items-center menu-item-label">
          <ListItemText className="btn-sidebar">
            {menu.name}
          </ListItemText>
        </div>);

      if (menu.children?.length > 0) {
        collapseIcon = <div className="col-1 text-right d-flex align-items-center"><i className={"fa fa-chevron-down menu-collapse-icon " + (menu.$visible ? 'visible' : '')}></i></div>
      }
    }

    if (menu.icon.match(/(fa|fas)/))
      iconElement = <i className={menu.icon + ' icon-menu'} color="primary"></i>;
    else
      iconElement = <Icon className="icon-menu">{menu.icon}</Icon>

    return (
      <ListItem button onClick={() => this.handleMenuClick(menu)} className={'pl-0 '} key={id}>
        <div className="menu-item-field row mx-0 text-white w-100">
          <div className="col-1 d-flex align-items-center">
            <ListItemIcon className="btn-sidebar-icon text-white">
              {iconElement}
            </ListItemIcon>
          </div>
          {elementName}
          {collapseIcon}
        </div>
      </ListItem>
    );
  }

  renderMenu(menu) {
    if (menu == undefined)
      return;
    let id = 'nav-menu-frag_' + menu.name;
    let hasChildren = menu.children?.length > 0;
    let element = null;
    if (hasChildren) {
      element = (
        <React.Fragment key={id}>
          {this.renderMenuItem(menu)}
          <div className="menu-sub-items">
            <Collapse in={menu.$visible} timeout="auto">
              <List className="py-0">
                {menu.children.map(x => this.renderMenu(x))}
              </List>
            </Collapse>
          </div>
        </React.Fragment>
      );
    } else
      element = this.renderMenuItem(menu);
    return element;
  }

  render() {
    let menuItems = gestora ? MENU_LIST_ALT : MENU_LIST_ORIGIN;

    return <div>
      <List>
        {menuItems.map(x => this.renderMenu(x))}
      </List>
    </div>
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ authorizationFunction }, dispatch);
SideBar = connect(null, mapDispatchToProps)(SideBar);
export default withStyles(styles)(SideBar);
