import React from 'react';
import { Route, Switch } from "react-router-dom";

import {
    ASSEMBLEIA_ROUTE_PATH, LISTA_ROUTE_PATH, NOVO_ROUTE_PATH, EDITAR_ROUTE_PATH, DETALHAR_ROUTE_PATH,
    GERENCIAR_ROUTE_PATH, RESUMO_ROUTE_PATH, PARTICIPANTE_ROUTE_PATH, DOCUMENTO_ROUTE_PATH
} from '../../../common/routers/constantesRoutes';

import AssembleiaPage from '../pages/AssembleiaPage';
import AssembleiaPageIncluir from '../pages/AssembleiaPageIncluir';
import AssembleiaPageEditar from '../pages/AssembleiaPageEditar';
import AssembleiaVizualizePage from '../pages/AssembleiaVisualizePage';

import AssembleiaGerenciarResumoPage from '../pages/gerenciar/AssembleiaGerenciarResumoPage';
import AssembleiaGerenciarParticipantePage from "../pages/gerenciar/AssembleiaGerenciarParticipantePage";
import AssembleiaGerenciarDocumentoPage from "../pages/gerenciar/AssembleiaGerenciarDocumentoPage";

export default () => (
    <Switch>
        <Route exact={true} path={`/${ASSEMBLEIA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} component={AssembleiaPage} />
        <Route exact={true} path={`/${ASSEMBLEIA_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={AssembleiaPageIncluir} />
        <Route exact={true} path={`/${ASSEMBLEIA_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idAssembleia`} component={AssembleiaPageEditar} />
        <Route exact={true} path={`/${ASSEMBLEIA_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idAssembleia`} component={AssembleiaVizualizePage} />

        <Route exact={true} path={`/${ASSEMBLEIA_ROUTE_PATH}/${GERENCIAR_ROUTE_PATH}/${RESUMO_ROUTE_PATH}/:idAssembleia`} component={AssembleiaGerenciarResumoPage} />
        <Route exact={true} path={`/${ASSEMBLEIA_ROUTE_PATH}/${GERENCIAR_ROUTE_PATH}/${PARTICIPANTE_ROUTE_PATH}/:idAssembleia`} component={AssembleiaGerenciarParticipantePage} />
        <Route exact={true} path={`/${ASSEMBLEIA_ROUTE_PATH}/${GERENCIAR_ROUTE_PATH}/${DOCUMENTO_ROUTE_PATH}/:idAssembleia`} component={AssembleiaGerenciarDocumentoPage} />
    </Switch>
);
