import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field, formValueSelector } from "redux-form";

import Form from "../../../../../common/form/Form";
import FormHeader from "../../../../../common/form/FormHeader";
import FormContent from "../../../../../common/form/FormContent";
import GenericPickerInput from "../../../../../common/input/GenericPickerInput";
import RadioGroupList from "../../../../../common/input/RadioGroupList";
import Input from "../../../../../common/input/Input";
import MaskedInput from "../../../../../common/input/MaskedInput";
import CheckboxInput from "../../../../../common/input/CheckboxInput";
import If from "../../../../../common/If/If";
import Button from "../../../../../common/button/Button";

import {
  getOperacoes,
  selectOperacaoNoForm,
} from "../../../../../../actions/operacao/OperacaoAction";

import {
  TAXA_KEY,
  PRECO_KEY,
  consultar,
  loadSpinner,
  cleanForm,
  getTaxaPorOperacao,
} from "../../../../../../actions/precificacao/CalculadoraAction";
import LoadingSpinner from "../../../../../common/loading/LoadingScreen";

const TIPO_PAGAMENTO_FLUTUANTE = 2;

class CalculadoraListFilter extends Component {
  componentWillUnmount() {
    this.props.cleanForm();
  }

  render() {
    const {
      operacao,
      tipo,
      data,
      taxa,
      preco,
      percentualFlutuante,
      tipoOperacao,
      projecao,
      selectedMoeda,
    } = this.props;

    return (
      <div className="container-fluid">
        <Form>
          <FormHeader title={"Calculadora de Preço Unitário"} />
          <FormContent classes="shadow-custom rounded-12 bg-white px-3 py-2">
            <div className="row">
              <div className="col-lg-7 mb-2">
                <div className="d-flex flex-column">
                  <label className="f-12 text-dark mb-1">Busca</label>
                  <GenericPickerInput
                    loadData={this.props.getOperacoes}
                    options={this.props.options}
                    formName={"calculadoraFilterForm"}
                    fieldName={"operacao"}
                    placeholder={"Pesquisar operação ex.:CRI.1.SEC.2"}
                    loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                    noOptionsMessage="Nenhum registro encontrado"
                    value={operacao}
                    nextSelectHandler={this.props.getTaxaPorOperacao}
                    selectedOptionForm={this.props.selectOperacaoNoForm}
                  />
                </div>
              </div>
              <div className="col-lg-5 m-0 align-self-end pt-3">
                <Field
                  name="tipoRelatorio"
                  id="tipoRelatorio"
                  label="Tipo"
                  inLine={true}
                  formName="calculadoraFilterForm"
                  component={RadioGroupList}
                  changeAction={this.props.getTaxaPorOperacao}
                  options={[
                    { value: TAXA_KEY, label: "Taxa" },
                    { value: PRECO_KEY, label: "Preço Unitário" },
                  ]}
                />
              </div>
              <div className="col-lg-2">
                <Field
                  component={Input}
                  className={"filter-input"}
                  inputId="data"
                  type={"date"}
                  name="data"
                  label="Data"
                />
              </div>
              <If
                test={
                  tipo === PRECO_KEY &&
                  tipoOperacao === TIPO_PAGAMENTO_FLUTUANTE
                }
              >
                <div className="col-lg-2">
                  <Field
                    component={MaskedInput}
                    inputId="percentualFlutuante"
                    name="percentualFlutuante"
                    label="% Flutuante"
                    suffix={"%"}
                    className={"filter-input"}
                    decimalSeparator={","}
                    decimalScale={4}
                    maxLength={9}
                    fixedDecimalScale
                    placeholder={"0,00%"}
                  />
                </div>
                <div className="col-lg-4 col-xl-2">
                  <Field
                    component={Input}
                    className={"filter-input"}
                    inputId="labelTaxaPosFixada"
                    type={"text"}
                    name="labelTaxaPosFixada"
                    readOnly={true}
                    label="Taxa Pós Fixada"
                  />
                </div>
              </If>
              <div className="col-lg-2">
                <Field
                  component={MaskedInput}
                  inputId={tipo === TAXA_KEY ? "preco" : "taxa"}
                  name={tipo === TAXA_KEY ? "preco" : "taxa"}
                  label={tipo === TAXA_KEY ? "Preço Unitário" : "Taxa Spread"}
                  suffix={tipo === PRECO_KEY ? "%" : ""}
                  prefix={tipo === TAXA_KEY ? `${selectedMoeda} ` : ""}
                  className={"filter-input"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  maxLength={tipo === PRECO_KEY ? 9 : 27}
                  decimalScale={tipo === PRECO_KEY ? 4 : 8}
                  placeholder={
                    tipo === TAXA_KEY ? `${selectedMoeda} 0,00` : "0,00 %"
                  }
                  fixedDecimalScale
                />
              </div>

              <div className="col-lg-2 mt-3">
                <Field
                  component={CheckboxInput}
                  inputId="projecao"
                  inputName="projecao"
                  name="projecao"
                  label="Utilizar Projeção"
                />
              </div>
            </div>
            <Button
              action={() => {
                this.props.loadSpinner(true);
                this.props.consultar({
                  operacao,
                  tipoRelatorio: tipo,
                  data,
                  taxa,
                  preco,
                  percentualFlutuante,
                  projecao,
                });
              }}
              classes="p-bg-imp rounded"
              icon={"calculator"}
              name={"Calcular"}
            />
          </FormContent>
        </Form>

        <If test={this.props.loading}>
          <LoadingSpinner />
        </If>
      </div>
    );
  }
}

CalculadoraListFilter = reduxForm({
  form: "calculadoraFilterForm",
  destroyOnUnmount: true,
})(CalculadoraListFilter);
const selector = formValueSelector("calculadoraFilterForm");
const mapStateToProps = (state) => ({
  initialValues: {
    tipoRelatorio: TAXA_KEY,
  },
  operacao: selector(state, "operacao"),
  tipo: selector(state, "tipoRelatorio"),
  data: selector(state, "data"),
  taxa: selector(state, "taxa"),
  percentualFlutuante: selector(state, "percentualFlutuante"),
  labelTaxaPosFixada: selector(state, "labelTaxaPosFixada"),
  tipoOperacao: selector(state, "tipoOperacao"),
  preco: selector(state, "preco"),
  projecao: selector(state, "projecao"),
  options: state.operacaoReducer.operacoes,
  loading: state.calculadoraReducer.loading,
  selectedMoeda: state.operacaoReducer.selectedMoeda || "",
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOperacoes,
      selectOperacaoNoForm,
      consultar,
      loadSpinner,
      cleanForm,
      getTaxaPorOperacao,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculadoraListFilter);
