/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, formValueSelector, getFormValues } from "redux-form";
import { withRouter } from "react-router-dom";

import Form from "../../../../../common/form/Form";
import FormHeader from "../../../../../common/form/FormHeader";
import FormTab from "../../../../../common/form/FormTab";
import FormContent from "../../../../../common/form/FormContent";

import { validate } from "../validation/passivoFormValidation";
import ModalPassivoAprovacao from "../modals/ModalPassivoAprovacao";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";

import { showConfirmDialog } from "../../../../../../actions/common/modal/ConfirmDialogAction";
import {
  changePassivoFormTab,
  showPassivoList,
  changeDataEmissao,
  showMessageAlert,
  showModalAprovacao,
  showModalOnboarding,
  showModalFinalizacao,
  resetLoadFormPassivo,
  loadFormPassivo,
  confirmarOnboarding,
  limpaDependenteOnChange,
  editPassivo,
} from "../../../../../../actions/operacao/PassivoAction";

import PassivoFormGeral from "./tabs/PassivoFormGeral";
import PassivoFormPagamentos from "./tabs/PassivoFormPagamentos";
import PassivoFormCurvaPagamentos from "./tabs/PassivoFormCurvaPagamentos";
import PassivoFormIntegralizacao from "./tabs/PassivoFormIntegralizacao";
import PassivoFormIntegralizacaoGest from "./tabs/PassivoFormIntegralizacaoGestora";
import ConfirmDialog from "../../../../../common/modal/ConfirmDialog";
import Button from "../../../../../common/button/Button";
import FixedFloaterErrors from "../../../../../common/floats/FixedFloaterErrors";

import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import PassivoFormAprovacao from "./tabs/PassivoFormAprovacao";
import ModalConfirmaOnboarding from "../modals/ModalConfirmaOnboarding";
import ModalFinalizaDistribuicao from "../modals/ModalFinalizaDistribuicao";
import PassivoFormFaq from "./tabs/PassivoFormFaq";
import PassivoFormRegistro from "./tabs/PassivoFormRegistro";

const PASSIVO_FORM_GERAL = "GERAL";
const PASSIVO_FORM_PAGAMENTOS = "PAGAMENTOS";
const PASSIVO_FORM_CURVA_PAGAMENTOS = "CURVA DE PAGAMENTOS";
const PASSIVO_FORM_INTEGRALIZACAO = "DISTRIBUIÇÃO";
const PASSIVO_FORM_APROVACAO = "APROVAÇÃO";
const PASSIVO_FORM_REGISTRO = "REGISTRO";
const PASSIVO_FORM_FAQ = "FAQ";

const styles = (theme) => ({
  parent: {
    width: "100%",
    marginTop: "0.5%",
    backgroundColor: "transparent",
  },
  mrButton: {
    marginRight: theme.spacing.unit,
  },
  button: {
    color: "#630A37",
    "&:disabled": {
      color: "#D0D0D0",
    },
  },
  buttons: {
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: "1rem !important",
  },
  stepper: {
    backgroundColor: "transparent",
  },
  active: {},
  completed: {},
  customStepLabel: {
    "& [class*='MuiStepLabel-labelContainer']": {
      display: "flex",
      justifyContent: "center",
    },
    "& [class*='MuiTypography-root']": {
      // width: "max-content",
      padding: "0 10px",
      borderRadius: "20px",
      // fontSize: "12px",
      // textTransform: "uppercase",
      "&[class*='MuiStepLabel']": {
        backgroundColor: "#B8B8B8",
        color: "#121212c9",
        fontWeight: 500,
      },
      "&[class*='MuiStepLabel-active']": {
        backgroundColor: "#FFC107",
      },
      "&[class*='MuiStepLabel-completed']": {
        backgroundColor: "#83BF74",
        color: "#ffffff",
        "&.revisao": {
          backgroundColor: "#cc0000",
        },
      },
    },
  },
});

class PassivoForm extends Component {
  componentDidMount() {
    this.props.changePassivoFormTab(PASSIVO_FORM_GERAL);
  }

  componentWillUnmount() {
    this.props.changeDataEmissao("");
    this.props.resetLoadFormPassivo();
  }

  isActivateForm(state) {
    return this.props.formActive === state;
  }

  changeStateGestao(state = false) {
    this.setState({ showModalGestao: state })
  }

  constructor(props) {
    super(props);
    this.state = {
        showModalGestao: false
    };
  }

  render() {
    const {
      handleSubmit,
      readOnly,
      floatButtonVisible,
      title,
      operacao,
      passivoOperacao,
      showConfirmDialog,
      showCancelConfirmDialog,
      invalid,
      showMessageAlert,
      showModalAprovacao,
      showModalOnboarding,
      showModalFinalizacao,
      messages,
      classes,
      showAprovacao,
      showOnboarding,
      showFinalizacao,
      loadFormPassivo,
      confirmarOnboarding,
      limpaDependenteOnChange,
      formValues,
      editPassivo,
    } = this.props;

    const currEnv = localStorage.getItem("currEnv") ?? localStorage.getItem("currEnv");
    const gestora = currEnv == process.env.REACT_APP_ENV_GEST_ID;

    const statusKey = passivoOperacao?.statusAprovacao?.key;

    const REPASSADO = statusKey == 5;

    let renderStatus = [
      {
        key: 1,
        value: "Passivo Cadastrado",
        raw: "passivoCadastrado",
        show: true,
        concluidos: [1],
      },
      {
        key: 2,
        value: "Passivo Ativado",
        raw: "passivoAtivado",
        show: true,
        concluidos: [1, 2],
      },
      {
        key: 3,
        value: "Dados Adicionais Cadastrados",
        raw: "dadosAdicionaisCadastrados",
        show: true,
        concluidos: [1, 2, 3],
      },
      {
        key: 4,
        value: "Onboarding",
        raw: "onboarding",
        show: true,
        concluidos: [1, 2, 3, 4],
      },
      {
        key: 5,
        value: "Totalmente Integralizada",
        raw: "totalmenteIntegralizada",
        show: true,
        concluidos: [1, 2, 3, 4, 5],
      },
    ];

    const steps = renderStatus.filter((sts) => sts.show).map((sts) => sts.value);
    const concluidos = renderStatus.filter((sts) => sts.key === statusKey).flatMap((sts) => sts.concluidos);

    function CustomStepIcon({ active, completed, classes }) {

      const cComp = "#83BF74";
      const cActv = "#FFC107";
      const cDflt = "#888888";

      return completed ? (
        <CheckCircleOutline style={{ color: cComp }} />
      ) : active ? (
        <RadioButtonChecked style={{ color: cActv }} />
      ) : (
        <RadioButtonUnchecked style={{ color: cDflt }} />
      );
    }

    const defaultTabs = [
      PASSIVO_FORM_GERAL,
      PASSIVO_FORM_PAGAMENTOS,
      PASSIVO_FORM_CURVA_PAGAMENTOS,
      PASSIVO_FORM_INTEGRALIZACAO,
    ];
    
    const formTabNames = gestora ? defaultTabs : [...defaultTabs, PASSIVO_FORM_APROVACAO, PASSIVO_FORM_FAQ];

    return (
      <Form>
        <h3 className="mt-0 page-head__title f-36">{title}</h3>

        {/* {!gestora && (
          <Stepper
              className="mt-4"
              classes={{ root: classes.stepper }}
              activeStep={concluidos.length || 0}
              alternativeLabel
            >
              {steps.map((step, index) => (
                <Step
                  key={index}
                  completed={concluidos.some((et) => et === index + 1)}
                >
                  <StepLabel
                    completed={concluidos.some((et) => et === index + 1)}
                    className={classes.customStepLabel}
                    StepIconComponent={CustomStepIcon}
                    classes={{
                      completed: classes.completed,
                    }}
                    StepIconProps={{
                      classes: {
                        completed: classes.completed,
                        active: classes.active,
                        disabled: classes.disabled,
                      },
                    }}
                  >
                    {step}
                  </StepLabel>
                </Step>
              ))}
          </Stepper>
        )}  */}

        <FormHeader
          noTitle
          offSet={20}
          col={"col-md"}
          codigoOpea={operacao ? operacao.CodigoOpea : ""}
        >
          <FormTab
            formTabNames={formTabNames}
            formTabActive={this.props.formActive}
            changeFormTabActive={this.props.changePassivoFormTab}
          />
        </FormHeader>
        <FormContent handleSubmit={handleSubmit}>
          {messages?.hasError && (
            <FixedFloaterErrors
              messages={messages}
              title="Status do Cadastro"
            />
          )}

          <div
            className={`d-${this.isActivateForm(PASSIVO_FORM_GERAL) ? "block" : "none"}`}
          >
            <PassivoFormGeral readOnly={readOnly} REPASSADO={REPASSADO} />
          </div>
          <div
            className={`d-${
              this.isActivateForm(PASSIVO_FORM_PAGAMENTOS) ? "block" : "none"
            }`}
          >
            <PassivoFormPagamentos formActive={this.props.formActive} readOnly={readOnly} />
          </div>
          <div
            className={`d-${
              this.isActivateForm(PASSIVO_FORM_CURVA_PAGAMENTOS) ? "block" : "none"}`}
          >
            <PassivoFormCurvaPagamentos readOnly={readOnly} />
          </div>
          <div
            className={`d-${
              this.isActivateForm(PASSIVO_FORM_INTEGRALIZACAO) ? "block" : "none"}`}
          >
            {gestora ? <PassivoFormIntegralizacaoGest readOnly={readOnly} /> : <PassivoFormIntegralizacao readOnly={readOnly} /> }
          </div>
          <div
            className={`d-${
              this.isActivateForm(PASSIVO_FORM_APROVACAO) ? "block" : "none"
            }`}
          >
            <PassivoFormAprovacao readOnly={readOnly} passivoOperacao={passivoOperacao} />
          </div>

          {this.isActivateForm(PASSIVO_FORM_REGISTRO) && (
            <div>
              <PassivoFormRegistro readOnly={readOnly} passivoOperacao={passivoOperacao} />
            </div>
          )}

          {this.isActivateForm(PASSIVO_FORM_FAQ) && (
            <div>
              <PassivoFormFaq readOnly={readOnly} passivoOperacao={passivoOperacao} />
            </div>
          )}

          {/* ----- */}

          <div className="col-12 px-0">
            <div className="d-flex flex-row justify-content-between align-items-center col-12 mb-4 mt-2 p-0">
              <Button
                name={`Cancelar ${floatButtonVisible ? "Alterações" : ""}`}
                classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                action={() => {
                  floatButtonVisible
                    ? showConfirmDialog(true)
                    : this.props.showPassivoList();
                }}
              />

              {floatButtonVisible && (
                <Button
                  type="submit"
                  name={"Salvar Cadastro"}
                  classes="warn-bg-imp text-dark px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                  action={() => {
                    limpaDependenteOnChange("typeSubmit", "Salvar");
                    if (invalid) showMessageAlert( "Alguns erros foram encontrados. Por favor, revise atentamente todas as abas.");
                  }}
                />
              )}

              {(!readOnly && statusKey == 2) && (
                <Button
                  type="submit"
                  name="Enviar para Gestão"
                  action={() => {
                    limpaDependenteOnChange("typeSubmit", "Enviar");
                    if (invalid) showMessageAlert( "Alguns erros foram encontrados. Por favor, revise atentamente todas as abas.");
                  }}
                  classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                />
              )}

              {(!readOnly && statusKey == 3) && (
                <Button
                  type="button"
                  name="Confirmar Onboarding"
                  action={async () => {
                    const result = await editPassivo(formValues, "Onboarding")
                    if (result) showModalOnboarding(true);
                  }}
                  classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                />
              )}

              {(!readOnly && statusKey == 4) && (
                <Button
                  type="button"
                  name="Finalizar Distribuição"
                  action={() => showModalFinalizacao(true)}
                  classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                />
              )}
            </div>
          </div>
        </FormContent>


        {/* MODALS */}

        <ConfirmDialog
          title="Aviso"
          contentText="Os dados do formulário serão perdidos, deseja realmente sair?"
          textButtonCancel="CANCELAR"
          textButtonConfirm="CONFIRMAR"
          showDialog={showCancelConfirmDialog}
          onClose={() => showConfirmDialog(false)}
          onConfirm={() => this.props.showPassivoList()}
          type="warning"
          maxWidth="md"
        />

        {!invalid && showAprovacao && (
          <ModalPassivoAprovacao
            open={showAprovacao}
            close={showModalAprovacao}
          />
        )}

        {!invalid && showOnboarding && (
          <ModalConfirmaOnboarding
            open={showOnboarding}
            close={showModalOnboarding}
            confirm={async (data) => {

              await confirmarOnboarding({ ...data, id: passivoOperacao?.id });
              showModalOnboarding();
              loadFormPassivo(passivoOperacao?.id)
            }}
          />
        )}

        {!invalid && showFinalizacao && (
          <ModalFinalizaDistribuicao
            open={showFinalizacao}
            close={showModalFinalizacao}
          />
        )}

      </Form>
    );
  }
}

const selector = formValueSelector("passivoForm");

const mapStateToProps = (state) => ({
  operacao: selector(state, "operacao"),
  formValues: getFormValues('passivoForm')(state),
  formActive: state.passivoReducer.changePassivoFormTab,
  showCancelConfirmDialog: state.confirmDialogReducer.showConfirmDialog,
  messages: state.passivoReducer.messages || [],
  showAprovacao: state.passivoReducer.showModalAprovacao,
  showOnboarding: state.passivoReducer.showModalOnboarding,
  showFinalizacao: state.passivoReducer.showModalFinalizacao,
  passivoOperacao: state.passivoReducer.passivoFormTypes.passivoOperacao || {},
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changePassivoFormTab,
      showPassivoList,
      showConfirmDialog,
      changeDataEmissao,
      showMessageAlert,
      showModalAprovacao,
      showModalOnboarding,
      showModalFinalizacao,
      resetLoadFormPassivo,
      loadFormPassivo,
      confirmarOnboarding,
      limpaDependenteOnChange,
      editPassivo,
    },
    dispatch
  );

const ConnectedPatrimonioForm = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PassivoForm))
);

export default reduxForm({ form: "passivoForm", validate })(
  ConnectedPatrimonioForm
);
