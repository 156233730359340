/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import PaginationRB from "../../../../common/pagination/PaginationRB";
import {
  getRemessa,
  loadFormRemessa,
} from "../../../../../actions/remessa/RemessaAction";
import TableActionWithRouteAuthorization from "../../../../common/table/TableActionWithRouteAuthorization";
import { REMESSA } from "../../../../../utils/actionsAndFeaturesAuth";
import { convertCurrencyNumber } from "../../../../../utils/helpers/NumberHelper";
import { getDateFormatedFromString } from "../../../../../utils/helpers/DateTimeHelper";

const RemessaList = (props) => {
  const {
    getRemessa,
    remessaData,
    remessaConsult,
    messages,
    loadFormRemessa,
  } = props;

  const classTable = "text-center fw-700 d-flex justify-content-center w-100";
  const emptyData = messages?.infos[0];

  const tableHeader = (
    <tr>
      <th>
        <div className={`${classTable}`}>ARQUIVO DE EXPORTAÇÃO</div>
      </th>
      <th>
        <div className={`${classTable}`}>STATUS</div>
      </th>
      <th>
        <div className={`${classTable}`}>RESPONSÁVEL</div>
      </th>
      <th>
        <div className={`${classTable}`}>DATA DE CRIAÇÃO</div>
      </th>
      <th>
        <div className={`${classTable}`}>QTDE LANÇAMENTOS</div>
      </th>
      <th>
        <div className={`${classTable}`}>VALOR TOTAL</div>
      </th>
      <th className="text-center"></th>
    </tr>
  );

  const tableContent =
    remessaData &&
    remessaData?.hasItems &&
    remessaData?.items.map((item, index) => (
      <tr key={index}>
        <td className="text-center fw-400">
          {item?.arquivoRemessa || "--"}
        </td>
        <td className="text-center fw-400">
          {item?.status?.value || "--"}
        </td>
        <td className="text-center fw-400">
          {item?.responsavel || "--"}
        </td>
        <td className="text-center fw-400">
          {item?.dataCriacao ? getDateFormatedFromString(item?.dataCriacao) : "--"}
        </td>
        <td className="text-center fw-400">
          {item?.quantidade || "--"}
        </td>
        <td className="text-center fw-400">
          {item?.valorTotal ? convertCurrencyNumber(item?.valorTotal, 'R$ ', 2) : "--"}
        </td>

        <TableActionWithRouteAuthorization
          arrow={true}
          feature={REMESSA}
          viewAction={() => loadFormRemessa(item.idRemessa)}
          data={{ validarAreaAtuacao: true }}
        />
      </tr>
    ));

  return (
    <div className="container-fluid">
      <div className="p-3">
        {remessaData && remessaData?.items?.length > 0 && (
          <>
            <Table
              withoutHeader
              classes="shadow-custom rounded-12 col-12 mt-4 mb-3 pd-10 bg-white"
              totalItems={remessaData.totalCount}
              pagination={
                <PaginationRB
                  pageNumber={remessaData.pageIndex - 1}
                  itemsPerPage={remessaData.pageSize}
                  totalItems={remessaData.totalCount}
                  filter={remessaConsult}
                  fetchFunction={getRemessa}
                  pageRange={5}
                />
              }
            >
              <TableHeader>{tableHeader}</TableHeader>
              <TableContent>{tableContent}</TableContent>
            </Table>
          </>
        )}

        {remessaData && emptyData && (
          <div className="shadow-custom rounded-12 col-12 mt-4 mb-3 py-5 pd-10 bg-white d-flex align-items-center justify-content-center f-24 fw-400 txt-grey">
            <p className="px-3 py-5 mb-0">{emptyData}</p>
          </div>
        )}
      </div>
    </div>
  );
};

formValueSelector("AprovacaoForm");

const mapStateToProps = (state) => ({
  remessaData: state.remessaReducer.remessa,
  remessaConsult: state.remessaReducer.remessaConsultCurrent,
  messages: state.remessaReducer.messages,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getRemessa,
      loadFormRemessa,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(RemessaList);
