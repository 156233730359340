import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
    tableCell: {
        fontWeight: 500,
        textTransform: 'uppercase !important',
        fontSize: '1em',
    },
});

let TableSelectHeader = (props) => {
    const {
        classes, 
        onSelectAllClick, 
        numSelected, 
        rowCount, 
        dataHeader 
    } = props;

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color='default'
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                    />
                </TableCell>
                {dataHeader.map(item => {
                    return (
                        <TableCell
                            className={classes.tableCell}
                            key={item.id}
                            numeric={item.numeric}
                            padding={item.disablePadding ? 'none' : 'default'}
                        >
                            {item.label}
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}

export default withStyles(styles)(TableSelectHeader);

TableSelectHeader.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
    dataHeader: PropTypes.array.isRequired,
  };