import React from "react";
import { makeStyles } from "@material-ui/styles";
import Icon from '@material-ui/core/Icon';
import Popover from '@material-ui/core/Popover';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: "none"
    },
    paper: {
        padding: theme.spacing.unit * 1
    }
}));

const IconWithSubtitle = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const anchorOriginVertical = props.anchorOriginVertical || 'bottom';
    const anchorOriginHorizontal = props.anchorOriginHorizontal || 'left';
    const transformOriginVertical = props.transformOriginVertical || 'top';
    const transformOriginHorizontal = props.transformOriginHorizontal || 'left';

    return (
        <>
            <Icon
                style={{ color: props.colorIcon ?? 'grey' }}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {props.icon}
            </Icon>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: anchorOriginVertical,
                    horizontal: anchorOriginHorizontal
                }}
                transformOrigin={{
                    vertical: transformOriginVertical,
                    horizontal: transformOriginHorizontal
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography>{props.subtitle}</Typography>
            </Popover>
        </>
    );
}
export default IconWithSubtitle;
