import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Form from '../../../../../common/form/Form';
import FormHeader from '../../../../../common/form/FormHeader';
import FormContent from '../../../../../common/form/FormContent';
import FloatButtonGroup from '../../../../../common/button/FloatButtonGroup';
import FloatButton from '../../../../../common/button/FloatButton';

import { 
    saveAtivosVinculados, 
    showAtivoOperacaoList 
} from '../../../../../../actions/ativo/AtivoAction';
import {
    showConfirmDialog,
} from '../../../../../../actions/common/modal/ConfirmDialogAction'

import AtivoNaoVinculadoPicker from '../../../../pickers/AtivoNaoVinculadoPicker';
import AtivoVinculoOperacaoList from './AtivoVinculoOperacaoList';
import ConfirmDialog from '../../../../../common/modal/ConfirmDialog';

const AtivoOperacaoForm = (props) => {
    const { 
        ativosVinculados, 
        operacoes, 
        saveAtivosVinculados, 
        showAtivoOperacaoList,
        showConfirmDialog,
        showCancelConfirmDialog,
    } = props;

    const operacao = (operacoes && operacoes.length > 0 ) 
        ? operacoes[0].key
        : 0;

    return (
        <Form>
            <FormHeader
                title={'Vincular Ativos à Operação'} 
                codigoOpea={(operacoes && operacoes.length > 0 ) ? operacoes[0].value : ''}
                col={"col-md"} 
                offSet={20}
            />
            <div className="col-md pb-4">
                <FormContent>
                    <div className={'row'}>
                        <AtivoNaoVinculadoPicker
                            col={"12 px-0"}
                            buttonColor={"btn-circle"}
                            icon={"plus"}
                        />
                    </div>
                    <div className={'row'}>
                        <div className={'col-12 px-0'}>
                            <AtivoVinculoOperacaoList dataList={props.list} />
                        </div>
                    </div>
                    <FloatButtonGroup >
                        <FloatButton
                            activeClassName={'nd1 nds'}
                            title={'Cancelar'}
                            icon={'fa fa-ban edit edit-sub-menu'}
                            action={() => showConfirmDialog(true)}
                        />
                        <FloatButton
                            activeClassName={'nd3 nds'} 
                            title={'Salvar'}
                            icon={'material-icons edit edit-sub-menu'}
                            cloudDone={true}
                            action={() => saveAtivosVinculados(ativosVinculados, operacao)}
                        />
                    </FloatButtonGroup>
                </FormContent>
            </div>
            <ConfirmDialog
                title="Aviso"
                contentText="Os dados serão perdidos, deseja realmente sair?"
                textButtonCancel="CANCELAR"
                textButtonConfirm="CONFIRMAR"
                showDialog={showCancelConfirmDialog}
                onClose={() => showConfirmDialog(false)}
                onConfirm={() => showAtivoOperacaoList(true)}
                type="warning"
                maxWidth="md"
            />
        </Form>
    );
}

const mapStateToProps = state => ({
    ativosVinculados: state.tableSelectReduder.selectedItems,
    operacoes: state.operacaoReducer.selectedOperacoes || [],
    list: state.ativoReducer.ativosVinculadoOperacao,
    showCancelConfirmDialog: state.confirmDialogReducer.showConfirmDialog,
});

const mapDispatchToProps = dispatch => bindActionCreators({ 
    saveAtivosVinculados,
    showAtivoOperacaoList,
    showConfirmDialog,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AtivoOperacaoForm);
