import React, { Component } from "react";

export default class Textarea extends Component {
  render() {
    const props = this.props;
    return (
      <div className={`form-group field-floating has-float-label ${props.outerClass} ${props.readOnly ? 'disabled-field pb-2' : ''}`}>
        <textarea
          {...props.input}
          className={`form-control ${props.className}`}
          id={"txtfield_" + props.inputId}
          readOnly={props.readOnly}
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          style={{'minHeight': props.minHeight ? `${props.minHeight}em` : 'none','resize' : props.resize ? props.resize : 'none'}}
          rows={props.rows}
          cols={props.cols}
        />
        <label
          htmlFor={props.name}
          className="control-label"
          id={"lbl_" + props.inputId}
        >
          {props.label}
          {props.required ? <span className="text-warning font-weight-bold ml-1">*</span> : ""}
        </label>
        
        {(props.meta && props.meta.error && props.meta.touched &&
          <p className="error-msg sp-error text-danger f-12 mb-0">{props.meta.error}</p>)
        }
      </div>
    );
  }
}
