import React from "react";
import './style/TextWithToolTip.css'
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

export default (props) => {
    return (
        <Tooltip TransitionComponent={Zoom} title={props.text} placement={props.placement}>
            <div className="text-overflow-ellipsis">
                {props.text}
            </div>
        </Tooltip>
        
    );
}
