import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  getSubclasses,
  removeSubclasse,
  showSubclasseDeleteDialog,
  loadFormSubclasse,
} from "../../../../../actions/subclasse/SubclasseAction";

import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import TableEmptyData from "../../../../common/table/TableEmptyData";
import PaginationRB from "../../../../common/pagination/PaginationRB";
import ConfirmDialog from "../../../../common/modal/ConfirmDialog";
import TableActionWithRouteAuthorization from "../../../../common/table/TableActionWithRouteAuthorization";
import {
  SUBCLASSE,
  DELETE,
} from "../../../../../utils/actionsAndFeaturesAuth";
import { authorizationFunction } from "../../../../../actions/authorization/AuthorizationAction";
import If from "../../../../common/If/If";
import { getDateFormatedFromString } from "../../../../../utils/helpers/DateTimeHelper";
import { convertCurrencyNumber } from "../../../../../utils/helpers/NumberHelper";

class SubclasseList extends Component {
  render() {
    const {
      selectedSubclasses,
      subclasseData,
      messages,
      getSubclasses,
      removeSubclasse,
      showSubclasseDeleteDialog,
      showDeleteDialog,
      loadFormSubclasse,
      authorizationFunction,
    } = this.props;

    const idsSubclasses = selectedSubclasses.map((o) => o.key);
    const emptyData = messages && messages.infos && messages.infos[0];

    const tableHeader = (
      <tr>
        <th className="text-center fw-700">Fundo</th>
        <th className="text-center fw-700">Classe</th>
        <th className="text-center fw-700">Subclasse</th>
        <th className="text-center fw-700">Data Emissão</th>
        <th className="text-center fw-700">Total Distribuído</th>
        <th className="text-center fw-700">Status</th>
        <th className="text-center"></th>
      </tr>
    );

    const tableContent =
      subclasseData && subclasseData.hasItems ? (
        subclasseData.items.map((subclasse) => (
          <tr key={subclasse.id}>
            <td className="text-center fw-400">
              {subclasse.nomeFundo || "--"}
            </td>
            <td className="text-center fw-400">
              {subclasse.nomeClasse || "--"}
            </td>
            <td className="text-center fw-400">
              {subclasse?.subClasse.value || "--"} {subclasse?.codigoSubClasse || ''}
            </td>
            <td className="text-center fw-400">
              {getDateFormatedFromString(subclasse.dataEmissao) || "--"}
            </td>
            <td className="text-center fw-400">
              {convertCurrencyNumber(subclasse.totalDistribuido, "R$", 2) || "--"}
            </td>
            <td className="text-center fw-400">
              {subclasse?.status.value || "--"}
            </td>
            <TableActionWithRouteAuthorization
              feature={SUBCLASSE}
              viewAction={() => loadFormSubclasse(subclasse.id)}
              updateAction={() => loadFormSubclasse(subclasse.id, true)}
            />
          </tr>
        ))
      ) : (
        <TableEmptyData message={emptyData} />
      );

    return (
      <div>
        <If test={subclasseData.totalCount > 0}>
          <Table
            withoutHeader
            classes="shadow-custom rounded-12 col-12 my-3 pd-10 bg-white"
            totalItems={subclasseData.totalCount}
            pagination={
              <PaginationRB
                pageNumber={subclasseData.pageIndex - 1}
                itemsPerPage={subclasseData.pageSize}
                totalItems={subclasseData.totalCount}
                filter={idsSubclasses}
                fetchFunction={getSubclasses}
                pageRange={5}
              />
            }
          >
            <TableHeader>{tableHeader}</TableHeader>
            <TableContent>{tableContent}</TableContent>
          </Table>
        </If>

        <ConfirmDialog
          title="Aviso"
          contentText="Confirmar exclusão do subclasse selecionado?"
          textButtonCancel="CANCELAR"
          textButtonConfirm="CONFIRMAR"
          showDialog={showDeleteDialog.showDialog}
          onClose={() => showSubclasseDeleteDialog(false)}
          onConfirm={() =>
            authorizationFunction(
              { idAcao: DELETE, idFuncionalidade: SUBCLASSE },
              () => removeSubclasse(showDeleteDialog.idSubclasse, idsSubclasses)
            )
          }
          type="warning"
          maxWidth="md"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  showDeleteDialog: state.subclasseReducer.showSubclasseDeleteDialog,
  subclasseData: state.subclasseReducer.subclasses,
  messages: state.subclasseReducer.messages,
  selectedSubclasses: state.subclasseReducer.selectedSubclasseOptions,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getSubclasses,
      removeSubclasse,
      showSubclasseDeleteDialog,
      loadFormSubclasse,
      authorizationFunction,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(SubclasseList)
);
