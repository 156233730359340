import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import Table from "../../../../../common/table/Table";
import TableHeader from "../../../../../common/table/TableHeader";
import TableContent from "../../../../../common/table/TableContent";
import PaginationRB from "../../../../../common/pagination/PaginationRB";

import { getDateFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";

import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import {
  exportPagamentosToExcel,
  getPagamentos,
  loadPagamentoForm,
} from "../../../../../../actions/precificacao/PagamentoAction";
import TableActionWithRouteAuthorization from "../../../../../common/table/TableActionWithRouteAuthorization";
import { PAGAMENTO } from "../../../../../../utils/actionsAndFeaturesAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import { convertCurrencyNumber } from "../../../../../../utils/helpers/NumberHelper";

const PagamentoList = (props) => {
  const {
    getPagamentos,
    pagamentoData,
    pagamentoConsult,
    messages,
    loadPagamentoForm,
    exportPagamentosToExcel,
    filtrosPagamento,
  } = props;

  const tableHeader = (
    <tr>
      <th className="text-center"></th>
      <th>
        <div className="fw-700 d-flex justify-content-start w-100 pl-2">
          Código Fundo
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Fundo
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Administrador Fiduciário
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Evento
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          pagamento
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Responsável
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Pagamento Total PMT
        </div>
      </th>
      <th className="text-center"></th>
    </tr>
  );

  const emptyData = messages?.infos[0];
  let moedaSimbolo = "R$";

  const tableContent =
    pagamentoData &&
    pagamentoData.hasItems &&
    pagamentoData.items.map((item, index) => (
      <tr key={index} className={`tr-status sts-${item.ultimaEtapa.id}`}>
        <td className="text-center">
          {item.alertaSla && (
            <FontAwesomeIcon color="#FFC107" icon={faExclamationTriangle} />
          )}
        </td>
        <td className="text-center td-status">
          <div className="d-flex justify-content-start align-items-center pl-2">
            <div
              data-type="light"
              data-for={`ttp-list-${index}`}
              data-tip={item.ultimaEtapa.descricao}
              className={`circle-status sts-${item.ultimaEtapa.id} rounded-circle mr-2`}
            />
            <ReactTooltip
              noArrow
              effect="solid"
              id={`ttp-list-${index}`}
              className="px-2 py-1"
              globalEventOff="click"
              style={{ width: "100px" }}
            />
            <span className="fw-400 mt-1 mx-0 mb-0">
              {item.codigoOpea || "--"}
            </span>
          </div>
        </td>
        <td className="text-center fw-400">{item.fundo || "--"}</td>
        <td className="text-center fw-400">
          {item.administradorFiduciario || "--"}
        </td>
        <td className="text-center fw-400">
          {getDateFormatedFromString(item.dataEvento) || "--"}
        </td>
        <td className="text-center fw-400">
          {getDateFormatedFromString(item.dataPagamento) || "--"}
        </td>
        <td className="text-center fw-400">{item.responsavelGestao || "--"}</td>
        <td className="text-center fw-400">
          {convertCurrencyNumber(item.pagamentoTotalPmt, item.simboloMoeda || moedaSimbolo, 2) || "--"}
        </td>
        <TableActionWithRouteAuthorization
          arrow={true}
          feature={PAGAMENTO}
          updateAction={() => {
            const idPagamento =
              item.idPagamentoPrecificacao ?? item.idPrecificacao;
            loadPagamentoForm(idPagamento);
          }}
        />
      </tr>
    ));

  return (
    <div className="container-fluid">
      <div className="p-3">
        {pagamentoData.totalCount > 0 && (
          <Table
            withoutHeader
            fileExcel={() => exportPagamentosToExcel(filtrosPagamento)}
            classes="shadow-custom rounded-12 col-12 mt-4 mb-3 pd-10 bg-white"
            totalItems={pagamentoData.totalCount}
            pagination={
              <PaginationRB
                pageNumber={pagamentoData.pageIndex - 1}
                itemsPerPage={pagamentoData.pageSize}
                totalItems={pagamentoData.totalCount}
                filter={pagamentoConsult}
                fetchFunction={getPagamentos}
                pageRange={5}
              />
            }
          >
            <TableHeader>{tableHeader}</TableHeader>
            <TableContent>{tableContent}</TableContent>
          </Table>
        )}

        {pagamentoData.totalCount <= 0 && emptyData && (
          <div className="shadow-custom rounded-12 col-12 mt-4 mb-3 py-5 pd-10 bg-white d-flex align-items-center justify-content-center f-24 fw-400 txt-grey">
            <p className="px-3 py-5 mb-0">{emptyData}</p>
          </div>
        )}
      </div>
    </div>
  );
};

formValueSelector("PagamentoForm");

const mapStateToProps = (state) => ({
  pagamentoData: state.pagamentoReducer.pagamentos,
  pagamentoConsult: state.pagamentoReducer.pagamentosConsultCurrent,
  messages: state.pagamentoReducer.messages,
  filtrosPagamento: state.pagamentoReducer.filtrosPagamento,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getPagamentos,
      loadPagamentoForm,
      exportPagamentosToExcel,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(PagamentoList);
