import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import SubjectIcon from "@material-ui/icons/Subject";

import PaginationRB from "../../../../common/pagination/PaginationRB";

import {
  ORDEM_APROVADA,
  ORDEM_REJEITADA,
  getOrdens,
  showModalDescricaoOrdem,
} from "../../../../../actions/assembleia/AssembleiaAction";
import { formatDecimal } from "../../../../../utils/helpers/NumberHelper";
import EventLogbookModal from "../../../gestao/pages/diarioBordo/modal/EventLogbookModal";
import TableHeader from "../../../../common/table/TableHeader";
import Table from "../../../../common/table/Table";
import TableContent from "../../../../common/table/TableContent";

const AssembleiaGerenciarResumoList = (props) => {
  return (
    <MuiThemeProvider>
      <div className="monitor-workflow px-3 w-100">
        <div className="monitor-workflow-table-field h-100 px-2 mx-1">
          <Table
            withoutHeader
            classes="shadow-custom rounded-12 col-12 my-3 pd-10 bg-white"
            totalItems={props.ordemList.totalCount}
            pagination={
              <PaginationRB
                pageNumber={props.ordemList.pageIndex - 1}
                itemsPerPage={props.ordemList.pageSize}
                totalItems={props.ordemList.totalCount}
                filter={props.idAssembleia}
                fetchFunction={props.getOrdens}
                pageRange={5}
              />
            }
          >
            <TableHeader>
              <tr>
                <th className="text-center fw-700 text-nowrap">Título</th>
                <th className="text-center fw-700 text-nowrap">Tipo quórum</th>
                <th className="text-center fw-700 text-nowrap">
                  Quórum de aprovação
                </th>
                <th className="text-center fw-700 text-nowrap">Aprovação</th>
                <th className="text-center fw-700 text-nowrap">Rejeição</th>
                <th className="text-center fw-700 text-nowrap">Abstenção</th>
                <th className="text-center fw-700 text-nowrap">%Aprovação</th>
                <th className="text-center fw-700 text-nowrap">Situação</th>
                <th className="text-center fw-700 text-nowrap">Descrição</th>
              </tr>
            </TableHeader>
            <TableContent>
              {props.ordemList.items.map((item, idx) => {
                let key = "monitor_workflow_table_item__" + idx;
                let statusColor = "grey";
                if (item.situacao) {
                  if (ORDEM_APROVADA === item.situacao.key)
                    statusColor = "lightgreen";
                  else if (ORDEM_REJEITADA === item.situacao.key)
                    statusColor = "darkred";
                }

                return (
                  <tr key={key}>
                    <td
                      className={
                        "text-center fw-400 text-nowrap border " + statusColor
                      }
                    >
                      {item.titulo}
                    </td>
                    <td className="text-center fw-400 text-nowrap">
                      {item.tipoQuorum ? item.tipoQuorum.value : ""}
                    </td>
                    <td className="text-center fw-400 text-nowrap">
                      {item.quorumAprovacao ? item.quorumAprovacao.value : ""}
                    </td>
                    <td className="text-center fw-400 text-nowrap">
                      {item.aprovacao}
                    </td>
                    <td className="text-center fw-400 text-nowrap">
                      {item.rejeicao}
                    </td>
                    <td className="text-center fw-400 text-nowrap">
                      {item.abstinencia}
                    </td>
                    <td className="text-center fw-400 text-nowrap">
                      {item.percentualAprovacao
                        ? `${formatDecimal(item.percentualAprovacao, 2)}%`
                        : "0,00%"}
                    </td>
                    <td className="text-center fw-400 text-nowrap">
                      {item.situacao ? item.situacao.raw : ""}
                    </td>
                    <td className="text-center fw-400 text-nowrap">
                      <SubjectIcon
                        className="pointer p-color-imp f-22"
                        onClick={() =>
                          props.showModalDescricaoOrdem(
                            true,
                            item.titulo,
                            item.descricao
                          )
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </TableContent>
          </Table>

          <EventLogbookModal
            className="modal-descricao-ordem"
            title={props.dialogDescricao ? props.dialogDescricao.titulo : ""}
            open={
              props.dialogDescricao ? props.dialogDescricao.showDialog : false
            }
            onClose={() => props.showModalDescricaoOrdem(false)}
          >
            {props.dialogDescricao ? props.dialogDescricao.descricao : ""}
          </EventLogbookModal>
        </div>
      </div>
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  tab: state.tab,
  ordemList: state.assembleiaReducer.assembleiaDadosGerenciar.ordemList || null,
  dialogDescricao: state.assembleiaReducer.showDialogDescricaoOrdem || null,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOrdens,
      showModalDescricaoOrdem,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssembleiaGerenciarResumoList);
