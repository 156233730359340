/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import If from "../If/If";

import { authorizationFunction } from "../../../actions/authorization/AuthorizationAction";
import { UPDATE, READ } from "../../../utils/actionsAndFeaturesAuth";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const TableActionWithRouteAuthorization = (props) => {
  const authDataUpdate = { idAcao: UPDATE, idFuncionalidade: props.feature, ...props?.data };
  const authDataRead = { idAcao: READ, idFuncionalidade: props.feature, ...props?.data };
  return (
    <td className="text-center wdth-125">
      <div className="d-flex justify-content-center">
        <If test={props.updateAction}>
          <a
            className={`view pointer mx-${props.space || '3'} p-color-imp f-22`}
            data-toggle="tooltip"
            data-placement="top"
            data-original-title="Editar"
            onClick={() => props.authorizationFunction(authDataUpdate, () => props.updateAction())}
            container="body"
          >
            <If test={props.arrow}>
              <FontAwesomeIcon color='#630a37' icon={faChevronRight} />
            </If>
            <If test={!props.arrow}>
              <i className="fa fa-pencil" aria-hidden="true" />
            </If>
          </a>
        </If>

        <If test={props.remove}>
          <a
            className={`view pointer mx-${props.space || '3'} p-color-imp f-22`}
            data-toggle="tooltip"
            data-placement="top"
            data-original-title="Remover"
            onClick={props.remove}
            container="body"
          >
            <If test={props.arrow}>
              <FontAwesomeIcon color='#630a37' icon={faChevronRight} />
            </If>
            <If test={!props.arrow}>
              <i className="fa fa-trash" aria-hidden="true" />
            </If>
          </a>
        </If>

        <If test={props.viewAction}>
          <a
            className={`view pointer mx-${props.space || '3'} p-color-imp f-22`}
            data-toggle="tooltip"
            data-placement="top"
            data-original-title="Visualizar"
            onClick={() => props.authorizationFunction(authDataRead, () => props.viewAction())}
            container="body"
          >
            <If test={props.arrow}>
              <FontAwesomeIcon color='#630a37' icon={faChevronRight} />
            </If>
            <If test={!props.arrow}>
              <i className="fa fa-eye" aria-hidden="true" />
            </If>
          </a>
        </If>

        <If test={props.gerarPrecificacao}>
          <a
            className={`view pointer mx-${props.space || '3'} p-color-imp f-22`}
            data-toggle="tooltip"
            data-placement="top"
            data-original-title="Gerar Precificação"
            onClick={props.gerarPrecificacao}
            container="body"
          >
            <If test={props.arrow}>
              <FontAwesomeIcon color='#630a37' icon={faChevronRight} />
            </If>
            <If test={!props.arrow}>
              <i className="fa fa-usd" aria-hidden="true" />
            </If>
          </a>
        </If>

        <If test={props.excelAction?.callback}>
          <Tooltip TransitionComponent={Zoom} title={props.excelAction?.tooltipTitle}>
            <span>
              <a
                className={[`view pointer mx-${props.space || '3'} p-color-imp btn-link f-22`, props.excelAction?.disabled ? "disabled" : null].join(" ")}
                data-toggle="tooltip"
                data-placement="top"
                data-original-title="Exportar Excel"
                onClick={props.excelAction?.callback}
                container="body"
              >
                <If test={props.arrow}>
                  <FontAwesomeIcon color='#630a37' icon={faChevronRight} />
                </If>
                <If test={!props.arrow}>
                  <i className="fa fa-file-excel-o" aria-hidden="true" />
                </If>
              </a>
            </span>
          </Tooltip>
        </If>
      </div>
    </td>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ authorizationFunction }, dispatch);
export default connect(null, mapDispatchToProps)(TableActionWithRouteAuthorization);
