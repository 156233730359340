import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ContainerPage from "../../../common/container/ContainerPage";

import { cleanResult, loadFilterFormAssembleia } from '../../../../actions/assembleia/AssembleiaAction';
import AssembleiaFilter from "./form/AssembleiaFilter";
import AssembleiaList from "./list/AssembleiaList";


class AssembleiaPage extends Component {

    componentDidMount() {
        this.props.cleanResult();
        this.props.loadFilterFormAssembleia();
    }

    render() {
        return (
            <ContainerPage>
               <AssembleiaFilter />
               <AssembleiaList />
            </ContainerPage>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
    cleanResult, 
    loadFilterFormAssembleia 
}, dispatch);

export default connect(null, mapDispatchToProps)(AssembleiaPage);
