/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ContainerPage from "../../../../common/container/ContainerPage";
import OpcaoListFilter from "./OpcaoListFilter";
import MoedaList from "./moeda/list/MoedaList";
import SeloList from "./selo/list/SeloList";
import TipoAprovacaoList from "./tipoAprovacao/list/TipoAprovacaoList";
import ProdutoList from "./produto/list/ProdutoList";
import MovimentacaoFinanceiraList from "./movimentacaoFinanceira/list/MovimentacaoFinanceiraList";
import NaturezaList from "./natureza/list/NaturezaList";
import BancosList from "./bancos/list/BancosList";

import { cleanResult } from "../../../../../actions/precificacao/OpcaoAction";

class OpcaoPage extends Component {
  componentDidMount() {
    this.props.cleanResult();
  }

  componentDidUpdate() {
    this.props.cleanResult();
  }

  render() {
    return (
      <ContainerPage>
        <OpcaoListFilter />
        {this.props.tipo == "moeda" && <MoedaList />}
        {this.props.tipo == "selo" && <SeloList />}
        {this.props.tipo == "tipo-aprovacao" && <TipoAprovacaoList />}
        {this.props.tipo == "produto" && <ProdutoList />}
        {this.props.tipo == "movimentacao-financeira" && <MovimentacaoFinanceiraList /> }
        {this.props.tipo == "natureza" && <NaturezaList />}
        {this.props.tipo == "banco-externo" && <BancosList />}
      </ContainerPage>
    );
  }
}

const mapStateToProps = (state) => ({
  tipo: state.opcaoReducer.tipo,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      cleanResult,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OpcaoPage);
