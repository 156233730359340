import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import TableEmptyData from "../../../../common/table/TableEmptyData";
import PaginationRB from "../../../../common/pagination/PaginationRB";
import FloatButton from "../../../../common/button/FloatButton";
import ConfirmDialog from "../../../../common/modal/ConfirmDialog";

import {
  getUsuarios,
  showForm,
  showUsuarioPerfilDeleteDialog,
  removerPerfisDoUsuario,
  loadFormUsuario,
} from "../../../../../actions/usuario/UsuarioAction";

import { getDateFormatedFromString } from "../../../../../utils/helpers/DateTimeHelper";
import TableActionWithRouteAuthorization from "../../../../common/table/TableActionWithRouteAuthorization";
import {
  USUARIOS,
  CREATE,
  DELETE,
} from "../../../../../utils/actionsAndFeaturesAuth";
import { authorizationFunction } from "../../../../../actions/authorization/AuthorizationAction";
import If from "../../../../common/If/If";

const UsuarioList = (props) => {
  const emptyData =
    props.messages && props.messages.infos && props.messages.infos[0];

  const tableContent =
    props.usuarios && props.usuarios.hasItems ? (
      props.usuarios.items.map((item, index) => (
        <tr key={index}>
          <td className="text-center fw-400 text-nowrap">{item.usuario}</td>
          <td className="text-center fw-400 text-nowrap">{item.perfil}</td>
          <td className="text-center fw-400 text-nowrap">
            {getDateFormatedFromString(item.dataUltimoAcesso)}
          </td>
          <TableActionWithRouteAuthorization
            updateAction={() => props.loadFormUsuario(item.idUsuario, true)}
            viewAction={() => props.loadFormUsuario(item.idUsuario)}
            remove={() =>
              props.showUsuarioPerfilDeleteDialog(true, item.idUsuario)
            }
            feature={USUARIOS}
          />
        </tr>
      ))
    ) : (
      <TableEmptyData message={emptyData} />
    );

  return (
    <div className="px-4 w-100 mt-4 mx-2">
      <If test={props.usuarios.totalCount > 0}>
        <Table
          withoutHeader
          classes="shadow-custom rounded-12 col-12 mb-4 mt-0 pd-10 bg-white"
          totalItems={props.usuarios.totalCount}
          pagination={
            <PaginationRB
              pageNumber={props.usuarios.pageIndex - 1}
              itemsPerPage={props.usuarios.pageSize}
              totalItems={props.usuarios.totalCount}
              filter={{ usuario: props.usuario, perfil: props.perfil }}
              fetchFunction={props.getUsuarios}
              pageRange={5}
            />
          }
        >
          <TableHeader>
            <tr>
              <th className="text-center fw-700 text-nowrap">Usuário</th>
              <th className="text-center fw-700 text-nowrap">Perfis</th>
              <th className="text-center fw-700 text-nowrap">
                Data do último acesso
              </th>
              <th className="text-center fw-700 text-nowrap"></th>
            </tr>
          </TableHeader>
          <TableContent>{tableContent}</TableContent>
        </Table>
      </If>
      <FloatButton
        title="Novo"
        activeClassName={"float"}
        icon={"fa fa-plus my-float"}
        action={() =>
          props.authorizationFunction(
            { idAcao: CREATE, idFuncionalidade: USUARIOS },
            () => props.showForm()
          )
        }
      />
      <ConfirmDialog
        title="Aviso"
        contentText="Você realmente deseja excluir os perfis do usuário selecionado ?"
        textButtonCancel="CANCELAR"
        textButtonConfirm="CONFIRMAR"
        showDialog={props.showDeleteDialog.showDialog}
        onClose={() => props.showUsuarioPerfilDeleteDialog(false)}
        onConfirm={() =>
          props.authorizationFunction(
            { idAcao: DELETE, idFuncionalidade: USUARIOS },
            () => props.removerPerfisDoUsuario(props.showDeleteDialog.idUsuario)
          )
        }
        type="warning"
        maxWidth="md"
      />
    </div>
  );
};

const selector = formValueSelector("usuarioFilterForm");

const mapStateToProps = (state) => ({
  usuarios: state.usuarioReducer.usuarios || [],
  messages: state.usuarioReducer.messages,
  showDeleteDialog: state.usuarioReducer.showUsuarioDeleteDialog,
  usuario: selector(state, "usuario"),
  perfil: selector(state, "perfil"),
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getUsuarios,
      showForm,
      showUsuarioPerfilDeleteDialog,
      removerPerfisDoUsuario,
      loadFormUsuario,
      authorizationFunction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(UsuarioList);
