import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, Field } from 'redux-form';

import { HORA, CEP, formatHora, formatCEP } from '../../../../../utils/helpers/StringHelper';
import { getArrayUF } from '../../../../../utils/helpers/ArrayHelper';

import ButtonMuiTheme from '../../../../common/button/ButtonMuiTheme';
import If from '../../../../common/If/If';
import Input from '../../../../common/input/Input';
import MaskedInput from '../../../../common/input/MaskedInput';
import MaskedFormatInput from '../../../../common/input/MaskedFormatInput';
import DropdownListInput from '../../../../common/input/DropdownListInput';
import LinkInput from '../../../../common/input/LinkInput';

import EventLogbookModal from '../../../gestao/pages/diarioBordo/modal/EventLogbookModal';

import {
    showDialogSuspenderAssembleia,
    suspenderAssembleia,
    TIPO_ASSEMBLEIA_PRESENCIAL,
    TIPO_ASSEMBLEIA_REMOTO,
    TIPO_ASSEMBLEIA_AMBOS,
} from '../../../../../actions/assembleia/AssembleiaAction';
import { authorizationFunction } from '../../../../../actions/authorization/AuthorizationAction';

import { ASSEMBLEIA, UPDATE } from '../../../../../utils/actionsAndFeaturesAuth';


let SuspenderAssembleiaModal = (props) => {
    const {
        idAssembleia,
        dataAssembleia,
        hora,
        linkAssembleia,
        endereco,
        tipoLocalizacao,
        tipoLocalizacaoAssembleiaList,
        listUF,
    } = props;

    return (
        <EventLogbookModal
            title={'Suspender assembleia - Informar os dados da nova assembleia'}
            open={props.modalSuspenderAssembleia.showDialog}
            dialogSize="xl"
            titleClass="title-modal-investidor"
            disableBackdropClick
            onClose={() => props.showDialogSuspenderAssembleia(false)}>
            <div className="monitor-workflow pb-4">
                <div className="monitor-workflow-head w-100 pb-3">
                    <div className="row pt-2">
                        <div className="col-lg-2 ml-3">
                            <Field
                                component={Input}
                                className={"filter-input"}
                                inputId="dataAssembleia"
                                name={`dataAssembleia`}
                                type={"date"}
                                label="Data"
                            />
                        </div>
                        <div className="col-lg-1">
                            <Field
                                maxLength="5"
                                name={'hora'}
                                inputId="hora"
                                label="Horário"
                                formatMask={formatHora}
                                className={"filter-input"}
                                component={MaskedFormatInput}
                                placeholder={HORA.placeholder}
                            />
                        </div>
                        <div className="col-lg-2">
                            <Field
                                component={DropdownListInput}
                                inputId="tipoLocalizacao"
                                name="tipoLocalizacao"
                                label="Tipo Localização"
                                options={tipoLocalizacaoAssembleiaList}
                                textField="value"
                                valueField="key"
                                filter="contains"
                            />
                        </div>
                        <If test={tipoLocalizacao && (tipoLocalizacao.key === TIPO_ASSEMBLEIA_REMOTO || tipoLocalizacao.key === TIPO_ASSEMBLEIA_AMBOS)}>
                            <div className="col-lg-6">
                                <Field
                                    copyLink
                                    component={LinkInput}
                                    inputId="linkAssembleia"
                                    name={"linkAssembleia"}
                                    label={"Link acesso conferência"}
                                    className={"filter-input"}
                                    maxLength={255}
                                />
                            </div>
                        </If>
                    </div>
                    <If test={tipoLocalizacao && (tipoLocalizacao.key === TIPO_ASSEMBLEIA_PRESENCIAL || tipoLocalizacao.key === TIPO_ASSEMBLEIA_AMBOS)}>
                        <div className="row">
                            <div className="col-lg-10 ml-3" style={{ fontWeight: "bold", marginTop: "15px" }}>
                                <span>Endereço</span>
                            </div>
                            <div className="col-lg-5 ml-3">
                                <Field
                                    component={Input}
                                    inputId="logradouro"
                                    name={'endereco.logradouro'}
                                    label="Logradouro"
                                    maxLength={120}
                                    type="text"
                                    className={"filter-input"}
                                />
                            </div>
                            <div className="col-lg-1">
                                <Field
                                    component={MaskedInput}
                                    inputId="numero"
                                    name={'endereco.numero'}
                                    label="Número"
                                    className={"filter-input"}
                                    maxLength={10}
                                    allowNegative={false}
                                    decimalScale={0}
                                />
                            </div>

                            <div className="col-lg-3">
                                <Field
                                    component={Input}
                                    inputId="complemento"
                                    name={'endereco.complemento'}
                                    label="Complemento"
                                    className={"filter-input"}
                                    maxLength={30}
                                />
                            </div>
                            <div className="col-lg-2">
                                <Field
                                    label="CEP"
                                    inputId="cep"
                                    maxLength="9"
                                    name={'endereco.cep'}
                                    formatMask={formatCEP}
                                    className={"filter-input"}
                                    component={MaskedFormatInput}
                                    placeholder={CEP.placeholder}
                                />
                            </div>
                            <div className="col-lg-2 ml-3">
                                <Field
                                    component={Input}
                                    inputId="bairro"
                                    name={'endereco.bairro'}
                                    label="Bairro"
                                    className={"filter-input"}
                                    maxLength={30}
                                />
                            </div>
                            <div className="col-lg-3">
                                <Field
                                    component={Input}
                                    inputId="cidade"
                                    name={'endereco.cidade'}
                                    label="Cidade"
                                    className={"filter-input"}
                                    maxLength={30}
                                />
                            </div>

                            <div className="col-lg-2">
                                <Field
                                    component={DropdownListInput}
                                    inputId="estado"
                                    name="endereco.estado"
                                    label="Estado"
                                    options={listUF}
                                    filter="contains"
                                    textField="value"
                                    valueField="key"
                                />
                            </div>
                        </div>
                    </If>
                </div>
            </div>
            <hr className="hr-modal-investidor" />
            <div className="row pr-3 pt-4 justify-content-end">
                <div className="mr-3">
                    <ButtonMuiTheme
                        textColor={"#630A37"}
                        mainColor={"#79C1DB"}
                        action={() => props.authorizationFunction(
                            { idAcao: UPDATE, idFuncionalidade: ASSEMBLEIA },
                            () => props.suspenderAssembleia({ idAssembleia, dataAssembleia, hora, tipoLocalizacao, linkAssembleia, endereco })
                        )}
                        name="Salvar"
                    />
                </div>
                <div>

                    <ButtonMuiTheme
                        textColor={"#333333"}
                        mainColor={"#D0D0D0"}
                        action={() => props.showDialogSuspenderAssembleia(false)}
                        name="Cancelar"
                    />
                </div>
            </div>
        </EventLogbookModal >
    );
}

SuspenderAssembleiaModal = reduxForm({ form: 'suspenderAssembleiaForm', destroyOnUnmount: true })(SuspenderAssembleiaModal);
const selector = formValueSelector('suspenderAssembleiaForm');
const mapStateToProps = state => ({
    idAssembleia: selector(state, 'idAssembleia'),
    dataAssembleia: selector(state, 'dataAssembleia'),
    hora: selector(state, 'hora'),
    tipoLocalizacao: selector(state, "tipoLocalizacao"),
    linkAssembleia: selector(state, "linkAssembleia"),
    endereco: selector(state, "endereco"),
    modalSuspenderAssembleia: state.assembleiaReducer.showSuspenderAssembleiaDialog || null,
    tipoLocalizacaoAssembleiaList: state.assembleiaReducer.assembleiaFormTypes.tipoLocalizacaoAssembleiaList,
    listUF: getArrayUF(),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    showDialogSuspenderAssembleia,
    suspenderAssembleia,
    authorizationFunction
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SuspenderAssembleiaModal);
