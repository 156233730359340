/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
/* no-mixed-operators: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm } from "redux-form";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";

import Button from "../../../../common/button/Button";

import {
  salvaFiltrosAprovadores,
  getAprovadores,
  loadFilterFormAprovadores,
} from "../../../../../actions/aprovadores/AprovadoresAction";

import {
  getOperacoes,
  selectOperacaoNoForm,
} from "../../../../../actions/operacao/OperacaoAction";
import { DropdownList } from "react-widgets";

let AprovadoresListFilter = (props) => {
  const {
    getAprovadores,
    salvaFiltrosAprovadores,
    aprovadoresConsult,
    tiposAprovacoesList,
    usuariosList,
    loadFilterFormAprovadores,
  } = props;

  const [idUsuario, setIdUsuario] = React.useState(null);
  const [idTipoAprovacao, setIdTipoAprovacao] = React.useState(null);
  const [ativo, setAtivo] = React.useState(null);

  async function searchFilters() {
    const data = {
      idUsuario,
      idTipoAprovacao,
      ativo,
    };

    await getAprovadores(data);
  }

  React.useEffect(() => {
    async function load() {
      await loadFilterFormAprovadores();
      if (!aprovadoresConsult) searchFilters();
    }

    load();
  }, []);

  // prettier-ignore
  React.useEffect(() => {
    salvaFiltrosAprovadores({
      idUsuario,
      idTipoAprovacao,
      ativo,
    })

  }, [idUsuario, idTipoAprovacao, ativo]);

  const messages = {
    emptyList: "Nenhum registro encontrado",
    emptyFilter: "Nenhum registro encontrado com esse filtro",
  };

  return (
    <div className="container-fluid">
      <Form>
        <FormHeader title={"Aprovadores"} />
        <FormContent
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
          handleSubmit={getAprovadores}
        >
          <div className="row w-100 align-items-start mx-0 mt-2">
            <div className="col-xl align-self-start mb-2 pl-0 pr-0 pr-xl-3">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p mt-2p lh-normal opacity-25 text-dark">
                  Usuário
                </label>
                <DropdownList
                  value={idUsuario ? idUsuario : ""}
                  valueField="id"
                  textField="nome"
                  data={[
                    { id: null, nome: "Selecione..." },
                    ...usuariosList
                  ]}
                  placeholder={"Todos"}
                  filter="contains"
                  containerClassName="h-38-imp"
                  messages={messages}
                  onChange={(e) => setIdUsuario(e.id)}
                />
              </div>
            </div>

            <div className="col-xl align-self-start mb-2 px-0">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p mt-2p lh-normal opacity-25 text-dark">
                  Tipo
                </label>
                <DropdownList
                  value={idTipoAprovacao ? idTipoAprovacao : ""}
                  valueField="id"
                  textField="tipo"
                  data={[
                    { id: null, tipo: "Selecione..." },
                    ...tiposAprovacoesList
                  ]}
                  placeholder={"Todos"}
                  filter="contains"
                  containerClassName="h-38-imp"
                  messages={messages}
                  onChange={(e) => setIdTipoAprovacao(e.id)}
                />
              </div>
            </div>

            <div className="col-xl align-self-start mb-2 pl-0 pl-xl-3 pr-0">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p mt-2p lh-normal opacity-25 text-dark">
                  Status
                </label>
                <DropdownList
                  value={ativo ? ativo : ""}
                  valueField="key"
                  textField="value"
                  data={[
                    { key: null, value: "Selecione..." },
                    { key: true, value: "Ativo" },
                    { key: false, value: "Inativo" },
                  ]}
                  placeholder={"Todos"}
                  filter="contains"
                  containerClassName="h-38-imp"
                  messages={messages}
                  onChange={(e) => e.key == null ? setAtivo(null) : setAtivo(e.key)}
                />
              </div>
            </div>
          </div>

          <div className="col-xl-2 col-12 px-0 mt-0">
            <Button
              activeClassName="w-100"
              action={() => searchFilters()}
              classes="p-bg-imp rounded btn-search h-38 mt-3 w-100 d-flex justify-content-center"
              name={"Buscar"}
            />
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

AprovadoresListFilter = reduxForm({ form: "AprovadoresForm" })(AprovadoresListFilter);

const mapStateToProps = (state) => ({
  tiposAprovacoesList: state.aprovadoresReducer.aprovadoresFormTypesFilter.tipoAprovacoes || [],
  usuariosList: state.aprovadoresReducer.aprovadoresFormTypesFilter.usuarios || [],
  aprovadoresConsult: state.aprovadoresReducer.aprovadoresConsultCurrent,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAprovadores,
      salvaFiltrosAprovadores,
      getOperacoes,
      selectOperacaoNoForm,
      loadFilterFormAprovadores,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AprovadoresListFilter);
