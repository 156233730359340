/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import Table from "../../../../../common/table/Table";
import TableHeader from "../../../../../common/table/TableHeader";
import TableContent from "../../../../../common/table/TableContent";
import PaginationRB from "../../../../../common/pagination/PaginationRB";

import { getDateFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";
import {
  formatStatusPGMTInverted,
  formatStatusPGMT,
} from "../../../../../../utils/helpers/OthersHelpers";

import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import {
  exportPagamentosToExcel,
  getPagamentos,
  loadPagamentoForm,
} from "../../../../../../actions/precificacao/PagamentoAction";
import TableActionWithRouteAuthorization from "../../../../../common/table/TableActionWithRouteAuthorization";
import { PAGAMENTO } from "../../../../../../utils/actionsAndFeaturesAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import {
  convertCurrencyNumber,
  formatDecimal,
} from "../../../../../../utils/helpers/NumberHelper";

const PagamentoList = (props) => {
  const {
    getPagamentos,
    pagamentoData,
    pagamentoConsult,
    indicadores,
    messages,
    loadPagamentoForm,
    exportPagamentosToExcel,
    filtrosPagamento,
  } = props;

  const tableHeader = (
    <tr>
      <th className="text-center"></th>
      <th>
        <div className="fw-700 d-flex justify-content-start w-100 pl-2">
          Código Opea
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Câmara de Liquidação
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Evento
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Pagamento
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Responsável
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Banco Liquidante
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Pagamento Total PMT
        </div>
      </th>
      <th className="text-center"></th>
    </tr>
  );

  const emptyData = messages?.infos[0];

  function indicadorBase(ind, mb = true) {
    return (
      <div
        key={`indicador-${ind.status}`}
        className={`w-100 shadow-custom rounded-8 bg-white mx-lg-1 mb-lg-0 p-2 overflow-hidden border-bottom-card-pgmt-base ${ind.status} mb-${mb ? '3' : '0'}`}
      >
        <p className="d-flex flex-row align-items-center justify-content-end card-pgmt-warn">
          {ind.quantidadeAlerta > 0 && (
            <>
              <FontAwesomeIcon color="#FFC107" icon={faExclamationTriangle} />
              <span className="mt-1 ml-1">
                {formatDecimal(ind.quantidadeAlerta, 0)}
              </span>
            </>
          )}
        </p>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p className="fw-500">{formatDecimal(ind.quantidade, 0)}</p>
          <div
            data-type="opea"
            data-for={`ttp-${ind.status}`}
            className="text-nowrap overflow-hidden text-overflow-ellipsis"
            data-tip={`R$ ${convertCurrencyNumber(ind.valor, false, 2)}`}
          >
            <p className="fw-400 text-nowrap overflow-hidden text-overflow-ellipsis text-center">
              R$ {convertCurrencyNumber(ind.valor, false, 2)}
            </p>
            <ReactTooltip
              effect="solid"
              className="px-2 py-1"
              globalEventOff="click"
              id={`ttp-${ind.status}`}
              style={{ width: "100px" }}
            />
          </div>
          <span className="fw-500 text-nowrap">
            {formatStatusPGMT(formatStatusPGMTInverted(ind.status))}
          </span>
        </div>
      </div>
    );
  }

  function indicadorOuter(ind, mb = true) {
    return (
      <div
        key={`indicador-${ind.status}`}
        className={`d-flex flex-column justify-content-between w-100 h-100 shadow-custom rounded-8 bg-white border-left-card-pgmt-base ${ind.status} px-3 py-2 mb-${mb ? '3' : '0'}`}
      >
        <div className="d-flex flex-row align-items-lg-center justify-content-between">
          <span className="fw-400 mr-2">
            {formatStatusPGMT(formatStatusPGMTInverted(ind.status))}
          </span>
          {ind.quantidadeAlerta > 0 && (
            <span className="d-flex flex-row align-items-center justify-content-end card-pgmt-warn">
              <FontAwesomeIcon color="#FFC107" icon={faExclamationTriangle} />
              <span className="mt-1 ml-1">
                {formatDecimal(ind.quantidadeAlerta, 0)}
              </span>
            </span>
          )}
        </div>
        <div className="d-flex flex-xl-row flex-column align-items-xl-end justify-content-xl-between justify-content-start mb-1 mt-2">
          <span className="f-22 lh-22 fw-400 text-nowrap mb-xl-0 mb-2 mr-2">
            {formatDecimal(ind.quantidade, 0)}
          </span>
          <div
            data-type="opea"
            data-for={`ttp-${ind.status}`}
            className="text-nowrap overflow-hidden text-overflow-ellipsis text-xl-right text-left"
            data-tip={`R$ ${convertCurrencyNumber(ind.valor, false, 2)}`}
          >
            <span className="f-12 lh-12 fw-400 text-nowrap overflow-hidden w-auto text-overflow-ellipsis text-xl-right text-left">
              R$ {convertCurrencyNumber(ind.valor, false, 2)}
            </span>
            <ReactTooltip
              effect="solid"
              className="px-2 py-1"
              globalEventOff="click"
              id={`ttp-${ind.status}`}
              style={{ width: "100px" }}
            />
          </div>
        </div>
      </div>
    );
  }

  const tableContent =
    pagamentoData &&
    pagamentoData.hasItems &&
    pagamentoData.items.map((item, index) => (
      <tr key={index} className={`tr-status sts-${item.ultimaEtapa.id}`}>
        <td className="text-center">
          {item.alertaSla && (
            <FontAwesomeIcon color="#FFC107" icon={faExclamationTriangle} />
          )}
        </td>
        <td className="text-center td-status">
          <div className="d-flex justify-content-start align-items-center pl-2">
            <div
              data-type="light"
              data-for={`ttp-list-${index}`}
              data-tip={item.ultimaEtapa.descricao}
              className={`circle-status sts-${item.ultimaEtapa.id} rounded-circle mr-2`}
            />
            <ReactTooltip
              noArrow
              effect="solid"
              id={`ttp-list-${index}`}
              className="px-2 py-1"
              globalEventOff="click"
              style={{ width: "100px" }}
            />
            <span className="fw-400 mt-1 mx-0 mb-0">
              {item.codigoOpea || "--"}
            </span>
          </div>
        </td>
        <td className="text-center fw-400">{item.camaraLiquidacao || "--"}</td>
        <td className="text-center fw-400">
          {getDateFormatedFromString(item.dataEvento) || "--"}
        </td>
        <td className="text-center fw-400">
          {getDateFormatedFromString(item.dataPagamento) || "--"}
        </td>
        <td className="text-center fw-400">{item.responsavelGestao || "--"}</td>
        <td className="text-center fw-400">{item.bancoLiquidante || "--"}</td>
        <td className="text-center fw-400">{convertCurrencyNumber(item.pagamentoTotalPmt, item.simboloMoeda, 2) || '--'}</td>
        <TableActionWithRouteAuthorization
          arrow={true}
          feature={PAGAMENTO}
          updateAction={() => {
            const idPagamento =
              item.idPagamentoPrecificacao ?? item.idPrecificacao;
            loadPagamentoForm(idPagamento);
          }}
        />
      </tr>
    ));


  return (
    <div className="container-fluid">
      <div className="p-3">
        {indicadores && (
          <div className="row cards-pgmt mt-3">
            <div className="col-12 col-xl-4 pl-3 pr-xl-2 pr-3">
              <div className="w-100 h-100 position-relative border-content-pgmt rounded-8 p-3 d-flex flex-column flex-lg-row">
                <span className="position-absolute fw-400 title-pgmt px-2">
                  Gestão
                </span>
                {indicadores
                  .filter((ind) => ind.status == "Disponivel")
                  .map((ind) => {
                    return indicadorBase(ind);
                  })}
                {indicadores
                  .filter((ind) => ind.status == "Fechado")
                  .map((ind) => {
                    return indicadorBase(ind, false);
                  })}
              </div>
            </div>
            <div className="col-12 col-xl-5 mt-4 mt-xl-0 px-xl-2 px-3">
              <div className="w-100 h-100 position-relative border-content-pgmt rounded-8 p-3 d-flex flex-column flex-lg-row">
                <span className="position-absolute fw-400 title-pgmt px-2">
                  Operations
                </span>
                {indicadores
                  .filter((ind) => ind.status == "Validado")
                  .map((ind) => {
                    return indicadorBase(ind);
                  })}
                {indicadores
                  .filter((ind) => ind.status == "Lancado")
                  .map((ind) => {
                    return indicadorBase(ind);
                  })}
                {indicadores
                  .filter((ind) => ind.status == "Agendado")
                  .map((ind) => {
                    return indicadorBase(ind, false);
                  })}
              </div>
            </div>
            <div className="col-12 col-xl-3 px-0">
              <div className="w-100 h-100 py-3 pl-xl-2 pl-3 pr-3 d-flex flex-column justify-content-center align-items-center cards-right">
                {indicadores
                  .filter((ind) => ind.status == "Revisado")
                  .map((ind) => {
                    return indicadorOuter(ind);
                  })}
                {indicadores
                  .filter((ind) => ind.status == "Pago")
                  .map((ind) => {
                    return indicadorOuter(ind, false);
                  })}
              </div>
            </div>
          </div>
        )}

        {pagamentoData.totalCount > 0 && (
          <Table
            withoutHeader
            fileExcel={() => exportPagamentosToExcel(filtrosPagamento)}
            classes="shadow-custom rounded-12 col-12 mt-4 mb-3 pd-10 bg-white"
            totalItems={pagamentoData.totalCount}
            pagination={
              <PaginationRB
                pageNumber={pagamentoData.pageIndex - 1}
                itemsPerPage={pagamentoData.pageSize}
                totalItems={pagamentoData.totalCount}
                filter={pagamentoConsult}
                fetchFunction={getPagamentos}
                pageRange={5}
              />
            }
          >
            <TableHeader>{tableHeader}</TableHeader>
            <TableContent>{tableContent}</TableContent>
          </Table>
        )}

        {pagamentoData.totalCount <= 0 && emptyData && (
          <div className="shadow-custom rounded-12 col-12 mt-4 mb-3 py-5 pd-10 bg-white d-flex align-items-center justify-content-center f-24 fw-400 txt-grey">
            <p className="px-3 py-5 mb-0">{emptyData}</p>
          </div>
        )}
      </div>
    </div>
  );
};

formValueSelector("PagamentoForm");

const mapStateToProps = (state) => ({
  pagamentoData: state.pagamentoReducer.pagamentos,
  pagamentoConsult: state.pagamentoReducer.pagamentosConsultCurrent,
  indicadores: state.pagamentoReducer.indicadores,
  messages: state.pagamentoReducer.messages,
  filtrosPagamento: state.pagamentoReducer.filtrosPagamento,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getPagamentos,
      loadPagamentoForm,
      exportPagamentosToExcel,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(PagamentoList);
