import {
  LOAD_FORM_OPCAO,
  LOAD_FILTER_FORM_OPCAO,
  INDICE_SHOW_DELETE_DIALOG,
  CLEAN_LIST_CONTENT,
  SET_TIPO_VALUE,
} from "../../actions/precificacao/OpcaoAction";

const INITIAL_STATE = {
  opcao: {
    lastPage: 0,
    totalCount: 0,
    hasItems: false,
    pageSize: 0,
    pageIndex: 0,
    items: [],
  },
  opcaoFormTypes: {},
  showOpcaoDeleteDialog: {
    showDialog: false,
    idOpcao: 0,
  },
  messages: null,
  isLoading: false,
};

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case INDICE_SHOW_DELETE_DIALOG:
      return {
        ...state,
        showOpcaoDeleteDialog: action.payload,
      };
    case LOAD_FORM_OPCAO:
      return {
        ...state,
        opcaoForm: action.payload,
      };
    case LOAD_FILTER_FORM_OPCAO:
      return {
        ...state,
        opcaoFormTypes: action.payload,
      };
    case CLEAN_LIST_CONTENT:
      return {
        ...state,
        opcao: {
          lastPage: 0,
          totalCount: 0,
          hasItems: false,
          pageSize: 0,
          pageIndex: 0,
          items: [],
        },
        opcaoForm: null,
        opcaoFormTypes: null,
      };
    case SET_TIPO_VALUE:
      return {
        ...state,
        tipo: action.payload,
      };

    default:
      return state;
  }
}
