/* eslint no-useless-escape: 0 */

import { getStringDateTimeTwoField, getDateInvertedFormatedFromString, getHourFormatedFromString } from "../../../../utils/helpers/DateTimeHelper";
import { initialize } from "redux-form";

export function deserializeFieldsAssembleiaFormFilter(filters, page) {
    return {
        idOperacao: (filters.operacao) ? filters.operacao.key : 0,
        status: filters.status,
        dataInicio: filters.dataInicio,
        dataFim: filters.dataFim,
        pagingConfig: {
            pageIndex: page,
            pageSize: 10
        },
    }
}

export function serializeFieldsAssembleia(dadosAssembleia) {
    return {

        ...dadosAssembleia,
        id: (dadosAssembleia && dadosAssembleia.id > 0) ? dadosAssembleia.id : null,
        idOperacao: (dadosAssembleia.operacao) ? dadosAssembleia.operacao.key : -1,
        dataHora: (dadosAssembleia.dataAssembleia) ? getStringDateTimeTwoField(dadosAssembleia.dataAssembleia, dadosAssembleia.hora) : null,
        idAssembleiaOrigem: (dadosAssembleia && dadosAssembleia.idAssembleiaOrigem > 0) ? dadosAssembleia.idAssembleiaOrigem : null,
        convocacao: dadosAssembleia.convocacao,
        quorumInstalacao: (dadosAssembleia && dadosAssembleia.quorumInstalacao) ? dadosAssembleia.quorumInstalacao : null,
        tipoLocalizacao: (dadosAssembleia && dadosAssembleia.tipoLocalizacao) ? dadosAssembleia.tipoLocalizacao : null,
        tipoQuorum: (dadosAssembleia && dadosAssembleia.tipoQuorum) ? dadosAssembleia.tipoQuorum : null,
        porcentagemQuorum: (dadosAssembleia && dadosAssembleia.porcentagemQuorum) ? dadosAssembleia.porcentagemQuorum : null,
        linkAssembleia: (dadosAssembleia && dadosAssembleia.linkAssembleia) ? dadosAssembleia.linkAssembleia : null,

        editalConvovacaoDto: dadosAssembleia.editalConvovacaoDto && {
            ...dadosAssembleia.editalConvovacaoDto[0],
            id: (dadosAssembleia.editalConvovacaoDto[0]) ? dadosAssembleia.editalConvovacaoDto[0].id : null,
            idAssembleia: (dadosAssembleia.editalConvovacaoDto[0]) ? dadosAssembleia.editalConvovacaoDto[0].idAssembleia : null,
            nomeArquivo: (dadosAssembleia.editalConvovacaoDto[0]) ? dadosAssembleia.editalConvovacaoDto[0].name : null,
            arquivo: (dadosAssembleia.editalConvovacaoDto[0]) ? dadosAssembleia.editalConvovacaoDto[0].arquivo : null
        },
        ordemAssembleiaDtoList: dadosAssembleia.ordemAssembleiaDtoList && dadosAssembleia.ordemAssembleiaDtoList.map(ordem => ({
            id: (ordem.id) ? ordem.id : null,
            idAssembleia: (ordem.idAssembleia) ? ordem.idAssembleia : null,
            titulo: ordem.titulo,
            descricao: ordem.descricao,
            quorum: ordem.quorum,
            tipoQuorumOrdem: ordem.tipoQuorumOrdem,
            quorumAprovacao: ordem.quorumAprovacao
        })),
        endereco: dadosAssembleia.endereco && {
            ...dadosAssembleia.endereco,
            estado: (dadosAssembleia.endereco)
                ? (dadosAssembleia.endereco.estado && dadosAssembleia.endereco.estado.key ? dadosAssembleia.endereco.estado.key : dadosAssembleia.endereco.estado) : null
        }
    };
}

export function deserializeFieldsAssembleia(retorno, idOperacao = 0) {

    let assembleia = {};
    let assembleiaRecebida = retorno.assembleia;

    if (assembleiaRecebida) {
        assembleia = {
            ...assembleiaRecebida,
            id: ((assembleiaRecebida && assembleiaRecebida.id > 0) ? assembleiaRecebida.id : null),
            idOperacao: (assembleiaRecebida.operacoes) ? assembleiaRecebida.operacoes.key : -1,
            operacao: (assembleiaRecebida.operacoes) ? assembleiaRecebida.operacoes : null,
            dataAssembleia: getDateInvertedFormatedFromString(assembleiaRecebida.dataHora),
            hora: getHourFormatedFromString(assembleiaRecebida.dataHora),
            editalConvovacaoDto: assembleiaRecebida.editalConvovacaoDto && [
                {
                    id: (assembleiaRecebida.editalConvovacaoDto) ? assembleiaRecebida.editalConvovacaoDto.id : null,
                    idAssembleia: (assembleiaRecebida.editalConvovacaoDto) ? assembleiaRecebida.editalConvovacaoDto.idAssembleia : null,
                    name: (assembleiaRecebida.editalConvovacaoDto) ? assembleiaRecebida.editalConvovacaoDto.nomeArquivo : null,
                    arquivo: (assembleiaRecebida.editalConvovacaoDto) ? assembleiaRecebida.editalConvovacaoDto.arquivo : null
                }
            ],
            listaAnexosInvestidores: assembleiaRecebida.arquivoInvestidor && [
                {
                    name: (assembleiaRecebida.arquivoInvestidor) ? assembleiaRecebida.arquivoInvestidor.name : null,
                    arquivo: (assembleiaRecebida.arquivoInvestidor) ? assembleiaRecebida.arquivoInvestidor.arquivo : null
                }
            ],
            investidoresAssembleiaDtoList: assembleiaRecebida.investidoresAssembleiaDtoList.map(i => {
                return ({
                    ...i,
                    documento: i.documento.length === 11 ?
                        i.documento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4") :
                        i.documento.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5"),
                    cnpjCustodiante: i.cnpjCustodiante.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")
                })
            }),
            ordemAssembleiaDtoList: assembleiaRecebida.ordemAssembleiaDtoList && assembleiaRecebida.ordemAssembleiaDtoList,
            listaInvestidorExpanded: (assembleiaRecebida.investidoresAssembleiaDtoList && assembleiaRecebida.investidoresAssembleiaDtoList.length > 0) ? true : false,
        };
    }

    return initialize("assembleiaIncluirForm", assembleia);
}
