import { getCurrentYearDateString } from "./../..../../../../../utils/helpers/DateTimeHelper";

export function deserializeFieldsDiarioBordoFormFilter(filters, page = 1) {

    const operacao = filters.operacao && filters.operacao.key ? filters.operacao.key : undefined;
    let dataInicio = filters.dataInicio;
    let dataFim = filters.dataFim;

    return {

        operacao: operacao,
        idsDiarioBordoTipoEvento: filters.idsDiarioBordoTipoEvento,
        dataInicio: dataInicio,
        dataFim: dataFim,
        pagingConfig: {
            pageIndex: 1,
            pageSize: page
        }
    }
}

export function deserializeFieldsCadastroDiarioBordoForm(diarioBordo) {
    const dataBase = diarioBordo?.dataBase?.split("-")

    return {
        ...diarioBordo,
        dataBase: dataBase && `${dataBase[0]}-${dataBase[1]}`,
        dataAssembleia: diarioBordo?.dataAssembleia ? diarioBordo?.dataAssembleia.slice(0, 10) : null,
        diarioBordoAnexoList: diarioBordo.diarioBordoAnexoList && diarioBordo.diarioBordoAnexoList.map(file => ({
            idDiarioBordo: file.idDiarioBordo,
            id: file.id,
            name: file.nomeArquivo,
            arquivo: file.arquivo,
        })),
        composicaoTitulo : {
            ordemContrato: diarioBordo.composicaoTitulo && diarioBordo.composicaoTitulo.ordemContrato,
            idDiarioBordo: diarioBordo.composicaoTitulo.idDiarioBordo,
            
            idTipoEspecie: 
                {
                    ...diarioBordo.composicaoTitulo.idTipoEspecie,
                    id: diarioBordo.composicaoTitulo.idTipoEspecie,
                },
                idTipoContrato: 
                {
                    ...diarioBordo.composicaoTitulo.idTipoContrato,
                    id: diarioBordo.composicaoTitulo.idTipoContrato,
                }
        }
    }
}

export function serializeFieldsDiarioBordoFormFilter(eventoDiarioBordo) {
    
    return {
        ...eventoDiarioBordo,
        id: ((eventoDiarioBordo && eventoDiarioBordo.id > 0) ? eventoDiarioBordo.id : 0),
        idOperacao: (eventoDiarioBordo.operacao) ? eventoDiarioBordo.operacao.key: -1,
        dataOcorrencia: eventoDiarioBordo.dataOcorrencia,
        dataBase: eventoDiarioBordo.dataBase,
        titulo: eventoDiarioBordo.titulo,
        descricao: eventoDiarioBordo.descricao,
        link: eventoDiarioBordo.link,
        tipoEvento: eventoDiarioBordo.tipoEvento,
        tipoEventoEspecieAssembeia: eventoDiarioBordo.tipoEspecieAssembleia,
        tipoEventoEspecieComunicado: eventoDiarioBordo.tipoEspecieComunicado,
        tipoEventoAditamentoContrato: eventoDiarioBordo.tipoContratoAditamento,
        ordemContrato: eventoDiarioBordo.ordemContrato,
        diarioBordoAnexoList: eventoDiarioBordo.diarioBordoAnexoList && eventoDiarioBordo.diarioBordoAnexoList.map(file => ({
            idDiarioBordo : file.idDiarioBordo,
            id: file.id,
            nomeArquivo: file.name,
            arquivo: file.arquivo
        })),
        composicaoTitulo : {
            idDiarioBordo: eventoDiarioBordo.composicaoTitulo && eventoDiarioBordo.composicaoTitulo.idDiarioBordo,
            idTipoEspecie: eventoDiarioBordo.composicaoTitulo && (eventoDiarioBordo.composicaoTitulo.idTipoEspecie && eventoDiarioBordo.composicaoTitulo.idTipoEspecie.id),
            idTipoContrato: eventoDiarioBordo.composicaoTitulo && (eventoDiarioBordo.composicaoTitulo.idTipoContrato && eventoDiarioBordo.composicaoTitulo.idTipoContrato.id),
            ordemContrato: eventoDiarioBordo.composicaoTitulo && eventoDiarioBordo.composicaoTitulo.ordemContrato,
        }
    };
}

export function mappingDiarioBordoByYear(diarioBordo) {

    let dictDiarioBordoPorAno = [];
    let dictDiarioBordoPorAnoAuxiliar = [];
    let anoEncontrado = 0;
    let anoAtual = 0;
    let proximoAno = 0;

    let ultimo = [diarioBordo.length - 1];

    diarioBordo.forEach((item, index) => {

        anoAtual = getCurrentYearDateString(item.dataOcorrencia);

        let jaFiltrados = dictDiarioBordoPorAno.filter((ano) => {
            return ano.ano === anoAtual;
          })

        if (jaFiltrados.length === 0) {

            // Busca todos os registros baseados apenas no AnoAtual
            diarioBordo.map((diario, index) => {

                anoEncontrado = getCurrentYearDateString(diario.dataOcorrencia);

                if (anoAtual === anoEncontrado) {
                    dictDiarioBordoPorAnoAuxiliar.push(diario)

                    if (index < ultimo) {
                        proximoAno = getCurrentYearDateString(diarioBordo[index + 1].dataOcorrencia)
                    }
                    else {
                        proximoAno = 0
                    }
                }
            });

            // Setando o array com o ano encontrado e sua lista
            dictDiarioBordoPorAno.push({
                ano: anoAtual,
                lista: dictDiarioBordoPorAnoAuxiliar
            });

            // Zerando o array auxiliar para adicionar outros
            dictDiarioBordoPorAnoAuxiliar = [];
        }

    });

    return dictDiarioBordoPorAno;
}

export function mappingTipoEspecieAssembleiaComunicadoList(dictEspecie, idBusca) {

    let dictTipoEspecieEscolhidoPeloIdBusca = [];

    dictTipoEspecieEscolhidoPeloIdBusca = dictEspecie.filter((tipo) => {
        return tipo.idTipoEvento === idBusca;
      })

      return dictTipoEspecieEscolhidoPeloIdBusca;
}