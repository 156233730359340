import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/* eslint eqeqeq: 0 */

import Table from "../../../../../common/table/Table";
import TableHeader from "../../../../../common/table/TableHeader";
import TableContent from "../../../../../common/table/TableContent";
import TableEmptyData from "../../../../../common/table/TableEmptyData";
import PaginationRB from "../../../../../common/pagination/PaginationRB";
import ConfirmDialog from "../../../../../common/modal/ConfirmDialog";

import { getDateFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";

import {
  getPassivos,
  gerarPrecificacao,
  showPassivoDeleteDialog,
  showPassivoList,
  goToFormPage,
} from "../../../../../../actions/operacao/PassivoAction";

import {
  OPERACAO_PASSIVO,
  GENERATE,
} from "../../../../../../utils/actionsAndFeaturesAuth";

import TableActionWithRouteAuthorization from "../../../../../common/table/TableActionWithRouteAuthorization";

import { authorizationFunction } from "../../../../../../actions/authorization/AuthorizationAction";
import { convertCurrencyNumber } from "../../../../../../utils/helpers/NumberHelper";

const currEnv = localStorage.getItem("currEnv") ?? localStorage.getItem("currEnv");

class PassivoList extends Component {
  getIdsOperacao = () => {
    let idsOperacao = 0;

    if (Array.isArray(this.props.operacoes) && this.props.operacoes.length) {
      idsOperacao = this.props.operacoes.map((o) => o.key);
    }

    return idsOperacao;
  };

  updateData = () => {
    this.props.getPassivos(this.getIdsOperacao());
  };

  componentDidMount() {
    this.updateData();
    // this.setIdsOperacaoFromRouteParams();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.operacoes.length !== this.props.operacoes.length) {
      this.updateData();
    }
  }

  render() {
    const {
      operacoes,
      passivoData,
      messages,
      getPassivos,
      gerarPrecificacao,
      showPassivoDeleteDialog,
      showDeleteDialog,
      goToFormPage,
      authorizationFunction,
      gestora
    } = this.props;

    const idsOperacoes = operacoes.map((o) => o.key);
    const emptyData = messages && messages.infos && messages.infos[0];

    const opHeader = (
      <tr>
        <th className="text-center fw-700">Cód. Opea</th>
        <th className="text-center fw-700">Código B3/Cetip</th>
        <th className="text-center fw-700">Apelido da operação</th>
        <th className="text-center fw-700">Data Emissão</th>
        <th className="text-center fw-700">Data de Vencimento</th>
        <th className="text-center fw-700">Remuneração</th>
        <th className="text-center"></th>
      </tr>
    );

    const coHeader = (
        <tr>
          <th className="text-center fw-700">Fundo</th>
          <th className="text-center fw-700">Classe</th>
          <th className="text-center fw-700">Subclasse</th>
          <th className="text-center fw-700">Emissão</th>
          <th className="text-center fw-700">Código B3/Cetip</th>
          <th className="text-center fw-700">Data 1ª Integralização</th>
          <th className="text-center fw-700">Total Distribuído</th>
          <th className="text-center fw-700">Remuneração</th>
          <th className="text-center"></th>
        </tr>
      );

    const tableHeader = gestora ? coHeader : opHeader;

    const opContent =
      passivoData && passivoData.hasItems ? (
        passivoData.items.map((passivo) => (
          <tr key={passivo.idPassivo}>
            <td className="text-center fw-400">{passivo.CodigoOpea || '--'}</td>
            <td className="text-center fw-400">{passivo.codigoCetipBbb || '--'}</td>
            <td className="text-center fw-400">{passivo.apelidoOperacao || '--'}</td>
            <td className="text-center fw-400">{getDateFormatedFromString(passivo.dataEmissaoSerie) || '--'}</td>
            <td className="text-center fw-400">{getDateFormatedFromString(passivo.dataVencimentoSerie) || '--'}</td>
            <td className="text-center fw-400">{passivo.remuneracao || '--'}</td>
            <TableActionWithRouteAuthorization
              updateAction={() => goToFormPage(true, passivo.idPassivo)}
              viewAction={() => goToFormPage(false, passivo.idPassivo)}
              feature={OPERACAO_PASSIVO}
              gerarPrecificacao={() => showPassivoDeleteDialog(true, passivo.CodigoOpea, passivo.idOperacao)}
            />
          </tr>
        ))
      ) : (
        <TableEmptyData message={emptyData} />
      );
    
    const coContent =
      passivoData && passivoData.hasItems ? (
        passivoData.items.map((passivo) => (
          <tr key={passivo.idPassivo}>
            <td className="text-center fw-400">{passivo.fundo || '--'}</td>
            <td className="text-center fw-400">{passivo.classe || '--'}</td>
            <td className="text-center fw-400">{passivo.subClasse?.value || '--'} {passivo.codigoSubClasse || ''}</td>
            <td className="text-center fw-400">{passivo.emissao || '--'}</td>
            <td className="text-center fw-400">{passivo.codigoCetipBbb || '--'}</td>
            <td className="text-center fw-400">{getDateFormatedFromString(passivo.dataPrimeiraIntegralizacao) || '--'}</td>
            <td className="text-center fw-400">{convertCurrencyNumber(passivo.totalDistribuido, "R$", 2) || '--'}</td>
            <td className="text-center fw-400">{passivo.remuneracao || '--'}</td>
            <TableActionWithRouteAuthorization
              updateAction={() => goToFormPage(true, passivo.idPassivo)}
              viewAction={() => goToFormPage(false, passivo.idPassivo)}
              feature={OPERACAO_PASSIVO}
              gerarPrecificacao={() => showPassivoDeleteDialog(true, passivo.CodigoOpea, passivo.idOperacao)}
            />
          </tr>
        ))
      ) : (
        <TableEmptyData message={emptyData} />
      );
    
    const tableContent = gestora ? coContent : opContent; 

    return (
      <div>
        {passivoData.totalCount > 0 && (
          <div className="d-flex flex-column">
            <div className="mx-2 shadow-custom bg-white rounded-12">
              <p className="page-head__title f-28 mb-0 px-3 mt-4 mb-3">
                {gestora ? 'Cotas' : 'Passivos das Operações'}
              </p>
              <Table
                withoutHeader
                classes="card col-12 shadow-none bg-white py-0 m-0 mb-2 rounded-12"
                totalItems={passivoData.totalCount}
                pagination={
                  <PaginationRB
                    pageNumber={passivoData.pageIndex - 1}
                    itemsPerPage={passivoData.pageSize}
                    totalItems={passivoData.totalCount}
                    filter={idsOperacoes}
                    fetchFunction={getPassivos}
                    pageRange={5}
                  />
                }
              >
                <TableHeader>{tableHeader}</TableHeader>
                <TableContent>{tableContent}</TableContent>
              </Table>
            </div>
          </div>
        )}

        <ConfirmDialog
          title="Aviso"
          contentText={`Deseja realmente gerar Precificação ${gestora ? 'da Cota' : 'do Passivo'} selecionad${gestora ? 'a' : 'o'}?`}
          textButtonCancel="CANCELAR"
          textButtonConfirm="CONFIRMAR"
          showDialog={showDeleteDialog.showDialog}
          onClose={() => showPassivoDeleteDialog(false)}
          onConfirm={() =>
            authorizationFunction(
              { idAcao: GENERATE, idFuncionalidade: OPERACAO_PASSIVO },
              () => gerarPrecificacao(showDeleteDialog.idOperacao)
            )
          }
          type="warning"
          maxWidth="md"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  showDeleteDialog: state.passivoReducer.showPassivoDeleteDialog,
  passivoData: state.passivoReducer.passivos,
  messages: state.passivoReducer.messages,
  operacoes: state.operacaoReducer.selectedOperacoes,
  gestora: currEnv == process.env.REACT_APP_ENV_GEST_ID,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getPassivos,
      gerarPrecificacao,
      showPassivoDeleteDialog,
      showPassivoList,
      authorizationFunction,
      goToFormPage,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(PassivoList)
);
