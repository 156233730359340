import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AtivoForm from './AtivoForm';
import { getAtivo } from '../../../../../actions/ativo/AtivoAction';

class AtivoFormVisualize extends Component {
    componentDidMount() {
        let idAtivo = this.props.match.params.idAtivo;
        this.props.getAtivo(idAtivo);
    }

    render() {
        return (
            <AtivoForm 
                title={"Detalhar - Ativo"} 
                readOnly={true}
                floatButtonVisible={false} />
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getAtivo
}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(AtivoFormVisualize));