/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, formValueSelector, Field } from "redux-form";
import { withRouter } from "react-router-dom";

import logo from "./../../../../assets/img/LOGO_OPEA_NEG_RGB.svg";
import logo2 from "./../../../../assets/img/LOGO_OPEA_POS_RGB.svg";
import Button from "./../../../common/button/Button";

import history from "../../../../components/common/routers/history";

import {
  authenticateCredentials,
  authenticateCredentialsAdAzure,
} from "./../../../../actions/login/LoginAction";
import Message from "./../../../common/messages/Message";

import { signIn } from "./../../../../utils/helpers/AuthorizationAzureADHelper";
import VersionComponent from "../../../../utils/gitVersion/VersionComponent";
import AgenteFiduciarioPageExterna from "../../precificacao/pages/pagamento/AgenteFiduciarioPageExterna";
import LoadingScreen from "../../../common/loading/LoadingScreen";
import { changeLoading } from "../../../../actions/infra/LoadingScreenAction";

const AZURE_CLIENT_APPLICATION_ID =
  process.env.REACT_APP_AD_AZURE_CLIENT_APPLICATION_ID;

class LoginPage extends Component {
  componentDidMount() {
    this.loginAdAzureRB();
  }

  loginAdAzureRB() {
    let localUserAdAzure = localStorage.getItem("userAdAzure");
    let localIdsGroup = localStorage.getItem("idsGroup");

    let userAzure =
      localUserAdAzure != null &&
      localIdsGroup != "null" &&
      localIdsGroup != undefined &&
      localIdsGroup != "undefined"
        ? JSON.parse(localUserAdAzure)
        : null;

    if (userAzure != null) {
      //userAzure.token = localStorage.getItem('msal.idtoken');
      userAzure.token = localStorage.getItem("msal.idtoken")
        ? localStorage.getItem("msal.idtoken")
        : localStorage.getItem(`msal.${AZURE_CLIENT_APPLICATION_ID}.idtoken`);
      userAzure.idsGroup = localStorage.getItem("idsGroup").split(",");

      localStorage.setItem("userAdAzure", null);
      localStorage.setItem("idsGroup", undefined);

      this.props.authenticateCredentialsAdAzure(userAzure);
    }
  }

  extractTokenFromRoute() {
    const route = window.location.pathname;
    const regex = /precificacao\/pagamento\/af\/([^/]+)$/;
    const match = route.match(regex);

    return match ? match[1] : null;
  }

  render() {
    const { authenticateCredentials, email, senha } = this.props;

    const token = localStorage.getItem(process.env.REACT_APP_URL_TOKEN);
    const noToken = token === null || token === "undefined" || token === "null";

    // AF EXTERNO
    const queryToken = new URLSearchParams(window.location.search).get("token");
    const tokenRoute = this.extractTokenFromRoute();

    if (queryToken || tokenRoute) {
      console.log("Query found!");
      localStorage.clear();
      history.push(`/precificacao/pagamento/af/${queryToken || tokenRoute}`);
    }

    return queryToken || tokenRoute ? (
      <AgenteFiduciarioPageExterna />
    ) : noToken ? (
      <div className="login-page">
        <div className="page-header header-filter vh-100 d-flex align-items-center pr-5">
          <div className="container">
            <div className="row column bg-white rounded border border-1 pl-3 pr-4 py-4 mx-5">
                <form className="form col-md-12 col-lg-7 form-content-login d-flex align-items-center flex-column" autoComplete="off">
                  <p className="d-lg-flex d-none f-40 font-weight-bolder text-center welcome m-0 mt-5 mb-2 text-nowrap flex-nowrap">Bem vindo(a)</p>
                  <div className="d-flex d-lg-none flex-column align-items-center justify-content-center pt-3">
                    <img
                      src={logo2}
                      title="Logo Opea"
                      alt="Logo Opea"
                      className="px-3"
                      style={{ objectFit: "cover", width: "80%" }}
                    />
                    <span class="align-self-end fw-500 f-20 lh-1" style={{ color: "#630A37", letterSpacing: '1px', paddingRight: '46px' }}>Portfólio</span>
                  </div>

                  <div className="card-body px-0 off-set-top-50" style={{ width: "60%" }}>
                        <div
                          className="input-group border rounded my-2 bg-dark-grey" style={{ height: "46px" }}
                        >
                          <div className="input-group-prepend">
                            <span  className="input-group-text border-0 bg-transparent">
                              <i className="material-icons f-18">email</i>
                            </span>
                          </div>
                          <Field
                            className="form-control px-1 bg-dark-grey h-100" style={{ transition: "all 0.3s"}}
                            autocomplete="off"
                            name="email"
                            component="input"
                            type="email"
                            placeholder="Email"
                          />
                        </div>
                        <div
                          className="input-group border rounded my-2 bg-dark-grey" style={{ height: "46px" }}
                        >
                          <div className="input-group-prepend">
                            <span  className="input-group-text border-0 bg-transparent">
                              <i className="material-icons f-18">lock_outline</i>
                            </span>
                          </div>
                          <Field
                            className="form-control px-1 bg-dark-grey h-100" style={{ transition: "all 0.3s"}}
                            autocomplete="off"
                            name="senha"
                            component="input"
                            type="password"
                            placeholder="Senha"
                          />
                        </div>
                        
                        {/*  */}

                        <Button
                          action={() =>
                            authenticateCredentials({ email, senha })
                          }
                          buttonStyle={"success rounded"}
                          name={"Entrar"}
                          backgroundButtonColor={true}
                          style={{
                            width: "100%",
                            marginBottom: 0,
                            color: "#FFF1E9",
                            textTransform: "capitalize",
                            fontSize: "16px",
                            outline: "none",
                            boxShadow: "none"
                          }}
                          containerProps={{ style: { width: "100%" } }}
                        />
                  </div>
                  <div className="card-footer border-0 px-0 d-flex flex-column" style={{ width: "60%" }}>
                    <div className="position-relative w-100 d-flex align-items-center flex-row justify-content-center pb-3 pt-0">
                      <div className="position-absolute w-100" style={{ height: '1px', backgroundColor: 'grey' }}></div>
                      <p className="f-18 font-weight-bolder bg-white text-center welcome position-absolute top-0 m-auto px-2">Outras formas de <strong>entrar</strong></p>
                    </div>

                    <div style={{ textAlign: "center" }}>
                      <Button
                        action={async () => {
                          this.props.changeLoading(true);
                          await signIn();
                          this.props.changeLoading(false);
                        }}
                        buttonStyle={"success bg-transparent rounded text-dark w-100 border shadow-none text-capitalize"}
                        name={" Login with "}
                        icon="windows"
                        endStrongName={"Microsoft"}
                        containerProps={{ style: { width: "100%" } }}
                      />
                      <VersionComponent className="card-value-footer-text opacity-50 mt-2" />
                    </div>
                  </div>
                </form>
                <div className="p-bg col rounded flex-column align-items-center justify-content-center d-lg-flex d-none">
                  <img
                    src={logo}
                    title="Logo Opea"
                    alt="Logo Opea"
                    className="px-3"
                    style={{ objectFit: "cover", width: "80%" }}
                  />
                  <span class="align-self-end fw-500 f-20 lh-1" style={{ color: "#fff1e9", letterSpacing: '1px', paddingRight: '46px' }}>Portfólio</span>
                </div>
            </div>
          </div>
        </div>
        <Message />
        <LoadingScreen />
      </div>
    ) : null;
  }
}

LoginPage = reduxForm({ form: "loginForm" })(LoginPage);
const selector = formValueSelector("loginForm");

const mapStateToProps = (state) => ({
  email: selector(state, "email"),
  senha: selector(state, "senha"),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      authenticateCredentials,
      authenticateCredentialsAdAzure,
      changeLoading,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage)
);
