/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
/* no-mixed-operators: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field } from "redux-form";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";

import GenericPickerInput from "../../../../common/input/GenericPickerInput";
import Input from "../../../../common/input/Input";
import Button from "../../../../common/button/Button";

import {
  salvaFiltrosMonitoramento,
  getMonitoramentos,
  loadFilterMonitoramento,
} from "../../../../../actions/monitoramento/MonitoramentoAction";

import { getOperacoes, selectOperacaoNoForm } from "../../../../../actions/operacao/OperacaoAction";
import { getSugestaoPatrimonio } from "../../../../../actions/patrimonio/PatrimonioAction";
import { DropdownList, Multiselect } from "react-widgets";

let MonitoramentoListFilter = (props) => {
  const {
    getMonitoramentos,
    loadFilterMonitoramento,
    salvaFiltrosMonitoramento,
    monitoramentoConsult
  } = props;
  
  const [registroDaSemana, setRegistroDaSemana] = React.useState(true);
  const [operacoes, setOperacoes] = React.useState([]);
  const [idsPatrimoniosSeparados, setIdsPatrimoniosSeparados] = React.useState([]);
  const [dataInicio, setDataInicio] = React.useState("");
  const [dataFim, setDataFim] = React.useState("");
  const [usuarioGestorOperacaoId, setUsuarioGestorOperacaoId] = React.useState(null);
  const [usuarioServicoCreditoId, setUsuarioServicoCreditoId] = React.useState(null);
  const [usuarioResponsavelId, setUsuarioResponsavelId] = React.useState(null);
  const [tipoComplementoId, setTipoComplementoId] = React.useState(null);
  const [tituloComplementoId, setTituloComplementoId] = React.useState(null);
  const [tituloComplementoList, setTituloComplementoList] = React.useState([]);
  const [siglasOperacaoEnum, setSiglasOperacaoEnum] = React.useState([]);
  const [agendaStatusId, setAgendaStatusId] = React.useState(null);
  const [idStatus, setIdStatus] = React.useState([]);

  const [counterStatus, setCounterStatus] = React.useState(0);

  const chooseStatus = (key) => {
    if (idStatus.includes(key)) {
      let newArrIds = idStatus.filter((elemento) => elemento !== key);
      setIdStatus(newArrIds);
    } else {
      setIdStatus([...idStatus, key]);
    }
  };

  const checkStatus = (key) => {
    return idStatus?.some((o) => o == key) ? key : "";
  };

  const selecionarStatus = () => {
    const chips = Array.from(document.querySelectorAll(".monit-sts"));
    let arrStatus = [];

    chips.forEach((el) => {
      if (!el.classList.contains("pe-none")) arrStatus.push(Number(el.dataset.status));
    })

    setIdStatus(arrStatus);
  }

  const cleanStatus = () => {
    let arrStatus = [];
    setIdStatus(arrStatus);
  }

  async function searchFilters() {
    const data = {
      registroDaSemana,
      operacoes,
      idsPatrimoniosSeparados,
      dataInicio,
      dataFim,
      usuarioGestorOperacaoId,
      usuarioServicoCreditoId,
      usuarioResponsavelId,
      tipoComplementoId,
      tituloComplementoId,
      siglasOperacaoEnum,
      agendaStatusId,
      idStatus,
    }

    await getMonitoramentos(data);
    setRegistroDaSemana(false)
  }

  React.useEffect(() => {
    const fetchData = async () => {
      await loadFilterMonitoramento();
      if (!monitoramentoConsult) searchFilters();
    };
  
    fetchData();
  }, []);

  // prettier-ignore
  React.useEffect(() => {
    salvaFiltrosMonitoramento({
      registroDaSemana,
      operacoes,
      idsPatrimoniosSeparados,
      dataInicio,
      dataFim,
      usuarioGestorOperacaoId,
      usuarioServicoCreditoId,
      usuarioResponsavelId,
      tipoComplementoId,
      tituloComplementoId,
      siglasOperacaoEnum,
      agendaStatusId,
      idStatus,
    })

    if (idStatus) {
      const chips = Array.from(document.querySelectorAll(".monit-sts"));
      let countBlocked = 0;

      chips.forEach((el) => {
        if (el.classList.contains("pe-none")) countBlocked++;
      })

      setCounterStatus(4 - countBlocked);
    }

  }, [
    registroDaSemana,
    operacoes,
    idsPatrimoniosSeparados,
    dataInicio,
    dataFim,
    usuarioGestorOperacaoId,
    usuarioServicoCreditoId,
    usuarioResponsavelId,
    tipoComplementoId,
    tituloComplementoId,
    siglasOperacaoEnum,
    agendaStatusId,
    idStatus,
  ]);

  return (
    <div className="container-fluid">
      <Form>
        <FormHeader title={"Monitoramentos"} />
        <FormContent
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
          handleSubmit={getMonitoramentos}
        >
          <div className="row w-100 align-items-start mx-0 mt-2">
            <div className="col-12 col-xl-4 align-self-start mt-1p mb-xl-3 pl-0 pr-0 pr-xl-2">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Patrimônio Separado
                </label>
                <GenericPickerInput
                  loadData={props.getSugestaoPatrimonio}
                  options={props.patrimoniosOptions}
                  activeClassName="inputPgmt"
                  formName={"MonitoramentoForm"}
                  fieldName={"operacao"}
                  isMulti={true}
                  multiControl={true}
                  placeholder={"Pesquisar Patrimônio"}
                  loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                  noOptionsMessage="Nenhum registro encontrado"
                  value={idsPatrimoniosSeparados}
                  selectedOptionForm={(e) => setIdsPatrimoniosSeparados(e)}
                />
              </div>
            </div>

            <div className="col-12 col-xl-4 align-self-start mt-1p mb-xl-3 pl-0 pr-0 pr-xl-2">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Operações
                </label>
                <GenericPickerInput
                  loadData={(term) => props.getOperacoes(term, idsPatrimoniosSeparados)}
                  options={props.options}
                  activeClassName="inputPgmt"
                  formName={"MonitoramentoForm"}
                  fieldName={"operacao"}
                  isMulti={true}
                  multiControl={true}
                  placeholder={"Pesquisar operação ex.:CRI.1.SEC.2"}
                  loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                  noOptionsMessage="Nenhum registro encontrado"
                  value={operacoes}
                  selectedOptionForm={(e) => setOperacoes(e)}
                />
              </div>
            </div>

            <div className="col-12 col-xl-2 align-self-start mt-3 mt-xl-0 pl-0 pr-0 pr-xl-2">
              <Field
                component={Input}
                outerClassName={"my-3"}
                className={"filter-input"}
                inputId="dataInicio"
                type={"date"}
                name="dataInicio"
                label="Data Início"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </div>

            <div className="col-12 col-xl-2 align-self-start mt-3 mt-xl-0 pl-0 pr-0 pr-xl-2">
              <Field
                component={Input}
                outerClassName={"my-3"}
                className={"filter-input"}
                inputId="dataFim"
                type={"date"}
                name="dataFim"
                label="Data Final"
                value={dataFim}
                onChange={(e) => setDataFim(e.target.value)}
              />
            </div>
          </div>

          <div className="row w-100 align-items-start mx-0">
            <div className="col-12 col-xl-4 align-self-start mt-1p mb-xl-3 pl-0 pr-0 pr-xl-2">
              <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                Gestor da Operação
              </label>
              <DropdownList
                value={usuarioGestorOperacaoId || ""}
                onChange={(e) => setUsuarioGestorOperacaoId(e.id ? e : null)}
                valueField="id"
                textField={"nome"}
                messages={{
                  emptyList: "Lista vazia",
                  emptyFilter: "Nenhum registro encontrado",
                }}
                placeholder={"Todos"}
                containerClassName="h-38-imp"
                filter={"contains"}
                data={[
                  { id: null, nome: "Selecione..." },
                  ...props.usuariosGestaoList
                ]}
              />
            </div>

            <div className="col-12 col-xl-4 align-self-start mt-1p mb-xl-3 pl-0 pr-0 pr-xl-2">
              <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                Serviço de Crédito
              </label>
              <DropdownList
                value={usuarioServicoCreditoId || ""}
                onChange={(e) => setUsuarioServicoCreditoId(e.id ? e : null)}
                valueField="id"
                textField={"nome"}
                messages={{
                  emptyList: "Lista vazia",
                  emptyFilter: "Nenhum registro encontrado",
                }}
                placeholder={"Todos"}
                containerClassName="h-38-imp"
                filter={"contains"}
                data={[
                  { id: null, nome: "Selecione..." },
                  ...props.usuariosServicoCreditoList
                ]}
              />
            </div>

            <div className="col-12 col-xl-4 align-self-start mt-1p mb-xl-3 pl-0 pr-0 pr-xl-2">
              <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                Responsável pelo Monitoramento
              </label>
              <DropdownList
                value={usuarioResponsavelId || ""}
                onChange={(e) => setUsuarioResponsavelId(e.id ? e : null)}
                valueField="id"
                textField={"descricao"}
                messages={{
                  emptyList: "Lista vazia",
                  emptyFilter: "Nenhum registro encontrado",
                }}
                placeholder={"Todos"}
                containerClassName="h-38-imp"
                filter={"contains"}
                data={[
                  { id: null, descricao: "Selecione..." },
                  ...props.usuarioResponsavelList
                ]}
              />
            </div>
          </div>

          <div className="row w-100 align-items-start mx-0">
            <div className="col-12 col-xl-3 align-self-start mt-1p mb-xl-3 pl-0 pr-0 pr-xl-2">
              <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                Tipo de Complemento
              </label>
              <DropdownList
                value={tipoComplementoId || ""}
                onChange={(e) => {
                  setTipoComplementoId(e.id ? e : null);
                  setTituloComplementoList(e.id ? e.tituloList : []);
                  setTituloComplementoId(null);
                }}
                valueField="id"
                textField={"descricao"}
                messages={{
                  emptyList: "Lista vazia",
                  emptyFilter: "Nenhum registro encontrado",
                }}
                placeholder={"Todos"}
                containerClassName="h-38-imp"
                filter={"contains"}
                data={[
                  { id: null, descricao: "Selecione..." },
                  ...props.tipoComplementoList
                ]}
              />
            </div>

            <div className="col-12 col-xl-3 align-self-start mt-1p mb-xl-3 pl-0 pr-0 pr-xl-2">
              <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                Título do Complemento
              </label>
              <DropdownList
                value={tituloComplementoId || ""}
                onChange={(e) => setTituloComplementoId(e.id ? e : null)}
                valueField="id"
                textField={"descricao"}
                messages={{
                  emptyList: "Lista vazia",
                  emptyFilter: "Nenhum registro encontrado",
                }}
                placeholder={"Todos"}
                containerClassName="h-38-imp"
                filter={"contains"}
                data={[
                  { id: null, descricao: "Selecione..." },
                  ...tituloComplementoList
                ]}
              />
            </div>


            <div className="col-12 col-xl-3 align-self-start mt-1p mb-xl-3 pl-0 pr-0 pr-xl-2">
              <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                Produto
              </label>
              <Multiselect
                value={siglasOperacaoEnum}
                onChange={(e) => setSiglasOperacaoEnum(e)}
                valueField="key"
                textField={"value"}
                messages={{
                  emptyList: "Lista vazia",
                  emptyFilter: "Nenhum registro encontrado",
                }}
                placeholder={"Todos"}
                className="my-0"
                filter={"contains"}
                data={props.siglasOperacaoList}
              />
            </div>

            <div className="col-12 col-xl-3 align-self-start mt-1p mb-xl-3 pl-0 pr-0 pr-xl-2">
              <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                Etapa
              </label>
              <DropdownList
                value={agendaStatusId || ""}
                onChange={(e) => setAgendaStatusId(e.id ? e : null)}
                valueField="id"
                textField={"descricao"}
                messages={{
                  emptyList: "Lista vazia",
                  emptyFilter: "Nenhum registro encontrado",
                }}
                placeholder={"Todos"}
                containerClassName="h-38-imp"
                filter={"contains"}
                data={[
                  { id: null, descricao: "Selecione..." },
                  ...props.agendaStatusList
                ]}
              />
            </div>
          </div>

          {props.statusEnumListList.length > 0 && (
            <div className="row w-100 align-items-center mx-0 mb-2 mt-2 pl-0 pr-3">
              <span className="d-lg-block d-none col-lg-auto fw-500 f-12 pl-0 mb-1">
                Status
              </span>
              <div className="px-0 col-12 col-lg-11 w-100 d-flex flex-row flex-wrap align-items-center">
                {props.statusEnumListList.map((sts, index) => {
                  return (
                    <div key={`sts-${index}`} className="col-auto mb-1 px-2 tr-status">
                      <div
                        role="button"
                        onClick={() => chooseStatus(sts.id)}
                        className="d-flex flex-row align-items-center td-status prevent-select"
                      >
                        <div data-status={sts.id} className={`monit-sts circle-status sts-monit-${checkStatus(sts.id)} rounded-circle mr-1`}></div>
                        <p className="mb-0 mt-1px">{sts.descricao}</p>
                      </div>  
                    </div>
                  );
                })}

                {idStatus.length < counterStatus && (
                  <span onClick={selecionarStatus} role="button" className="text-underline p-color-lighten mb-xl-1 ml-2">Selecionar todos</span>
                )}
                {idStatus.length == counterStatus && (
                  <span onClick={cleanStatus} role="button" className="text-underline p-color-lighten mb-xl-1 ml-2">Desmarcar todos</span>
                )}
              </div>
            </div>
          )}

          <div className="col-xl-2 col-12 px-0 mt-0">
            <Button
              activeClassName="w-100"
              action={() => searchFilters()}
              classes="p-bg-imp rounded btn-search h-38 mt-3 w-100 d-flex justify-content-center"
              name={"Buscar"}
            />
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

MonitoramentoListFilter = reduxForm({ form: "MonitoramentoForm" })(MonitoramentoListFilter);

const mapStateToProps = (state) => ({
  options: state.operacaoReducer.operacoes,
  patrimoniosOptions: state.patrimonioReducer.patrimoniosOptions,
  statusEnumListList: state.monitoramentoReducer.monitoramentoFormTypesFilter.statusEnumList || [],
  usuariosGestaoList: state.monitoramentoReducer.monitoramentoFormTypesFilter.usuariosGestao || [],
  usuariosServicoCreditoList: state.monitoramentoReducer.monitoramentoFormTypesFilter.usuariosServicoCredito || [],
  usuarioResponsavelList: state.monitoramentoReducer.monitoramentoFormTypesFilter.usuarioResponsavelList || [],
  tipoComplementoList: state.monitoramentoReducer.monitoramentoFormTypesFilter.tipoComplementoList || [],
  siglasOperacaoList: state.monitoramentoReducer.monitoramentoFormTypesFilter.siglasOperacao || [],
  agendaStatusList: state.monitoramentoReducer.monitoramentoFormTypesFilter.monitoramentoAgendaStatusList || [],
  monitoramentoConsult: state.monitoramentoReducer.monitoramentosConsultCurrent,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMonitoramentos,
      loadFilterMonitoramento,
      salvaFiltrosMonitoramento,
      getOperacoes,
      getSugestaoPatrimonio,
      selectOperacaoNoForm,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonitoramentoListFilter);
