import axios from "axios";
import { initialize } from "redux-form";

import { showAllMessages } from "./../../actions/message/ErrorMessagesAPIAction";
import { removeFormat } from "../../utils/helpers/StringHelper";

import history from "../../components/common/routers/history";
import { toastr } from "react-redux-toastr";
import { changeLoading } from "../infra/LoadingScreenAction";

export const SHOW_PRIMEIRO_ACESSO = 'SHOW_PRIMEIRO_ACESSO';
export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';
export const SET_ACCESS_PRIMARY = 'SET_ACCESS_PRIMARY';
export const LOGOUT = 'LOGOUT';

const AUTENTICACAO_API = process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_AUTENTICACAO_API;

export function showPrimeiroAcesso(primeiroAcesso) {
    return  {
        type: SHOW_PRIMEIRO_ACESSO,
        payload: primeiroAcesso
    };
}

export function alterarAmbienteUsuario(params) {
    return (dispatch) => {
        return axios
        .post(`${AUTENTICACAO_API}alterar`, params)
        .then(result => 
            dispatch([
                {
                    type: SET_AUTHENTICATION,
                    payload: result,
                },
                changeLoading(true)
            ])
        );
    }
}

export function authenticateCredentialsAdAzure(credentialsAdAzure) {
    return (dispatch) => {
        return axios
        .post(`${AUTENTICACAO_API}autenticar/adazure`, { ...credentialsAdAzure })
        .then(result => 
            dispatch([
                {
                    type: SET_AUTHENTICATION,
                    payload: result,
                },
            ])
        );
    }
}


export function authenticateCredentials(credentials) {
    credentials.nome = null;

    return (dispatch) => {
        return axios
        .post(`${AUTENTICACAO_API}autenticar`, { ...credentials })
        .then(result => 
            dispatch([
                {
                    type: SET_AUTHENTICATION,
                    payload: result,
                },
                result.content.primeiroAcesso ? history.push(`/primeiroacesso`) : undefined,
                result.content.primeiroAcesso ? 
                    initialize(
                            'loginPrimeiroAcessoForm', 
                            {email: result.content.email, cpf: result.content.cpf, primeiroAcesso: result.content.primeiroAcesso}
                    ) : undefined,
            ])
        );
    }
}

export function savePrimeiroAcesso(newCredentials) {
    newCredentials.nome = null;
    newCredentials.cpf = removeFormat(newCredentials.cpf);

    return (dispatch) => {
        return axios
        .post(`${AUTENTICACAO_API}primeiroacesso`, { ...newCredentials })
        .then(result => 
            dispatch([
                {
                    type: SET_ACCESS_PRIMARY,
                    payload: result,
                },
                showAllMessages(result.messages),
                history.push(`/`),
            ])
        );
    }
}


export function logout() {
    return (dispatch) => {
        return dispatch([
            { type: LOGOUT },
            history.go('/')
        ])
    }
}

export function logoutMessage(message = "Ocorreu um erro, favor realizar o acesso ao sistema novamente") {
    return (dispatch)  => {
        toastr.error("Erro", message, {
            onHideComplete: () => dispatch(logout()),
            onAttentionClick: () => dispatch(logout()),
            attention: true
        });
    }
}