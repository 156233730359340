import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';

import { selectTab } from '../../../../../actions/operacao/tabs/TabsAction';

import AtivoList from './list/AtivoList';
import OperacaoHeader from './../OperacaoHeader';
import { 
    OPERACAO_TABS, 
    ATIVO_TAB
} from './../OperacaoTabs';
import ContainerPage from '../../../../common/container/ContainerPage';

class AtivoPage extends Component {
    componentDidMount() {
        this.props.selectTab(ATIVO_TAB);
    }
    
    render() {
        return (
            <ContainerPage
                tabs={OPERACAO_TABS}
                paramsRoute={''}
                header={<OperacaoHeader />}
            >
                <AtivoList/>
            </ContainerPage>
        );
    }
}

const mapStateToProps = state => ({ 
    tab: state.tab,
    operacoes: state.operacaoReducer.selectedOperacoes, 
});

const mapDispacthToProps = dispatch => bindActionCreators({     
    selectTab,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(AtivoPage));
