import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, arrayInsert, arrayRemove } from "redux-form";
import { bindActionCreators } from "redux";

import If from "../../../../../common/If/If";
import Input from "../../../../../common/input/Input";
import MaskedInput from "../../../../../common/input/MaskedInput";
import DropdownListInput from "../../../../../common/input/DropdownListInput";
import Button from "../../../../../common/button/Button";

class AtivoFormObjetoImovel extends Component {

  componentDidMount() {
    if(this.props.objetoImovelList.length === 0) {
      this.addObjetoImovel(this.props.objetoImovelList.length);
    }
  }
  
  addObjetoImovel(index) {
    if (!this.props.readOnly) {
      this.props.arrayInsert("ativoForm", "ativoObjetoImovelList", index, {});
    }
  }

  removeObjetoImovel(index) {
    if (!this.props.readOnly && this.props.objetoImovelList.length > 0) {
      this.props.arrayRemove("ativoForm", "ativoObjetoImovelList", index);
    }
  }

  
  render() {
    const { readOnly, objetoImovelList, finalidadeList, ativo, moedaDinamica } = this.props;

    let moedaSimbolo = moedaDinamica || ativo?.moeda?.simbolo || "R$";

    return(
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4 mt-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Objeto Imobiliário</span>
            <div className="d-flex flex-column w-100">
              <div className="col more-fields p-0">
                {objetoImovelList 
                && objetoImovelList.map((item, index) => {
                  return (
                    <div key={index} className="more-fields-item">
                        <div className="d-flex">
                          <div className="row w-100">
                              <div className="col-12 col-lg-4">
                                <Field
                                  component={Input}
                                  inputId="matricula"
                                  name={`ativoObjetoImovelList[${index}].matricula`}
                                  type="text"
                                  label="Matrícula"
                                  readOnly={readOnly}
                                />
                              </div>
              
                              <div className="col-12 col-lg-4">
                                <Field
                                  component={Input}
                                  inputId="cartorioRegistro"
                                  name={`ativoObjetoImovelList[${index}].cartorioRegistro`}
                                  type="text"
                                  label="Cartório de Registro de Imóveis"
                                  readOnly={readOnly}
                                />
                              </div>
              
                              <div className="col-12 col-lg-4">
                                <Field
                                  component={Input}
                                  inputId="endereco"
                                  name={`ativoObjetoImovelList[${index}].endereco`}
                                  type="text"
                                  label="Endereço"
                                  readOnly={readOnly}
                                />
                              </div>
              
                              <div className="col-12 col-lg-4">
                                <Field
                                  component={Input}
                                  inputId="ativoObjetoImovelNome"
                                  name={`ativoObjetoImovelList[${index}].nome`}
                                  type="text"
                                  label="Nome"
                                  readOnly={readOnly}
                                />
                              </div>
              
                              <div className="col-12 col-lg-4">
                                <Field
                                  component={MaskedInput}
                                  inputId="areaTotal"
                                  name={`ativoObjetoImovelList[${index}].areaTotal`}
                                  label="Área Total (m²)"
                                  readOnly={readOnly}
                                  //format={() => teste()} TODO: Necessário limitar a quantidade de números de acordo com o banco de dados
                                  thousandSeparator={'.'}
                                  decimalSeparator={","}
                                  decimalScale={2}
                                  fixedDecimalScale
                                />
                              </div>
              
                              <div className="col-12 col-lg-4">
                                <Field
                                  component={MaskedInput}
                                  inputId="areaTotalConstruida"
                                  name={`ativoObjetoImovelList[${index}].areaTotalConstruida`}
                                  label="Área Construída (m²)"
                                  readOnly={readOnly}
                                  //format={() => teste()} TODO: Necessário limitar a quantidade de números de acordo com o banco de dados
                                  thousandSeparator={'.'}
                                  decimalSeparator={","}
                                  decimalScale={2}
                                  fixedDecimalScale
                                />
                              </div>
              
                              <div className="col-12 col-lg-4">
                                <Field
                                  component={MaskedInput}
                                  inputId="areaPrivadaAbl"
                                  name={`ativoObjetoImovelList[${index}].areaPrivadaAbl`}
                                  label="Área Privativa/ABL (m²)"
                                  readOnly={readOnly}
                                  //format={() => teste()} TODO: Necessário limitar a quantidade de números de acordo com o banco de dados
                                  thousandSeparator={'.'}
                                  decimalSeparator={","}
                                  decimalScale={2}
                                  fixedDecimalScale
                                />
                              </div>
              
                              <div className="col-12 col-lg-4">
                                <Field
                                  component={MaskedInput}
                                  inputId="valorAvaliacao"
                                  name={`ativoObjetoImovelList[${index}].valorAvaliacao`}
                                  label="Valor Avaliação"
                                  readOnly={readOnly}
                                  prefix={`${moedaSimbolo} `}
                                  //format={() => teste()} TODO: Necessário limitar a quantidade de números de acordo com o banco de dados
                                  thousandSeparator={'.'}
                                  decimalSeparator={","}
                                  decimalScale={8}
                                  fixedDecimalScale
                                  placeholder={`${moedaSimbolo} 0,00000000`}
                                />
                              </div>
              
                              <div className="col-12 col-lg-4">
                                <Field
                                  component={Input}
                                  inputId="linkImagemObjeto"
                                  name={`ativoObjetoImovelList[${index}].linkImagemObjeto`}
                                  type="text"
                                  label="Imagem do objeto"
                                  readOnly={readOnly}
                                />
                              </div>
              
                              <div className="col-12 col-lg-4">
                                <Field
                                  component={DropdownListInput}
                                  inputId="finalidade"
                                  name={`ativoObjetoImovelList[${index}].finalidade`}
                                  label="Finalidade"
                                  options={finalidadeList}
                                  textField="value"
                                  valueField="key"
                                  readOnly={readOnly}
                                  filter="contains"
                                />
                              </div>

                              <If test={!readOnly}>
                                <div className="col-12 col-lg-2 d-flex align-items-center minus-box-btn">
                                  <Button
                                    classes="rounded bg-white shadow-none border-0 text-danger m-0 mxh-40 p-0 my-2 text-center text-capitalize"
                                    activeClassName={"col-lg-auto p-0"}
                                    buttonStyle={"success"}
                                    action={() => this.removeObjetoImovel(index)}
                                    icon={"trash"}
                                    name={"Remover"}
                                  />
                                </div>
                              </If>
                          </div>
                        </div>
                    </div>
                  );
                })
              }
              </div>
              <If test={!readOnly}>
                <div className="mx-auto mt-3">
                  <Button
                    classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                    activeClassName={"col-lg-auto p-0"}
                    buttonStyle={"success"}
                    action={() => this.addObjetoImovel(objetoImovelList.length)}
                    icon={"plus"}
                    name={"Adicionar"}
                  />
                </div>
              </If>
            </div>
        </div>
      </div>
    );
  }
  
}

const selector = formValueSelector("ativoForm");

const mapDispatchToProps = dispatch =>
  bindActionCreators({ arrayInsert, arrayRemove }, dispatch);

const mapStateToProps = state => ({
  objetoImovelList: selector(state, "ativoObjetoImovelList") || [],
  finalidadeList: state.ativoReducer.ativoFormTypes.finalidadeList,
  ativo: state.ativoReducer.ativoFormTypes.ativo,
  moedaDinamica: state.ativoReducer.moedaDinamica || null,
});

export default connect(mapStateToProps, mapDispatchToProps)(AtivoFormObjetoImovel);
