import React from 'react';
import { Router, Route } from "react-router-dom";

import OperacaoRouter from './../../business/operacao/routes/OperacaoRouter';
import MonitoramentoRouter from './../../business/monitoramentos/routes/MonitoramentoRouter';
import AtivoRouter from './../../business/ativo/routes/AtivoRouter';
import PatrimonioRouter from './../../business/patrimonio/routes/PatrimonioRouter';
import FundoRouter from '../../business/fundo/routes/FundoRouter';
import SubclasseRouter from '../../business/subclasse/routes/SubclasseRouter';
import CarteiraRouter from '../../business/carteira/routes/CarteiraRouter';
import CotaRouter from '../../business/cota/routes/CotaRouter';
import HistoricoCarteiraRouter from '../../business/historicocarteira/routes/HistoricoRouter';
import HistoricoCotasRouter from '../../business/historicocotas/routes/HistoricoRouter';
import PrecificacaoRouter from './../../business/precificacao/routes/PrecificacaoRouter';
import PrestadoraRouter from './../../business/prestadora/routes/PrestadoraRouter';
import UsuarioRouter from './../../business/usuario/routes/UsuarioRouter';
import GerenciamentoJobRouter from './../../business/job/routes/JobRouter';

import LoginPage from './../../business/login/pages/LoginPage';
import LoginPrimeiroAcessoPage from './../../business/login/pages/LoginPrimeiroAcessoPage';

import history from './history';
import ReportRouters from './../../business/relatorios/routes/ReportRouters';
import GestaoRouter from '../../business/gestao/routes/GestaoRouter';
import AprovacaoRouter from '../../business/aprovacao/routes/AprovacaoRouter';
import PedidosRouter from '../../business/pedidos/routes/PedidosRouter';
import AprovadoresRouter from '../../business/aprovadores/routes/AprovadoresRouter';
import ExportacaoRouter from '../../business/exportacao/routes/ExportacaoRouter';
import RemessaRouter from '../../business/remessa/routes/RemessaRouter';

// import { RouteView } from 'rb-core/lib/Components';
import AssembleiaRouter from '../../business/assembleia/routes/AssembleiaRouter';
import AcessoPortalRouter from '../../business/acessoPortal/routes/AcessoPortalRouter';
import { SUBDOMINIO } from './constantesRoutes';

const Routes = () => (
    <Router history={history} basename={SUBDOMINIO}>
        <div className="h-100 overflow-x-hidden" id='topContent'>
            <Route path="/" component={LoginPage} />
            <Route path="/primeiroAcesso" component={LoginPrimeiroAcessoPage} />
            <OperacaoRouter />
            <MonitoramentoRouter />
            <AtivoRouter />
            <PedidosRouter />
            <AprovacaoRouter />
            <AprovadoresRouter />
            <ExportacaoRouter />
            <RemessaRouter />
            <PatrimonioRouter />
            <FundoRouter />
            <SubclasseRouter />
            <CarteiraRouter />
            <CotaRouter />
            <HistoricoCarteiraRouter />
            <HistoricoCotasRouter />
            <GestaoRouter />
            <PrestadoraRouter />
            <UsuarioRouter />
            <PrecificacaoRouter />
            <GerenciamentoJobRouter />
            <ReportRouters />
            <AssembleiaRouter />
            <AcessoPortalRouter />
            {/* <RouteView /> */}
        </div>
    </Router>
);

export default Routes;
